/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { Api, IResponse, Settings } from '@msi/js-sdk';

import { ISettings } from '../../settings/settings.interface';
import { PackageContent } from '../package/classes/package/PackageContent';
import { ILawIncidentRequest, ILawIncidentResponse } from './law-incident.interfaces';

@Injectable()
export class LawIncidentService {
  constructor(
    private _settings: Settings<ISettings>,
    private _api: Api
  ) {}

  async getLawIncidentModel(caseNumber: string): Promise<PackageContent> {
    const platform: any = this._settings.get<any>('PLATFORM');
    const url: string =
      platform.API + this._settings.get<string>('SEARCH_LAW_INCIDENTS_URL');
    const lawIncidentRequest: ILawIncidentRequest = {
      pageSize: 10,
      pageToken: '',
      query: `caseNumber='${caseNumber}'`,
      resolveCodes: true,
      version: 'v2',
    };

    let response: IResponse<ILawIncidentResponse>;

    try {
      response = await this._api.post<ILawIncidentResponse, ILawIncidentRequest>(
        url,
        lawIncidentRequest
      );
    } catch (err) {
      throw err;
    }

    if (response.data.items && response.data.items[0]) {
      return new PackageContent(response.data.items[0]);
    } else {
      throw new Error('Law Incident is not found');
    }
  }
}
