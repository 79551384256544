/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DropdownComponent } from '@msi/cobalt';

import { IPackageActions } from './actions.interface';

@Component({
  selector: 'pp-actions-dropdown',
  templateUrl: './actions-dropdown.component.html',
})
export class ActionsDropdownComponent {
  @ViewChild('dropdown') dropdown!: DropdownComponent;
  @Input()
  actions: IPackageActions;

  @Input()
  isShares: boolean;

  @Input()
  rowId: string;

  @Output()
  auditLog = new EventEmitter<void>();

  @Output()
  deletePackage = new EventEmitter<void>();

  @Output()
  patchPackage = new EventEmitter<void>();

  @Output()
  regeneratePackageLink = new EventEmitter<void>();

  @Output()
  resharePackage = new EventEmitter<void>();

  @Output()
  updatePackage = new EventEmitter<void>();

  @Output()
  copyId = new EventEmitter<void>();
}
