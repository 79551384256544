/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsiCommonHeaderComponentsModule } from '@msi/cobalt';
import { DropdownModule, IconModule } from '@msi/cobalt';
import { CommandCentralHeaderComponentsModule } from '@msi/commandcentral-common-header';
import { CommandCentralShareDataModule } from '@msi/commandcentral-share-data';
import { PPComponentsModule } from '@msi/pp-components';
import { TranslocoModule } from '@ngneat/transloco';

import { AuthComponent } from './auth/auth.component';
import { GuestComponent } from './guest/guest.component';
import { GuestProtectedComponent } from './guest-protected/guest-protected.component';
import { HeaderAuthComponent } from './header/auth/auth.component';
import { HeaderGuestComponent } from './header/guest/guest.component';
import { MainComponent } from './main/main.component';
import { MfCreatePackageComponent } from './mf/create-package/create-package.component';
import { MfSmComponent } from './mf/sm/sm.component';
import { MfSsmComponent } from './mf/ssm/ssm.component';
import { ReviewFilesComponent } from './sandbox-share-data/review-files/review-files.component';
import { ShareDataSandboxComponent } from './sandbox-share-data/sandbox-share-data.component';
import { ShareManagerComponent } from './share-manager/share-manager.component';
import { SingleShareManagerPageComponent } from './single-share-manager/single-share-manager.component';
import { SingleViewComponent } from './single-view/single-view.component';
import { TestComponent } from './test/test.component';

@NgModule({
  declarations: [
    MainComponent,
    TestComponent,
    MfSmComponent,
    MfSsmComponent,
    MfCreatePackageComponent,
    HeaderAuthComponent,
    HeaderGuestComponent,
    AuthComponent,
    GuestComponent,
    GuestProtectedComponent,
    ShareManagerComponent,
    SingleShareManagerPageComponent,
    ShareDataSandboxComponent,
    SingleViewComponent,
    ReviewFilesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    OverlayModule,
    MsiCommonHeaderComponentsModule,
    CommandCentralHeaderComponentsModule,
    PPComponentsModule,
    TranslocoModule,
    DropdownModule,
    IconModule,
    CommandCentralShareDataModule,
  ],
  exports: [
    MainComponent,
    TestComponent,
    HeaderAuthComponent,
    HeaderGuestComponent,
    AuthComponent,
    GuestComponent,
    GuestProtectedComponent,
    ShareManagerComponent,
    SingleShareManagerPageComponent,
    ShareDataSandboxComponent,
    SingleViewComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
