/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { createReducer, on } from '@ngrx/store';

import * as FeatureFlagsActions from '../actions/feature-flags.actions';

export interface IFeatureFlagsState {
  [key: string]: boolean;
}

export const initialFeatureFlagsState: IFeatureFlagsState = {
  'bdp-reindex-enqueueing': false,
  'bdp-reindexing': false,
  'sharing-exclude-directory-asset-type': true,
  'sharing-enable-share-manager': false,
  'sharing-enable-asset-owner-email-notification': false,
};

export const featureFlagsReducer = createReducer(
  initialFeatureFlagsState,
  on(FeatureFlagsActions.setFeatureFlags, (currentState, action) => ({
    ...currentState,
    ...action.flags,
  })),
  on(FeatureFlagsActions.loadFeatureFlagsFailure, () => ({
    ...initialFeatureFlagsState,
  })),
  on(FeatureFlagsActions.clearStore, () => ({
    ...initialFeatureFlagsState,
  }))
);
