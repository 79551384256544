/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Utils } from '@msi/js-sdk';

import { IOfficer, ISimpleOfficer } from './officer.interfaces';

export enum EValidTag {
  VALID = 'primary',
  INVALID = 'alert',
}

export class Officer implements IOfficer {
  private _searchName: string;

  public id: string;
  public officerId: string;
  public email: string;
  public agency: string;
  public agencyName: string;
  public firstName: string;
  public lastName: string;
  public title: string;
  public descr: string;
  public valid: EValidTag;
  public oid: string;
  public user: string;

  constructor(props: IOfficer) {
    this.id = Utils.uniqueId();
    this.officerId = props.officerId || '';
    this.email = props.email;
    this.agency = props.agency;
    this.agencyName = props.agencyName || '';
    this.firstName = props.firstName || '';
    this.lastName = props.lastName || '';
    this.valid = EValidTag.VALID;
    this.oid = props.oid || '';
    this.user = props.user || '';

    this.title = this.getTagName();

    this._searchName =
      this.firstName.toLowerCase() +
      this.lastName.toLowerCase() +
      this.email.toLowerCase();
  }

  includes(query: string): boolean {
    return this._searchName.includes(query);
  }

  setError(error: boolean): void {
    this.valid = error ? EValidTag.INVALID : EValidTag.VALID;
  }

  getFullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  getTagName(): string {
    return this.lastName + ', ' + this.firstName;
  }

  format(formatStr: string = '{{ firstName }} {{ lastName }}'): string {
    return Utils.format(formatStr, this);
  }

  isValid(): boolean {
    return true;
  }

  toString(): string {
    return this.officerId;
  }

  toSimple(): ISimpleOfficer {
    return {
      oid: this.oid,
      user: this.user,
      agency: this.agency,
      email: this.email,
      agencyName: this.agencyName,
    };
  }
}
