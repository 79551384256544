<ng-template
  *ngIf="packageItem"
  #resharePackageTemplate>
  <msi-modal>
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit($event)"
      ><msi-modal-header>{{ title | transloco }}</msi-modal-header>

      <msi-modal-content class="form-contents">
        <div class="row first-line">
          <div class="col-left">
            <label for="package-name">{{ 'Package name/ Reference label' | transloco }}</label>
            <div class="pp-reshare-package-readonly">
              {{ packageItem.name }}
            </div>
          </div>
          <div class="col-right">
            <label for="expiration-date">{{ 'Expires in' | transloco }}</label>
            <div class="pp-reshare-package-readonly">
              {{ packageItem.expireTime }}&nbsp;{{ packageItem.timeIndicator | transloco }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label for="package-name">{{ 'Name' | transloco }}</label>
            <input
              id="package-name"
              class="msi-input"
              autocomplete="off"
              placeholder="{{ 'Enter name' | transloco }}"
              required
              [formControl]="form.controls['name']" />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label for="to">{{ 'To' | transloco }}</label>
            <pp-prosecutors
              id="to"
              [isReshareModal]="true"
              [authMode]="authMode"
              [sentRecipients]="sentRecipients"
              (updateSelectedProsecutors)="onUpdateSelectedProsecutors($event)"></pp-prosecutors>
          </div>
        </div>

        <ng-template [ngIf]="additionalInfo">
          <ng-template
            ngFor
            let-item
            [ngForOf]="additionalInfo"
            let-i="index">
            <div class="row">
              <div class="col">
                <label class="info">{{ 'Subject' | transloco }}</label>
                <div>{{ item.title }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label class="info">{{ 'Body' | transloco }}</label>
                <div>{{ item.body }}</div>
              </div>
            </div>
          </ng-template>
        </ng-template>

        <div
          *ngIf="isUnauthSharingEnabled"
          class="disclaimer-footer">
          <strong>{{ 'Warning' | transloco }}</strong>
          {{
            'By pressing ‘Send’ you are acknowledging that anyone with this link can view the material.'
              | transloco
          }}
        </div>
      </msi-modal-content>

      <msi-modal-actions
        align="end"
        class="hide-overflow-x">
        <button
          type="button"
          class="msi-btn msi-btn-action"
          [attr.disabled]="sending || undefined"
          (click)="onCancel($event)">
          {{ 'Cancel' | transloco }}
        </button>
        <button
          type="submit"
          class="msi-btn spinner-parent"
          [attr.disabled]="sending || form.invalid || undefined">
          <ng-template [ngIf]="sending">{{ 'Sending...' | transloco }}</ng-template>
          <ng-template [ngIf]="!sending">{{ 'Send' | transloco }}</ng-template>
          <msi-spinner
            *ngIf="sending"
            size="small"></msi-spinner>
        </button>
      </msi-modal-actions>
    </form>
  </msi-modal>
</ng-template>
