/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { EventEmitter } from '@msi/js-sdk';
import { Utils } from '@msi/js-sdk';

import { IAuditLogData } from '../../../services/audit-log/audit-log.interfaces';
import { AuditLogService } from '../../../services/audit-log/audit-log.service';
import { EDetailPackageMode } from '../../../services/package/package.enums';
import { FS } from '../../FS';
import { DownloadItemDTO } from '../DownloadItemDTO';
import { EDownloadStatus } from '../enums';
import { DownloadItemsTask } from './tasks/DownloadItemsTask';
import { NewQueueTask } from './tasks/NewQueueTask';

class DownloadAllProcess extends EventEmitter {
  private _queue: DownloadItemDTO[] = [];
  private _downloadItems: DownloadItemDTO[] = [];
  private _auditLog: AuditLogService;
  private _auditLogData: IAuditLogData;
  private _mode: EDetailPackageMode;

  constructor(
    downloadItems: DownloadItemDTO[] = [],
    auditLog: AuditLogService,
    auditLogData: IAuditLogData,
    mode: EDetailPackageMode
  ) {
    super();

    this._downloadItems = [...downloadItems];
    this._auditLog = auditLog;
    this._auditLogData = auditLogData;
    this._mode = mode;
  }

  async run(fs: FS): Promise<void> {
    this._auditLog.log(this._auditLogData, this._mode);
    this._downloadItems.forEach((downloadItemDTO: DownloadItemDTO): void => {
      downloadItemDTO.status = EDownloadStatus.NEW;
      downloadItemDTO.emit(EDownloadStatus.NEW);
      downloadItemDTO.once(EDownloadStatus.REMOVE, () => {
        setTimeout(() => {
          Utils.removeItemFromArray(this._downloadItems, downloadItemDTO);
          Utils.removeItemFromArray(this._queue, downloadItemDTO);
        });
      });
    });

    while (this._downloadItems.length > 0) {
      const pendingDownload = this._downloadItems.filter(
        (item) => item.status !== EDownloadStatus.PENDING
      );
      const activeDownload = this._downloadItems.filter(
        (item) => item.status === EDownloadStatus.PENDING
      );

      this._downloadItems = [...activeDownload, ...pendingDownload];

      const newQueue: DownloadItemDTO[] = new NewQueueTask(
        this._downloadItems,
        this._queue
      ).run();

      if (newQueue.length > 0) {
        Utils.removeFromArray(this._downloadItems, newQueue);

        this._queue.push(...newQueue);

        await new DownloadItemsTask(newQueue, fs).run();

        Utils.removeFromArray(this._queue, newQueue);

        const errors: DownloadItemDTO[] = newQueue.filter(
          (item: DownloadItemDTO): boolean => {
            return (
              item.status === EDownloadStatus.CANCEL ||
              item.status === EDownloadStatus.ERROR
            );
          }
        );

        if (errors.length) {
          this._downloadItems.push(...errors);

          await Utils.wait();
        }
      } else {
        await Utils.wait();
      }
    }
  }
}

export { DownloadAllProcess };
