/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { EAccessProtectionType } from '../../../services/package/classes/accessProtection/AccessProtection.enum';

@Component({
  selector: 'pp-access-method-select',
  templateUrl: './access-method-select.component.html',
  styleUrls: ['./access-method-select.component.scss'],
})
export class AccessMethodSelectComponent {
  @Input() accessMethodControl: FormControl;

  passwordProtectionType = EAccessProtectionType;

  protectionChange(accessMethod: EAccessProtectionType): void {
    this.accessMethodControl.setValue(accessMethod);
  }
}
