/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Api, IResponse, Settings, Utils } from '@msi/js-sdk';

import { ISettings } from '../../settings/settings.interface';
import { AssetEntityService } from '../asset-entity/asset-entity.service';
import { AssetItem } from '../asset-entity/AssetItem';
import { Narrative } from './classes/narrative/Narrative';
import { INarrative } from './classes/narrative/Narrative.interfaces';
import { Organization } from './classes/organization/Organization';
import { IOrganization } from './classes/organization/Organization.interfaces';
import { Overview } from './classes/overview/Overview';
import { Person } from './classes/person/Person';
import { IPerson } from './classes/person/Person.interfaces';
import { Property } from './classes/property/Property';
import { IProperty } from './classes/property/Property.interfaces';
import { Vehicle } from './classes/vehicle/Vehicle';
import { IVehicle } from './classes/vehicle/Vehicle.interfaces';
import { IAdditionalInfo } from './create-package.interfaces';
import { EDetailPackageMode, EPackageAccessType } from './package.enums';
import { IPackage, IPackageBody, IPackageFile } from './package.interfaces';

class GetPackage {
  private _assetEntityServ: AssetEntityService;
  private _settingsGetPackage: Settings<ISettings>;
  private _apiGetPackage: Api;

  constructor(
    settings: Settings<ISettings>,
    api: Api,
    assetEntityServ: AssetEntityService
  ) {
    this._settingsGetPackage = settings;
    this._apiGetPackage = api;
    this._assetEntityServ = assetEntityServ;
  }

  async get(packageId: string, mode: EDetailPackageMode): Promise<IPackage> {
    const domain: string = this._settingsGetPackage.get<string>('PLATFORM.API' as any);

    let url = '';

    if (mode === EDetailPackageMode.GUESTS) {
      url =
        domain +
        this._settingsGetPackage.get<string>('PM_API_PACKAGE_ENDPOINT') +
        '/' +
        packageId;
    } else if (mode === EDetailPackageMode.SHARES) {
      url = Utils.format(
        this._settingsGetPackage.get<string>('PM_API_PACKAGE_PRIVATE_ENDPOINT'),
        { domain, packageId }
      );
    } else {
      throw new Error('GetPackage/get: unknown mode type');
    }

    let response: IResponse<IPackageBody>;

    try {
      response = await this._apiGetPackage.get<IPackageBody>(url);
    } catch (err) {
      throw err;
    }

    try {
      if (mode === EDetailPackageMode.GUESTS) {
        return this.mapPackageDataToObjects(response.data.body);
      } else {
        return this.mapPackageDataToObjects(response.data as any);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Service GetPackage: transforming raw data ->', err);

      // set response to PP_COMPONENTS_INFO object to make debug easily
      (window as any).PP_COMPONENTS_INFO.getPackage = { response };

      throw err;
    }
  }

  async getAdditionalInfoFromPackageForReshare(
    pkg: IPackage
  ): Promise<IAdditionalInfo[]> {
    const title: string =
      pkg.content.caseNumber + (pkg.content.nature ? ' - ' + pkg.content.nature : '');
    const body = `The case package ${title} was shared with you`;

    return [{ title, body }];
  }

  mapFilesToPackageAssets(files: IPackageFile[]): AssetItem[] {
    return files.map((file) => new AssetItem(file));
  }

  async mapPackageDataToObjects(pkg: IPackage): Promise<IPackage> {
    pkg.content.vehicles = pkg.content.vehicles || [];
    pkg.content.persons = pkg.content.persons || [];
    pkg.content.organizations = pkg.content.organizations || [];
    pkg.content.propertyItems = pkg.content.propertyItems || [];
    pkg.content.narratives = pkg.content.narratives || [];
    pkg.content.propertyItems = pkg.content.propertyItems || [];

    pkg.updates = pkg.updates || [];
    pkg.fileUpdates = [];
    pkg.narrativeUpdates = [];
    pkg.personsUpdates = [];
    pkg.organizationsUpdates = [];
    pkg.vehicleUpdates = [];
    pkg.propertyUpdates = [];

    pkg.assetFiles = pkg.files.filter(
      (file: IPackageFile): boolean =>
        (pkg.header.accessType === EPackageAccessType.AUTH || !!file.publicShareId) &&
        file.entityId === pkg.content.caseNumber
    );
    pkg.assetEntitiesLen = pkg.assetFiles.length;

    pkg.overview = new Overview(pkg.content, pkg.header.hasOverview);
    pkg.narratives = pkg.content.narratives.map(
      (item: INarrative): Narrative => new Narrative(item)
    );
    pkg.persons = await Promise.all(
      pkg.content.persons.map(async (item: IPerson): Promise<Person> => {
        item.whenReported = pkg.content.whenReported;

        const person: Person = new Person(item);
        const personId: string | null = person.getId();

        if (personId) {
          for (const file of pkg.files) {
            if (file.entityId === personId) {
              await person.setAssetEntity(
                this._assetEntityServ.getAssetEntity(file.link)
              );

              break;
            }
          }

          if (pkg.content.chainOfCustody && pkg.content.chainOfCustody[personId]) {
            person.setChainOfCustody(pkg.content.chainOfCustody[personId]);
          }
        }

        return person;
      })
    );
    pkg.organizations = await Promise.all(
      pkg.content.organizations.map(
        async (item: IOrganization): Promise<Organization> => {
          const organization: Organization = new Organization(item);
          const organizationId: string | null = organization.getId();

          if (organizationId) {
            for (const file of pkg.files) {
              if (file.entityId === organizationId) {
                await organization.setAssetEntity(
                  this._assetEntityServ.getAssetEntity(file.link)
                );

                break;
              }
            }

            if (
              pkg.content.chainOfCustody &&
              pkg.content.chainOfCustody[organizationId]
            ) {
              organization.setChainOfCustody(pkg.content.chainOfCustody[organizationId]);
            }
          }

          return organization;
        }
      )
    );

    pkg.vehicles = await Promise.all(
      pkg.content.vehicles.map(async (item: IVehicle): Promise<Vehicle> => {
        const vehicle: Vehicle = new Vehicle(item);
        const vehicleId: string | null = vehicle.getIncidentContextId();

        if (vehicleId) {
          for (const file of pkg.files) {
            if (file.entityId === vehicleId) {
              await vehicle.setAssetEntity(
                this._assetEntityServ.getAssetEntity(file.link)
              );

              break;
            }
          }

          if (pkg.content.chainOfCustody && pkg.content.chainOfCustody[vehicleId]) {
            vehicle.setChainOfCustody(pkg.content.chainOfCustody[vehicleId]);
          }
        }

        return vehicle;
      })
    );

    pkg.properties = await Promise.all(
      pkg.content.propertyItems.map(async (item: IProperty): Promise<Property> => {
        const property = new Property(item);
        const propertyId = property.getId();

        if (propertyId) {
          for (const file of pkg.files) {
            if (file.entityId === propertyId) {
              await property.setAssetEntity(
                this._assetEntityServ.getAssetEntity(file.link)
              );

              break;
            }
          }

          if (pkg.content.chainOfCustody && pkg.content.chainOfCustody[propertyId]) {
            property.setChainOfCustody(pkg.content.chainOfCustody[propertyId]);
          }
        }

        return property;
      })
    );

    pkg.assetEntities = await this._assetEntityServ.getAssetEntities(pkg);

    return pkg;
  }
}

export { GetPackage };
