/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { ApmSpan } from './ApmSpan';
import { ApmTransaction } from './ApmTransaction';
import {
  ApmTransactionComponent,
  ApmTransactionMethod,
  register,
} from './elastic-apm.decorators';
import { ESpanTypes, ETransactionTypes } from './elastic-apm.enums';
import { ISpan, ITransaction } from './elastic-apm.interfaces';
import { ElasticApmService } from './elastic-apm.service';

export {
  ApmSpan,
  ApmTransaction,
  ApmTransactionComponent,
  ApmTransactionMethod,
  ElasticApmService,
  ISpan,
  ITransaction,
  register,
  ESpanTypes as spanTypes,
  ETransactionTypes as transactionTypes,
};
