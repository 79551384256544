/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { AssetStorageType } from '@msi/emm-sdk';
import { BehaviorSubject, Observable } from 'rxjs';

import { IShareableAsset } from '../../models/share-data.models';
import { ENotShareableAssetType } from '../../models/share-data.models';

@Injectable({
  providedIn: 'root',
})
export class AssetVerificationService {
  private notShareableAssets = new Map<ENotShareableAssetType, string[]>();
  private notShareableAssetsSubject = new BehaviorSubject(this.notShareableAssets);
  readonly notShareableAssets$: Observable<Map<ENotShareableAssetType, string[]>>;

  constructor() {
    this.notShareableAssets$ = this.notShareableAssetsSubject.asObservable();
  }

  resetAssetVerifications(): void {
    this.notShareableAssets = new Map<ENotShareableAssetType, string[]>();
    this.notShareableAssetsSubject.next(this.notShareableAssets);
  }

  countNotShareableAssets(asset: IShareableAsset): void {
    const assetId = asset.derivedItemId ? asset.derivedItemId : asset.fileId;
    if (
      !this._hasIncompleteAsset(assetId) &&
      this.isIncompleteAsset(asset.assetStorageType)
    ) {
      this._setNotShareableAssets(assetId, ENotShareableAssetType.Incomplete);
    } else if (
      !this._hasDirectoryAsset(assetId) &&
      this.isDirectoryAsset(asset.assetStorageType)
    ) {
      this._setNotShareableAssets(assetId, ENotShareableAssetType.Directory);
    }
  }

  getShareableAssets(assets: IShareableAsset[]): IShareableAsset[] {
    return (assets = assets.filter(
      (asset: IShareableAsset) => !this.notShareableAsset(asset.assetStorageType)
    ));
  }

  isIncompleteAsset(assetStorageType: AssetStorageType): boolean {
    return assetStorageType?.toLowerCase() === AssetStorageType.EXTERNALLINK;
  }

  isDirectoryAsset(assetStorageType: AssetStorageType): boolean {
    return assetStorageType?.toLowerCase() === AssetStorageType.DIRECTORY;
  }

  notShareableAsset(assetStorageType: AssetStorageType): boolean {
    return (
      this.isIncompleteAsset(assetStorageType) || this.isDirectoryAsset(assetStorageType)
    );
  }

  updateNotShareableAssets(asset: IShareableAsset): void {
    const notSharableType =
      asset.assetStorageType.toLocaleLowerCase() === AssetStorageType.EXTERNALLINK
        ? ENotShareableAssetType.Incomplete
        : ENotShareableAssetType.Directory;
    const assetId = asset.derivedItemId ? asset.derivedItemId : asset.fileId;

    const currentIds = this.notShareableAssets.get(notSharableType);
    const updatedIds = currentIds.filter((id: string) => assetId !== id);
    this.notShareableAssets.set(notSharableType, updatedIds);
    this.notShareableAssetsSubject.next(this.notShareableAssets);
  }

  private _setNotShareableAssets(
    assetId: string,
    notSharableType: ENotShareableAssetType
  ): void {
    const currentIncompleteIds = this.notShareableAssets.get(notSharableType) || [];
    const updatedIncompleteIds = [...currentIncompleteIds, assetId];
    this.notShareableAssets.set(notSharableType, updatedIncompleteIds);
    this.notShareableAssetsSubject.next(this.notShareableAssets);
  }

  private _hasIncompleteAsset(id: string): boolean {
    return this.notShareableAssets.get(ENotShareableAssetType.Incomplete)?.includes(id);
  }

  private _hasDirectoryAsset(id: string): boolean {
    return this.notShareableAssets.get(ENotShareableAssetType.Directory)?.includes(id);
  }
}
