/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { createSelector } from '@ngrx/store';

import { IFeatureFlagsState } from '../reducers/feature-flags.reducer';

export const getFeatureFlagsStore = (store) => store.featureFlags as IFeatureFlagsState;

export const getFeatureFlag = (flagName: string) =>
  createSelector(getFeatureFlagsStore, (store) => store[flagName]);
