<div
  *ngIf="packageItem && pkg"
  class="pp-detail-package-header">
  <div class="pp-detail-package-header__print-icon">
    <msi-icon
      [size]="24"
      name="ic_emsignia_dark"></msi-icon>
    <div class="separator dark"></div>
    <h5 class="pp-detail-package-header__title">{{ headerTitle }}</h5>
  </div>

  <div class="pp-row">
    <div class="pp-detail-package-header__left">
      <h2 class="pp-detail-package-header__header">
        {{ packageItem.name }}
      </h2>
      <span
        *ngIf="pkg.header.packageVersion === 'v1'"
        class="pp-detail-package-header__description">
        {{ 'Incident ID:' | transloco }}&nbsp;{{ packageItem.description || '--' }}
      </span>
      <span class="pp-detail-package-header__expired">
        {{ 'Expiration: ' | transloco }}{{ packageItem.expireTime }}&nbsp;{{
          packageItem.timeIndicator | transloco
        }}
      </span>
    </div>
    <div class="pp-detail-package-header__right">
      <pp-detail-package-subscribe
        *ngIf="!isPackageOwnerPD && pkg.header.packageVersion === 'v1'"
        [pkg]="pkg"
        [mode]="mode"></pp-detail-package-subscribe>
      <section *ngIf="assetsLoaded && !isPrintPreviewOn">
        <pp-action-controls
          [data]="packageItem"
          [options]="getActionOptions()"
          (action)="onAction($event)">
        </pp-action-controls>
      </section>
      <ng-container *ngIf="isPrintPreviewOn">
        <button
          type="button"
          class="msi-btn mr-3"
          (click)="onAction([actionControlsTypes.PRINT, undefined, pkg])">
          {{ 'Print' | transloco }}
        </button>
        <button
          type="button"
          class="msi-btn msi-btn-secondary"
          (click)="onAction([actionControlsTypes.CLASSIC_VIEW, undefined, pkg])">
          {{ 'Exit preview' | transloco }}
        </button>
      </ng-container>
    </div>
  </div>
  <div
    class="pp-row"
    *ngIf="pkg.incompletedFiles > 0">
    <div class="banner">
      <div class="banner__body">
        <div class="banner__icon">
          <msi-icon
            [name]="'ic_error'"
            color="white"
            size="18"></msi-icon>
        </div>
        <div class="banner__msg">
          {{ 'Only ' | transloco }}{{ pkg.files.length - pkg.incompletedFiles }}{{ 'of ' | transloco
          }}{{ pkg.files.length }} digital evidence items are shown, please contact sharing agency for
          complete information
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template [ngIf]="pkg && additionalInfo && packageItem">
  <pp-reshare-package
    #reshareComponent
    [packageItem]="packageItem"
    [additionalInfo]="additionalInfo"
    (done)="onReshareDone($event)">
  </pp-reshare-package>
</ng-template>
