/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { forkJoin, map, Observable, of } from 'rxjs';

import enLang from '../../assets/i18n/en-US.json';
import esLang from '../../assets/i18n/es-XL.json';
import frLang from '../../assets/i18n/fr-CA.json';
import plLang from '../../assets/i18n/pl-PL.json';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<Translation> {
    const langKeys = this._getLanguageFile(lang);
    const headers: HttpHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    });

    if (lang.includes('/')) {
      return of(null);
    }

    const base = of(langKeys);
    const vendor = this.http.get(`./assets/i18n-dist/${lang}.vendor.json`, { headers });

    return forkJoin([base, vendor]).pipe(
      map(([base, vendor]) => ({ ...base, ...vendor }))
    );
  }

  private _getLanguageFile(lang: string): object {
    switch (lang) {
      case 'pl-PL':
      case 'pl':
        return plLang;
      case 'fr-CA':
        return frLang;
      case 'es-XL':
        return esLang;
      case 'en-US':
      default:
        return enLang;
    }
  }
}
