<pp-header-auth *ngIf="!guestMode">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</pp-header-auth>

<pp-header-guest *ngIf="guestMode">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</pp-header-guest>

<ng-template #content>
  <button
    (click)="onClick($event)"
    class="create-package-button"
    >Create Share Package</button
  >
  <button (click)="onModifyClick($event)">Update Share Package</button>
  <button (click)="onUpdateContentClick($event)">Update Share Package Content</button>
  <button
    (click)="onFilterPkgWithAsset()"
    class="filter-pkg-button"
    >Redirect to Sharing with Asset Filter</button
  >

  <section class="authenticate-package">
    <strong>Generate password protected Url</strong>
    <label for="package-id">Input valid unauth package id:</label>
    <input
      id="package-id"
      type="text"
      class="authenticate-package__input"
      [(ngModel)]="unauthPkgId" />
    <label for="agency-id">Input package creator agency (for v2 package only):</label>
    <input
      id="agency-id"
      type="text"
      class="authenticate-package__input"
      [(ngModel)]="unauthAgencyId" />
    <button
      (click)="redirectToProtectedPackage()"
      [disabled]="!unauthPkgId"
      >Redirect to protected package</button
    >
  </section>

  <div style="width: 200px; margin: 20px; border: 1px solid var(--pp-primary-font-color)">
    <pp-more-details
      [details]="[
        'asdfasdfzdsdfasfasdfaasdfasdfasdf',
        'asdfasdf',
        'adfasdfaf',
        'zxcvzxvc',
        'zxvczfeg'
      ]"></pp-more-details>
  </div>

  <pp-create-package
    #createSharePackage
    [files]="files"
    [content]="pkgContent"
    [fullContent]="fullContent"
    [filesLoading]="filesLoading"
    [additionalInfo]="additionalInfo"></pp-create-package>
  <pp-modify-package #modifySharePackage></pp-modify-package>
  <pp-update-package-content
    #updateSharePackageContent
    [lawIncidentData]="lawIncidentData"
    [filesSharingInfo]="files">
  </pp-update-package-content>
</ng-template>
