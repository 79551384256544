/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function specialCharacterValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const valid =
      /[!@#$%^&*~()_+\-=\[\]{};`':"\\|,.<>\/?]/.test(control.value) || !control.value;

    return valid ? null : { specialCharacter: { value: control.value } };
  };
}
