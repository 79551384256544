<section class="search-packages">
  <ng-container *ngIf="assetFilterChip">
    <div
      class="asset-filter-chip"
      msiTooltip="{{ assetFilterChip.value }}">
      <span>{{ assetFilterChip.value }}</span>
      <msi-icon
        name="ic_remove"
        color="white"
        [size]="12"
        (click)="onTagRemove()"></msi-icon>
    </div>
  </ng-container>
  <input
    #input
    class="msi-input pp-packages-search mr-3"
    type="text"
    value="{{ query }}"
    placeholder="{{ 'Find packages' | transloco }}"
    (keyup)="debounceQuery($event)" />
</section>
