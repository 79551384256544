/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Pipe, PipeTransform } from '@angular/core';

import { IShareableAsset } from '../../models/share-data.models';
import { ShareDataService } from '../../services/share-data/share-data.service';

@Pipe({
  name: 'activeAsset',
})
export class ActiveAssetPipe implements PipeTransform {
  constructor(private _shareDataService: ShareDataService) {}

  transform(asset: IShareableAsset, activeAsset: IShareableAsset): boolean {
    return this._shareDataService.isActiveAsset(asset, activeAsset);
  }
}
