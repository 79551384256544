/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

import { getFeatureFlag } from '../../../projects/pp-components/src/lib/components/+store/selectors/feature-flags.selector';

@Injectable()
export class BetaViewGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store.select(getFeatureFlag('enabled-beta-view')).pipe(
      filter((values) => values !== undefined),
      tap((value) => {
        if (!value) {
          this.router.navigateByUrl('/', { replaceUrl: true });
        }
      })
    );
  }
}
