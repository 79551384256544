/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OidcSecurityService } from '@msi/angular-auth-oidc-client';
import {
  AppSpecificConfigToken,
  ConfirmAuthGuardService,
} from '@msi/commandcentral-common-header';

import { ComponentsModule } from './components/components.module';
import { appSpecificConfig } from './config/app-specific.config';
import { BetaViewGuard } from './guards/beta-view.guard';
import { GuardService } from './guards/guard.service';
import { AuthHeadersInterceptor } from './interceptors/auth-headers.interceptor';
import { RoutingModule } from './routing.module';
import { PackageService } from './services/package/package.service';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, ComponentsModule, RoutingModule],
  providers: [
    PackageService,
    GuardService,
    ConfirmAuthGuardService,
    OidcSecurityService,
    BetaViewGuard,
    {
      provide: AppSpecificConfigToken,
      useValue: appSpecificConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeadersInterceptor,
      multi: true,
    },
  ],
  exports: [ComponentsModule],
})
export class SharedModule {}
