/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Pipe, PipeTransform } from '@angular/core';

import { IShareableAsset } from '../../models/share-data.models';
import { ShareDataUtils } from '../../utils/share-data.utils';

@Pipe({
  name: 'evidIconName',
})
export class EvidIconNamePipe implements PipeTransform {
  transform(asset: IShareableAsset): string {
    return ShareDataUtils.getIconNameByItem(asset);
  }
}
