/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { EventEmitter } from '@msi/js-sdk';
import { Api } from '@msi/js-sdk';
import { IResponse } from '@msi/js-sdk';
import { ReplayStatuses, Utils } from '@msi/js-sdk';

import { FS } from '../../../FS';
import { DownloadItemDTO } from '../../DownloadItemDTO';
import { EDownloadStatus } from '../../enums';
import { IDownloadContent } from '../../interfaces';
import { SaveFileTask } from './SaveFile';

type TResponse = IResponse<ReadableStreamDefaultReader<Uint8Array>> | IDownloadContent;

class FetchFileTask extends EventEmitter {
  private _downloadItemDTO: DownloadItemDTO;
  private _api: Api = new Api();
  private _maxStep = 2;
  private _step = 0;
  private _fs: FS;

  constructor(downloadItemDTO: DownloadItemDTO, fs: FS) {
    super();

    this._downloadItemDTO = downloadItemDTO;
    this._fs = fs;
  }

  private _setPercentage(): void {
    this._downloadItemDTO.percentage = 1;
    this._downloadItemDTO.emit('percentage', 1);
  }

  private async _repeat(options: RequestInit): Promise<TResponse> {
    if (this._downloadItemDTO.handlerGetContent) {
      return await this._downloadItemDTO.getContent(options);
    } else {
      await this._downloadItemDTO.refreshLink();

      let link: string;

      if (typeof this._downloadItemDTO.link === 'function') {
        link = await this._downloadItemDTO.link();
      } else {
        link = this._downloadItemDTO.link;
      }

      return await this._api.download(link, options);
    }
  }

  private async _condition(err: Error | null): Promise<ReplayStatuses> {
    if (this._step >= this._maxStep) {
      throw new Error(
        `FetchFileTask: Max attempts to download file for "${this._downloadItemDTO.link}"`
      );
    }

    if (err) {
      if (err.name && err.name === 'AbortError') {
        const err: Error = new Error('FetchFileTask: download was canceled');

        this._downloadItemDTO.error = err;
        throw err;
      }
      this._step++;

      return ReplayStatuses.DELAY;
    }
    return ReplayStatuses.PROCEED;
  }

  async _run(resolve: any, reject: any): Promise<void> {
    const abortController = new AbortController();
    const cbCancel = () => {
      console.error('FetchFileTask: callback cancel was called', this._downloadItemDTO);
      abortController.abort();
    };
    const options: RequestInit = {
      signal: abortController.signal,
      keepalive: true,
    };

    this._downloadItemDTO.once(EDownloadStatus.CANCEL, async () => {
      abortController.abort();
      await this._fs.removeEntry(this._downloadItemDTO.name, this._downloadItemDTO.path);
      console.error('FetchFileTask: download was canceled', this._downloadItemDTO);
      reject(error);
    });

    this._downloadItemDTO.once(EDownloadStatus.ERROR, async () => {
      abortController.abort();
      await this._fs.removeEntry(this._downloadItemDTO.name, this._downloadItemDTO.path);
      console.error('FetchFileTask: download error', this._downloadItemDTO);
      reject(error);
    });

    const repeat: any = async (): Promise<TResponse> => await this._repeat(options);
    const condition: any = async (err: Error) => await this._condition(err);

    let response: TResponse | null = null;
    let error: Error | unknown;

    try {
      response = await Utils.repeat(repeat, condition);
    } catch (err) {
      error = err;
    }

    if (error) {
      reject(error);

      return;
    } else {
      this._setPercentage();

      try {
        if (response) {
          await new SaveFileTask(this._downloadItemDTO, this._fs).run(
            response.data,
            true
          );
        } else {
          throw new Error('FetchFileTask: response is null');
        }
      } catch (err) {
        reject(err);

        return;
      }
    }

    this._downloadItemDTO.off(EDownloadStatus.CANCEL, cbCancel);
    this._downloadItemDTO.off(EDownloadStatus.ERROR, cbCancel);

    resolve();
  }

  run(): Promise<void> {
    const result: Promise<void> = new Promise((resolve, reject) =>
      this._run(resolve, reject)
    );

    return result;
  }
}

export { FetchFileTask };
