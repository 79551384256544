/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { DerivedItemType } from '@msi/emm-sdk';
import { BehaviorSubject, Observable } from 'rxjs';

import { IShareableAsset } from '../../models/share-data.models';
import { EAssetType } from '../../models/share-data.models';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private packageContent = new Map<string, IShareableAsset[]>();
  private packageContentsSubject = new BehaviorSubject(this.packageContent);
  readonly packageContents$: Observable<Map<string, IShareableAsset[]>>;

  constructor() {
    this.packageContents$ = this.packageContentsSubject.asObservable();
  }

  calculateContent(assets: IShareableAsset[]): void {
    assets.forEach((asset: IShareableAsset) => {
      const assetType = asset.derivedItemType
        ? this.getDerivedItemType(asset)
        : this.getAssetType(asset);
      const currentAssets = this.packageContent.get(assetType) || [];
      const updatedAssets = currentAssets.includes(asset)
        ? currentAssets
        : [...currentAssets, asset];
      this.packageContent.set(assetType, updatedAssets);
      this.packageContentsSubject.next(this.packageContent);
    });
  }

  resetPackageContent(): void {
    this.packageContent = new Map<string, IShareableAsset[]>();
    this.packageContentsSubject.next(this.packageContent);
  }

  updatePackageContents(asset: IShareableAsset): void {
    const assetId = asset.derivedItemId ? asset.derivedItemId : asset.fileId;
    const assetType = asset.derivedItemType
      ? this.getDerivedItemType(asset)
      : this.getAssetType(asset);
    const currentAssets = this.packageContent.get(assetType) || [];
    const updatedAssets = currentAssets.filter((currentAsset: IShareableAsset) =>
      currentAsset.derivedItemId
        ? currentAsset.derivedItemId !== assetId
        : currentAsset.fileId !== assetId
    );
    this.packageContent.set(assetType, updatedAssets);
    this.packageContentsSubject.next(this.packageContent);
  }

  getDerivedItemType(asset: IShareableAsset): EAssetType {
    switch (asset.derivedItemType) {
      case DerivedItemType.EXPORTED_REDACTION:
        return EAssetType.Redaction;
      case DerivedItemType.CLIP:
        return EAssetType.Clip;
      default:
        return EAssetType.Snapshot;
    }
  }

  getAssetType(asset: IShareableAsset): EAssetType {
    if (asset.mimeType.includes('video')) {
      return EAssetType.Video;
    } else if (asset.mimeType.includes('audio')) {
      return EAssetType.Audio;
    } else if (asset.mimeType.includes('image')) {
      return EAssetType.Image;
    } else if (asset.mimeType.includes('pdf')) {
      return EAssetType.Pdf;
    } else {
      return EAssetType.File;
    }
  }
}
