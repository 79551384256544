<div>
  <msi-icon
    name="ic_more"
    class="icon-trigger"
    [size]="18"
    [msiDropdownTriggerFor]="dropdown"></msi-icon>
  <msi-dropdown
    #dropdown
    [backdrop]="false"
    yPosition="below"
    xPosition="before">
    <msi-dropdown-item
      *ngIf="actions.auditLog"
      (click)="auditLog.emit()"
      >{{ 'View audit log' | transloco }}</msi-dropdown-item
    >
    <msi-dropdown-item
      *ngIf="actions.deletePackage"
      (click)="deletePackage.emit()"
      >{{ 'Delete package' | transloco }}</msi-dropdown-item
    >
    <msi-dropdown-item
      *ngIf="actions.patchPackage"
      (click)="patchPackage.emit()"
      >{{ 'Modify package' | transloco }}</msi-dropdown-item
    >
    <msi-dropdown-item
      *ngIf="actions.regeneratePackageLink"
      (click)="regeneratePackageLink.emit()"
      >{{ 'Renew unauthenticated link' | transloco }}</msi-dropdown-item
    >
    <msi-dropdown-item
      *ngIf="actions.resharePackage"
      (click)="resharePackage.emit()"
      >{{ 'Share package' | transloco }}</msi-dropdown-item
    >
    <msi-dropdown-item
      *ngIf="actions.updatePackage"
      (click)="updatePackage.emit()"
      >{{ 'Send updates' | transloco }}</msi-dropdown-item
    >
    <msi-dropdown-item
      *ngIf="isShares && actions.auditLog"
      (click)="copyId.emit()"
      >{{ 'Copy package ID' | transloco }}</msi-dropdown-item
    >
  </msi-dropdown>
</div>
