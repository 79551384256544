<pp-error-block
  *ngIf="!loading && statusCode !== 200"
  [statusCode]="statusCode"></pp-error-block>
<!-- <pp-error-block *ngIf="!loading && statusCode !== 200 && sharePermissions" [statusCode]="statusCode"></pp-error-block> -->

<div
  *ngIf="!isError"
  class="pp-packages">
  <!-- <ng-container *ngIf="sharePermissions?.viewShares"> -->
  <pp-packages-header></pp-packages-header>
  <div class="pp-packages-row">
    <pp-packages-search (changed)="onSearch($event)"></pp-packages-search>
    <pp-filter-packages
      (changed)="onShare($event)"
      [current]="share">
    </pp-filter-packages>
  </div>
  <div class="pp-packages-content">
    <pp-packages-table
      [statusCode]="statusCode"
      [packages]="packages"
      [loading]="loading"
      [readAuditLogsPermission]="readAuditLogsPermission"
      (loadMore)="onLoadMore()"
      (sort)="onSort($event)"
      (reload)="onReload($event)"></pp-packages-table>
  </div>
</div>
