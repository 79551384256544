/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { settings } from '../../config/Settings';
import { IFeatureFlagsResponse } from '../../models/feature-flag.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  constructor(private _http: HttpClient) {}

  public getFeatureFlags(): Observable<IFeatureFlagsResponse> {
    const url = settings.getUrl('FEATURE_FLAGS');
    return this._http.get<IFeatureFlagsResponse>(url);
  }
}
