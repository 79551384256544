/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function alphanumericValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const valid = (/[a-zA-Z0-9]/.test(control.value) && control.value) || !control.value;

    return valid ? null : { alphanumeric: { value: control.value } };
  };
}
