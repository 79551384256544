/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { EPackageAccessType } from '../../../services/package/package.enums';

@Component({
  selector: 'pp-more-details',
  templateUrl: './more-details.component.html',
  styleUrls: ['./more-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreDetailsComponent implements OnInit, AfterViewInit {
  @Input()
  details: string[] = [];

  @Input()
  accessType: EPackageAccessType;

  @Input()
  columnRef: ElementRef;

  isAuthenticated = false;
  hiddenTags = [];
  visibleTags = [];

  private _padding = 6;
  private _border = 1;
  private _margin = 5;
  private _maxTagWidth = 180 + 2 * (this._border + this._margin + this._padding);
  private _spacePlaceholderForTagMore = 50;
  private _minimalColumnWidth = 280;
  private _font = '700 0.75rem Sans-serif';

  constructor(private _change: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isAuthenticated = this.accessType === 'accountLogin';
    this.hiddenTags = this.details;
    this.updateView();
  }

  ngAfterViewInit(): void {
    this.updateView();
  }

  @HostListener('window:resize')
  updateView(): void {
    this.calculateVisibleTags();
    this._change.markForCheck();
  }

  private calculateVisibleTags(): void {
    this.visibleTags = [];
    this.hiddenTags = [];
    let availableWidth =
      this.columnRef?.nativeElement.offsetWidth || this._minimalColumnWidth;

    let firstDetailThatCannotFit = true;
    this.details?.forEach((detail) => {
      const tagWidth = this.getTextWidth(detail, this._font);
      if (
        firstDetailThatCannotFit ||
        availableWidth - tagWidth > this._spacePlaceholderForTagMore
      ) {
        availableWidth -= tagWidth;
        firstDetailThatCannotFit = false;
        this.visibleTags.push(detail);
      } else {
        this.hiddenTags.push(detail);
      }
    });
  }

  private getTextWidth(text: string, font: string): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;

    return Math.min(
      this._maxTagWidth,
      context.measureText(text).width + 2 * (this._padding + this._border + this._margin)
    );
  }
}
