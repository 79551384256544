<msi-select
  [(ngModel)]="value"
  (ngModelChange)="onChange($event)"
  class="pp-expires-select">
  <ng-container
    *ngIf="pkgAction === createAction"
    class="pp-expires-option">
    <msi-select-option
      *ngFor="let item of createExpires"
      [value]="item.id">
      {{ item.name | transloco }}
    </msi-select-option>
  </ng-container>
  <ng-container
    *ngIf="pkgAction === modifyAction"
    class="pp-expires-option">
    <msi-select-option
      *ngFor="let item of modifyExpires"
      [value]="item.id">
      {{ item.name | transloco }}
    </msi-select-option>
  </ng-container>
</msi-select>
