/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Connect, Store } from '@msi/js-sdk';

import { ISidebarItem } from '../../services/sidebar/sidebar.interfaces';
import { IStore } from './store.interface';

class GlobalStore extends Store<IStore> implements IStore {
  topSidebarItems: ISidebarItem[];
  bottomSidebarItems: ISidebarItem[];
  isUnauthSharingEnabled: boolean;
  sending: boolean;
  lang: string;
  loadingOfficers: boolean;
  displayElasticApmLog: boolean;
}

const store = new GlobalStore({
  topSidebarItems: [],
  bottomSidebarItems: [],
  isUnauthSharingEnabled: false,
  sending: false,
  lang: 'en-US',
  loadingOfficers: false,
  displayElasticApmLog: false,
});

Connect.registerGlobalStore(store);

export { GlobalStore, IStore, store };
