/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastService } from '@msi/cobalt';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';

import { IShareableAsset } from '../../models/share-data.models';
import { EAssetType } from '../../models/share-data.models';
import { AssetVerificationService } from '../asset-verification/asset-verification.service';
import { AssetsService } from '../assets-items/assets.service';

@Injectable({
  providedIn: 'root',
})
export class ShareDataService {
  private reviewFilesSubject = new BehaviorSubject<boolean>(true);
  private createPackageFormSubject = new BehaviorSubject<FormGroup | null>(null);
  private activeAssetSubject = new BehaviorSubject<IShareableAsset | null>(null);
  private assetsAddedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private fullscreenSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private assetsLoadingSubject = new BehaviorSubject(true);

  readonly reviewFilesActive$: Observable<boolean>;
  readonly createPackageForm$: Observable<FormGroup | null>;
  readonly activeAsset$: Observable<IShareableAsset | null>;
  readonly assetsAdded$: Observable<boolean>;
  readonly fullscreen$: Observable<boolean>;
  readonly assetsLoading$: Observable<boolean>;
  assetsMap = new Map<string, IShareableAsset>();

  constructor(
    private toastService: ToastService,
    private transloco: TranslocoService,
    private assetsService: AssetsService,
    private assetVerificationService: AssetVerificationService
  ) {
    this.reviewFilesActive$ = this.reviewFilesSubject.asObservable();
    this.activeAsset$ = this.activeAssetSubject.asObservable();
    this.createPackageForm$ = this.createPackageFormSubject.asObservable();
    this.assetsAdded$ = this.assetsAddedSubject.asObservable();
    this.fullscreen$ = this.fullscreenSubject.asObservable();
    this.assetsLoading$ = this.assetsLoadingSubject.asObservable();
  }

  displayReviewFilesTab(active: boolean): void {
    this.reviewFilesSubject.next(active);
  }

  setActiveAsset(asset: IShareableAsset): void {
    this.activeAssetSubject.next(asset);
  }

  setAssets(assets: IShareableAsset[]): void {
    this.assetsLoadingSubject.next(true);
    assets.forEach((asset) => {
      this.assetVerificationService.countNotShareableAssets(asset);
      this.assetsMap.set(asset.derivedItemId ? asset.derivedItemId : asset.fileId, asset);
    });
    this.assetsAddedSubject.next(true);
    this.assetsService.calculateContent(assets);
    this.toastService.success(
      this.transloco.translate('ccs.Added files to share folder'),
      undefined,
      { autoDismiss: 3000, closeButton: false }
    );
    this.assetsLoadingSubject.next(false);
  }

  resetActiveAsset(): void {
    this.activeAssetSubject.next(null);
  }

  setPackageForm(form: FormGroup): void {
    this.createPackageFormSubject.next(form);
  }

  isActiveAsset(asset: IShareableAsset, activeAsset: IShareableAsset): boolean {
    return asset?.derivedItemId
      ? asset?.derivedItemId === activeAsset?.derivedItemId
      : asset?.fileId === activeAsset?.fileId;
  }

  getAssetType(asset: IShareableAsset): EAssetType {
    return asset.derivedItemType ? this._getDerivedItemType(asset) : this._getType(asset);
  }

  getCreatePackageFormValue(): FormGroup | null {
    return this.createPackageFormSubject.getValue();
  }

  resetCreatePackageForm(): void {
    this.createPackageFormSubject.next(null);
  }

  removeAsset(asset: IShareableAsset): void {
    this.assetsMap.delete(asset.derivedItemId ? asset.derivedItemId : asset.fileId);
    this.assetsAddedSubject.next(false);
    this.assetsService.updatePackageContents(asset);
  }

  isEmpty(): boolean {
    return !this.assetsMap.size;
  }

  toggleFullscreen(fullscreenEnabled: boolean): void {
    this.fullscreenSubject.next(fullscreenEnabled);
  }

  private _getDerivedItemType(asset: IShareableAsset): EAssetType {
    return this.assetsService.getDerivedItemType(asset);
  }

  private _getType(asset: IShareableAsset): EAssetType {
    return this.assetsService.getAssetType(asset);
  }
}
