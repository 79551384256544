/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component } from '@angular/core';

@Component({
  selector: 'app-single-view',
  templateUrl: './single-view.component.html',
})
export class SingleViewComponent {}
