/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { DateTimeRange, QuickSelectionDateRange } from '@msi/cobalt';
import { NgbDate, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import dayjs, { Dayjs } from 'dayjs';
import { BehaviorSubject, Observable } from 'rxjs';

import { ESelectedDateOptionCode } from '../../models/share-data.models';

@Injectable({
  providedIn: 'root',
})
export class DatePickerService {
  private currentDateValueSubject = new BehaviorSubject<string>('');
  private _inputDateTouchedState = false;
  readonly currentDateValue$: Observable<string>;

  public get inputDateTouchedState() {
    return this._inputDateTouchedState;
  }
  public set inputDateTouchedState(value) {
    this._inputDateTouchedState = value;
  }

  constructor(private _transloco: TranslocoService) {
    this.currentDateValue$ = this.currentDateValueSubject.asObservable();
  }

  formatValue(
    dateTimeRange: DateTimeRange | null,
    shortestRetentionTime: string
  ): string {
    if (!dateTimeRange?.startDate && !dateTimeRange?.endDate) {
      return '';
    }
    const endDate: string | null = dateTimeRange.endDate
      ? this.getFullDateString(dateTimeRange.endDate, dateTimeRange.endTime)
      : ESelectedDateOptionCode.Never;

    const formattedValue =
      endDate === ESelectedDateOptionCode.Never
        ? ESelectedDateOptionCode.Never
        : dayjs(endDate).toISOString();

    if (dayjs(endDate).isSame(dayjs(shortestRetentionTime), 'day')) {
      this.currentDateValueSubject.next(shortestRetentionTime);
      return shortestRetentionTime;
    } else {
      this.currentDateValueSubject.next(formattedValue);
      return formattedValue;
    }
  }

  formatNeverDate(): string {
    return dayjs('2999-12-31T23:59:59.000Z').toISOString();
  }

  getCapturedDate(): QuickSelectionDateRange[] {
    return [
      {
        text: this._transloco.translate(
          `ccs.${this.getSelectedDateOptionLabel(ESelectedDateOptionCode.ExpiresIn30Days)}`
        ),
        code: ESelectedDateOptionCode.ExpiresIn30Days,
        dateFrom: (today: Dayjs) => today.add(30, 'day').startOf('day'),
        dateTo: (today: Dayjs) => today.add(30, 'day').endOf('day'),
      },
      {
        text: this._transloco.translate(
          `ccs.${this.getSelectedDateOptionLabel(ESelectedDateOptionCode.ExpiresIn90Days)}`
        ),
        code: ESelectedDateOptionCode.ExpiresIn90Days,
        dateFrom: (today: Dayjs) => today.add(90, 'day').startOf('day'),
        dateTo: (today: Dayjs) => today.add(90, 'day').endOf('day'),
      },
      {
        text: this._transloco.translate(
          `ccs.${this.getSelectedDateOptionLabel(ESelectedDateOptionCode.ExpiresIn120Days)}`
        ),
        code: ESelectedDateOptionCode.ExpiresIn120Days,
        dateFrom: (today: Dayjs) => today.add(120, 'day').startOf('day'),
        dateTo: (today: Dayjs) => today.add(120, 'day').endOf('day'),
      },
      {
        text: this._transloco.translate(
          `ccs.${this.getSelectedDateOptionLabel(ESelectedDateOptionCode.ExpiresIn365Days)}`
        ),
        code: ESelectedDateOptionCode.ExpiresIn365Days,
        dateFrom: (today: Dayjs) => today.add(365, 'day').startOf('day'),
        dateTo: (today: Dayjs) => today.add(365, 'day').endOf('day'),
      },
      {
        text: this._transloco.translate(
          `ccs.${this.getSelectedDateOptionLabel(ESelectedDateOptionCode.Never)}`
        ),
        code: ESelectedDateOptionCode.Never,
        dateFrom: (today: Dayjs) => today.year(0).startOf('year'),
      },
    ];
  }

  getSelectedDateOptionLabel(option: ESelectedDateOptionCode): string {
    switch (option) {
      case ESelectedDateOptionCode.ExpiresIn30Days:
        return '30 days';
      case ESelectedDateOptionCode.ExpiresIn90Days:
        return '90 days';
      case ESelectedDateOptionCode.ExpiresIn120Days:
        return '120 days';
      case ESelectedDateOptionCode.Never:
        return 'Never';
      default:
        return option;
    }
  }

  getStartDate(): NgbDate {
    const date = dayjs().toDate();

    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  getEndDate(retentionTime?: string): NgbDate {
    const aYearFromToday = dayjs().add(1, 'year');
    const retentionDate = dayjs(retentionTime);
    if (retentionTime && retentionDate.isBefore(aYearFromToday)) {
      return new NgbDate(
        retentionDate.year(),
        retentionDate.month() + 1,
        retentionDate.date()
      );
    }

    return new NgbDate(
      aYearFromToday.year(),
      aYearFromToday.month() + 1,
      aYearFromToday.date()
    );
  }

  resetSelectedDateValue(): void {
    setTimeout(() => {
      this.currentDateValueSubject.next('');
      this.inputDateTouchedState = false;
    }, 300);
  }

  setCurrentDateValue(value: string): void {
    this.currentDateValueSubject.next(value);
  }

  private getFullDateString(date: NgbDate, time: NgbTimeStruct): string {
    const full = { ...date, ...time };

    return dayjs(
      `${full.year}/${full.month}/${full.day}T${full.hour}:${full.minute}:${full.second}`
    ).toISOString();
  }
}
