/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { TAlert } from '../types';
import { IAlert } from './Alert.interfaces';

class Alert {
  public id = '';
  public level: number = null;
  public alert = '';
  public type: TAlert = 'record';
  public startDate = '';
  public reviewDate = '';
  public endDate = '';
  public additionalInformation = '';

  constructor(props: IAlert) {
    this.id = props.id || '';
    this.level = props.level || null;
    this.alert = props.alert || '';
    this.type = props.type || 'record';
    this.startDate = props.startDate || '';
    this.reviewDate = props.reviewDate || '';
    this.endDate = props.endDate || '';
    this.additionalInformation = props.additionalInformation || '';
  }
}

export { Alert };
