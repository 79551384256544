<div class="pp-asset-entity-tile">
  <msi-card
    *ngIf="assetEntity"
    [media-card]="true"
    (click)="onClick($event)"
    [disable]="!assetsLoaded"
    [class.loading]="!assetsLoaded">
    <msi-card-title>
      <span class="pp-asset-entity-tile__card-title">{{ tileTitle }}</span>
    </msi-card-title>
    <msi-card-image [full-bleed]="true">
      <ng-template [ngIf]="thumb">
        <img
          [src]="thumb"
          alt="image {{ assetEntity.name }}"
          (error)="onImageError()" />
      </ng-template>
      <ng-template [ngIf]="!thumb">
        <msi-icon
          [name]="assetEntity.iconName"
          [size]="40"
          class="pp-asset-entity-tile__default-icon"></msi-icon>
      </ng-template>

      <div
        *ngIf="assetsLoaded"
        class="msi-card-overlay">
        <div class="msi-card-overlay-icons">
          <msi-icon [name]="assetEntity.iconName"></msi-icon>
        </div>
        <span>{{ assetEntity.derivedItemType ? '' : assetEntity.humanSize }}</span>
        <div
          class="msi-card-overlay-hover"
          (click)="onClick($event)"
          >{{ 'Click to see preview' | transloco }}</div
        >
      </div>

      <div
        *ngIf="!assetsLoaded"
        class="msi-card-overlay">
        <span class="asset-loading">{{ 'Loading...' | transloco }}</span>
        <div class="msi-card-overlay-hover">{{ 'Loading...' | transloco }}</div>
      </div>
    </msi-card-image>
    <msi-card-content>
      <div class="pp-asset-entity-tile__card-owner">
        {{ tileOwner }}
      </div>
      <div>{{ displayAssetStartTime }}</div>
    </msi-card-content>
  </msi-card>
</div>
