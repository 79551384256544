/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ccs-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
})
export class NoContentComponent {
  @Input()
  fullScreenEnabled: boolean;

  @Input()
  assetsLoading = true;
}
