/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AssetEntity } from '@msi/emm-sdk';

@Component({
  selector: 'pp-detail-package-asset-gallery-view',
  templateUrl: './detail-package-asset-gallery-view.component.html',
  styleUrls: ['./detail-package-asset-gallery-view.component.scss'],
})
export class DetailPackageAssetGalleryViewComponent implements OnChanges {
  @Input()
  assetEntities: AssetEntity[];

  @Input()
  groupedAssets: Map<string, AssetEntity[]>;

  @Input()
  collapsedAssetsSection: Map<string, boolean>;

  @Input()
  printOptionEnabled: boolean;

  @Output()
  tileClick: EventEmitter<AssetEntity> = new EventEmitter();

  @Output()
  toggleSection: EventEmitter<string> = new EventEmitter();

  displayGroups = false;

  ngOnChanges(): void {
    this.displayGroups = this.groupedAssets?.keys().next().value !== 'noGroupingSelected';
  }

  toggleCollapedSection(key: string): void {
    this.toggleSection.emit(key);
  }

  setCollapsedSection(key: string): boolean {
    return this.printOptionEnabled ? false : this.collapsedAssetsSection?.get(key);
  }
}
