/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient } from '@angular/common/http';
import { Api, IResponse, Settings, Utils } from '@msi/js-sdk';
import { cloneDeep } from 'lodash-es';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { ISettings } from '../../settings/settings.interface';
import { AssetEntityService } from '../asset-entity/asset-entity.service';
import { IListBody } from '../filter/filter.interfaces';
import { FilterService } from '../filter/filter.service';
import { PackageItem } from './classes/package/PackageItem';
import { GetPackage } from './GetPackage';
import { EBdpSearchUrl } from './package.enums';
import {
  IBdpPackagesBody,
  IPackage,
  IPackageCalculationRequest,
  IPackageCalculationResponse,
} from './package.interfaces';

interface IPackageItems {
  continuation: string | null;
  packages: PackageItem[];
}

interface IBdpPackageItems {
  searchId: string;
  packages: PackageItem[];
}

class ListPackage extends GetPackage {
  private _settingsListPackage: Settings<ISettings>;
  private _apiListPackage: Api;

  constructor(
    settings: Settings<ISettings>,
    api: Api,
    assetEntityServ: AssetEntityService,
    private _filterService: FilterService,
    protected http: HttpClient
  ) {
    super(settings, api, assetEntityServ);

    this._settingsListPackage = settings;
    this._apiListPackage = api;
  }

  private async calculateUpdatesSeparated(packages: PackageItem[]): Promise<void> {
    const fullContentPackages: PackageItem[] = packages.filter(
      (item) => item.fullContent !== false
    );
    const totalNumberOfPackages: number = fullContentPackages.length;

    if (totalNumberOfPackages > 0) {
      const numberOfParts = totalNumberOfPackages / 10;
      const minimumCount: number =
        totalNumberOfPackages >= 10 ? 10 : totalNumberOfPackages;

      for (let j = 0; j < numberOfParts; j++) {
        const beginNumber: number = j * 10;
        const endNumber: number = beginNumber + minimumCount;

        const subList: PackageItem[] = [];
        for (let i = beginNumber; i < endNumber; i++) {
          subList.push(fullContentPackages[i]);
        }
        await this.calculateUpdates(subList);
      }
    }
  }

  async calculateUpdates(packageItems: PackageItem[]): Promise<void> {
    const copyOfPackageItems = cloneDeep(packageItems);
    const itemIds: string[] = copyOfPackageItems
      .filter((packageItem: PackageItem): boolean => !!packageItem)
      .map((packageItem: PackageItem): string => packageItem.id);
    const domain: string = this._settingsListPackage.get<string>('PLATFORM.API' as any);
    const url: string =
      domain +
      this._settingsListPackage.get<string>('CALCULATE_PACKAGE_CHANGES') +
      '/' +
      'calculate-updates';

    const body: IPackageCalculationRequest = {
      items: itemIds,
    };

    let response: IResponse<IPackageCalculationResponse>;

    try {
      response = await this._apiListPackage.post<
        IPackageCalculationResponse,
        Record<string, any>
      >(url, body);
    } catch (err) {
      return;
    }

    response.data.packageIds.forEach((id: string): void => {
      const pkg: PackageItem = copyOfPackageItems.find(
        (item: PackageItem) => item.id === id
      );

      if (pkg) {
        pkg.needsUpdate = true;
      }
    });
  }

  public bdpSearchHttpClient(
    options: IListBody = {},
    type: EBdpSearchUrl
  ): Observable<IPackageItems> {
    const pageSize: number = this._settingsListPackage.get<number>('PAGE_SIZE');
    const domain: string = this._settingsListPackage.get<string>('PLATFORM.API' as any);
    const url: string = Utils.format(this._settingsListPackage.get<string>(type), {
      domain,
    });
    const body: Record<string, any> = this._filterService.getBdpListBody(options);
    const pageNumber: number = body.pageNumber || 0;
    let totalCount = 0;
    let continuation: string | null = null;

    return this.http.post<IBdpPackagesBody>(url, body).pipe(
      tap((response) => {
        totalCount = response.totalCount;
        if (
          response.searchId &&
          response.items.length > 0 &&
          Utils.isMoreData(pageSize, pageNumber, totalCount)
        ) {
          continuation = response.searchId;
        }
      }),
      map((response) => {
        const packages = response.items.map(
          (item: IPackage): PackageItem => new PackageItem(item, options.query)
        );

        if (type === EBdpSearchUrl.PUBLISHERS) {
          this.calculateUpdatesSeparated(packages);
        }

        return {
          continuation,
          packages,
        };
      })
    );
  }

  async bdpSearch(options: IListBody = {}, type: EBdpSearchUrl): Promise<IPackageItems> {
    const pageSize: number = this._settingsListPackage.get<number>('PAGE_SIZE');
    const domain: string = this._settingsListPackage.get<string>('PLATFORM.API' as any);
    const url: string = Utils.format(this._settingsListPackage.get<string>(type), {
      domain,
    });
    const body: Record<string, any> = this._filterService.getBdpListBody(options);
    const pageNumber: number = body.pageNumber || 0;
    let response: IResponse<IBdpPackagesBody>;

    try {
      response = await this._apiListPackage.post<IBdpPackagesBody, Record<string, any>>(
        url,
        body
      );
    } catch (err) {
      throw err;
    }

    let continuation: string | null = null;

    const totalCount: number = response.data.totalCount || 0;

    if (
      response.data.searchId &&
      response.data.items.length > 0 &&
      Utils.isMoreData(pageSize, pageNumber, totalCount)
    ) {
      continuation = response.data.searchId;
    }

    const packages: PackageItem[] = response.data.items.map(
      (item: IPackage): PackageItem => new PackageItem(item, options.query)
    );

    if (type === EBdpSearchUrl.PUBLISHERS) {
      this.calculateUpdatesSeparated(packages);
    }
    return { packages, continuation };
  }
}

export { IBdpPackageItems, IPackageItems, ListPackage };
