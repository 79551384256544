/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AutocompleteModule,
  ButtonGroupModule,
  CalendarModule,
  CalloutModule,
  CheckboxModule,
  ChipsModule,
  DropdownModule,
  IconModule,
  InputModule,
  PopUpModalModule,
  SpinnerModule,
  TagModule,
  TooltipModule,
} from '@msi/cobalt';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';

import { AssetsListViewComponent } from './components/assets-list-view/assets-list-view.component';
import { AssetsWarningsComponent } from './components/assets-warnings/assets-warnings.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { PasswordCreatorComponent } from './components/password-creator/password-creator.component';
import { RecipientsListComponent } from './components/recipients-list/recipients-list.component';
import { ReviewFilesDynamicComponent } from './components/review-files-dynamic/review-files-dynamic.component';
import { FullScreenModalContentComponent } from './components/share-data/full-screen-modal-content/full-screen-modal-content.component';
import { ReviewFilesComponent } from './components/share-data/full-screen-modal-content/review-files/review-files.component';
import { SharePackageSetupComponent } from './components/share-data/full-screen-modal-content/share-package-setup/share-package-setup.component';
import { SidePanelComponent } from './components/share-data/full-screen-modal-content/side-panel/side-panel.component';
import { ModalContentComponent } from './components/share-data/modal-content/modal-content.component';
import { NoContentComponent } from './components/share-data/no-content/no-content.component';
import { ShareDataComponent } from './components/share-data/share-data.component';
import { ccsInfo } from './info';
import { AuthHeadersInterceptor } from './interceptors/auth-headers.interceptor';
import { ActiveAssetPipe } from './pipes/active-asset/active-asset.pipe';
import { AssetDisplayNamePipe } from './pipes/asset-display-name/asset-display-name.pipe';
import { AssetTypePipe } from './pipes/asset-type/asset-type.pipe';
import { EvidIconNamePipe } from './pipes/evid-icon-name/evid-icon-name.pipe';
import { NotSharableAssetPipe } from './pipes/not-sharable-asset/not-sharable-asset.pipe';
import { OwnerDisplayNamePipe } from './pipes/owner-display-name/owner-display-name.pipe';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).CCS_INFO = ccsInfo;

@NgModule({
  declarations: [
    ShareDataComponent,
    ModalContentComponent,
    FullScreenModalContentComponent,
    EvidIconNamePipe,
    SidePanelComponent,
    ReviewFilesComponent,
    SharePackageSetupComponent,
    DatePickerComponent,
    AssetsListViewComponent,
    ActiveAssetPipe,
    NoContentComponent,
    AssetTypePipe,
    RecipientsListComponent,
    NotSharableAssetPipe,
    AssetsWarningsComponent,
    AssetDisplayNamePipe,
    OwnerDisplayNamePipe,
    ReviewFilesDynamicComponent,
    PasswordCreatorComponent,
  ],
  imports: [
    CommonModule,
    PopUpModalModule,
    IconModule,
    ButtonGroupModule,
    ReactiveFormsModule,
    InputModule,
    CalendarModule,
    TooltipModule,
    ChipsModule,
    TagModule,
    TranslocoModule,
    AutocompleteModule,
    FormsModule,
    SpinnerModule,
    CheckboxModule,
    DropdownModule,
    CalloutModule,
  ],
  exports: [ShareDataComponent, ReviewFilesDynamicComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeadersInterceptor,
      multi: true,
    },
    DatePipe,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'ccs',
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommandCentralShareDataModule {}
