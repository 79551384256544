/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { EventEmitter } from '@msi/js-sdk';
import { ReplayStatuses, Utils } from '@msi/js-sdk';

import { DownloadItemDTO } from '../../DownloadItemDTO';

class ExpireLinkTask extends EventEmitter {
  private _downloadItemDTO: DownloadItemDTO;
  private _maxStep = 2;

  constructor(downloadItemDTO: DownloadItemDTO) {
    super();

    this._downloadItemDTO = downloadItemDTO;
  }

  async run(): Promise<void> {
    if (this._downloadItemDTO.isExpired()) {
      const repeat: any = async () => await this._downloadItemDTO.refreshLink();

      let step = 0;

      const condition: any = async (err: Error | null): Promise<ReplayStatuses> => {
        if (step >= this._maxStep) {
          throw new Error(
            `ExpireLinkTask: Cannot refresh link for parentId = "${this._downloadItemDTO.parentId}"`
          );
        }

        if (err) {
          step++;

          return ReplayStatuses.DELAY;
        }

        if (this._downloadItemDTO.isExpired()) {
          throw new Error(
            `ExpireLinkTask: link is expired for parentId = "${this._downloadItemDTO.parentId}"`
          );
        } else {
          return ReplayStatuses.PROCEED;
        }
      };

      await Utils.repeat(repeat, condition);
    }
  }
}

export { ExpireLinkTask };
