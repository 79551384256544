/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Settings } from '@msi/js-sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of, tap } from 'rxjs';

import { IFeatureFlagsResponse } from '../../models/feature-flags/feature-flags.model';
import { ISettings } from '../../settings/settings.interface';

@UntilDestroy()
@Injectable()
export class FeatureFlagsService {
  private _flags: IFeatureFlagsResponse;

  public get flags(): IFeatureFlagsResponse {
    return this._flags;
  }
  public set flags(value: IFeatureFlagsResponse) {
    this._flags = value;
  }

  constructor(
    private _settings: Settings<ISettings>,
    private _http: HttpClient
  ) {}

  public getFeatureFlags(): Observable<IFeatureFlagsResponse> {
    if (this.flags) {
      return of(this.flags);
    }

    const domain = this._settings.get<string>('PLATFORM.API' as any);
    const path =
      this._settings.get<string>('PM_API_FEATURE_FLAGS_ENDPOINT') ||
      '/package-manager/v1/configuration/flags';
    return this._http.get<IFeatureFlagsResponse>(`${domain}${path}`).pipe(
      untilDestroyed(this),
      tap((flags) => (this.flags = flags))
    );
  }
}
