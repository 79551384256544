/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Settings } from '@msi/js-sdk';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ISettings } from '../../settings/settings.interface';
import { EPackageVersionType } from '../package/package.enums';
import { IProtectedPackageResponse } from '../package/package.interfaces';

@Injectable({
  providedIn: 'root',
})
export class PasswordValidatorService {
  readonly authenticated$: Observable<boolean>;
  private _packageId: string;
  private authenticatedSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private _settings: Settings<ISettings>
  ) {
    this.authenticated$ = this.authenticatedSubject.asObservable();
  }

  public get packageId(): string {
    return this._packageId;
  }

  public set packageId(value: string) {
    this._packageId = value;
  }

  validatePassword(
    email: string,
    password: string,
    packageId: string,
    agencyId: string,
    packageVersion: EPackageVersionType
  ): Observable<IProtectedPackageResponse> {
    const domain: string = this._settings.get<string>('PLATFORM.API' as any);
    const url: string =
      domain + this._settings.get<string>('PROTECTED_PACKAGE_AUTHORIZATION_URL');

    const body = new FormData();
    body.append('grant_type', 'password');
    body.append('username', email);
    body.append('password', password);
    body.append('package_agency_id', agencyId);
    body.append('package_public_id', packageId);
    body.append('package_version', packageVersion);

    return this.http.post<IProtectedPackageResponse>(url, body).pipe(
      tap((response) => {
        if (response?.access_token) {
          // based on OPENID_CONFIG structure
          sessionStorage.setItem(
            `PROTECTED_CONFIG_${packageId}`,
            JSON.stringify({
              access_token_expires_at: dayjs().add(response.expires_in, 's').valueOf(),
              authResult: response,
            })
          );
          this.authenticatedSubject.next(true);
        }
      })
    );
  }
}
