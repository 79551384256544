<div
  *ngIf="pkg.narratives.length"
  class="pp-detail-package-narrative">
  <h2
    id="pp-detail-package-narrative"
    class="pp-detail-package-narrative__header">
    {{ 'Narratives ' | transloco }}({{ pkg.narratives.length }})
  </h2>
  <ng-template
    ngFor
    let-item
    [ngForOf]="pkg.narratives"
    let-i="index">
    <ng-container *ngTemplateOutlet="childItem; context: { $implicit: item }"></ng-container>
  </ng-template>
</div>

<ng-template
  #childItem
  let-item>
  <div
    *ngIf="item.titleType"
    class="pp-detail-package-narrative__title"
    >{{ item.titleType | transloco }}<span *ngIf="item.titleType">: </span>{{ item.title }}</div
  >
  <div
    *ngIf="item.created"
    class="pp-detail-package-narrative__subtitle"
    >{{ item.created }}</div
  >
  <div
    *ngIf="item.body"
    class="pp-detail-package-narrative__body"
    [innerHTML]="item.body"></div>
</ng-template>
