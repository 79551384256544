/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AssetEntity } from '@msi/emm-sdk';
import { DateTimeUtils } from '@msi/js-sdk';

import { ChainOfCustody } from '../chainOfCustody/ChainOfCustody';
import { IChainOfCustody } from '../chainOfCustody/ChainOfCustody.interfaces';
import { IProperty } from './Property.interfaces';

class Property {
  private _props: IProperty = {};
  private _defaultThumb = 'assets/icons/ic_property.svg';

  public thumb = '';
  public isDefaultThumb = true;
  public hasChainOfCustody = false;

  public brand = '';
  public model = '';
  public serialNumber = '';
  public qty = '';
  public tag = '';
  public ownerApplied = '';
  public totalValue = '';
  public itemStatus = '';
  public valueRecovered = '';
  public accumulativeValueRecovered = '';
  public dateRecovered = '';

  public urcStatus = '';
  public urcStatusDate = '';
  public localStatus = '';
  public localStatusDate = '';
  public agency = '';

  public chainOfCustody: ChainOfCustody[] = [];

  constructor(props: IProperty) {
    this._props = props;

    this.thumb = this._defaultThumb;
    this.brand = this._props.brand || '';
    this.model = this._props.model || '';
    this.serialNumber = this._props.serialNumber || '';
    this.qty = this._props.quantity?.toString() || '';
    this.tag = this._props.tagNumber || '';
    this.ownerApplied = this._props.ownerAppliedNumber || '';
    this.totalValue = this._props.value || '';
    this.itemStatus = this._props.inventoryStatus || '';
    this.valueRecovered = this._props.valueRecovered || '';
    this.accumulativeValueRecovered = this._props.accumulativeValueRecovered || '';
    this.dateRecovered = this._props.dateRecovered || '';

    this.urcStatus = this._props.status || '';
    this.urcStatusDate = this.getUrcStatusDate();
    this.localStatus = this._props.localStatus || '';
    this.localStatusDate = this.getLocalStatusDate();
    this.agency = this._props.agency || '';
  }

  get(name: keyof IProperty): IProperty[keyof IProperty] {
    return this._props[name];
  }

  getId(): string {
    return this._props.id || '';
  }

  getUrcStatusDate(): string {
    if (this._props.whenStatusDeclared) {
      return DateTimeUtils.format(this._props.whenStatusDeclared);
    } else {
      return '';
    }
  }

  getLocalStatusDate() {
    if (this._props.whenLocalStatusDeclared) {
      return DateTimeUtils.format(this._props.whenLocalStatusDeclared);
    } else {
      return '';
    }
  }

  getValue(): string {
    return this._props.value || '';
  }

  setChainOfCustody(chainOfCustody: IChainOfCustody[]): void {
    this.chainOfCustody = chainOfCustody.map(
      (item: IChainOfCustody) => new ChainOfCustody(item)
    );

    if (this.chainOfCustody.length) {
      this.hasChainOfCustody = true;
    }
  }

  async setAssetEntity(assetEntityPromise: Promise<AssetEntity>): Promise<void> {
    const assetEnity: AssetEntity = await assetEntityPromise;

    if (assetEnity) {
      this.isDefaultThumb = false;
      this.thumb = assetEnity.getThumb();
    }
  }
}

export { Property };
