<div
  *ngIf="authMode && loadingOfficers"
  class="loading-officers">
  <msi-spinner size="small"></msi-spinner>
  <span>{{ 'Loading...' | transloco }}</span>
</div>

<div
  *ngIf="serviceError && authMode"
  class="error">
  <span>{{ serviceError | transloco }}</span>
  <button
    *ngIf="loadingOfficers === false"
    class="msi-btn error-button"
    (click)="onRetry($event)"
    >{{ 'Retry' | transloco }}</button
  >
</div>

<div
  #origin="msiAutocompleteOrigin"
  msiAutocompleteOrigin
  class="pp-prosecutors-field"
  [class.readonly]="authMode && loadingOfficers">
  <ng-container *ngIf="!(authMode && loadingOfficers)">
    <ng-template
      ngFor
      let-item
      [ngForOf]="selectedProsecutors">
      <msi-tag
        class="prosecutor-tag"
        actionable="true"
        removeIsSecondaryAction="true"
        [value]="item.valid"
        (remove)="removeTag(item)"
        >{{ item.getTagName() }}</msi-tag
      >
    </ng-template>

    <input
      #tagInput
      class="pp-prosecutors-input"
      [activateNumber]="0"
      [msiAutocomplete]="list"
      [msiAutocompleteConnectedTo]="origin"
      [readonly]="authMode && loadingOfficers"
      [disabled]="authMode && serviceError"
      (optionSelected)="onOptionSelected($event)"
      (keydown)="onKeyDown($event)"
      (keyup)="onKeyUp($event)"
      (blur)="onBlur()" />
  </ng-container>
</div>

<div
  *ngIf="errorField"
  class="errorField">
  <span>{{ errorField | transloco }}</span>
</div>

<msi-autocomplete
  #list
  panelWidth="572px">
  <ng-template [ngIf]="authMode">
    <msi-autocomplete-option
      *ngFor="let item of filteredProsecutors"
      [value]="item.id"
      class="pp-prosecutors-ac-option"
      [class.hidden]="selectedProsecutors.includes(item)">
      <div class="pp-prosecutors-option">
        <span
          class="pp-prosecutors-first"
          [innerHTML]="item.title | highlightSearchTerm: search"></span>
        <span
          class="pp-prosecutors-second"
          [innerHTML]="item.email | highlightSearchTerm: search"></span>
        <span
          class="pp-prosecutors-third"
          [innerHTML]="item.agencyName | highlightSearchTerm: search"></span>
      </div>
    </msi-autocomplete-option>
  </ng-template>
  <ng-template [ngIf]="!authMode"></ng-template>
</msi-autocomplete>
