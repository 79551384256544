/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'pp-error-block',
  templateUrl: './error-block.component.html',
  styleUrls: ['./error-block.component.scss'],
})
export class ErrorBlockComponent implements OnInit, OnChanges {
  @Input()
  statusCode: number;

  title = '';
  text = '';
  iconName = 'ic_error';

  constructor(private _transloco: TranslocoService) {}

  ngOnInit(): void {
    this.init(this.statusCode);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.statusCode &&
      changes.statusCode.previousValue !== changes.statusCode.currentValue
    ) {
      this.init(changes.statusCode.currentValue);
    }
  }

  init(statusCode: number): void {
    switch (statusCode) {
      case 403:
        this.title = this._transloco.translate('Unauthorized Access');
        this.text = this._transloco.translate(
          'You do not have permission to access this page.'
        );
        this.iconName = 'ic_lock_closed';
        break;

      case 404:
        this.title = this._transloco.translate('Page not found');
        this.text = this._transloco.translate(
          'This record has expired or been deleted. Please contact the agency for further details.'
        );
        break;

      case 400:
        this.title = this._transloco.translate('Something went wrong');
        this.text = this._transloco.translate(
          'For help, please contact Motorola Solutions support.'
        );
        break;

      default:
        this.title = this._transloco.translate('Something went wrong');
        this.text = this._transloco.translate(
          'Please try refreshing your browser or log out and back in.'
        );
    }
  }
}
