<pp-header-auth>
  <div
    *ngIf="loaded"
    class="mf-test-loading">
    <button
      class="msi-button"
      (click)="onOpen($event)"
      >Open</button
    >
  </div>
  <div
    *ngIf="loading"
    class="mf-test-loading">
    Loading...
  </div>
  <ng-template [ngIf]="loaded && config && token && userInfo">
    <pp-create-package-wrapper
      [open]="open"
      [content]="content"
      [files]="files"
      [fullContent]="fullContent"
      [hasOverview]="hasOverview"
      [additionalInfo]="additionalInfo"
      [token]="token"
      [userInfo]="userInfo"
      [config]="config"></pp-create-package-wrapper>
  </ng-template>
</pp-header-auth>
