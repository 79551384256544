/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { DateTimeUtils } from '@msi/js-sdk';

import { IChainOfCustody } from './ChainOfCustody.interfaces';

class ChainOfCustody {
  private _props: IChainOfCustody = {};

  public date = '';
  public type = '';
  public from = '';
  public custodian = '';
  public reason = '';

  constructor(props: IChainOfCustody) {
    this._props = props;

    this.date = this.getDate();
    this.type = this._props.transactionType || '';
    this.from = this._props.receivedFrom?.personnel || '';
    this.custodian = this._props.custodian?.personnel || '';
    this.reason = this._props.reasonForCustodyChange || '';
  }

  get(name: keyof IChainOfCustody): IChainOfCustody[keyof IChainOfCustody] {
    return this._props[name];
  }

  getId(): string {
    return this._props.id || '';
  }

  getDate(): string {
    if (this._props.whenCustodyChangeOccurred) {
      return DateTimeUtils.formatTZ(this._props.whenCustodyChangeOccurred);
    } else {
      return '';
    }
  }

  getProps(): IChainOfCustody {
    return this._props;
  }
}

export { ChainOfCustody };
