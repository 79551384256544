/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ElasticApmService, settings } from '@msi/pp-components';

@Component({
  selector: 'pp-header-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss'],
})
export class HeaderGuestComponent implements OnInit {
  appInfo: Record<string, string> = {};
  baseUrl: string;

  constructor(
    private _elasticApmService: ElasticApmService,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.setHomeUrl();

    this.appInfo = {
      appVersion:
        settings.get<string>('SOURCE_BRANCH').split('releases/release-')[1] || '',
      buildNumber: settings.get<string>('BUILD_NUMBER'),
      sourceVersion: settings.get<string>('SOURCE_VERSION').substr(0, 8),
    };

    this._elasticApmService.initialize();
  }

  setHomeUrl(): void {
    const isBeta = this.router.snapshot.routeConfig.path.includes('beta');
    this.baseUrl = isBeta
      ? settings.get<string>('BASE_URL_BETA')
      : settings.get<string>('BASE_URL');
  }
}
