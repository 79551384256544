/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, MsiModalConfig, MsiModalRef } from '@msi/cobalt';

import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../../services/elastic-apm';

@Component({
  selector: 'pp-detail-package-unsubscribe',
  templateUrl: './detail-package-unsubscribe.component.html',
  styleUrls: ['./detail-package-unsubscribe.component.scss'],
})
@ApmTransactionComponent('unsubscribe modal')
export class DetailPackageUnsubscribeComponent {
  @Output()
  confirm: EventEmitter<void> = new EventEmitter();

  @ViewChild('modalUnsubscribe')
  modalUnsubscribeTemplate: TemplateRef<any>;

  @ViewChild('modalUnsubscribeSuccess')
  modalUnsubscribeSuccessTemplate: TemplateRef<any>;

  modalRef?: MsiModalRef;
  modalUnsubscribe: MsiModalRef;
  modalUnsubscribeSuccess: MsiModalRef;
  config: MsiModalConfig = {
    disableClose: true,
    hasBackdrop: true,
  };

  constructor(private _modalService: ModalService) {}

  @ApmTransactionMethod('unsubsribed case package')
  onConfirm(): void {
    this.modalUnsubscribe.close();
    this.modalUnsubscribeSuccess = this._modalService.open(
      this.modalUnsubscribeSuccessTemplate,
      this.config
    );
    this.confirm.emit();
  }

  onCancel() {
    this.close();
  }

  open(): void {
    if (!this.modalUnsubscribe) {
      this.modalUnsubscribe = this._modalService.open(
        this.modalUnsubscribeTemplate,
        this.config
      );
    }
  }

  close(): void {
    if (this.modalUnsubscribe) {
      this.modalUnsubscribe.close();
      this.modalUnsubscribe = undefined;
    }
  }
}
