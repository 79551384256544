/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { DerivedItemType, FileIconManager } from '@msi/emm-sdk';

import { IShareableAsset } from '../models/share-data.models';

export class ShareDataUtils {
  static getIconNameByItem(asset: IShareableAsset): string {
    return FileIconManager.getIconNameByItem({
      cameraType: asset?.device?.cameraType,
      storageType: asset?.assetStorageType || '',
      mimeType: asset?.mimeType || '',
      derivedItemType: asset?.derivedItemType as DerivedItemType,
    });
  }

  static formatUrl(
    url: string,
    args: unknown,
    formatter: RegExp = /{{\s?(\w+)\s?}}/g
  ): string {
    return url.replace(formatter, (_argPattern: string, argId: string) => args[argId]);
  }
}
