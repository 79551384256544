/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { IAddress } from '../address/Address.interfaces';
import { ILocation } from './EntityLocation.interfaces';

class EntityLocation {
  public id = '';
  public type = '';
  public address: IAddress = {};
  public comment = '';

  constructor(props: ILocation) {
    this.id = props.id || '';
    this.type = props.type || '';
    this.address = props.address || {};
    this.comment = props.comment || '';
  }
}

export { EntityLocation };
