/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'pp-packages-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class PackagesHeaderComponent {
  @Input()
  headerName = 'Packages';
}
