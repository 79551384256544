<ng-template #createPackageTemplate>
  <msi-modal>
    <form
      (ngSubmit)="onSubmit($event)"
      [formGroup]="form">
      <msi-modal-header>{{ title | transloco }}</msi-modal-header>

      <msi-modal-content class="form-contents">
        <div class="file-contents-describe">
          {{
            'An email will be sent to the recipients with the package information and access link.'
              | transloco
          }}
        </div>
        <div class="row first-line">
          <div class="col-left">
            <label for="package-name">{{ 'Package name/ Reference label' | transloco }}</label>
            <input
              id="package-name"
              class="msi-input"
              autocomplete="off"
              placeholder="{{ 'Enter name here' | transloco }}"
              required
              [formControl]="form.controls['name']" />
          </div>
          <div class="col-right">
            <label for="expiration-date">{{ 'Expires in' | transloco }}</label>
            <pp-expires
              *ngIf="!enableSelectingDateWithCalendar"
              id="expiration-date"
              [pkgAction]="createAction"
              [dateControl]="form.controls['validTo']"></pp-expires>
            <pp-date-picker
              *ngIf="enableSelectingDateWithCalendar"
              id="expiration-date"
              [shortestRetentionDay]="purgeDate"
              [dateControl]="form.controls['validTo']">
            </pp-date-picker>
          </div>
        </div>

        <ng-container *ngIf="!isEmptyAgency && isUnauthSharingEnabled">
          <div class="access-method">
            <div class="access-method__wrapper">
              <label for="access-method">{{ 'Access method' | transloco }}</label>
              <msi-radio-group
                [formControl]="form.controls['authMode']"
                (change)="onChangeAuthMode($event)">
                <div class="info-icon-container">
                  <msi-radio-button
                    class="pp-radio-button"
                    [checked]="authMode"
                    value="1"
                    >{{ 'Authenticated (Requires email login)' | transloco }}</msi-radio-button
                  >
                </div>
                <msi-radio-button
                  class="pp-radio-button"
                  [checked]="!authMode"
                  value=""
                  >{{ 'Unauthenticated (Open link)' | transloco }}</msi-radio-button
                >
              </msi-radio-group>
            </div>

            <ng-container *ngIf="!authMode && enablePasswordProtectionForUnauthPkg">
              <section class="access-method__password">
                <label for="password">{{ 'Password (optional)' | transloco }}</label>
                <pp-password-creator
                  [controlPassword]="form.controls['password']"
                  [controlEmailPassword]="form.controls['sendEmailNotification']"
                  [(isGeneratedPasswordUsed)]="isGeneratedPasswordUsed"></pp-password-creator>
              </section>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="(shortRetentionAssets$ | async)?.count || (restrictedAssets$ | async)?.count">
          <div class="asset-retention-warrning">
            <pp-asset-warning
              [filesLoading]="filesLoading"
              [shortRetentionAssetsCount]="(shortRetentionAssets$ | async)?.count"
              [restrictedAssetsCount]="(restrictedAssets$ | async)?.count">
            </pp-asset-warning>
          </div>
        </ng-container>
        <div class="row">
          <div class="col">
            <label for="to">{{ 'To' | transloco }}</label>
            <pp-prosecutors
              id="to"
              [authMode]="authMode"
              (updateSelectedProsecutors)="onUpdateSelectedProsecutors($event)"></pp-prosecutors>
          </div>
        </div>

        <ng-template
          ngFor
          let-item
          [ngForOf]="additionalInfo"
          let-i="index"
          *ngIf="sendEmailNotification || !authMode">
          <div class="row">
            <div class="col">
              <label class="info">{{ 'Title' | transloco }}</label>
              <msi-radio-group
                id="subject-type"
                inline="true"
                (change)="onChangeSubject($event)">
                <msi-radio-button
                  [checked]="!customSubject"
                  value=""
                  >{{ 'Default subject' | transloco }}</msi-radio-button
                >
                <msi-radio-button
                  [checked]="customSubject"
                  value="1"
                  >{{ 'Custom subject' | transloco }}</msi-radio-button
                >
              </msi-radio-group>

              <input
                *ngIf="customSubject"
                class="msi-input"
                [formControl]="form.controls['subject']"
                placeholder="{{ 'Enter custom subject here' | transloco }}" />
              <div *ngIf="!customSubject"> {{ form.controls['subject'].value }} </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label class="info">{{ 'Message for recipient' | transloco }}</label>
              <div>{{ item.body }}</div>
            </div>
          </div>
        </ng-template>

        <div
          *ngIf="isUnauthSharingEnabled && (isEmptyAgency || !authMode)"
          class="disclaimer-footer">
          <strong>{{ 'Warning' | transloco }}</strong>
          {{
            form.controls['password'].value
              ? ('By pressing ‘Send’ you are acknowledging that anyone with this link and password can view the material.'
                | transloco)
              : ('By pressing ‘Send’ you are acknowledging that anyone with this link can view the material.'
                | transloco)
          }}

          <span
            class="disclaimer-footer-info"
            msiTooltip="{{
              'We now offer a secure sharing feature. To get started, your agency partner must set up a CommandCentral account'
                | transloco
            }}">
            <msi-icon
              name="ic_information"
              size="18"></msi-icon>
          </span>
        </div>
      </msi-modal-content>

      <pp-asset-warning
        [filesLoading]="filesLoading"
        [incompleteAssetsCount]="(incompleteAssets$ | async)?.count"
        [directoryAssetsCount]="(directoryAssets$ | async)?.count">
      </pp-asset-warning>

      <msi-modal-actions class="create-package-modal__modal-action hide-overflow-x">
        <div
          class="create-package-modal__checkbox-wrapper"
          [class.cursor-not-allowed]="sharing">
          <msi-checkbox
            *ngIf="authMode && !isEmptyAgency"
            (click)="onClickNotifications()"
            [class.msi-checkbox-disabled]="sharing"
            [class.disabled-click]="sharing"
            [formControl]="form.controls['sendEmailNotification']">
            {{ 'Send email notification' | transloco }}
          </msi-checkbox>
        </div>
        <div class="create-package-modal__buttons-wrapper">
          <button
            type="button"
            class="msi-btn msi-btn-action"
            [attr.disabled]="sharing || loading || undefined"
            (click)="onCancel($event)">
            {{ 'Cancel' | transloco }}
          </button>
          <ng-template [ngIf]="retrying">
            <button
              type="button"
              class="msi-btn spinner-parent"
              [attr.disabled]="loading || filesLoading">
              {{ 'Retry' | transloco }}
            </button>
          </ng-template>
          <ng-template [ngIf]="!retrying">
            <ng-template #sendButtonTemplate>
              <button
                id="create-package-submit"
                type="submit"
                class="msi-btn spinner-parent"
                [attr.disabled]="sharing || loading || filesLoading || form.invalid || undefined">
                <ng-template [ngIf]="loading || filesLoading">{{ 'Loading...' | transloco }}</ng-template>
                <ng-template [ngIf]="sharing">{{ 'Sending...' | transloco }}</ng-template>
                <ng-template [ngIf]="!sharing && !(loading || filesLoading)">{{
                  'Send' | transloco
                }}</ng-template>
                <msi-spinner
                  *ngIf="sharing || loading || filesLoading"
                  size="small"></msi-spinner>
              </button>
            </ng-template>
            <ng-template [ngIf]="filesLoading">
              <div
                class="msi-btn-wrapper"
                #tooltipAbove="msiTooltip"
                (mouseenter)="tooltipAbove.show()"
                msiTooltip="{{ 'Calculating the package...' | transloco }}"
                position="above">
                <ng-container *ngTemplateOutlet="sendButtonTemplate"></ng-container>
              </div>
            </ng-template>
            <ng-template [ngIf]="!filesLoading">
              <ng-container *ngTemplateOutlet="sendButtonTemplate"></ng-container>
            </ng-template>
          </ng-template>
        </div>
      </msi-modal-actions>
    </form>
  </msi-modal>
</ng-template>
