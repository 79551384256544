/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastService } from '@msi/cobalt';
import { ResponseError, Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';

import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../services/elastic-apm';
import { ESharesType } from '../../services/filter/filter.enums';
import { IListBody, ISort } from '../../services/filter/filter.interfaces';
import { LawIncidentService } from '../../services/law-incident/law-incident.service';
import { PackageContent } from '../../services/package/classes/package/PackageContent';
import { PackageItem } from '../../services/package/classes/package/PackageItem';
import { EBdpSearchUrl } from '../../services/package/package.enums';
import { IPackageItems, PackageService } from '../../services/package/package.service';
import { ISettings } from '../../settings/settings.interface';
import { EShareManagerTableMode } from '../share-manager/table/share-manager-table.enums';

@Component({
  selector: 'pp-single-share-manager',
  templateUrl: './single-share-manager.component.html',
  styleUrls: ['./single-share-manager.component.scss'],
})
@ApmTransactionComponent('single share manager')
export class SingleShareManagerComponent implements OnInit, OnChanges {
  @Input()
  id = '';

  @Input()
  lang: string;

  packages: PackageItem[] = [];
  loading = false;
  loadingPackageContent = false;
  statusCode: number = null;
  isError = false;

  private _id = '';
  private _pageNumber = 0;
  private _continuation: string | null = null;
  private _query = '';
  private _sort: ISort | null = null;
  private _toastOptions: Record<string, any> = {
    closeButton: true,
    autoDismiss: 3000,
  };

  share: Array<ESharesType> = [ESharesType.ACTIVE];
  packageContent: PackageContent;
  mode: EShareManagerTableMode = EShareManagerTableMode.SINGLE;

  constructor(
    private _settings: Settings<ISettings>,
    private _packageService: PackageService,
    private _lawIncidentService: LawIncidentService,
    private _toastService: ToastService,
    private _transloco: TranslocoService
  ) {
    this._toastOptions.autoDismiss = this._settings.get<number>('AUTODISMISS');
  }

  async ngOnInit(): Promise<void> {
    await this.init(this.id);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.id.previousValue !== changes.id.currentValue) {
      await this.init(changes.id.currentValue);
    }
    if (changes.lang?.currentValue !== changes.lang?.previousValue) {
      this._transloco.setActiveLang(this.lang);
    }
  }

  async init(id: string): Promise<void> {
    if (!id) {
      return;
    }

    this._id = id;

    this.share = [ESharesType.ACTIVE];

    await Promise.all([this.fetchPackageContent(), this.fetch()]);
  }

  @ApmTransactionMethod('get data content')
  async fetchPackageContent(): Promise<void> {
    this.loadingPackageContent = true;

    try {
      this.packageContent = await this._lawIncidentService.getLawIncidentModel(this._id);
    } catch (err) {
      const toastOptions: Record<string, any> = { closeButton: true };

      this._toastService.error(
        this._transloco.translate(err.message),
        void 0,
        toastOptions
      );
    }

    this.loadingPackageContent = false;
  }

  @ApmTransactionMethod('get data')
  async fetch(continuation?: string): Promise<void> {
    this.loading = true;
    this.statusCode = null;

    if (continuation) {
      this._pageNumber++;
    } else {
      this._pageNumber = 0;
    }

    const options: IListBody = {
      caseId: this._id,
      query: this._query,
      share: this.share,
      sort: this._sort,
      pageNumber: this._pageNumber,
      searchId: continuation,
    };

    let packages: PackageItem[] = [];
    let responseContinuation: string | null = null;
    let response: IPackageItems;
    let errResponse: ResponseError;

    try {
      response = await this._packageService.bdpSearch(options, EBdpSearchUrl.PUBLISHERS);

      packages = response.packages;
      responseContinuation = response.continuation;
      this.statusCode = 200;
    } catch (err) {
      errResponse = err;
      this.statusCode = err.status;
      this.isError = true;
    }

    if (errResponse) {
      if (errResponse.status === 403) {
        this._toastService.error(
          this._transloco.translate('You don\'t have permissions'),
          void 0,
          this._toastOptions
        );
      } else if (errResponse.status === 500) {
        this._toastService.error(
          this._transloco.translate('Internal Server Error'),
          void 0,
          this._toastOptions
        );
      }
    }

    if (continuation) {
      this.packages = [...this.packages, ...packages];
    } else {
      this.packages = packages;
    }

    this._continuation = responseContinuation;
    this.loading = false;
  }

  @ApmTransactionMethod('quick filter buttons')
  async onShare(share: Array<ESharesType>): Promise<void> {
    this.share = share;

    await this.fetch();
  }

  @ApmTransactionMethod('search bar')
  async onSearch(query: string): Promise<void> {
    this._query = query;

    await this.fetch();
  }

  @ApmTransactionMethod('sort')
  async onSort(sort: ISort): Promise<void> {
    this._sort = sort;

    await this.fetch();
  }

  async onLoadMore(): Promise<void> {
    if (!this.loading && this._continuation) {
      await this.fetch(this._continuation);
    }
  }

  async onRefresh(): Promise<void> {
    await this.fetch();
  }
}
