/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@msi/cobalt';
import { ConfirmAuthGuardService } from '@msi/commandcentral-common-header';
import { Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, EMPTY } from 'rxjs';

import { EPackageVersionType } from '../../services/package/package.enums';
import { PasswordValidatorService } from '../../services/password-validator/password-validator.service';
import { ISettings } from '../../settings/settings.interface';

@UntilDestroy()
@Component({
  selector: 'pp-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss'],
})
export class PasswordValidatorComponent implements OnInit {
  @Input() packageId: string;
  @Input() agencyId: string;
  @Input() packageVersion: EPackageVersionType;

  private readonly toastOptions: Record<string, number | boolean> = {
    closeButton: true,
    autoDismiss: this.settings.get<number>('AUTODISMISS'),
  };

  forgotPasswordDisabled = true;
  validating = false;
  isVisible = false;
  packageLocked = false;
  passwordForm: FormGroup;
  passwordError = 'Incorrect password or email';

  get password() {
    return this.passwordForm.get('password');
  }

  get email() {
    return this.passwordForm.get('email');
  }

  constructor(
    private passwordValidator: PasswordValidatorService,
    private formBuilder: FormBuilder,
    private settings: Settings<ISettings>,
    private toastService: ToastService,
    private transloco: TranslocoService,
    private guard: ConfirmAuthGuardService
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.passwordForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (
        this.email.hasError('verificationError') ||
        this.password.hasError('verificationError')
      ) {
        this.email.updateValueAndValidity({ emitEvent: false });
        this.password.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  onVerifyPassword(): void {
    this.validating = true;

    this.passwordValidator
      .validatePassword(
        this.passwordForm.value.email,
        this.passwordForm.value.password,
        this.packageId,
        this.agencyId,
        this.packageVersion
      )
      .pipe(
        untilDestroyed(this),
        catchError((e: HttpErrorResponse) => {
          this.validating = false;
          if (e.status === 401) {
            this.password.setErrors({ verificationError: true });
            this.email.setErrors({ verificationError: true });
            this.setPasswordErrorMessage(e.error.attempts_left);
          } else if (e.status === 404) {
            this.toastService.error(
              this.transloco.translate('Something went wrong. Package not found.'),
              void 0,
              this.toastOptions
            );
          } else {
            this.toastService.error(
              this.transloco.translate('Something went wrong. Server error.'),
              void 0,
              this.toastOptions
            );
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  onForgotPassword(): void {
    this.forgotPasswordDisabled = !this.forgotPasswordDisabled;
  }

  onSignInToCC(): void {
    this.guard.canActivate();
  }

  onReturnToSignIn(): void {
    this.forgotPasswordDisabled = !this.forgotPasswordDisabled;
  }

  private setPasswordErrorMessage(attemptsLeft: number): void {
    switch (attemptsLeft) {
      case 0:
        this.packageLocked = true;
        break;
      case 1:
        this.passwordError = this.transloco.translate(
          '1 attempt remaining. Package will be locked.'
        );
        break;
      case 2:
      case 3:
        this.passwordError = this.transloco.translate(
          `${attemptsLeft} attempts remaining`
        );
        break;
      default:
        this.passwordError = this.transloco.translate('Incorrect password or email');
        break;
    }
  }
}
