<div
  *ngIf="pkg.organizations.length"
  class="pp-detail-package-organization">
  <h2
    id="pp-detail-package-organizations"
    class="pp-detail-package-organization__header">
    {{ 'Organizations ' | transloco }}({{ pkg.organizations.length }})
  </h2>
  <ng-template
    ngFor
    let-item
    [ngForOf]="pkg.organizations"
    let-i="index">
    <ng-container
      *ngTemplateOutlet="
        childItem;
        context: { $implicit: { organization: item, idx: i + 1, len: pkg.organizations.length } }
      "></ng-container>
  </ng-template>
</div>

<ng-template
  #childItem
  let-item>
  <div class="pp-detail-package-organization__item">
    <div class="pp-row-item">
      <div class="pp-detail-package-organization__item__img">
        <div
          class="image"
          [class.image--default]="item.organization.isDefaultThumb"
          [ngStyle]="{ 'background-image': 'url(' + item.organization.thumb + ')' }"></div>
      </div>
      <div class="pp-detail-package-organization__item__descr">
        <div class="pp-detail-package-organization__item__descr__count"
          >{{ 'Organizations ' | transloco }}{{ item.idx }}{{ ' of ' | transloco }}{{ item.len }}</div
        >
        <div
          *ngIf="item.organization.name"
          class="pp-detail-package-organization__item__descr__title"
          >{{ item.organization.name }}</div
        >
        <div
          *ngIf="item.organization.type"
          class="pp-detail-package-organization__item__descr__title"
          >{{ item.organization.type }}</div
        >
      </div>
    </div>
  </div>

  <div class="pp-detail-package-organization__info">
    <ng-container *ngIf="informationPresent(item.organization)">
      <h2>{{ 'Information' | transloco }}</h2>
      <div class="pp-detail-package-organization__info__row">
        <ng-template [ngIf]="item.organization.phones?.length">
          <div
            *ngFor="let phone of item.organization.phones"
            class="pp-detail-package-organization__info__row__cell">
            <h3>{{ phone.type }}</h3>
            <span>{{ phone.number }}</span>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="locationPresent(item.organization)">
      <h2>{{ 'Locations' | transloco }}</h2>
      <div class="pp-detail-package-organization__info__row">
        <ng-template [ngIf]="item.organization.locations?.length">
          <div
            *ngFor="let location of item.organization.locations"
            class="pp-detail-package-organization__info__row__cell">
            <ng-container *ngIf="(location.address | keyvalue)?.length">
              <h3>{{ 'Address: ' | transloco }}{{ location.type }}</h3>
              <ol>
                <li *ngIf="location.address.streetFullText">{{ location.address.streetFullText }},</li>
                <li *ngIf="location.address.city">{{ location.address.city }},</li>
                <li *ngIf="location.address.state">{{ location.address.state }},</li>
                <li *ngIf="location.address.zip">{{ location.address.zip }}</li>
              </ol>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="alertPresent(item.organization)">
      <h2>{{ 'Alerts' | transloco }}</h2>
      <div class="pp-detail-package-organization__info__row">
        <ng-template [ngIf]="item.organization.alerts && item.organization.alerts.length">
          <div
            *ngFor="let alert of item.organization.alerts"
            class="pp-detail-package-organization__info__row__cell">
            <h3>{{ alert.alert }}</h3>
            <ol>
              <li>{{ 'Level: ' | transloco }}{{ alert.level }}</li>
              <li>{{ 'Type: ' | transloco }}{{ alert.type }}</li>
              <li>{{ 'Start date: ' | transloco }}{{ alert.startDate }}</li>
              <li>{{ 'End date: ' | transloco }}{{ alert.endDate }}</li>
            </ol>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</ng-template>
