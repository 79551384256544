/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import {
  TRANSLOCO_MISSING_HANDLER,
  TranslocoMissingHandler,
  TranslocoMissingHandlerData,
} from '@ngneat/transloco';

@Injectable()
export class MissingKeyHandler implements TranslocoMissingHandler {
  private readonly win: any = window as any;
  readonly SUPPORTED_LANGS: string[] = ['en-US', 'pl-PL', 'fr-CA', 'es-XL'];

  handle(key: string, data: TranslocoMissingHandlerData): string {
    if (!this.win.PP_COMPONENTS_MISSING_KEYS) {
      this.win.PP_COMPONENTS_MISSING_KEYS = new Set();

      this.win.PP_COMPONENTS_GEN_MISSING_KEYS = (pref = '') => {
        let response = '{';

        for (const key of this.win.PP_COMPONENTS_MISSING_KEYS) {
          response = response + `'${key}': '${pref} - ${key}',`;
        }

        response = response + '};';

        return response;
      };
    }

    this.win.PP_COMPONENTS_MISSING_KEYS.add(key);

    const defaultKey = this.SUPPORTED_LANGS.includes(data.activeLang)
      ? key
      : key.split('.')[1];

    return defaultKey;
  }
}

export const missingKeyHandler = {
  provide: TRANSLOCO_MISSING_HANDLER,
  useClass: MissingKeyHandler,
};
