/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pp-single-share-manager-page',
  templateUrl: './single-share-manager.component.html',
  styleUrls: ['./single-share-manager.component.scss'],
})
export class SingleShareManagerPageComponent implements OnInit, OnDestroy {
  private _paramsSubscription: Subscription;

  id = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this._paramsSubscription = this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  ngOnDestroy(): void {
    if (this._paramsSubscription) {
      this._paramsSubscription.unsubscribe();
    }
  }
}
