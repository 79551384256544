/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component } from '@angular/core';
import { Connect } from '@msi/js-sdk';

import { ISidebarItem } from '../../../services/sidebar/sidebar.interfaces';
import { SidebarService } from '../../../services/sidebar/sidebar.service';

@Component({
  selector: 'pp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Connect('topSidebarItems')
  topSidebarItems: ISidebarItem[] = [];

  @Connect('bottomSidebarItems')
  bottomSidebarItems: ISidebarItem[] = [];

  constructor(private _sideBarService: SidebarService) {}

  onClick(item: ISidebarItem): void {
    this._sideBarService.click(item);
  }
}
