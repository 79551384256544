/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
const expressions: Record<string, any> = [
  {
    expresion: /__PERSON__/g,
    display: 'Person',
  },
  {
    expresion: /__OFFICER__/g,
    display: 'Officer',
  },
  {
    expresion: /__VICTIM__/g,
    display: 'Victim',
  },
  {
    expresion: /__SUSPECT__/g,
    display: 'Suspect',
  },
  {
    expresion: /__DATE__/g,
    display: 'Date',
  },
  {
    expresion: /__LOCATION__/g,
    display: 'Location',
  },
  {
    expresion: /__DOB__/g,
    display: 'DOB',
  },
  {
    expresion: /__SSN__/g,
    display: 'SSN',
  },
  {
    expresion: /__EMAIL__/g,
    display: 'Email',
  },
  {
    expresion: /__URL__/g,
    display: 'URL',
  },
  {
    expresion: /__IP__/g,
    display: 'Ip',
  },
  {
    expresion: /__REDACTED__/g,
    display: 'Redacted',
  },
  {
    expresion: /__COMPLAINANT__/g,
    display: 'Complainant',
  },
  {
    expresion: /__ORGANIZATION__/g,
    display: 'Organization',
  },
];

export { expressions };
