<div
  *ngIf="hasFiles"
  class="pp-detail-package-assets">
  <div
    class="pp-detail-package-assets__wrap-header"
    [class.no-padding]="packageVersion === 'v2'">
    <h2
      *ngIf="!assetsLoaded"
      id="pp-detail-package-assets"
      class="pp-detail-package-assets__header">
      {{ 'Digital Evidence ' | transloco }}({{ assetEntities.length }}/{{ fullPackageAssetsLen }})
    </h2>
    <h2
      *ngIf="assetsLoaded"
      id="pp-detail-package-assets"
      class="pp-detail-package-assets__header">
      {{ 'Digital Evidence ' | transloco }}({{ assetEntities.length }})
    </h2>
    <section
      class="pp-detail-package-assets__filter-options"
      *ngIf="assetEntities?.length && assetsLoaded">
      <div>
        <pp-search-sorting [groupedAssets]="groupedAssets"></pp-search-sorting>
      </div>
      <div>
        <pp-group-digital-evidence [assetEntities]="assetEntities"></pp-group-digital-evidence>
      </div>
    </section>
    <section class="pp-detail-package-assets__buttons">
      <span *ngIf="assetEntities?.length && assetsLoaded">
        <msi-icon
          name="ic_download"
          class="icon-trigger msi-hover msi-focus"
          [size]="24"
          [msiDropdownTriggerFor]="dropdown"></msi-icon>
        <msi-dropdown
          #dropdown
          xPosition="before">
          <msi-dropdown-item (press)="onDownload('all')">{{
            'Download evidence and metadata' | transloco
          }}</msi-dropdown-item>
          <msi-dropdown-item (press)="onDownload('evidence')">{{
            'Download evidence' | transloco
          }}</msi-dropdown-item>
        </msi-dropdown>
      </span>
      <msi-button-group *ngIf="assetEntities && assetEntities.length && assetsLoaded">
        <button
          class="msi-btn"
          id="gallery-view-btn"
          [class.msi-btn-secondary]="!useGalleryGrid"
          (click)="changeGridView(true)">
          <msi-icon
            name="ic_cards"
            class="grid-view-icon--grid"></msi-icon>
        </button>
        <button
          class="msi-btn"
          id="list-view-btn"
          [class.msi-btn-secondary]="useGalleryGrid"
          (click)="changeGridView(false)">
          <msi-icon
            name="ic_listview"
            class="grid-view-icon--list"></msi-icon>
        </button>
      </msi-button-group>
    </section>
  </div>
  <div
    *ngIf="!assetsLoaded"
    class="pp-detail-package-assets__loader">
    <msi-spinner
      *ngIf="assetsLoading"
      size="medium"></msi-spinner>
  </div>
  <div *ngIf="useGalleryGrid; then galleryGrid; else listGrid"></div>
</div>

<ng-template #galleryGrid>
  <div class="pp-detail-package-assets__view">
    <pp-detail-package-asset-gallery-view
      [assetEntities]="assetEntities"
      [groupedAssets]="groupedAssets"
      [printOptionEnabled]="printOptionEnabled"
      [collapsedAssetsSection]="collapsedAssetsSection"
      (tileClick)="onClick($event)"
      (toggleSection)="toggleSection($event)">
    </pp-detail-package-asset-gallery-view>
  </div>
</ng-template>

<ng-template #listGrid>
  <div class="pp-detail-package-assets__view">
    <pp-detail-package-asset-list-view
      [assetEntities]="assetEntities"
      [groupedAssets]="groupedAssets"
      [collapsedAssetsSection]="collapsedAssetsSection"
      [printOptionEnabled]="printOptionEnabled"
      (tileClick)="onClick($event)"
      (toggleSection)="toggleSection($event)">
    </pp-detail-package-asset-list-view>
  </div>
</ng-template>

<ng-template [ngIf]="isReady">
  <msi-emm-asset-viewer
    #assetViewerPopup
    [items]="assetEntities">
  </msi-emm-asset-viewer>
</ng-template>
