<ng-template [ngIf]="details && details?.length">
  <div class="pp-more-details">
    <msi-tag
      *ngFor="let tag of visibleTags"
      class="recipient-tag"
      [filled]="true"
      [class.auth]="isAuthenticated"
      [class.unauth]="!isAuthenticated"
      msiTooltip="{{ isAuthenticated ? tag : tag + ' (unauthenticated)' }}">
      <div class="recipient-name">
        {{ tag }}
      </div>
    </msi-tag>
    <msi-tag
      *ngIf="hiddenTags.length > 0"
      class="more-tag"
      [filled]="true"
      [class.auth]="isAuthenticated"
      [class.unauth]="!isAuthenticated"
      msiTooltip
      [templateRef]="otherRecipients">
      <div class="other-recipients-number"> +{{ hiddenTags.length }} </div>
    </msi-tag>
  </div>
</ng-template>

<ng-template #otherRecipients>
  <div class="hidden-tags">
    <a *ngFor="let tag of hiddenTags">
      {{ isAuthenticated ? tag : tag + ' (unauthenticated)' }}
    </a>
  </div>
</ng-template>
