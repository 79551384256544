/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
// import { Api, IResponse } from '@msi/pp-components';
// import { settings } from '@msi/pp-components';
// import { Package } from './Package';
// import { IPackageBody } from './package.interfaces';

@Injectable()
export class PackageService {
  // async getAssetsByPackageFiles(pkg: Package): Promise<void> {
  // }
  // async get(token: string): Promise<Package> {
  //   const api: Api = new Api();
  //   const platformApi: string = settings.get<string>('PLATFORM.API');
  //   const endpointApi: string = settings.get<string>('PACKAGE_VIEWER_API_PACKAGE_ENDPOINT');
  //   let response: IResponse<IPackageBody>;
  //   try {
  //     response = await api.get<IPackageBody>(platformApi + endpointApi + '/' + token);
  //   } catch (err) {
  //     throw err;
  //   }
  //   const pkg: Package = new Package(response.data);
  //   this.getAssetsByPackageFiles(pkg);
  //   // console.log('url ->', platformApi + endpointApi + '/' + token);
  //   // console.log('package ->', pkg);
  //   return pkg;
  // }
}
