/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import {
  AssetEntity,
  AssetEntityDir,
  AssetEntityItem,
  AssetStorageType,
  AvScan,
  AvStatus,
  EAssetEntityState,
  emm,
  IAssetEntityResponse,
  IEventInfo,
  ImportanceLevel,
  RetentionState,
} from '@msi/emm-sdk';
import { Api, DateTimeUtils, IResponse, Settings } from '@msi/js-sdk';
import { cloneDeep } from 'lodash-es';

import { ISettings } from '../../settings/settings.interface';
import { detailPackageLocalStore } from '../../stores/detail-package/detail-package.store';
import { DerivedItemService } from '../derived-item/derived-item.service';
import { DownloableLinkService } from '../downloadable-link/downloable-link.service';
import { DerivedItemType, EPackageAccessType } from '../package/package.enums';
import { IPackage, IPackageFile } from '../package/package.interfaces';
import {
  DerivedItem,
  IEmptyAssetEntity,
  IVaultContent,
  IVaultMetadata,
  IVaultShareFile,
} from './asset-entity.interface';

@Injectable()
export class AssetEntityService {
  readonly RETRY_COUNT: number = 3;
  readonly RETRY_DELAY: number = 1000;
  readonly REQUESTS_GROUP_SIZE: number = 50;
  private assetViewer = emm.getAssetViewer();

  constructor(
    private _settings: Settings<ISettings>,
    private _api: Api,
    private _derivedItemService: DerivedItemService,
    private _downloadableService: DownloableLinkService
  ) {}

  async getMetadata(assetEntity: AssetEntity): Promise<string> {
    const startTime = Date.parse(assetEntity.startTime)
      ? DateTimeUtils.format(assetEntity.startTime)
      : '--';
    const uploadedTime = Date.parse(assetEntity.uploadedTime)
      ? DateTimeUtils.format(assetEntity.uploadedTime)
      : '--';

    return [
      `Capture date,${startTime}`,
      `Officer,${assetEntity.ownerId}`,
      `Size,${assetEntity.humanSize}`,
      `Source,${assetEntity.source.name}`,
      `Case Numbers,${assetEntity.caseNumbers.join('; ')}`,
      `Import Date,${uploadedTime}`,
    ].join('\n');
  }

  async getAssetEntityByAsset(
    assetEntity: AssetEntity,
    pkg: IPackage
  ): Promise<AssetEntity> {
    const file: IPackageFile | void = pkg.files.find((f: IPackageFile): boolean => {
      if (f.derivedItemId) {
        return f.derivedItemId === assetEntity.derivedItemId;
      }
      return f.fileId === assetEntity.fileId;
    });

    if (!file) {
      throw new Error(
        'AssetEntityService.getAssetEntityByAsset: package file is not found'
      );
    }

    const isVault = pkg.source && pkg.source.toLowerCase() === 'vault';

    try {
      if (isVault) {
        return await this.getVaultAssetEntity(file);
      } else if (
        file.derivedItemType === DerivedItemType.CLIP ||
        file.derivedItemType === DerivedItemType.EXPORTED_REDACTION ||
        file.derivedItemType === DerivedItemType.SCREENSHOT
      ) {
        return await this.getDerivedAssetEntity(file, pkg);
      } else {
        return await this.getAssetEntity(file.link, pkg);
      }
    } catch (err) {
      return this.getEmptyAsset();
    }
  }

  async getAssetEntities(pkg: IPackage): Promise<(AssetEntity | IEmptyAssetEntity)[]> {
    const groups: IPackageFile[][] = this._groupAssetEntities(
      pkg.files,
      this.REQUESTS_GROUP_SIZE
    );
    const result: (AssetEntity | IEmptyAssetEntity)[] = [];
    detailPackageLocalStore.fullPackageAssetsLen = pkg.files.length;
    detailPackageLocalStore.thumbAssetsCount = pkg.files.length;

    for (const group of groups) {
      let attempt = 1;
      let success = false;

      while (!success && attempt <= this.RETRY_COUNT) {
        try {
          detailPackageLocalStore.assetsLoading = true;

          const response = await this._sendGroup(group, pkg);

          result.push(...response);
          detailPackageLocalStore.assetsLoading = false;
          detailPackageLocalStore.assetEntities = [...result];
          success = true;
        } catch (error) {
          attempt++;

          if (attempt <= this.RETRY_COUNT) {
            // eslint-disable-next-line no-console
            console.log(
              `Failed to send group ${group}. Retrying in ${this.RETRY_DELAY} ms...`
            );

            await this._delay(this.RETRY_DELAY);
          } else {
            console.error(error);
          }
        }
      }

      if (!success) {
        throw new Error(`Failed to send assetEntities: ${group}`);
      }
    }
    setTimeout(() => (detailPackageLocalStore.assetsLoaded = true), 200);

    return cloneDeep(result);
  }

  async getDerivedAssetEntity(file: IPackageFile, pkg: IPackage): Promise<AssetEntity> {
    const accessType: EPackageAccessType = pkg.header.accessType;
    const url: string = file.link;
    const derivedItemType: DerivedItemType = file.derivedItemType;
    const response: DerivedItem = await this._derivedItemService.getDerivedItemData(url);

    const currentDerivedItem: DerivedItem = response;

    if (derivedItemType === DerivedItemType.SCREENSHOT) {
      (currentDerivedItem as any).assetId = file.fileId;
    }
    (currentDerivedItem as any).source = { name: 'Manually uploaded', sourceId: '' };

    const emptyAsset: AssetEntity = this.getEmptyAsset();

    const asset: AssetEntity = this._derivedItemService.mapDerivedItemToAsset(
      emptyAsset,
      derivedItemType,
      currentDerivedItem,
      file.fileId
    );

    const playableLinkUrl = this._derivedItemService.getDerivedItemDisplayLinkUrl(
      derivedItemType,
      url,
      accessType
    );
    const downloadableLinkUrl =
      this._derivedItemService.getDerivedItemDownloadableLinkUrl(url, accessType);

    const playableLink = await this._derivedItemService.getDerivedItemPlayableLink(
      playableLinkUrl,
      currentDerivedItem,
      accessType
    );
    const downloadableLink =
      await this._derivedItemService.getDerivedItemDownloadableLink(
        downloadableLinkUrl,
        currentDerivedItem,
        accessType
      );
    await this._derivedItemService.updateAssetEntityItem(
      asset,
      playableLink,
      downloadableLink
    );

    asset.setDerivedItemData(response as any);

    return asset;
  }

  async getAssetEntity(url: string, pkg: IPackage = null): Promise<AssetEntity> {
    let response: IResponse<IAssetEntityResponse>;

    try {
      response = await this._api.get<IAssetEntityResponse>(url);
    } catch (err) {
      throw err;
    }

    const dataContent = response.data?.content;
    const assetEntity: AssetEntity = new AssetEntity(response.data);

    if (this._isMultiFileType(response.data.assetStorageType)) {
      this._decodeItems(assetEntity.getItems());
      this._decodeItems(assetEntity.getChildren() as any);
    }

    if (dataContent && Object.keys(dataContent).length === 0) {
      await this.setDownloadableLinkForAsset(pkg.header.accessType, url, assetEntity);
    }

    try {
      if (pkg && assetEntity.isEvent()) {
        const [eventInfoUrl, args] = url.split('?');
        const eventInfoResult = await this._api.get<IEventInfo>(
          `${eventInfoUrl}/streams${args ? '?' + args : ''}`
        );
        assetEntity.eventInfo = eventInfoResult.data;

        await this.assetViewer.loadEventStreams(
          assetEntity.fileId,
          assetEntity,
          pkg.header.agency
        );
      }
    } catch (err) {
      setTimeout(() => {
        throw err;
      });
    }
    // Inside AssetViewer and Evidence we don't use agencyId but we need this
    // value to download AuditLog that's why we extend each instance of AssetEntity
    // and add additional property.
    (assetEntity as any).ppAgencyId = response.data.agencyId;

    return assetEntity;
  }

  async getVaultAssetEntity(file: IPackageFile): Promise<AssetEntity> {
    const [thumb, metaData] = await Promise.all([
      this.getVaultThumb(file.shareId),
      this.getVaultMetadata(file.shareId),
    ]);
    const data: Record<string, any> = { ...file, ...thumb, ...metaData };

    let vaultContent: IVaultContent;

    try {
      vaultContent = await this.getVaultContent(data.link);
    } catch (err) {
      console.error(err);
    }

    if (vaultContent) {
      data.externalLink = vaultContent.uri;
    }

    const entity: Record<string, any> = this.prepareCorrectFileStructure(data);
    const assetEntity: AssetEntity = new AssetEntity(entity as any);

    return assetEntity;
  }

  async getVaultContent(url: string): Promise<IVaultContent> {
    let response: IResponse<IVaultContent>;

    try {
      response = await this._api.get<IVaultContent>(url);
    } catch (err) {
      throw err;
    }

    return response.data;
  }

  async getVaultThumb(shareId: string): Promise<IVaultShareFile> {
    const domain: string = this._settings.get<string>(
      'PLATFORM.VAULT_PUBLIC_SHARES_API' as any
    );
    const url: string =
      domain + this._settings.get<string>('VAULT_SHARE_URL') + '/' + shareId;

    let response: IResponse<IVaultShareFile>;

    try {
      response = await this._api.get<IVaultShareFile>(url);
    } catch (err) {
      throw err;
    }

    return response.data;
  }

  async getVaultMetadata(shareId: string): Promise<IVaultMetadata> {
    const domain: string = this._settings.get<string>(
      'PLATFORM.VAULT_PUBLIC_SHARES_API' as any
    );
    const url: string =
      domain +
      this._settings.get<string>('VAULT_SHARE_URL') +
      '/' +
      shareId +
      '/metadata?format=json';

    let response: IResponse<IVaultMetadata>;

    try {
      response = await this._api.get<IVaultMetadata>(url);
    } catch (err) {
      throw err;
    }

    return response.data;
  }

  getEmptyAsset(): AssetEntity {
    const props: IAssetEntityResponse = {
      agencyId: '',
      assetStorageType: AssetStorageType.SINGLEFILE,
      caseNumbers: [],
      endTime: '',
      externalLink: '',
      url: '',
      permissions: {
        includeMe: true,
        includeEntities: [],
        shareWithGroups: [],
      },
      fileId: '',
      fileName: '',
      displayName: '',
      fileSize: 0,
      mimeType: '',
      origin: '',
      ownerId: '',
      ownerDisplayName: '',
      parentFileId: '',
      services: {
        antivirusScan: {
          scan: AvScan.CLEAN,
          status: AvStatus.READY,
        },
        chainOfCustody: {
          lastVerified: '',
          status: '',
          verified: false,
        },
        thumbnail: {
          link: '',
          status: 'NOT_AVAILABLE' as any,
        },
      },
      source: {
        name: '',
        sourceId: '',
      },
      startTime: '',
      state: EAssetEntityState.Active,
      uploadedTime: '',
      versionId: '',
      importanceLevel: ImportanceLevel.NORMAL,
      retention: {
        retentionState: RetentionState.AUTO,
        purgeTime: '',
      },
      textualTags: [],
      enumeratedTags: [],
    };

    return new AssetEntity(props);
  }

  public getDownloadableLinkUrl(url: string, accessType: EPackageAccessType): string {
    return this._downloadableService.getDownloadableLinkUrl(url, accessType);
  }

  public async getDownloadableLink(
    url: string,
    asset: AssetEntity,
    accessType: EPackageAccessType
  ): Promise<string> {
    return await this._downloadableService.getDownloadableLink(url, asset, accessType);
  }

  async setDownloadableLinkForAsset(
    accessType: EPackageAccessType,
    url: string,
    assetEntity: AssetEntity
  ): Promise<void> {
    try {
      const fileLinkUrl = this.getDownloadableLinkUrl(url, accessType);
      const fileLink = await this.getDownloadableLink(
        fileLinkUrl,
        assetEntity,
        accessType
      );
      const assetEntityItem = assetEntity.getItems()[0];

      assetEntity.inlineLink = fileLink;
      assetEntityItem.fileLink = fileLink;
      assetEntityItem.inlineLink = fileLink;
    } catch (err) {
      throw err;
    }
  }

  prepareCorrectFileStructure(fileData): Record<string, any> {
    const startTime: string = fileData.startTime;

    const result: Record<string, any> = {
      fileId: fileData.fileId || '',
      fileName: fileData.file ? fileData.file.name : '',
      externalLink: fileData.externalLink || '',
      vaultLink: fileData.link || '',
      source: fileData.file ? fileData.file.source : '',
      fileSize: fileData.file ? fileData.file.size : '',
      mimeType: fileData.file ? fileData.file.mimeType : '',
      startTime: fileData.startTime
        ? DateTimeUtils.format(startTime, DateTimeUtils.defaultLongTimeFormat)
        : '',
      ownerDisplayName: fileData.ownerId ? fileData.ownerId : '',
      type: fileData.file.mimeType ? fileData.file.mimeType : '',
      linkUrl: fileData.link,
      thumbnailUrl: fileData.file ? fileData.file.thumbnailUrl : '',
      updateType: fileData.updateType,
      assetStorageType: fileData.assetStorageType || AssetStorageType.SINGLEFILE,
      assetRetentionState: fileData.assetRetentionState || '',
      status: fileData.assetRetentionState || '',
      duration:
        fileData.startTime && fileData.endTime && fileData.file.mimeType.includes('video')
          ? DateTimeUtils.utc(
              fileData.endTime - fileData.startTime,
              DateTimeUtils.defaultTimeFormat
            )
          : '',
    };

    if (result.externalLink) {
      result.content = {
        fileLink: result.externalLink,
        inlineLink: result.externalLink,
      };
    }

    if (!fileData.services) {
      result.services = {
        antivirusScan: {
          scan: 'CLEAN',
          status: 'READY',
        },
      };
    }

    if (fileData.file && fileData.file.thumbnailUrl) {
      result.services.thumbnail = { link: fileData.file.thumbnailUrl };
    }

    return result;
  }

  private _decodeItems(items: (AssetEntityDir | AssetEntityItem)[]): void {
    items?.map((item: AssetEntityDir | AssetEntityItem) => {
      item.name = decodeURIComponent(item.name);
      item.displayName = item.name;
      item.path = decodeURI(item.path);

      if (item instanceof AssetEntityItem && item?.directories) {
        item.directories = item?.directories.map((directory: string) =>
          decodeURIComponent(directory)
        );
        item.fullPath = decodeURI(item.fullPath);
      } else if (item instanceof AssetEntityDir && item?.isDir) {
        this._decodeItems(item.children as any);
      }
    });
  }

  private _isMultiFileType(assetStorageType: AssetStorageType): boolean {
    return (
      assetStorageType.toLowerCase() === AssetStorageType.MULTIFILE ||
      assetStorageType.toLowerCase() === AssetStorageType.LARGEMULTIFILE
    );
  }

  private _groupAssetEntities(
    files: IPackageFile[],
    groupSize: number
  ): IPackageFile[][] {
    const groups: IPackageFile[][] = [];

    for (let i = 0; i < files.length; i += groupSize) {
      groups.push(files.slice(i, i + groupSize));
    }

    return groups;
  }

  private async _sendGroup(group: IPackageFile[], pkg: IPackage): Promise<any> {
    const isVault: boolean = pkg.source && pkg.source.toLowerCase() === 'vault';

    return new Promise(async (resolve: any) => {
      const promises = group.map(
        async (file: IPackageFile): Promise<AssetEntity | IEmptyAssetEntity> => {
          try {
            if (isVault) {
              return await this.getVaultAssetEntity(file);
            } else if (
              file.derivedItemType === DerivedItemType.CLIP ||
              file.derivedItemType === DerivedItemType.EXPORTED_REDACTION ||
              file.derivedItemType === DerivedItemType.SCREENSHOT
            ) {
              return await this.getDerivedAssetEntity(file, pkg);
            } else {
              return await this.getAssetEntity(file.link, pkg);
            }
          } catch (err) {
            return this.getEmptyAsset();
          }
        }
      );

      const entities: (AssetEntity | IEmptyAssetEntity)[] = await Promise.all(promises);

      pkg.assetEntitiesLen = pkg.files.length;

      resolve(entities);
    });
  }

  private _delay(delayTime: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, delayTime));
  }
}
