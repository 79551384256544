/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  HeaderTokenService,
  TokenRefreshed,
  UserInfo,
} from '@msi/commandcentral-common-header';
import { Settings } from '@msi/js-sdk';
import { ISettings, MFLoaderService, UserService } from '@msi/pp-components';
import { IUserInfoCRV } from '@msi/pp-components/lib/services/user/user.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pp-mf-sm-test',
  templateUrl: './sm.component.html',
  styleUrls: ['./sm.component.scss'],
})
export class MfSmComponent implements OnInit, OnDestroy {
  private _tokenRefreshSubscription: Subscription;

  loading = false;
  loaded = false;

  userInfo: UserInfo | IUserInfoCRV;
  config: ISettings;
  token: string;

  constructor(
    private _headerTokenService: HeaderTokenService,
    private _mfLoaderService: MFLoaderService,
    private _userService: UserService,
    private _settings: Settings<ISettings>
  ) {}

  async ngOnInit(): Promise<void> {
    const env: string = this._settings.get<string>('PLATFORM.AZURE_ENV' as any);
    const url = `https://records.${env}.commandcentral.com/components/share-manager/single-bundle.js`;
    // const url: string = 'http://localhost:3000/share-manager/single-bundle.js';

    this.loading = true;

    await this._mfLoaderService.load(url);

    this.loading = false;

    this._tokenRefreshSubscription = this._headerTokenService.tokenRefreshed.subscribe(
      async (status: TokenRefreshed) => {
        if (status === 0) {
          this.token = await this._headerTokenService.getToken();
        }
      }
    );

    this.init();
  }

  ngOnDestroy() {
    if (this._tokenRefreshSubscription) {
      this._tokenRefreshSubscription.unsubscribe();
    }
  }

  async init(): Promise<void> {
    const config: ISettings = await this._settings.getOptions();
    const token: string = await this._headerTokenService.getToken();
    const userInfo: UserInfo | IUserInfoCRV = await this._userService.getUserInfo();

    if (!config || !token || !userInfo) {
      setTimeout(() => this.init(), 100);

      return;
    }

    this.config = config;
    this.userInfo = userInfo;
    this.token = token;

    this.loaded = true;
  }
}
