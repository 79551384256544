/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()
export class GuardService implements CanActivate {
  async canActivate(): Promise<boolean> {
    return true;
  }
}
