<ng-container *transloco="let t; read: 'ccs'">
  <section class="share-package-setup">
    <header class="share-package-setup__header">
      <h5>{{ t('Package Setup header') }}</h5>
    </header>
    <form
      [formGroup]="createPackageForm"
      class="share-package-setup__main">
      <section class="share-package-setup__name-and-date">
        <section class="share-package-setup__name">
          <label for="name">{{ t('Package name') }}</label>
          <input
            class="msi-input"
            [class.border-error]="name?.errors && (name?.touched || name?.dirty)"
            id="name"
            type="text"
            [formControl]="name" />
          <ng-container *ngIf="name.invalid && (name.dirty || name.touched)">
            <span
              *ngIf="name.errors?.required"
              class="share-package-setup__error">
              {{ t('You cannot proceed without defining a package name') }}
            </span>
          </ng-container>
        </section>
        <section class="share-package-setup__expiration-date">
          <label for="expiration-date">{{ t('Expiration Date') }}</label>
          <ccs-date-picker [shortestRetentionTime]="shortestRetentionTime"></ccs-date-picker>
        </section>
      </section>

      <section class="share-package-setup__security-wrapper">
        <div class="share-package-setup__security">
          <section class="share-package-setup__security-type">
            <label>{{ t('Security Type') }}</label>
            <msi-button-group
              class="share-package-setup__security-type-btns"
              [formControl]="createPackageForm.controls['authMode']">
              <button
                class="msi-btn share-package-setup__auth-button"
                [class.msi-btn-secondary]="!authMode"
                (click)="changeSecurityType(true)">
                {{ t('Authenticated') }}
              </button>
              <button
                class="msi-btn share-package-setup__unauth-button"
                [class.msi-btn-secondary]="authMode"
                (click)="changeSecurityType(false)">
                {{ t('Unauthenticated') }}
              </button>
            </msi-button-group>
          </section>
        </div>
        <ng-container *ngIf="!authMode && enablePasswordProtectionForUnauthPkg">
          <section class="share-package-setup__password">
            <label for="password">{{ t('Password (optional)') }}</label>
            <ccs-password-creator
              [controlPassword]="createPackageForm.get('password')"
              [controlEmailPassword]="createPackageForm.get('sendEmailNotification')"
              [(isGeneratedPasswordActive)]="isGeneratedPasswordActive.value"></ccs-password-creator>
          </section>
        </ng-container>
      </section>

      <section class="share-package-setup__recipients">
        <label for="recipients">{{ t('File Recipient(s)') }}</label>
        <ccs-recipients-list
          [authMode]="authMode"
          (recipients)="onUpdateRecipients($event)"></ccs-recipients-list>
      </section>

      <header class="share-package-setup__header">
        <h5>{{ t('Recipient view header') }}</h5>
      </header>

      <section class="share-package-setup__package-title">
        <label for="title">{{ t('Title') }}</label>
        <input
          [class.border-error]="title?.errors && (title?.touched || title?.dirty)"
          class="msi-input"
          id="title"
          type="text"
          [formControl]="title" />
        <ng-container *ngIf="title.invalid && (title.dirty || title.touched)">
          <span
            *ngIf="title.errors?.required"
            class="share-package-setup__error">
            {{ t('You cannot proceed without defining a title') }}
          </span>
        </ng-container>
      </section>

      <section class="share-package-setup__message">
        <label for="message">{{ t('Message for recipient') }}</label>
        <section
          class="share-package-setup__message-info"
          [class.border-error]="message?.errors && (message?.touched || message?.dirty)">
          <input
            id="message"
            type="text"
            value="{{ defaultMessageAndTime }}"
            class="msi-input share-package-setup__message-header"
            [formControl]="message" />
          <section class="share-package-setup__message-content">
            <ng-container *ngIf="packageContents$ | async as contents">
              <p>{{ t('Contents') }}</p>
              <div
                *ngFor="let item of contents | keyvalue"
                class="share-package-setup__content-list">
                <ng-container *ngIf="item.value.length">
                  <span>&nbsp;{{ item.value.length }}&nbsp;</span>
                  <span>{{ t(item.key) }}&nbsp;</span>
                  <span>{{ t('file(s)') }}</span>
                </ng-container>
              </div>
            </ng-container>
          </section>
        </section>
        <ng-container *ngIf="message.invalid && (message.dirty || message.touched)">
          <span
            *ngIf="message.errors?.required"
            class="share-package-setup__error">
            {{ t('You cannot proceed without defining a message') }}
          </span>
        </ng-container>
      </section>
    </form>
    <footer class="share-package-setup__footer">
      <div class="share-package-setup__asset-warnings">
        <ccs-assets-warnings></ccs-assets-warnings>
      </div>
    </footer>
  </section>
</ng-container>
