<div
  *ngIf="groupedAssets"
  class="digital-evidence-gallery-view">
  <ng-container *ngFor="let item of groupedAssets | keyvalue">
    <section
      *ngIf="!displayGroups"
      class="digital-evidence-gallery-view__group-assets">
      <ng-container *ngFor="let asset of item.value">
        <ng-container *ngTemplateOutlet="assetListItem; context: { $implicit: asset }"></ng-container>
      </ng-container>
    </section>
    <msi-accordion *ngIf="displayGroups">
      <msi-accordion-item
        class="digital-evidence-gallery-view__group-title"
        [collapsable]="!printOptionEnabled"
        [isCollapsed]="setCollapsedSection(item.key)"
        (click)="toggleCollapedSection(item.key)">
        {{ item.key | transloco }} ({{ item.value.length }})
        <section class="digital-evidence-gallery-view__group-assets">
          <ng-container *ngFor="let asset of item.value">
            <ng-container *ngTemplateOutlet="assetListItem; context: { $implicit: asset }"></ng-container>
          </ng-container>
        </section>
      </msi-accordion-item>
    </msi-accordion>
  </ng-container>
</div>

<ng-template
  #assetListItem
  let-asset>
  <div
    class="digital-evidence-list-view"
    (click)="tileClick.emit(asset)">
    <div class="digital-evidence-list-view__type">
      <msi-icon
        class="evidence-type-icon"
        [name]="asset.iconName"
        [size]="20"></msi-icon>
    </div>
    <div class="digital-evidence-list-view__title">
      {{ asset | assetDisplayName: tagCategoryIncidentTypeId }}
    </div>
    <div class="digital-evidence-list-view__owner">
      {{ asset | ownerDisplayName }}
    </div>
    <div class="digital-evidence-list-view__date">
      {{ asset.startTime | DateTimeUtils }}
    </div>
    <div
      *ngIf="asset.assetStorageType !== 'externalLink'"
      class="digital-evidence-list-view__size">
      {{ asset.derivedItemType ? '' : asset.humanSize }}
    </div>
  </div>
</ng-template>
