/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Chip } from '@msi/cobalt';
import {
  AssetStorageType,
  DerivedItemType,
  ECameraType,
  IEnumeratedTags,
  IRetentionData,
} from '@msi/emm-sdk';

export enum EPackageAccessType {
  AUTH = 'accountLogin',
  GUEST = 'openLink',
}

export interface IRemovedAssetId {
  id: string;
  derivedItemId: string;
}

export interface IShareDataConfig {
  shareModel: EShareModel;
  agencyId?: string;
}

export interface ICreatePackageHeader {
  title: string;
  validTo: string;
  accessType: EPackageAccessType;
  packageVersion: EPackageVersion;
  sharedWith?: string[];
  sharedWithAuthenticated?: Chip[];
  id?: string;
  passwordCreationData?: {
    password: string;
    sendPassword: boolean;
  };
}

export interface IPackageFile {
  fileId: string;
  fileAgency: string;
  derivedItemId?: string;
  derivedItemType?: string;
}

export interface ICreatePackage {
  header: ICreatePackageHeader;
  files: IPackageFile[];
}

export interface IPackageResponse {
  header: ICreatePackageHeader;
  source?: string;
  files: IPackageFile[];
  actions: IPackageActions;
}

export interface IPackageActions {
  auditLog: boolean;
  deletePackage: boolean;
  patchPackage: boolean;
  regeneratePackageLink: boolean;
  resharePackage: boolean;
  updatePackage: boolean;
}

export interface ICreatePackageBody {
  accessType: boolean;
  to?: string[];
  toAuthenticated?: Chip[];
}

export interface ICreatePackageForm {
  name: string;
  authMode: boolean;
  subject?: string;
  validTo: string;
  to: (string | Chip)[];
  password?: string;
  sendEmailNotification?: boolean;
}

export interface IAssetEntityThumbnail {
  link?: string;
  status: EThumbnailStatus;
}

export interface IAssetEntityServices {
  thumbnail: IAssetEntityThumbnail;
}

export interface IAssetEntityThumbnail {
  link?: string;
  status: EThumbnailStatus;
}
export enum EThumbnailStatus {
  NotAvailable = 'NOT_AVAILABLE',
  NotRequested = 'NOT_REQUESTED',
  Pending = 'PENDING',
  Ready = 'READY',
}

export interface IAssetEntityServices {
  thumbnail: IAssetEntityThumbnail;
}

export enum EPackageVersion {
  Version_1 = 'v1',
  Version_2 = 'v2',
}

export enum EShareModel {
  Evidence = 'evidence',
  Records = 'records',
}

export enum EShareInfoProperty {
  ReviewFiles = 'reviewFiles',
  SharePackageSetup = 'sharePackageSetup',
}

export enum ESelectedDateOptionCode {
  ExpiresIn30Days = '30 days',
  ExpiresIn90Days = '90 days',
  ExpiresIn120Days = '120 days',
  ExpiresIn365Days = '365 days',
  Never = 'Never',
}

export enum EAssetType {
  Video = 'Video',
  Audio = 'Audio',
  Image = 'Image',
  Pdf = 'Pdf',
  File = 'File',
  Clip = 'Clip',
  Snapshot = 'Snapshot',
  Redaction = 'Redaction',
}

export enum ENotShareableAssetType {
  Incomplete = 'Incomplete',
  Directory = 'Directory',
}

export interface IShareableAsset {
  fileId: string;
  displayName: string;
  name?: string;
  size: number;
  agencyId: string;
  retention: IRetentionData;
  assetStorageType: AssetStorageType;
  mimeType: string;
  iconName: string;
  startTime: string | null;
  enumeratedTags: IEnumeratedTags[];
  source: {
    name: string;
    sourceId: string;
  };
  device?: {
    type: string;
    serialNumber: string;
    alias: string;
    firmware: string;
    cameraType: ECameraType;
  };
  humanSize?: string;
  derivedItemId?: string;
  ownerDisplayName?: string;
  derivedItemType?: DerivedItemType;
  services?: IAssetEntityServices;
  restricted?: boolean;
}
