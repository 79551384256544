/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'pp-asset-warning',
  templateUrl: './asset-warning.component.html',
  styleUrls: ['./asset-warning.component.scss'],
})
export class AssetWarningComponent {
  @Input()
  incompleteAssetsCount = 0;

  @Input()
  directoryAssetsCount = 0;

  @Input()
  shortRetentionAssetsCount = 0;

  @Input()
  restrictedAssetsCount = 0;

  @Input()
  filesLoading = false;
}
