<ng-container *ngIf="assets">
  <section class="full-screen-modal">
    <nav class="full-screen-modal__nav">
      <ccs-side-panel
        [assets]="assets"
        [reviewFilesTabActive]="reviewFilesTabActive"
        (closeModal)="closeModal.emit()">
      </ccs-side-panel>
    </nav>
    <main
      class="full-screen-modal__main"
      (click)="onResetAsset($event)">
      <ng-container *ngIf="reviewFilesTabActive">
        <ng-container *ngIf="!enableContentProjection">
          <ccs-review-files
            [assets]="assets"
            [assetsLoading]="assetsLoading"
            (assetRemoved)="assetRemoved.emit($event)"></ccs-review-files>
        </ng-container>
        <ng-container *ngIf="enableContentProjection">
          <ccs-review-files-dynamic>
            <ng-content></ng-content>
          </ccs-review-files-dynamic>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!reviewFilesTabActive">
        <ccs-share-package-setup
          [shortestRetentionTime]="shortestRetentionTime"
          [enablePasswordProtectionForUnauthPkg]="
            enablePasswordProtectionForUnauthPkg
          "></ccs-share-package-setup>
      </ng-container>
    </main>
  </section>
</ng-container>
