/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderTokenService } from '@msi/commandcentral-common-header';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';



@Injectable()
export class AuthHeadersInterceptor implements HttpInterceptor {

  private ccUrlPattern = /https:\/\/\S+\.(commandcentral|motorolasolutions)\.(com|ca)/;
  private urlsWithAuthHeaderNotNeededOrProvided = [
    '.commandcentral.com/as/token.oauth2',
    '.sec.commandcentral.ca/as/token.oauth2',
    '.commandcentral.com/idp/userinfo.openid',
    '.sec.commandcentral.ca/idp/userinfo.openid',
    '.motorolasolutions.com/idp/userinfo.openid',
    '.commandcentral.com:443/idp/userinfo.openid',
    '.sec.commandcentral.ca:443/idp/userinfo.openid',
    '.motorolasolutions.com:443/idp/userinfo.openid',
  ];

  constructor(private headerTokenService: HeaderTokenService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.isAuthHeaderNeeded(request.url)) {
      return from(this.headerTokenService.getToken())
        .pipe(switchMap((token) => {
          return this.addAuthorizationHeader(request, token, next);
        }));
    } else {
      return next.handle(request);
    }
  }

  addAuthorizationHeader(request: HttpRequest<any>, token: string, next: HttpHandler): Observable<HttpEvent<any>>  {
    if (!!token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request);
  }

  private isAuthHeaderNeeded(requestUrl: string): boolean {
    return this.urlsWithAuthHeaderNotNeededOrProvided.every((url) => !requestUrl.includes(url)) && this.ccUrlPattern.test(requestUrl);
  }
}
