<div
  *ngIf="pkg.properties.length"
  class="pp-detail-package-property">
  <h2
    id="pp-detail-package-property"
    class="pp-detail-package-property__header">
    {{ 'Property & Evidence ' | transloco }}({{ pkg.properties.length }})
  </h2>
  <ng-template
    ngFor
    let-item
    [ngForOf]="pkg.properties"
    let-i="index">
    <ng-container
      *ngTemplateOutlet="
        childItem;
        context: { $implicit: { property: item, idx: i + 1, len: pkg.properties.length } }
      ">
    </ng-container>
  </ng-template>
</div>

<ng-template
  #childItem
  let-item>
  <div class="pp-detail-package-property__item">
    <div class="pp-row-item">
      <div class="pp-detail-package-property__item__img">
        <div
          class="image"
          [class.image--default]="item.property.isDefaultThumb"
          [ngStyle]="{ 'background-image': 'url(' + item.property.thumb + ')' }"></div>
      </div>
      <div class="pp-detail-package-property__item__descr">
        <div class="pp-detail-package-property__item__descr__count"
          >{{ 'Property & Evidence ' | transloco }}{{ item.idx }}{{ ' of ' | transloco }}{{ item.len }}</div
        >
        <div
          *ngIf="item.property.model"
          class="pp-detail-package-property__item__descr__title"
          >{{ item.property.model }}</div
        >
        <div
          *ngIf="item.property.brand"
          class="pp-detail-package-property__item__descr__title">
          {{ item.property.brand }}
        </div>
        <div
          *ngIf="item.property.serialNumber"
          class="pp-detail-package-property__item__descr__subtitle">
          {{ item.property.serialNumber }}
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="informationPresent(item.property)"
    class="pp-detail-package-property__info">
    <h2>{{ 'Information' | transloco }}</h2>

    <div class="pp-detail-package-property__info__row">
      <div
        *ngIf="item.property.qty"
        class="pp-detail-package-property__info__row__cell">
        <h3>{{ 'Qty:' | transloco }}</h3>
        <span>{{ item.property.qty }}</span>
      </div>
      <div
        *ngIf="item.property.tag"
        class="pp-detail-package-property__info__row__cell">
        <h3>{{ 'Tag #:' | transloco }}</h3>
        <span>{{ item.property.tag }}</span>
      </div>
      <div
        *ngIf="item.property.serialNumber"
        class="pp-detail-package-property__info__row__cell">
        <h3>{{ 'Serial #:' | transloco }}</h3>
        <span>{{ item.property.serialNumber }}</span>
      </div>
      <div
        *ngIf="item.property.ownerApplied"
        class="pp-detail-package-property__info__row__cell">
        <h3>{{ 'Owner Applied #:' | transloco }}</h3>
        <span>{{ item.property.ownerApplied }}</span>
      </div>
      <div
        *ngIf="item.property.totalValue"
        class="pp-detail-package-property__info__row__cell">
        <h3>{{ 'Total Value:' | transloco }}</h3>
        <span>{{ item.property.totalValue }}</span>
      </div>
      <div
        *ngIf="item.property.itemStatus"
        class="pp-detail-package-property__info__row__cell">
        <h3>{{ 'Item Status:' | transloco }}</h3>
        <span>{{ item.property.itemStatus }}</span>
      </div>
      <div
        *ngIf="item.property.valueRecovered"
        class="pp-detail-package-property__info__row__cell">
        <h3>{{ 'Value Recovered:' | transloco }}</h3>
        <span>{{ item.property.valueRecovered }}</span>
      </div>
      <div
        *ngIf="item.property.accumulativeValueRecovered"
        class="pp-detail-package-property__info__row__cell">
        <h3>{{ 'Accumulative Value Recovered:' | transloco }}</h3>
        <span>{{ item.property.accumulativeValueRecovered }}</span>
      </div>
      <div
        *ngIf="item.property.dateRecovered"
        class="pp-detail-package-property__info__row__cell">
        <h3>{{ 'Date Recovered:' | transloco }}</h3>
        <span>{{ item.property.dateRecovered }}</span>
      </div>
    </div>
  </div>

  <div
    *ngIf="statusPresent(item.property)"
    class="pp-detail-package-property__info last">
    <h2>{{ 'Status' | transloco }}</h2>

    <div class="pp-detail-package-property__info__row">
      <div
        *ngIf="item.property.urcStatus"
        class="pp-detail-package-property__info__row__cell2">
        <h3>{{ 'UCR Status:' | transloco }}</h3>
        <span>{{ item.property.urcStatus }}</span>
      </div>
      <div
        *ngIf="item.property.urcStatusDate"
        class="pp-detail-package-property__info__row__cell2">
        <h3>{{ 'UCR Status Date:' | transloco }}</h3>
        <span>{{ item.property.urcStatusDate }}</span>
      </div>
      <div
        *ngIf="item.property.localStatus"
        class="pp-detail-package-property__info__row__cell2">
        <h3>{{ 'Local Status:' | transloco }}</h3>
        <span>{{ item.property.localStatus }}</span>
      </div>
      <div
        *ngIf="item.property.localStatusDate"
        class="pp-detail-package-property__info__row__cell2">
        <h3>{{ 'Local Status Date:' | transloco }}</h3>
        <span>{{ item.property.localStatusDate }}</span>
      </div>
      <div
        *ngIf="item.property.agency"
        class="pp-detail-package-property__info__row__cell2">
        <h3>{{ 'Agency:' | transloco }}</h3>
        <span>{{ item.property.agency }}</span>
      </div>
    </div>
  </div>

  <pp-detail-package-chainofcustody
    [chainOfCustody]="item.property.chainOfCustody"></pp-detail-package-chainofcustody>
</ng-template>
