<div
  *ngIf="groupedAssets"
  class="digital-evidence-gallery-view">
  <ng-container *ngFor="let item of groupedAssets | keyvalue">
    <section
      *ngIf="!displayGroups"
      class="digital-evidence-gallery-view__group-assets">
      <ng-container *ngFor="let asset of item.value">
        <ng-container *ngTemplateOutlet="assetTile; context: { $implicit: asset }"></ng-container>
      </ng-container>
    </section>
    <msi-accordion *ngIf="displayGroups">
      <msi-accordion-item
        class="digital-evidence-gallery-view__group-title"
        [collapsable]="!printOptionEnabled"
        [isCollapsed]="setCollapsedSection(item.key)"
        (click)="toggleCollapedSection(item.key)">
        {{ item.key | transloco }} ({{ item.value.length }})
        <section class="digital-evidence-gallery-view__group-assets">
          <ng-container *ngFor="let asset of item.value">
            <ng-container *ngTemplateOutlet="assetTile; context: { $implicit: asset }"></ng-container>
          </ng-container>
        </section>
      </msi-accordion-item>
    </msi-accordion>
  </ng-container>
</div>

<ng-template
  #assetTile
  let-asset>
  <pp-asset-entity-tile
    [assetEntity]="asset"
    (tileClick)="tileClick.emit(asset)">
  </pp-asset-entity-tile>
</ng-template>
