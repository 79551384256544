/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

import { Vehicle } from '../../../services/package/classes/vehicle/Vehicle';
import { IPackage } from '../../../services/package/package.interfaces';
import { PackageService } from '../../../services/package/package.service';

@Component({
  selector: 'pp-detail-package-vehicle',
  templateUrl: './detail-package-vehicle.component.html',
  styleUrls: ['./detail-package-vehicle.component.scss', '../detail-package-print.scss'],
})
export class DetailPackageVehicleComponent {
  @Input()
  pkg: IPackage;

  constructor(private _packageService: PackageService) {}

  informationPresent(vehicle: Vehicle): boolean {
    return this._packageService.informationSectionPresent(vehicle);
  }

  statusPresent(vehicle: Vehicle): boolean {
    return this._packageService.statusSectionPresent(vehicle);
  }
}
