/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CalendarComponent,
  DateTimeRange,
  I18nLanguage,
  QuickSelectionDateRange,
} from '@msi/cobalt';
import { EDateFormatterFormat } from '@msi/emm-sdk';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { fromEvent, Subscription } from 'rxjs';

import { ESelectedDateOptionCode } from '../../models/share-data.models';
import { DatePickerService } from '../../services/date-picker/date-picker.service';

@UntilDestroy()
@Component({
  selector: 'ccs-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges {
  @ViewChild('msiCalendar', { read: ElementRef }) msiCalendarRef: ElementRef;
  @ViewChild('msiCalendar') msiCalendarComponent: CalendarComponent;
  @Input() shortestRetentionTime: string;

  isOpen = false;
  value: string;
  optionName = '';
  dateTimeRange: DateTimeRange;
  dateRanges: QuickSelectionDateRange[] = [];
  startDate: NgbDate;
  endDate: NgbDate;
  calendarDateFormat: EDateFormatterFormat = EDateFormatterFormat.MonthDayYear;
  inputDateTouchedState: boolean;
  language: I18nLanguage;

  constructor(
    private _datePickerService: DatePickerService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _transloco: TranslocoService
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.dateTimeRange) {
      this.onDateChanged(simpleChanges.dateTimeRange.currentValue);
    }
  }

  ngOnInit(): void {
    this.dateTimeRange = new DateTimeRange();
    this.startDate = this._datePickerService.getStartDate();
    this.endDate = this._datePickerService.getEndDate(this.shortestRetentionTime);
    this.inputDateTouchedState = this._datePickerService.inputDateTouchedState;
    this._transloco.langChanges$.pipe(untilDestroyed(this)).subscribe((lang) => {
      this.language = lang.split('-')[0] as I18nLanguage;
    });

    this.prepareDate();
    this.selectQuickOption(
      this.dateRanges?.find((range) => range.code === this.optionName)?.text as string
    );
  }

  toggle(e?: Event): void {
    let clickOutside$: Subscription;

    if (e?.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.isOpen = !this.isOpen;

    if (e && this.isOpen) {
      this.dateRanges.forEach((dateRange) => {
        if (
          this.shortestRetentionTime &&
          (dateRange?.code === ESelectedDateOptionCode.Never ||
            dateRange.dateTo(dayjs()).isAfter(this.shortestRetentionTime))
        ) {
          const radioButtonsWrapper =
            this.msiCalendarRef.nativeElement.querySelector('.msi-radio-wrapper');

          for (const quickOption of radioButtonsWrapper.querySelectorAll(
            'msi-radio-button'
          )) {
            if (
              quickOption
                .querySelector('.msi-radio-button-text')
                .textContent.includes(dateRange.text)
            ) {
              quickOption.classList.add('msi-radio-button-disabled');
              quickOption.querySelector('input').setAttribute('disabled', 'true');
            }
          }
        }
      });

      if (this.msiCalendarRef.nativeElement) {
        this.msiCalendarRef.nativeElement.parentElement.insertAdjacentElement(
          'afterbegin',
          this.msiCalendarRef.nativeElement
        );
      }

      clickOutside$ = fromEvent(document, 'click')
        .pipe(untilDestroyed(this))
        .subscribe((el) => {
          if (!this.msiCalendarRef.nativeElement.contains(el.target)) {
            this.isOpen = false;
            clickOutside$.unsubscribe();
            this._changeDetectorRef.detectChanges();
          }
          this.setInputDateState(true);
        });

      return;
    }
    this.setInputDateState(true);
  }

  onDateChanged(range: DateTimeRange): void {
    if (
      !this.msiCalendarRef ||
      !range?.startDate ||
      !range?.startTime ||
      !range?.endDate ||
      !range?.endTime
    ) {
      return;
    }

    setTimeout(() => {
      const formattedDate = this._datePickerService.formatValue(
        range,
        this.shortestRetentionTime
      );
      this.optionName = '';
      this.value = dayjs(formattedDate).format('YYYY-MM-DD');
    });
    this.dateTimeRange = range;
  }

  onDateRangeOptionObjectChange(dateRangeOption?: QuickSelectionDateRange): void {
    if (dateRangeOption) {
      setTimeout(() => (this.optionName = dateRangeOption?.code || ''));
      this.selectQuickOption(dateRangeOption.text);
      if (dateRangeOption?.code === ESelectedDateOptionCode.Never) {
        this.value = this._transloco.translate(ESelectedDateOptionCode.Never);
      }
    }
  }

  private prepareDate(): void {
    this.dateRanges = this._datePickerService.getCapturedDate();
  }

  private selectQuickOption(name: string): void {
    if (!this.msiCalendarRef || !name) {
      return;
    }
    const radioButtonsWrapper =
      this.msiCalendarRef.nativeElement.querySelector('.msi-radio-wrapper');

    for (const quickOption of radioButtonsWrapper.querySelectorAll('msi-radio-button')) {
      if (
        quickOption.querySelector('.msi-radio-button-text').textContent.includes(name)
      ) {
        setTimeout(() => quickOption.click());
      }
    }
  }

  private setInputDateState(state: boolean): void {
    this._datePickerService.inputDateTouchedState = state;
    this.inputDateTouchedState = state;
  }
}
