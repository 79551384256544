/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ToastService } from '@msi/cobalt';
import { ResponseError, Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';

import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../../services/elastic-apm';
import { ESharesType } from '../../../services/filter/filter.enums';
import { IListBody, ISort } from '../../../services/filter/filter.interfaces';
import { PackageItem } from '../../../services/package/classes/package/PackageItem';
import { EBdpSearchUrl } from '../../../services/package/package.enums';
import { IPackageItems, PackageService } from '../../../services/package/package.service';
import { UserService } from '../../../services/user/user.service';
import { ISettings } from '../../../settings/settings.interface';
import { EShareManagerTableMode } from '../table/share-manager-table.enums';

@UntilDestroy()
@Component({
  selector: 'pp-packages-share-manager',
  templateUrl: './packages-share-manager.component.html',
  styleUrls: ['./packages-share-manager.component.scss'],
})
@ApmTransactionComponent('share manager')
export class PackagesShareManagerComponent implements OnInit, OnChanges {
  @Input()
  packages: PackageItem[] = [];

  @Input()
  loading = false;

  @Input()
  lang: string;

  @Output()
  loadMore: EventEmitter<void> = new EventEmitter();

  @Output()
  sort: EventEmitter<ISort> = new EventEmitter();

  private readonly RECORDS_APP_NAME = 'Records-CRV-UI';
  private _pageNumber = 0;
  private _continuation: string | null = null;
  private _query = '';
  private _sort: ISort | null = null;
  private _toastOptions: Record<string, any> = {
    closeButton: true,
    autoDismiss: 3000,
  };

  share: Array<ESharesType> = [ESharesType.ACTIVE];
  headerName = 'Share Manager';
  sortable = true;
  statusCode = 200;
  mode: EShareManagerTableMode = EShareManagerTableMode.LANDING;
  isError = false;
  readAuditLogsPermission: boolean;

  constructor(
    private _settings: Settings<ISettings>,
    private _packageService: PackageService,
    private _toastService: ToastService,
    private _transloco: TranslocoService,
    private _userService: UserService
  ) {
    this._toastOptions.autoDismiss = this._settings.get<number>('AUTODISMISS');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lang?.currentValue !== changes.lang?.previousValue) {
      this._transloco.setActiveLang(this.lang);
    }
  }

  ngOnInit(): void {
    this.share = [ESharesType.ACTIVE];
    this.fetch();
  }

  handleError(errResponse: ResponseError): void {
    if (errResponse) {
      if (errResponse.status === 403) {
        this._toastService.error(
          this._transloco.translate('You don\'t have permissions'),
          void 0,
          this._toastOptions
        );
      } else if (errResponse.status === 500) {
        this._toastService.error(
          this._transloco.translate('Internal Server Error'),
          void 0,
          this._toastOptions
        );
      } else if (errResponse.status === 423) {
        this._toastService.error(
          this._transloco.translate('Dependency Service Failed'),
          void 0,
          this._toastOptions
        );
      }
    }
  }

  @ApmTransactionMethod('get data')
  async fetch(continuation?: string): Promise<void> {
    this.loading = true;
    this.statusCode = null;

    if (continuation) {
      this._pageNumber++;
    } else {
      this._pageNumber = 0;
    }

    let packages: PackageItem[] = [];
    let responseContinuation: string | null = null;
    let response: IPackageItems;
    let errResponse: ResponseError;

    const options: IListBody = {
      query: this._query,
      share: this.share,
      sort: this._sort,
      pageNumber: this._pageNumber,
      searchId: continuation,
    };

    try {
      response = await this._packageService.bdpSearch(options, EBdpSearchUrl.PUBLISHERS);
      packages = response.packages;
      responseContinuation = response.continuation;
      this.statusCode = 200;
    } catch (err) {
      errResponse = err;
      this.statusCode = err.status;
      this.isError = true;
    }

    this.handleError(errResponse);

    if (continuation) {
      this.packages = [...this.packages, ...packages];
    } else {
      this.packages = packages;
    }
    this.readAuditLogsPermission = this._userService.hasReadAuditLogsPermission();
    this._continuation = responseContinuation;
    this.loading = false;
  }

  @ApmTransactionMethod('sort')
  async onSort(sort: ISort): Promise<void> {
    this._sort = sort;

    await this.fetch();
  }

  @ApmTransactionMethod('quick filter buttons')
  async onShare(share: Array<ESharesType>): Promise<void> {
    this.share = share;

    await this.fetch();
  }

  @ApmTransactionMethod('search bar')
  async onSearch(query: string): Promise<void> {
    this._query = query;

    await this.fetch();
  }

  async onLoadMore(): Promise<void> {
    if (!this.loading && this._continuation) {
      await this.fetch(this._continuation);
    }
  }

  async onRefresh(): Promise<void> {
    await this.fetch();
  }
}
