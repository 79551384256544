/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetEntity, ECameraType } from '@msi/emm-sdk';
import { IDownloadContent } from '@msi/js-sdk';
import { StreamingMetadataService as MetadataService } from '@msi/msi-download-manager';

import { DownloadItemDTO } from '../../common/DownloadManager/DownloadItemDTO';
import {
  IAllStreamingMetadata,
  IParsedStreamingMetadata,
  IStreamingMetadataCue,
  IStreamingMetadataCueData,
} from '../asset-entity/asset-entity.interface';
import { DerivedItemType, EPackageAccessType } from '../package/package.enums';

export enum EDeviceTypes {
  VEHICLE = 'VEHICLE',
  BODY_WORN = 'BODY_WORN',
}

const ssaFileBeginning = `[Script Info]
  ; This is an Advanced Sub Station Alpha v4+ script.
  Title: phpGIVSAu
  ScriptType: v4.00+
  Collisions: Normal
  PlayDepth: 0

  [V4+ Styles]
  Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding
  Style: Default,Arial,15,&H00FFFFFF,&H0300FFFF,&H00000000,&H02000000,0,0,0,0,100,100,0,0,1,2,1,2,10,10,10,1

  [Events]
  Format: Layer, Start, End, Style, Actor, MarginL, MarginR, MarginV, Effect, Text`;

const vttFileBeginning = 'WEBVTT';

@Injectable({
  providedIn: 'root',
})
export class StreamingMetadataService {
  constructor(private _streamingMetadataService: MetadataService) {}

  private _cuesToSsaFileContent(streamingMetadataCues: IStreamingMetadataCue[]): string {
    return streamingMetadataCues.reduce(
      (content: string, cue: IStreamingMetadataCue) =>
        `${content}\n${this._generateSsaDialogue(cue)}`,
      ssaFileBeginning
    );
  }

  private _cuesToVttFileContent(streamingMetadataCues: IStreamingMetadataCue[]): string {
    return streamingMetadataCues.reduce(
      (content: string, cue: IStreamingMetadataCue) =>
        `${content}\n\n${this._generateVttDialogue(cue)}`,
      vttFileBeginning
    );
  }

  private _generateSsaDialogue(cue: IStreamingMetadataCue): string {
    return `Dialogue: 0,${this._msToTime(cue.startTime)},${this._msToTime(
      cue.endTime
    )},Default,,0,0,0,,${this._generateSubtitleTxt(cue.data)}`;
  }

  private _generateVttDialogue(cue: IStreamingMetadataCue): string {
    return `${this._msToTime(cue.startTime, 3)} --> ${this._msToTime(
      cue.endTime,
      3
    )}\n${this._generateSubtitleTxt(cue.data)}`;
  }

  private _msToTime(s, msDigitsNumber = 2): string {
    // Pad to 2 or 3 digits, default is 2
    function pad(n, z?): string {
      z = z || 2;
      return `00${n}`.slice(-z);
    }

    const ms = s % 1000;
    s = (s - (s % 1000)) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;

    return `${pad(hrs)}:${pad(mins)}:${pad(secs)}.${pad(ms, msDigitsNumber)}`;
  }

  private _generateSubtitleTxt(cueData: IStreamingMetadataCueData): string {
    const sensorState = (state: number): string => (state === 1 ? 'on' : 'off');
    const txts: string[] = [];

    if (cueData.hasOwnProperty('patrolSpeed')) {
      txts.push(
        `Patrol Speed: ${cueData.patrolSpeed === '' ? 0 : cueData.patrolSpeed} MPH`
      );
    }
    if (cueData.hasOwnProperty('siren')) {
      txts.push(`Siren: ${sensorState(cueData.siren)}`);
    }
    if (cueData.hasOwnProperty('lights')) {
      txts.push(`Lights: ${sensorState(cueData.lights)}`);
    }
    if (cueData.hasOwnProperty('brakes')) {
      txts.push(`Brakes: ${sensorState(cueData.brakes)}`);
    }

    txts.push(`Mic: ${cueData.mic ? 'on' : cueData.mic === 0 ? 'muted' : 'off'}`);

    return txts.join(' | ');
  }

  private _generateSubtitlesToDownload(
    path: string,
    accessType: EPackageAccessType,
    name: string,
    content: string
  ): DownloadItemDTO {
    return new DownloadItemDTO({
      accessType: accessType,
      name: name.replace(/[/\\?%*:|"<>]/g, '_'),
      path: path,
      icon: 'ic_file',
      handlerGetContent: async (): Promise<IDownloadContent> => {
        const data: string = content;

        return { data };
      },
    });
  }

  deviceTypeBySourceName(sourceName: string): EDeviceTypes {
    const devicesMap = new Map([
      ['4RE', EDeviceTypes.VEHICLE],
      ['Si500 1.5', EDeviceTypes.BODY_WORN],
      ['Si500', EDeviceTypes.BODY_WORN],
      ['Si700', EDeviceTypes.BODY_WORN],
      ['Vista', EDeviceTypes.BODY_WORN],
      ['V300', EDeviceTypes.BODY_WORN],
      ['M500', EDeviceTypes.VEHICLE],
    ]);

    return devicesMap.get(sourceName);
  }

  getStreamingMetadata(
    allStreamingMetadata: IAllStreamingMetadata[],
    fileId: string,
    streamId: string
  ): IParsedStreamingMetadata {
    let streamingMetadata: IParsedStreamingMetadata;

    allStreamingMetadata.forEach((metadata: IAllStreamingMetadata) => {
      if (fileId === metadata.id) {
        streamingMetadata = metadata.streamingMetadata.find(
          (sm: IParsedStreamingMetadata) => (sm.streamId === streamId ? streamId : fileId)
        );
      }
    });

    return streamingMetadata;
  }

  async getAllStreamingMetadata(assets: AssetEntity[]): Promise<IAllStreamingMetadata[]> {
    const allStreamingMetadata: IAllStreamingMetadata[] = [];
    let streamingMetadata: IParsedStreamingMetadata[] = [];

    for (const asset of assets) {
      if (
        asset.isEvent() ||
        (asset.isSingleFile() && asset.derivedItemType === DerivedItemType.CLIP) ||
        (asset.isSingleFile() &&
          asset.derivedItemType === DerivedItemType.EXPORTED_REDACTION)
      ) {
        streamingMetadata = await this.parseStreamingMetadata(asset);
      }

      if (streamingMetadata.length) {
        allStreamingMetadata.push({
          id: asset.fileId,
          streamingMetadata: streamingMetadata,
        });
      }
    }

    return allStreamingMetadata;
  }

  generateSsaVttSubtitlesFiles(
    item: DownloadItemDTO,
    asset: AssetEntity,
    cues: IStreamingMetadataCue[],
    accessType: EPackageAccessType
  ): DownloadItemDTO[] {
    const filenameWithouExt = item.name.replace('.mp4', '');
    const vttFileContent = this._cuesToVttFileContent(cues);
    const ssaFileContent = this._cuesToSsaFileContent(cues);
    const path = asset.isMultiFile() ? asset.name : '';
    const vttFile = this._generateSubtitlesToDownload(
      path,
      accessType,
      `${filenameWithouExt}.vtt`,
      vttFileContent
    );
    const ssaFile = this._generateSubtitlesToDownload(
      path,
      accessType,
      `${filenameWithouExt}.ssa`,
      ssaFileContent
    );

    return [vttFile, ssaFile];
  }

  generateSsaVttSubtitles(
    allStreamingMetadata: IAllStreamingMetadata[],
    item: DownloadItemDTO,
    asset: AssetEntity,
    accessType: EPackageAccessType
  ): DownloadItemDTO[] {
    let subtitles: DownloadItemDTO[] = [];
    const fileId = asset.derivedItemId ? asset.derivedItemId : item.parentId;
    const streamId = asset.derivedItemId
      ? (asset as any).videoStreamId
      : item.stream?.streamId;

    const streamingMetadata = this.getStreamingMetadata(
      allStreamingMetadata,
      fileId,
      streamId
    );
    if (streamingMetadata) {
      subtitles = this.generateSsaVttSubtitlesFiles(
        item,
        asset,
        streamingMetadata.cues,
        accessType
      );
    }
    return subtitles;
  }

  private async parseStreamingMetadata(
    asset: AssetEntity
  ): Promise<IParsedStreamingMetadata[]> {
    const isVehicle = asset.device?.cameraType === ECameraType.InCar;
    let streamingMetadata: IParsedStreamingMetadata[] = [];

    if (isVehicle) {
      streamingMetadata = await this.getParsedStreamingMetadataForAsset(asset.fileId);
    } else if (asset.derivedItemType === DerivedItemType.CLIP) {
      streamingMetadata = await this.getParsedStreamingMetadataForClip(asset);
    } else if (asset.derivedItemType === DerivedItemType.EXPORTED_REDACTION) {
      streamingMetadata = await this.getParsedStreamingMetadataForRedaction(asset);
    }
    return streamingMetadata;
  }

  private async getParsedStreamingMetadataForAsset(
    id: string
  ): Promise<IParsedStreamingMetadata[]> {
    try {
      return await this._streamingMetadataService.getParsedDataForAsset(id);
    } catch (error) {
      return this.handleNotFoundError(error);
    }
  }

  private async getParsedStreamingMetadataForClip(
    asset: AssetEntity
  ): Promise<IParsedStreamingMetadata[]> {
    try {
      const clipStreamingMetadata =
        await this._streamingMetadataService.getParsedDataForClip(
          asset.fileId,
          asset.parentFileId
        );
      return clipStreamingMetadata.length
        ? [
            {
              cues: clipStreamingMetadata,
              streamId: asset.derivedItemId,
            },
          ]
        : [];
    } catch (error) {
      return this.handleNotFoundError(error);
    }
  }

  private async getParsedStreamingMetadataForRedaction(
    asset: AssetEntity
  ): Promise<IParsedStreamingMetadata[]> {
    try {
      const redactionStreamingMetadata =
        await this._streamingMetadataService.getParsedDataForExportedRedaction(
          asset.fileId,
          asset.parentFileId
        );
      return redactionStreamingMetadata.length
        ? [
            {
              cues: redactionStreamingMetadata,
              streamId: asset.derivedItemId,
            },
          ]
        : [];
    } catch (error) {
      return this.handleNotFoundError(error);
    }
  }

  private handleNotFoundError(error: HttpErrorResponse): [] | never {
    if (error.status === 404 || error.status === 400 || error.status === 403) {
      return [];
    }
    throw error;
  }
}
