<div class="pp-container">
  <msi-common-header [fullWidth]="false" [search]="false" [responsive]="true">
    <msi-header-left-plugin-area
      class="msi-common-header-left-plugin-area"
      applicationName="CommandCentral"
      [applicationHomeUrl]="baseUrl">
    </msi-header-left-plugin-area>
    <msi-header-right-plugin-area class="msi-common-header-right-plugin-area">
      <div class="msi-common-header-plugin">
        <cc-support-information>
          <p>
            <span *ngIf="appInfo.appVersion">Version: {{ appInfo.appVersion }}</span>
            <br>
            <span *ngIf="appInfo.buildNumber">Build Number: {{ appInfo.buildNumber }}</span>
            <br>
            <span *ngIf="appInfo.sourceVersion">Source Version: {{ appInfo.sourceVersion }}</span>
          </p>
        </cc-support-information>
      </div>
    </msi-header-right-plugin-area>
  </msi-common-header>

  <div class="main">
    <ng-content></ng-content>
  </div>
</div>