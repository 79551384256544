<pp-header-auth *ngIf="!guestMode">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</pp-header-auth>

<pp-header-guest *ngIf="guestMode">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</pp-header-guest>

<ng-template #content>
  <p>{{ 'Received' | transloco }}</p>
  <button
    class="msi-btn m-3"
    (click)="onOpenShareModal()">
    <msi-icon
      [name]="'ic_share'"
      [size]="24"
      [color]="'white'">
    </msi-icon>
    <span>{{ 'Share files' | transloco }}</span>
  </button>
  <button
    class="msi-btn m-3"
    (click)="addMoreAssets()">
    <span>Add more assets</span>
  </button>

  <section class="ccs-sandbox">
    <ccs-share-data
      #shareDataModal
      [error]="error"
      [enableContentProjection]="false">
      <!-- When [enableContentProjection] is set to true 
        componetn needs to be passed to a <ccs-share-data> modal 
        in order to display content from external app
      -->
      <!-- <app-review-files [shareModalFullscreenEnabled]="shareModalFullscreenEnabled"></app-review-files> -->
    </ccs-share-data>
  </section>
</ng-template>
