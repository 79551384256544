/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { OriginConfigMap, PlatformSpecificVariables } from '../app.config.interface';
import { originSpecificMap } from './origin-specific-matrix.config';

export class OriginSpecificConfig {
  public static getConfig(): PlatformSpecificVariables {
    const host = OriginSpecificConfig.getHost();
    const found = originSpecificMap.find((conf: OriginConfigMap) => {
      return !!conf.origins.find((origin) => origin.includes(host));
    });
    return OriginSpecificConfig.getResponse(found);
  }

  private static getResponse(hostConfig: OriginConfigMap) {
    if (hostConfig) {
      // eslint-disable-next-line no-console
      console.info(`Applying config for host ${OriginSpecificConfig.getHost()}`);
      return hostConfig.config;
    } else {
      // eslint-disable-next-line no-console
      console.info('Applying default configuration.');
      return OriginSpecificConfig.getDefault();
    }
  }

  private static getDefault(): PlatformSpecificVariables {
    return originSpecificMap[0].config;
  }

  private static getHost(): string {
    return window.location.host;
  }
}
