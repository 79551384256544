<div class="download-item">
  <div class="download-item__info">
    <div class="download-item__info-text">
      <msi-icon
        [name]="item.icon"
        class="download-item__info-text-icon"></msi-icon>
      <div
        *ngIf="item.name.length > maxLen"
        msiTooltip="{{ item.name }}"
        class="download-item__info-text-name">
        {{ name }}
      </div>
      <div
        *ngIf="item.name.length <= maxLen"
        class="download-item__info-text-name">
        {{ name }}
      </div>
    </div>
    <div class="download-item__info-extra">
      <div
        *ngIf="item.status === downloadStatus.CANCEL"
        class="download-item__info-status">
        {{ mapDownloadStatus[item.status] | transloco }}
      </div>
      <div
        *ngIf="item.status === downloadStatus.ERROR"
        msiTooltip="{{ 'Server error. File could not be downloaded.' | transloco }}"
        class="download-item__info-status">
        {{ mapDownloadStatus[item.status] | transloco }}
      </div>
      <div
        *ngIf="item.status === downloadStatus.IN_PROGRESS || item.status === downloadStatus.DONE"
        class="download-item__info-size">
        {{ item.humanSize }}
      </div>
      <div *ngIf="item.status === downloadStatus.NEW || item.status === downloadStatus.PENDING">
        <span>{{ 'Pending' | transloco }}</span>
      </div>
    </div>
  </div>
  <div class="download-item__actions">
    <ng-template [ngIf]="item.status === downloadStatus.CANCEL || item.status === downloadStatus.ERROR">
      <msi-icon
        name="ic_error"
        class="download-item__actions-pause_icon"></msi-icon>
      <button
        class="msi-btn msi-btn-icon download-item__actions-resume"
        (click)="onAction(downloadStatus.RESUME, item)">
        <msi-icon name="ic_refresh"></msi-icon>
      </button>
    </ng-template>
    <ng-template [ngIf]="item.status === downloadStatus.DONE">
      <msi-icon
        name="ic_status_available"
        class="download-item__actions-done_icon"></msi-icon>
    </ng-template>
    <ng-template [ngIf]="item.status === downloadStatus.NEW || item.status === downloadStatus.PENDING">
      <msi-spinner
        class="download-item__actions-percentage"
        size="small"
        [value]="item.percentage"></msi-spinner>
    </ng-template>
    <ng-template [ngIf]="item.status === downloadStatus.IN_PROGRESS">
      <msi-spinner
        class="download-item__actions-percentage"
        size="small"></msi-spinner>
    </ng-template>
    <button
      class="msi-btn msi-btn-icon download-item__actions-close"
      (click)="onAction(downloadStatus.CANCEL, item)">
      <msi-icon name="ic_remove"></msi-icon>
    </button>
  </div>
</div>
