/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component } from '@angular/core';

@Component({
  selector: 'pp-share-manager',
  templateUrl: './share-manager.component.html',
  styleUrls: ['./share-manager.component.scss'],
})
export class ShareManagerComponent {}
