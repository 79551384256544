/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AssetEntity } from '@msi/emm-sdk';
import { DateTimeUtils } from '@msi/js-sdk';

import { ChainOfCustody } from '../chainOfCustody/ChainOfCustody';
import { IChainOfCustody } from '../chainOfCustody/ChainOfCustody.interfaces';
import { IVehicle } from './Vehicle.interfaces';

class Vehicle {
  private _props: IVehicle = {};
  private _defaultThumb = 'assets/icons/ic_vehicle.svg';

  public hasChainOfCustody = false;

  public id: string;
  public thumb = '';
  public isDefaultThumb = true;
  public model = '';
  public name = '';
  public year = '';

  public license = '';
  public expirationDate = '';
  public type = '';
  public vin = '';
  public value = '';
  public door = '';
  public primaryColor = '';
  public secondaryColor = '';
  public dateRecovered = '';
  public valueRecovered = '';

  public urcStatus = '';
  public localStatus = '';
  public agency = '';
  public urcStatusDate = '';
  public localSatusDate = '';

  public chainOfCustody: ChainOfCustody[] = [];

  constructor(props: IVehicle) {
    this._props = props;

    this.id = this._props.id || '';
    this.thumb = this._defaultThumb;
    this.model = this._props.model || '';
    this.name = this._props.make || '';

    if (this._props.year) {
      this.year =
        typeof this._props.year === 'number'
          ? this._props.year.toString()
          : this._props.year;
    } else {
      this.year = '';
    }

    this.license = this.getLicence();
    this.expirationDate = this._props.licenseExpirationDate || '';
    this.type = this._props.vehicleType || '';
    this.vin = this._props.vin || '';
    this.value = this._props.incidentContext?.value || '';
    this.door = this._props.doors || '';
    this.primaryColor = this._props.incidentContext?.primaryColor || '';
    this.secondaryColor = this._props.incidentContext?.secondaryColor || '';
    this.dateRecovered = this._props.dateRecovered || '';
    this.valueRecovered = this._props.valueRecovered || '';

    this.urcStatus = this._props.incidentContext?.status || '';
    this.localStatus = this._props.incidentContext?.localStatus || '';
    this.agency = this._props.incidentContext?.agency || '';
    this.urcStatusDate = this.getUrcStatusDate();
    this.localSatusDate = this.getLocalSatusDate();
  }

  get(name: keyof IVehicle): IVehicle[keyof IVehicle] {
    return this._props[name];
  }

  getIncidentContextId(): string | null {
    return this._props.incidentContext?.id || null;
  }

  getLicence(): string {
    const { licenseState, licensePlate } = this._props;

    return (licenseState ? licenseState + ' ' : '') + (licensePlate ? licensePlate : '');
  }

  getUrcStatusDate(): string {
    if (this._props.incidentContext?.whenStatusDeclared) {
      return DateTimeUtils.formatTZ(this._props.incidentContext.whenStatusDeclared);
    } else {
      return '';
    }
  }

  getLocalSatusDate(): string {
    if (this._props.incidentContext?.whenLocalStatusDeclared) {
      return DateTimeUtils.formatTZ(this._props.incidentContext.whenLocalStatusDeclared);
    } else {
      return '';
    }
  }

  setChainOfCustody(chainOfCustody: IChainOfCustody[]): void {
    this.chainOfCustody = chainOfCustody.map(
      (item: IChainOfCustody) => new ChainOfCustody(item)
    );

    if (this.chainOfCustody.length) {
      this.hasChainOfCustody = true;
    }
  }

  async setAssetEntity(assetEntityPromise: Promise<AssetEntity>): Promise<void> {
    const assetEnity: AssetEntity = await assetEntityPromise;

    if (assetEnity) {
      this.isDefaultThumb = false;
      this.thumb = assetEnity.getThumb();
    }
  }
}

export { Vehicle };
