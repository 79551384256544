<pp-error-block
  *ngIf="!loading && statusCode !== 200"
  [statusCode]="statusCode"></pp-error-block>

<div
  *ngIf="!isError"
  class="pp-packages">
  <pp-packages-header [headerName]="headerName"></pp-packages-header>
  <div class="pp-packages-row">
    <pp-packages-search (changed)="onSearch($event)"></pp-packages-search>
    <pp-filter-packages
      (changed)="onShare($event)"
      [current]="share">
    </pp-filter-packages>
  </div>
  <div class="pp-packages-content">
    <pp-share-manager-table
      [statusCode]="statusCode"
      [packages]="packages"
      [loading]="loading"
      [mode]="mode"
      [lang]="lang"
      [readAuditLogsPermission]="readAuditLogsPermission"
      (loadMore)="onLoadMore()"
      (sort)="onSort($event)"
      (refresh)="onRefresh()">
    </pp-share-manager-table>
  </div>
</div>
