/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { IComment } from './EntityComment.interfaces';

class EntityComment {
  public id = '';
  public author = '';
  public whenCreated = '';
  public type = '';
  public text = '';
  public mimeType = '';

  constructor(props: IComment) {
    this.id = props.id || '';
    this.author = props.author || '';
    this.whenCreated = props.whenCreated || '';
    this.type = props.type || '';
    this.text = props.text || '';
    this.mimeType = props.mimeType || '';
  }
}

export { EntityComment };
