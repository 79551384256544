<ng-container *ngIf="!shareModalFullscreenEnabled">
  <div>
    <h6>CONTENT PROJECTION ENABLED</h6>
    <p>Regular size modal</p>
  </div>
</ng-container>
<ng-container *ngIf="shareModalFullscreenEnabled">
  <div>
    <h6>CONTENT PROJECTION ENABLED</h6>
    <p>Fullscreen modal</p>
  </div>
</ng-container>
