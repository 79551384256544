/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@msi/cobalt';
import {
  InterAppCommunicationChannelService,
  SessionManagerService,
} from '@msi/commandcentral-common-header';
import { AssetEntity } from '@msi/emm-sdk';
import { Utils } from '@msi/js-sdk';
import {
  EmmAssetViewerService,
  EUserAction,
  IDispatchedAssetViewerAction,
} from '@msi/msi-asset-viewer';
import { IDispatchedDownloadManagerAction } from '@msi/msi-download-manager';
import { MsiDownloadAuditLogger } from '@msi/msi-download-manager';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { delay, interval, Observable, Subject, take, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DownloadAllProcess } from '../../../common/DownloadManager/DownloadAll/DownloadAllProcess';
import { DownloadItemDTO } from '../../../common/DownloadManager/DownloadItemDTO';
import { EDownloadStatus } from '../../../common/DownloadManager/enums';
import { FS } from '../../../common/FS';
import { AntiThrottleService } from '../../../services/anti-throttle/anti-throttle.service';
import { IAllStreamingMetadata } from '../../../services/asset-entity/asset-entity.interface';
import {
  EActionType,
  IAuditLogData,
} from '../../../services/audit-log/audit-log.interfaces';
import { AuditLogService } from '../../../services/audit-log/audit-log.service';
import { DetailPackageService } from '../../../services/detail-package/detail-package.service';
import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../../services/elastic-apm/elastic-apm.decorators';
import { ElasticApmService } from '../../../services/elastic-apm/elastic-apm.service';
import { FeatureFlagsService } from '../../../services/feature-flags/feature-flags.service';
import { PackageItem } from '../../../services/package/classes/package/PackageItem';
import { EDetailPackageMode } from '../../../services/package/package.enums';
import { IPackage } from '../../../services/package/package.interfaces';
import { PackageService } from '../../../services/package/package.service';
import { IPrintOptions } from '../../../services/print/print.interfaces';
import { PrintService } from '../../../services/print/print.service';
import { StreamingMetadataService } from '../../../services/streaming-metadata/streaming-metadata.service';
import { downloadManagerLocalStore } from '../../../stores/download-manager/download-manager.store';
import {
  clearPackageDetails,
  loadPackage,
} from '../../+store/actions/detail-package.actions';
import {
  getPackage,
  getPackageItem,
  getPackageLoadingError,
  isPackageLoading,
} from '../../+store/selectors/detail-package.selectors';
import { EActionControls } from '../../common/action-controls/action-controls.enums';
import { DownloadManagerModalComponent } from '../../download-manager-modal/download-manager-modal.component';
import { DetailPackageMenuComponent } from '../detail-package-menu/detail-package-menu.component';
import { DetailPackageUnsubscribeComponent } from '../detail-package-unsubscribe/detail-package-unsubscribe.component';

@UntilDestroy()
@Component({
  selector: 'pp-detail-package-provider',
  templateUrl: './detail-package-provider.component.html',
  styleUrls: ['./detail-package-provider.component.scss', '../detail-package-print.scss'],
})
@ApmTransactionComponent('detail share package page')
export class DetailPackageProviderComponent implements OnInit, OnDestroy {
  @Input()
  packageId = '';

  @Input()
  mode: EDetailPackageMode = EDetailPackageMode.GUESTS;

  @Input()
  agencyId = '';

  @ViewChild('downloadManagerModal', { static: false })
  downloadManagerModal: DownloadManagerModalComponent;

  @ViewChild('detailPackageUnsubscribe', { static: false })
  detailPackageUnsubscribe: DetailPackageUnsubscribeComponent;

  @ViewChild('detailPackageMenu', { static: false })
  detailPackageMenu: DetailPackageMenuComponent;

  private readonly defaultExtendSessionInterval = 10 * 60 * 1000; // 10min
  private readonly defaultRefreshTokenInterval = 45 * 60 * 1000; // 45min

  pkg$: Observable<IPackage>;
  iPackage: IPackage;
  packageLoadingError$: Observable<HttpErrorResponse>;
  subId: any;
  streamingMetadataLoaded = false;
  headerTitle = 'CommandCentral';
  isPrintPreviewOn$: Observable<boolean>;
  printPreview$ = new Subject<boolean>();
  shouldDisplayAssetsInList$: Observable<boolean>;
  displayAssetsInList$ = new Subject<boolean>();
  packageLoading$: Observable<boolean>;
  packageItem$: Observable<PackageItem>;
  isPackageOwnerPD = false;
  printOptionEnabled: boolean;
  downloadAllInProgress = false;
  extendSessionTimer: number;
  downloadStartIntervalTime: number;
  private _fs!: FS;
  extendSessioninterval: any;
  downloadAllProcess: DownloadAllProcess;
  downloadItems: DownloadItemDTO[];

  constructor(
    private _packageService: PackageService,
    private _auditLog: AuditLogService,
    private _detailPackageService: DetailPackageService,
    private _elasticApmService: ElasticApmService,
    private _toastService: ToastService,
    private _transloco: TranslocoService,
    private _printService: PrintService,
    private _antiThrottleService: AntiThrottleService,
    private _streamingMetadataService: StreamingMetadataService,
    private _assetViewerService: EmmAssetViewerService,
    private _downloadManagerAuditService: MsiDownloadAuditLogger,
    private store: Store,
    private route: ActivatedRoute,
    private sessionManagerService: SessionManagerService,
    private interAppCommunicationChannelService: InterAppCommunicationChannelService,
    private featureFlagsService: FeatureFlagsService
  ) {
    this.isPrintPreviewOn$ = this.printPreview$.asObservable();
    this.shouldDisplayAssetsInList$ = this.displayAssetsInList$.asObservable();
  }

  ngOnDestroy(): void {
    this.downloadManagerModal?.close();
    this.streamingMetadataLoaded = false;
    this.store.dispatch(clearPackageDetails());
  }

  ngOnInit(): void {
    this.store.dispatch(
      loadPackage({ packageId: this.packageId, mode: this.mode, agencyId: this.agencyId })
    );
    this.packageLoading$ = this.store.select(isPackageLoading);
    this.pkg$ = this.store.select(getPackage).pipe(tap((pkg) => (this.iPackage = pkg)));
    this.packageItem$ = this.store.select(getPackageItem);
    this.packageLoadingError$ = this.store.select(getPackageLoadingError);
    this.subId = this._packageService.getSubscriptionId();

    if (this.subId) {
      this.detailPackageUnsubscribe.open();
    }
    if (this.mode === EDetailPackageMode.GUESTS) {
      this.headerTitle = 'CommandCentral';
      document.title = 'CommandCentral';
    } else {
      this.headerTitle = 'Share Manager';
    }

    if (this.route.snapshot.queryParamMap.has('isPackageOwnerPD')) {
      this.isPackageOwnerPD = /true/i.test(
        this.route.snapshot.queryParamMap.get('isPackageOwnerPD')
      );
    } else {
      this.isPackageOwnerPD = false;
    }

    this.initSubscriptions();
    // TODO remove when evidence download will be ready
    // below 2 methods are necessary to keep user session alive when downloading large files
    this._initDownloadAllExtendSession();
    this._initForceRefreshTokenOnDownloadAll();
  }

  initSubscriptions(): void {
    this._assetViewerService.userAction$
      .pipe(untilDestroyed(this))
      .subscribe((action: IDispatchedAssetViewerAction) => {
        if (
          action.userAction !== EUserAction.VideoPlaying &&
          action.userAction !== EUserAction.VideoPause
        ) {
          const data = this._getAssetViewerAuditLogData(action);
          this._auditLog.log(data, this.mode);
        }
      });
    this._downloadManagerAuditService.userAction$
      .pipe(untilDestroyed(this))
      .subscribe((action: IDispatchedDownloadManagerAction) => {
        const data = this._getAssetViewerAuditLogData(action);
        this._auditLog.log(data, this.mode);
      });
    this.sessionManagerService.isInactivityModalOpened$
      .pipe(untilDestroyed(this))
      .subscribe(({ sessionTimeoutModalOpened }) => {
        this._assetViewerService.changeFullscreenAvailability(!sessionTimeoutModalOpened);
      });
    this._printService.isPrintOptionEnabled$
      .pipe(untilDestroyed(this))
      .subscribe((printOptionEnabled: IPrintOptions) => {
        this.printOptionEnabled =
          printOptionEnabled.printEnabled || printOptionEnabled.printPreviewEnabled;
      });
  }

  @HostListener('window:visibilitychange', ['$event'])
  onVisibilityChange(): Promise<void> | void {
    if (document.hidden) {
      return this._antiThrottleService.start();
    } else {
      return this._antiThrottleService.stop();
    }
  }

  @ApmTransactionMethod('download all')
  async onDownload(downloadType: string): Promise<void> {
    this.downloadAllInProgress = true;
    this.downloadStartIntervalTime = dayjs().valueOf();
    this._antiThrottleService.start();
    const assets: AssetEntity[] = this.iPackage.assetEntities as any;
    const auditLogData: IAuditLogData = this._getAuditLogData(
      EActionType.Download,
      downloadType
    );

    await this._handleDirectoryPicker();
    await this.downloadManagerModal.open();

    if (downloadManagerLocalStore.cancelDownload) {
      return;
    }

    const allStreamingMetadata: IAllStreamingMetadata[] =
      await this._streamingMetadataService.getAllStreamingMetadata(assets);
    this.downloadItems = this._detailPackageService.getDownloadItemsByAssets(
      assets,
      this.iPackage,
      this.mode,
      downloadType,
      allStreamingMetadata
    );

    this.downloadItems?.forEach((item: DownloadItemDTO) =>
      item.once(EDownloadStatus.REMOVE, () => {
        Utils.removeItemFromArray(this.downloadItems, item);

        if (!this.downloadItems.length) {
          this.downloadManagerModal.close();
          this.streamingMetadataLoaded = false;
        }
      })
    );

    if (this.downloadItems?.length) {
      if (downloadType === 'all') {
        this._elasticApmService.track('download evidence and metadata');
      } else if (downloadType === 'evidence') {
        this._elasticApmService.track('download evidence');
      }

      downloadManagerLocalStore.downloadItems = this.downloadItems;
      this.streamingMetadataLoaded = true;

      this.downloadAllProcess = new DownloadAllProcess(
        this.downloadItems,
        this._auditLog,
        auditLogData,
        this.mode
      );
      await this.downloadAllProcess.run(this._fs);
    } else {
      this._toastService.warning(
        this._transloco.translate('There are no available files to download')
      );
    }
    this.downloadAllInProgress = false;
    this._antiThrottleService.stop();
  }

  @ApmTransactionMethod('download action')
  onAction(e: [string, DownloadItemDTO]): void {
    const [action, item] = e;

    item[action]();
  }

  onUnsubscribe(): void {
    this._packageService.unsubscribe(this.packageId, this.subId, this.mode);
  }

  onReshareDone(): void {
    this.store.dispatch(
      loadPackage({ packageId: this.packageId, mode: this.mode, agencyId: this.agencyId })
    );
  }

  onResetDownloadSpinner(): void {
    this.streamingMetadataLoaded = false;
    this.downloadAllInProgress = false;
    this._antiThrottleService.stop();
  }

  removeAllDownloadElements(): void {
    downloadManagerLocalStore.cancelDownload = true;
    this.downloadAllInProgress = false;
  }

  handlePrintPreview(shouldDisplayPreview: boolean): void {
    this._printService.setPrintOption(
      shouldDisplayPreview,
      EActionControls.PRINT_PREVIEW
    );
    this.printPreview$.next(shouldDisplayPreview);
    if (shouldDisplayPreview) {
      this.displayAssetsInList$.next(true);
    }
  }

  handlePrint(printInfo: IPrintOptions): void {
    this.shouldDisplayAssetsInList$
      .pipe(
        untilDestroyed(this),
        delay(5),
        filter((value) => value),
        take(1)
      )
      .subscribe(() => {
        this._printService.setPrintOptions(printInfo);
        this._printService.print();
        const printAction = printInfo.printPreviewEnabled
          ? EActionType.PrintPreview
          : EActionType.Print;
        const auditLogData: IAuditLogData = this._getAuditLogData(printAction);

        this._auditLog.log(auditLogData, this.mode, printAction);
      });
    this.displayAssetsInList$.next(true);
  }

  private async _handleDirectoryPicker(): Promise<void> {
    this._fs = await new FS().initialize();

    if (this._fs?.directoryPickerCanceled) {
      this.removeAllDownloadElements();
      return;
    }

    const permission = await this._fs
      ?.getRootHandle()
      .requestPermission({ mode: 'readwrite' });

    if (permission !== 'granted') {
      this.removeAllDownloadElements();
      return;
    }
  }

  private _getAuditLogData(action: EActionType, downloadType = 'all'): IAuditLogData {
    return this._auditLog.prepareAuditLogData(this.iPackage, action, downloadType);
  }

  private _getAssetViewerAuditLogData(
    action: IDispatchedAssetViewerAction | IDispatchedDownloadManagerAction
  ): IAuditLogData {
    return this._auditLog.prepareAssetViewerAuditLogData(this.iPackage, action);
  }

  // TODO remove when evidence download will be ready
  private _onExtendUserSession(): void {
    this._dispatchEventToPreventUserIdleOnDownload();
    this.extendSessionTimer = this.downloadStartIntervalTime - dayjs().valueOf();

    if (this.extendSessionTimer <= this.defaultExtendSessionInterval) {
      this.interAppCommunicationChannelService.postMessage({ sessionExtended: true });
    }
  }

  // TODO remove when evidence download will be ready
  private _initForceRefreshTokenOnDownloadAll(): void {
    interval(this.defaultRefreshTokenInterval)
      .pipe(
        untilDestroyed(this),
        tap(() => {
          if (this.downloadAllInProgress && this.mode === EDetailPackageMode.SHARES) {
            this._sendRequestToKeepRefreshTokenUpToDate();
          }
        })
      )
      .subscribe();
  }

  // TODO remove when evidence download will be ready
  private _initDownloadAllExtendSession(): void {
    interval(this.defaultExtendSessionInterval)
      .pipe(
        untilDestroyed(this),
        tap(() => {
          if (this.downloadAllInProgress && this.mode === EDetailPackageMode.SHARES) {
            this._onExtendUserSession();
          }
        })
      )
      .subscribe();
  }

  // TODO remove when evidence download will be ready
  private _dispatchEventToPreventUserIdleOnDownload(): void {
    const event = new MouseEvent('mousemove', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    document.dispatchEvent(event);
  }

  // TODO remove when evidence download will be ready
  private _sendRequestToKeepRefreshTokenUpToDate(): void {
    this.featureFlagsService.getFeatureFlags().pipe(untilDestroyed(this)).subscribe();
  }
}
