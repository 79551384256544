<pp-header-auth>
  <div *ngIf="!loaded" class="mf-test-loading">
    <input class="msi-input" (blur)="onBlur($event)">
  </div>
  <div *ngIf="loading" class="mf-test-loading">
    Loading...
  </div>
  <ng-template [ngIf]="loaded && config && token && userInfo && caseId">
    <pp-ssm-wrapper [id]="caseId" [token]="token" [userInfo]="userInfo" [config]="config"></pp-ssm-wrapper>
  </ng-template>
</pp-header-auth>