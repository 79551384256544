<span class="access-method">
  <msi-select
    class="method-select"
    [(ngModel)]="accessMethodControl.value"
    (ngModelChange)="protectionChange($event)">
    <msi-select-option [value]="passwordProtectionType.NONE">
      {{ 'Unauthenticated (Open link)' | transloco }}
    </msi-select-option>
    <msi-select-option [value]="passwordProtectionType.PASSWORD">
      {{ 'Password protected' | transloco }}
    </msi-select-option>
  </msi-select>
</span>
