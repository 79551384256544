/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

import { IPackage } from '../../../services/package/package.interfaces';

@Component({
  selector: 'pp-detail-package-narrative',
  templateUrl: './detail-package-narrative.component.html',
  styleUrls: [
    './detail-package-narrative.component.scss',
    '../detail-package-print.scss',
  ],
})
export class DetailPackageNarrativeComponent {
  @Input()
  pkg: IPackage;
}
