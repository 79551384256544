/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Chip, ChipType } from '@msi/cobalt';
import { Utils } from '@msi/js-sdk';

import { EKeyCode } from '../../../common/enums';
import { ElasticApmService } from '../../../services/elastic-apm/elastic-apm.service';
import {
  EFilterType,
  IAssetFilter,
  IListBody,
  ISearchFilters,
} from '../../../services/filter/filter.interfaces';

@Component({
  selector: 'pp-packages-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class PackagesSearchComponent implements OnInit {
  @Input()
  query = '';

  @Input()
  searchFilters: ISearchFilters;

  @Output()
  changed: EventEmitter<IListBody> = new EventEmitter();

  @Output()
  removeAssetFilter: EventEmitter<void> = new EventEmitter();

  @ViewChild('input')
  input: ElementRef<HTMLInputElement>;

  debounceQuery: any;
  assetFilterChip: Chip;
  assetFilter: IAssetFilter;
  value: string;

  constructor(private _elasticApmService: ElasticApmService) {
    this.debounceQuery = Utils.debounce((e: KeyboardEvent) => this.onKeyUp(e), 400);
  }

  ngOnInit(): void {
    if (this.searchFilters) {
      this._prepareAssetFilterChip(
        this.searchFilters.assetFilter.assetId.id,
        this.searchFilters.assetName,
        this.searchFilters.filterType,
        this.searchFilters.assetFilter.assetId.agencyId
      );
    }
  }

  onKeyUp(e: KeyboardEvent): void {
    if (
      e.code !== EKeyCode.ARROW_BOTTOM &&
      e.code !== EKeyCode.ARROW_LEFT &&
      e.code !== EKeyCode.ARROW_RIGHT &&
      e.code !== EKeyCode.ARROW_TOP &&
      e.code !== EKeyCode.TAB &&
      e.code !== EKeyCode.ESC
    ) {
      this.value = this.input.nativeElement.value;
      this._detectAssetIdFilter(e);
      this.changed.emit({ query: this.value, assetFilter: this.assetFilter });

      if (this.value) {
        this._elasticApmService.track('home page search');
      }
    }
  }

  onTagRemove(): void {
    this.assetFilterChip = undefined;
    this.assetFilter = undefined;
    this.removeAssetFilter.emit();
    this.changed.emit({
      query: this.input.nativeElement.value,
      assetFilter: this.assetFilter,
    });
  }

  private _prepareAssetFilterChip(
    id: string,
    value: string,
    filterType: EFilterType,
    agencyId: string = ''
  ): void {
    this.assetFilterChip = {
      id,
      value,
      chipType: ChipType.HIGHLIGHT,
      filterType,
    };
    this.assetFilter = {
      assetId: { id: value, agencyId },
    };
  }

  private _detectAssetIdFilter(e: KeyboardEvent): void {
    if (this.value.includes('asset-id:')) {
      const idxDivider = this.value.indexOf(':');
      const assetIdQuery = this.value.slice(idxDivider + 1).trim();

      if (assetIdQuery.length && e.code === EKeyCode.ENTER) {
        this._prepareAssetFilterChip(assetIdQuery, assetIdQuery, EFilterType.AssetId);
        this.input.nativeElement.value = '';
        this.value = '';
      }
    }
  }
}
