/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-review-files',
  templateUrl: './review-files.component.html',
})
export class ReviewFilesComponent {
  @Input()
  shareModalFullscreenEnabled = false;
}
