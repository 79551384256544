/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';
import { AssetEntity } from '@msi/emm-sdk';
import { Connect } from '@msi/js-sdk';

import { IPackage } from '../../../services/package/package.interfaces';
import { IDetailPackageStore } from '../../../stores/detail-package/detail-package.interfaces';
import { detailPackageLocalStore } from '../../../stores/detail-package/detail-package.store';

@Component({
  selector: 'pp-detail-package-menu',
  templateUrl: './detail-package-menu.component.html',
  styleUrls: ['./detail-package-menu.component.scss', '../detail-package-print.scss'],
})
export class DetailPackageMenuComponent {
  @Input()
  pkg: IPackage;

  @Connect<IDetailPackageStore>('assetEntities', detailPackageLocalStore)
  assetEntities: AssetEntity[];

  @Connect<IDetailPackageStore>('assetsLoaded', detailPackageLocalStore)
  assetsLoaded: boolean;

  @Connect<IDetailPackageStore>('assetsLoading', detailPackageLocalStore)
  assetsLoading: boolean;

  @Connect<IDetailPackageStore>('fullPackageAssetsLen', detailPackageLocalStore)
  fullPackageAssetsLen: number;
}
