/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { ApmBase } from '@elastic/apm-rum';

import { store } from '../../stores/global/store';
import { generateCustomMetadata } from './apm-custom-metadata-generator';
import { ETransactionTypes } from './elastic-apm.enums';

type TFn = (...args: any[]) => any;
type TTarget = Record<string, any>;

let _apmBase: ApmBase;

function ApmTransactionMethod(
  name: string,
  type: ETransactionTypes = ETransactionTypes.METHOD
): TFn {
  const fn = (
    target: TTarget,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ): PropertyDescriptor => {
    const parent = descriptor.value;

    descriptor.value = async function (...args: any[]): Promise<any> {
      let transaction;

      if (_apmBase) {
        transaction = _apmBase.startTransaction(name, type);
      }

      const customMetadata = generateCustomMetadata(name, propertyKey, args);

      if (_apmBase && Object.keys(customMetadata).length) {
        _apmBase.addLabels(customMetadata);
      }

      const result: any = await parent.apply(this, args);

      if (transaction) {
        if (store.displayElasticApmLog) {
          // eslint-disable-next-line no-console
          console.log('apm log:', name, customMetadata);
        }

        await transaction.end();
      }

      return result;
    };

    return descriptor;
  };

  return fn;
}

function ApmTransactionComponent(name): TFn {
  const fn = (constructor: TTarget) => {
    const parentNgOnInit = constructor.prototype.ngOnInit || (() => {}); // eslint-disable-line @typescript-eslint/no-empty-function
    const parentNgOnDestroy = constructor.prototype.ngOnDestroy || (() => {}); // eslint-disable-line @typescript-eslint/no-empty-function
    const key = Symbol('__ampTransaction__');

    constructor.prototype.ngOnInit = function (...args: any[]): any {
      if (_apmBase) {
        this[key] = _apmBase.startTransaction(name, ETransactionTypes.COMPONENT);
      }

      return parentNgOnInit.apply(this, args);
    };

    constructor.prototype.ngOnDestroy = function (...args: any[]): any {
      const result = parentNgOnDestroy.apply(this, args);

      if (this[key] && typeof this[key].end === 'function') {
        this[key].end();

        delete this[key];
      }

      return result;
    };
  };

  return fn;
}

function register(apmBase: ApmBase): void {
  _apmBase = apmBase;
}

export { ApmTransactionComponent, ApmTransactionMethod, register };
