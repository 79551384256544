<section class="password-validator">
  <div class="password-validator__wrapper">
    <section class="password-validator__data">
      <header class="password-validator__header">
        <msi-icon
          [size]="24"
          name="ic_emsignia"></msi-icon>
        <h5 class="password-validator__header-title">CC Sharing Sign In</h5>
      </header>
      <ng-container *ngIf="packageLocked; then validationBlocked"></ng-container>
      <ng-container *ngIf="forgotPasswordDisabled && !packageLocked; then validatePassword"></ng-container>
      <ng-container *ngIf="!forgotPasswordDisabled && !packageLocked; then forgotPassword"></ng-container>
      <ng-container *ngIf="!packageLocked">
        <footer
          class="password-validator__buttons"
          [class.center-button]="!forgotPasswordDisabled">
          <ng-container *ngIf="forgotPasswordDisabled">
            <button
              class="msi-btn msi-btn-action password-validator__forgot-button"
              [disabled]="validating"
              (click)="onForgotPassword()">
              {{ 'Forgot password?' | transloco }}
            </button>
            <button
              class="msi-btn password-validator__sign-in-button"
              type="submit"
              [disabled]="passwordForm.invalid || validating"
              (click)="onVerifyPassword()">
              <ng-container *ngIf="!validating">{{ 'View package' | transloco }}</ng-container>
              <msi-spinner
                *ngIf="validating"
                size="small"></msi-spinner>
            </button>
          </ng-container>
          <ng-container *ngIf="!forgotPasswordDisabled">
            <button
              class="msi-btn msi-btn-action password-validator__go-back-button"
              (click)="onReturnToSignIn()">
              {{ 'Return to sign in' | transloco }}
            </button>
          </ng-container>
          <button
            class="msi-btn msi-btn-secondary password-validator__sign-in-cc-button"
            [disabled]="validating"
            (click)="onSignInToCC()">
            <msi-icon
              name="ic_open"
              size="18"></msi-icon>
            {{ 'Open in Command Central' | transloco }}
          </button>
        </footer>
      </ng-container>
    </section>
  </div>
  <footer class="password-validator__footer"
    >{{ 'Copyright' | transloco }}&#169;&#32;{{ 'Motorola Solutions Inc. All rights reserved.' | transloco }}
  </footer>
</section>

<ng-template #validatePassword>
  <section class="password-validator__content">
    <div class="password-validator__content-info">
      <p>{{ 'Enter your email and the password to view package' | transloco }}</p>
      <msi-icon
        class="msi-hover msi-btn msi-btn-icon"
        name="ic_help"
        [size]="20"
        msiTooltip="{{
          'Use the email address the package was shared to and the password given by the agency' | transloco
        }}"></msi-icon>
    </div>
    <div class="password-validator__content-form">
      <form
        [formGroup]="passwordForm"
        (ngSubmit)="onVerifyPassword()"
        (keydown.enter)="onVerifyPassword()">
        <msi-input-group class="mb-3">
          <label
            msiInputLabel
            for="email"
            >{{ 'Email' | transloco }}</label
          >
          <input
            msiInput
            id="email"
            type="email"
            formControlName="email"
            [width]="'100%'" />
          <ng-container *ngIf="email.invalid && (email.dirty || email.touched)">
            <p
              msiError
              class="password-validator__error"
              *ngIf="email.hasError('required')"
              >{{ 'errorRequired' | transloco }}</p
            >
            <p
              msiError
              class="password-validator__error"
              *ngIf="email.hasError('email')"
              >{{ 'Email must be a valid email address' | transloco }}</p
            >
          </ng-container>
        </msi-input-group>
        <msi-input-group>
          <label
            msiInputLabel
            for="password"
            >{{ 'Password' | transloco }}</label
          >
          <input
            msiInput
            id="password"
            formControlName="password"
            [type]="isVisible ? 'text' : 'password'"
            [width]="'100%'" />
          <msi-icon
            msiInputSuffix
            *ngIf="password.value"
            name="{{ isVisible ? 'ic_visibility_off' : 'ic_visibility_on' }}"
            (click)="isVisible = !isVisible"></msi-icon>
        </msi-input-group>
        <ng-container *ngIf="(password.invalid && (password.dirty || password.touched)) || packageLocked">
          <p
            msiError
            class="password-validator__error"
            >{{ passwordError | transloco }}</p
          >
        </ng-container>
      </form>
    </div>
  </section>
</ng-template>

<ng-template #forgotPassword>
  <section class="password-validator__content">
    <p>{{ 'Forgot password?' | transloco }}</p>
    <p>{{ 'Contact the agency that shared the package with you to retrieve your password.' | transloco }}</p>
  </section>
</ng-template>

<ng-template #validationBlocked>
  <section class="password-validator__content password-validator__content_locked">
    <div>
      <p>
        {{ 'Package is locked from incorrect password attempts.' | transloco }}
      </p>
      <p>{{ 'Contact the agency that shared the package with you to reset your password.' | transloco }}</p>
    </div>
    <msi-icon
      class="msi-hover msi-btn msi-btn-icon"
      name="ic_help"
      [size]="20"
      msiTooltip="{{
        'Check the email in which the package was shared for package name reference' | transloco
      }}"></msi-icon>
  </section>
</ng-template>
