/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService, MsiModalConfig, MsiModalRef, ToastService } from '@msi/cobalt';
import { Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { catchError, Observable } from 'rxjs';

import { ISpecificTypeAssetCount } from '../../services/asset-entity/asset-entity.interface';
import { AssetVerificationService } from '../../services/asset-verification/asset-verification.service';
import { PasswordChangeService } from '../../services/change-password/change-password.service';
import { ElasticApmService } from '../../services/elastic-apm';
import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../services/elastic-apm/elastic-apm.decorators';
import { Officer } from '../../services/officer/Officer';
import { ISimpleOfficer } from '../../services/officer/officer.interfaces';
import { OfficerService } from '../../services/officer/officer.service';
import { EAccessProtectionType } from '../../services/package/classes/accessProtection/AccessProtection.enum';
import { ICreatePackageForm } from '../../services/package/create-package.interfaces';
import {
  EPackageAccessType,
  EPackageVersionType,
} from '../../services/package/package.enums';
import { IPackage } from '../../services/package/package.interfaces';
import { PackageService } from '../../services/package/package.service';
import { SystemService } from '../../services/system-service/system.service';
import { ISettings } from '../../settings/settings.interface';
import { alphanumericValidator } from '../../utils/validators/alphanumeric.validator';
import { caseValidator } from '../../utils/validators/case.validator';
import { passwordLengthValidator } from '../../utils/validators/password-length.validator';
import { specialCharacterValidator } from '../../utils/validators/special-character.validator';
import { getFeatureFlag } from '../+store/selectors/feature-flags.selector';
import { EActionControls } from '../common/action-controls/action-controls.enums';
import { ProsecutorValidator, TPros } from '../common/prosecutors/prosecutor-validator';

@UntilDestroy()
@Component({
  selector: 'pp-modify-package',
  templateUrl: './modify-package.component.html',
  styleUrls: ['./modify-package.component.scss'],
})
@ApmTransactionComponent('modify modal')
export class ModifyPackageComponent implements OnInit {
  @Input()
  loading = false;

  @Output()
  done: EventEmitter<IPackage> = new EventEmitter();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter();

  @Output()
  send: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('modifyPackageTemplate', { static: false })
  modifyPackageTemplate: TemplateRef<any>;

  isUnauthSharingEnabled = true;

  title = 'Modify package';
  pkg: IPackage;
  modalRef: MsiModalRef;
  authMode = true;
  form: UntypedFormGroup;
  sharing = false;
  isEmptyAgency = false;
  prosecutors: Officer[] = [];
  serviceError = '';
  isPasswordChangedConfirmed = false;
  isNewRecipintAdded = false;
  passwordProtectionType = EAccessProtectionType;
  shortRetentionAssets$: Observable<ISpecificTypeAssetCount>;
  readonly modifyAction: EActionControls = EActionControls.MODIFY;
  enablePasswordProtectionForUnauthPkg: boolean;
  packageName = '';
  sentRecipients: (ISimpleOfficer | string)[] = [];
  emails: TPros[] = [];

  config: MsiModalConfig = {
    disableClose: true,
    hasBackdrop: true,
    width: 610,
  };

  readonly _pv: ProsecutorValidator = new ProsecutorValidator();

  constructor(
    private _settings: Settings<ISettings>,
    private _modalService: ModalService,
    private _packageService: PackageService,
    private _officerService: OfficerService,
    private _toastService: ToastService,
    private _transloco: TranslocoService,
    private _assetVerificationService: AssetVerificationService,
    private _elasticApmService: ElasticApmService,
    private _store: Store,
    private _passwordChangeService: PasswordChangeService,
    private _systemService: SystemService
  ) {}

  ngOnInit(): void {
    // TODO enable this after fix: property 'retention' needs to be added to files
    // this.shortRetentionAssets$ = this._assetVerificationService.shortRetentionAssets$;
    this.isUnauthSharingEnabled = this._systemService.isFedRamp() ? false : true;

    this._officerService.recipientsError$
      .pipe(untilDestroyed(this))
      .subscribe((error: string) => {
        this.serviceError = error;
      });

    this._officerService.isNewRecipintAdded$
      .pipe(untilDestroyed(this))
      .subscribe((isAdded: boolean) => {
        this.isNewRecipintAdded = isAdded;
      });

    this._passwordChangeService.isPasswordChangeConfirmed$
      .pipe(untilDestroyed(this))
      .subscribe((isConfirmed: boolean) => {
        this.isPasswordChangedConfirmed = isConfirmed;
      });

    const validator: any = (to: UntypedFormControl) => {
      const prosecutors: TPros[] = to.value;

      if (!prosecutors?.length) {
        return 'Please fill "to" field';
      }

      return this._pv.getAuthModeMessage(this.authMode, prosecutors);
    };

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.packageName),
      authMode: new UntypedFormControl('1'),
      validTo: new UntypedFormControl(null),
      to: new UntypedFormControl([], validator),
      password: new UntypedFormControl('', [
        passwordLengthValidator(15),
        Validators.maxLength(32),
        alphanumericValidator(),
        caseValidator(),
        specialCharacterValidator(),
      ]),
      sendEmailNotification: new UntypedFormControl(false),
      accessProtectionType: new UntypedFormControl(EAccessProtectionType.NONE),
    });

    this.form.valueChanges.subscribe((data: any) => {
      if (this.packageName !== data.name) {
        this.packageName = data.name.trim().length === 0 ? '' : data.name;
        this.form.controls.name.setValue(this.packageName);
      }

      this._store
        .select(getFeatureFlag('password-protected-unauth-pkg'))
        .subscribe((value: boolean) => {
          this.enablePasswordProtectionForUnauthPkg = value;
        });
    });
  }

  open(pkg: IPackage): void {
    if (!this.modalRef) {
      this.pkg = pkg;
      this.authMode = this.pkg.header.accessType === EPackageAccessType.AUTH;
      this._officerService.previousRecipients = this.authMode
        ? this.pkg.header.sharedWithAuthenticated
        : this.pkg.header.sharedWith;
      this.sentRecipients = this.authMode
        ? this.pkg.header.sharedWithAuthenticated
        : this.pkg.header.sharedWith;

      this.form.reset({
        name: this.pkg.header.title,
        authMode: this.authMode ? '1' : '',
        validTo: dayjs(this.pkg.header.validTo),
        to: this.emails,
        sendEmailNotification: false,
        accessProtectionType: this.pkg.header.accessProtectionType,
      });

      this.modalRef = this._modalService.open(this.modifyPackageTemplate, this.config);
    }
  }

  @HostListener('window:popstate', ['$event'])
  close(): void {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = undefined;
      this._assetVerificationService.resetAssetVerifications();
    }
    this.isPasswordChangedConfirmed = false;
  }

  onUpdateSelectedProsecutors(prosecutors: TPros[]): void {
    this.emails = prosecutors;
    this.form.controls.to.setValue(prosecutors);
  }

  @ApmTransactionMethod('modify package')
  async onSubmit(e: Event): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    this.sharing = true;
    this.send.emit(true);

    const toastOptions: Record<string, any> = {
      closeButton: true,
      autoDismiss: this._settings.get<number>('AUTODISMISS'),
    };

    const data: ICreatePackageForm = {
      name: this.form.value.name.trim(),
      authMode: this.authMode,
      validTo: this.form.value.validTo,
      to: this.form.value.to,
      password: this.form.value.password,
      sendEmailNotification: this.form.value.sendEmailNotification,
      accessProtectionType: this.form.value.accessProtectionType,
    };

    let pkg: IPackage;

    if (this.pkg.header.packageVersion === EPackageVersionType.Version_1) {
      try {
        pkg = await this._packageService.modify(data, this.pkg);
      } catch (err) {
        this._toastService.error(
          this._transloco.translate('Package is not shared. Server error.'),
          void 0,
          toastOptions
        );
        this.sharing = false;
        this.send.emit(false);
        this.done.emit(pkg);
        return;
      }
    } else {
      this._packageService
        .modifyV2(data, this.pkg)
        .pipe(
          untilDestroyed(this),
          catchError(() => {
            this._toastService.error(
              this._transloco.translate('Packge is not shared. Server error.'),
              void 0,
              toastOptions
            );
            return [];
          })
        )
        .subscribe(() => {
          this._toastService.success(
            this._transloco.translate(
              'Your request is being processed. The status of the operation will be available later.'
            ),
            void 0,
            toastOptions
          );
          this.done.emit(this.pkg);
        });
    }

    this._toastService.success(
      this._transloco.translate(
        'Your request is being processed. The status of the operation will be available later.'
      ),
      void 0,
      toastOptions
    );
    this._elasticApmService.track(`Package valid for ${this.form.value.validTo}`);

    this.close();

    this.sharing = false;
    this.send.emit(false);
    this.done.emit(pkg);
  }
}
