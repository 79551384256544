/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IShareableAsset } from '../../../models/share-data.models';
import { ShareDataService } from '../../../services/share-data/share-data.service';

@Component({
  selector: 'ccs-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss'],
})
export class ModalContentComponent {
  @Input()
  assets: IShareableAsset[];

  @Input()
  enableContentProjection = false;

  @Input()
  assetsLoading = true;

  @Output()
  toggleModalFullScreen: EventEmitter<void> = new EventEmitter();

  @Output()
  assetRemoved: EventEmitter<IShareableAsset> = new EventEmitter();

  constructor(private _shareDataService: ShareDataService) {}

  onPreview(): void {
    this._shareDataService.displayReviewFilesTab(true);
    this.toggleModalFullScreen.emit();
  }
}
