/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[ppInfiniteScroll]',
  exportAs: 'ppInfiniteScroll',
})
class InfiniteScrollDirective {
  @Input()
  ppISMargin = 280;

  @Input()
  ppISLoading = false;

  @Output()
  ppISBottom: EventEmitter<void> = new EventEmitter();

  private _loaded = true;

  constructor(private _elRef: ElementRef) {}

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    const $el: any = this._elRef.nativeElement;

    if ($el && !this.ppISLoading && this._loaded) {
      const toBottom =
        $el.scrollHeight - $el.scrollTop - $el.offsetHeight - this.ppISMargin;

      if (toBottom <= 0) {
        this._loaded = false;

        this.ppISBottom.emit();

        setTimeout(() => (this._loaded = true), 500);
      }
    }
  }
}

export { InfiniteScrollDirective };
