/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

export interface IDigitalEvidenceSortByConfig {
  property: EDigitalEvidenceSortProperty;
  direction: EDigitalEvidenceSortDirection;
}

export interface IDigitalEvidenceGroupByConfig {
  property: EDigitalEvidenceGroupProperty;
}

export enum EDigitalEvidenceSortProperty {
  CaptureDate = 'startTime',
  UploadDate = 'uploadedTime',
  UploadedBy = 'ownerDisplayName',
  Name = 'name',
}

export enum EDigitalEvidenceSortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum EDigitalEvidenceGroupProperty {
  None = 'noGroupingSelected',
  OfficerName = 'officerName',
  Type = 'type',
}
