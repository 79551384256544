/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';

import { settings } from '../../../settings/settings';
import { EActionControls } from '../action-controls/action-controls.enums';
import { IExpire } from './expires.interfaces';

@Component({
  selector: 'pp-expires',
  templateUrl: './expires.component.html',
  styleUrls: ['./expires.component.scss'],
})
export class ExpiresComponent implements OnInit {
  @Input() pkgAction: EActionControls;
  @Input() dateControl: FormControl;
  @Input() oldDate: string;

  today = dayjs();
  defaultValue = 90;
  modifyDefaultValue = 0;
  expiresIn = this.defaultValue;

  createExpires: IExpire[] = [
    { id: 30, name: '30 days' },
    { id: 90, name: '90 days' },
    { id: 120, name: '120 days' },
    { id: 365, name: '365 days' },
    { id: settings.get<number>('NEVER_ID'), name: 'Never' },
  ];
  modifyExpires: IExpire[] = [
    { id: 0, name: 'Keep unchanged' },
    { id: 30, name: '30 days from now' },
    { id: 90, name: '90 days from now' },
    { id: 120, name: '120 days from now' },
    { id: 365, name: '365 days from now' },
    { id: settings.get<number>('NEVER_ID'), name: 'Never' },
  ];

  readonly createAction: EActionControls = EActionControls.CREATE;
  readonly modifyAction: EActionControls = EActionControls.MODIFY;

  get value(): number {
    return this.expiresIn;
  }

  set value(val: number) {
    if (val !== this.expiresIn) {
      this.expiresIn = val;
    }
  }

  ngOnInit(): void {
    this.expiresIn =
      this.pkgAction === this.createAction ? this.defaultValue : this.modifyDefaultValue;
    this.dateControl.setValue(this.today.add(this.expiresIn, 'day'));
  }

  onChange(val: number): void {
    if (val !== 0) {
      this.value = val;
      this.dateControl.setValue(this.today.add(val, 'day'));
    } else {
      this.dateControl.setValue(dayjs(this.oldDate));
    }
  }
}
