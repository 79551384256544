<div class="pp-packages">
  <div class="pp-packages-row">
    <pp-packages-search
      (changed)="onSearch($event)"
      (removeAssetFilter)="onRemoveAssetFilter()"
      [searchFilters]="searchFilters"
      [query]="getQuery(isReceived$ | async) | async"></pp-packages-search>
    <pp-filter-packages
      [isReceived]="isReceived$ | async"
      (changed)="onShare($event)"
      [current]="provideFilters() | async"></pp-filter-packages>
    <div
      class="d-flex justify-content-end mt-2 mr-2"
      *ngIf="sharePermissions?.publishShares && sharePermissions?.viewShares">
      <button
        (click)="switchTables(true)"
        class="msi-btn"
        [ngClass]="(isReceived$ | async) ? '' : 'msi-btn-secondary'"
        >{{ 'Received' | transloco }}
      </button>
      <button
        (click)="switchTables(false)"
        class="msi-btn"
        [ngClass]="(isReceived$ | async) ? 'msi-btn-secondary' : ''"
        >{{ 'Shared' | transloco }}
      </button>
    </div>
  </div>
  <div class="pp-packages-content">
    <pp-packages-table
      *ngIf="isReceived$ | async; else shareManagerTable"
      [statusCode]="isReceivedError$ | async"
      [packages]="receivedPackages$ | async"
      [loading]="receivedPackagesLoading$ | async"
      [readAuditLogsPermission]="readAuditLogsPermission"
      (loadMore)="onLoadMore()"
      (sort)="onSort($event)"
      (reload)="onReload()"></pp-packages-table>
  </div>
  <ng-template #shareManagerTable>
    <pp-share-manager-table
      [statusCode]="isSharedError$ | async"
      [packages]="sharedPackages$ | async"
      [loading]="sharedPackagesLoading$ | async"
      [mode]="mode"
      [lang]="lang"
      [readAuditLogsPermission]="readAuditLogsPermission"
      (loadMore)="onLoadMore()"
      (sort)="onSort($event)"
      (refresh)="onRefresh()">
    </pp-share-manager-table>
    <div
      class="callout-container"
      *ngIf="isAnySharedPackageLocked$ | async">
      <msi-callout
        type="info"
        title="{{ 'Locked packages' | transloco }}"
        closable
        #callout
        (close)="onCalloutClose()">
        <div calloutContent>
          <span>{{ 'The lock icon' | transloco }}&nbsp;</span>
          <span class="lock-icon"
            ><msi-icon
              name="ic_lock_closed"
              size="18"></msi-icon
          ></span>
          <span>
            {{
              'indicates that a package has been locked because of too many sign-in attempts on password protected packages. Renew link or modify password to unlock the package.'
                | transloco
            }}</span
          >
        </div>
        <div calloutFooter>
          <button
            class="msi-btn msi-btn-action"
            (click)="callout.closeButtonPressed()"
            >{{ 'Dismiss' | transloco }}
          </button>
        </div>
      </msi-callout>
    </div>
  </ng-template>
</div>
