/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { PackageItem } from '../../services/package/classes/package/PackageItem';

@Pipe({
  name: 'msiPackageDetailsRedirectUrl',
})
export class PackageDetailsRedirectUrlPipe implements PipeTransform {
  transform(packageItem: PackageItem): string {
    if (packageItem.link) {
      if (packageItem.isOpenLink() && packageItem.isPasswordProtected()) {
        if (packageItem.isPackageVersionV2) {
          return `protected/${packageItem.link}?isPackageOwnerPD=true`;
        } else {
          return `protected/${packageItem.link}?isPackageOwnerPD=true&agency=${packageItem.agency}`;
        }
      } else if (packageItem.isOpenLink()) {
        return `public/${packageItem.link}?isPackageOwnerPD=true`;
      } else if (packageItem.isAccountLogin()) {
        return `packages/${packageItem.link}?isPackageOwnerPD=true`;
      }
    }
  }
}
