/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AntiThrottleService {
  private static readonly PING_INTERVAL = 60000;
  private subscription!: Subscription;
  private sendChannel!: RTCDataChannel | null;
  private receiveChannel!: RTCDataChannel | null;
  private localConnection: RTCPeerConnection = new RTCPeerConnection();
  private remoteConnection: RTCPeerConnection = new RTCPeerConnection();

  constructor() {
    this.localConnection.onicecandidate = (e): boolean | Promise<void> => {
      return !e.candidate || this.remoteConnection.addIceCandidate(e.candidate);
    };
    this.remoteConnection.onicecandidate = (e): boolean | Promise<void> => {
      return !e.candidate || this.localConnection.addIceCandidate(e.candidate);
    };
  }

  async start(): Promise<void> {
    this.sendChannel = this.localConnection.createDataChannel('channel');
    this.addSendChanelHandlers();
    this.addRemoteConnectionHandlers();

    try {
      await this.establishConnection();
    } catch (e) {
      throw new Error('Failed to establish RTC connection.');
    }
  }

  stop(): void {
    this.sendChannel?.close();
    this.receiveChannel?.close();
    this.sendChannel = null;
    this.receiveChannel = null;
  }

  private async establishConnection(): Promise<void> {
    const offer = await this.localConnection.createOffer();
    await this.localConnection.setLocalDescription(offer);
    await this.remoteConnection.setRemoteDescription(
      this.localConnection?.localDescription as RTCSessionDescription
    );
    const answer = await this.remoteConnection.createAnswer();
    await this.remoteConnection.setLocalDescription(answer);
    await this.localConnection.setRemoteDescription(
      this.remoteConnection?.localDescription as RTCSessionDescription
    );
  }

  private addRemoteConnectionHandlers(): void {
    this.remoteConnection.ondatachannel = this.onRemoteConnectionDataChannelHandler();
  }

  private addSendChanelHandlers(): void {
    if (this.sendChannel) {
      this.sendChannel.onopen = this.onOpenSendChannelHandler();
      this.sendChannel.onclose = this.onCloseSendChannelHandler();
    }
  }

  private onOpenSendChannelHandler(): () => void {
    return () => {
      this.subscription = interval(AntiThrottleService.PING_INTERVAL).subscribe(() => {
        this.sendChannel?.send('ping');
      });
    };
  }

  private onCloseSendChannelHandler(): () => void {
    return () => {
      this.subscription?.unsubscribe();
    };
  }

  private onRemoteConnectionDataChannelHandler(): (
    rtcDataChannelEvent: RTCDataChannelEvent
  ) => void {
    return (rtcDataChannelEvent: RTCDataChannelEvent) => {
      this.receiveChannel = rtcDataChannelEvent.channel;
      this.receiveChannel.onmessage = (): void => {
        this.receiveChannel?.send('pong');
      };
    };
  }
}
