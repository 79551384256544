/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderTokenService, TokenRefreshed } from '@msi/commandcentral-common-header';
import { ShareConfigService } from '@msi/commandcentral-share-data';
import { AllowHooks as EmmAllowHooks, emm, hooks as emmHooks } from '@msi/emm-sdk';
import { Api, LocalStorage } from '@msi/js-sdk';
import { settings } from '@msi/pp-components';
import { Store } from '@ngrx/store';
import { loadFeatureFlags } from 'projects/pp-components/src/lib/components/+store/actions/feature-flags.actions';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'prosecutor-portal';
  tokenRefreshSubscription: Subscription;

  private _tokenKey = 'authorization';

  constructor(
    private _headerTokenService: HeaderTokenService,
    private _localStorage: LocalStorage,
    private _store: Store,
    private _shareConfigService: ShareConfigService
  ) {
    const updateTokenHook = async () => {
      const authToken: string | void = await this._headerTokenService.getToken();

      if (authToken) {
        this.updateAuthToken(authToken);
      }
    };

    const setApiHeadersHook = async (headers: Headers) => {
      let authToken: string | void = await this._headerTokenService.getToken();

      authToken = authToken || settings.get<string>('ACCESS_TOKEN');

      if (authToken && !headers.has(this._tokenKey)) {
        headers.append(
          this._tokenKey,
          settings.get<string>('TOKEN_TYPE') + ' ' + authToken
        );
      }
    };

    Api.headersRequest(setApiHeadersHook);
    Api.beforeRequest(updateTokenHook);

    emmHooks.set(EmmAllowHooks.BEFORE_REQUEST, updateTokenHook);
  }

  ngOnInit(): void {
    this._store.dispatch(loadFeatureFlags());

    const options: Record<string, any> = settings.getOptions();
    if (options.PLATFORM.AZURE_ENV === 'cccs-prod') {
      emm.initUrls('canada-prod');
    } else if (options.PLATFORM.AZURE_ENV === 'cccs-preprod') {
      const evidenceUrl: string = options.PLATFORM.EVIDENCE_URL;
      emm.initUrls(
        'canada-preprod',
        evidenceUrl.endsWith('/') ? evidenceUrl.slice(0, -1) : evidenceUrl
      );
    } else {
      emm.initUrls(options.PLATFORM.AZURE_ENV);
    }
    emm.setOptions(options.EMM);
  }

  async ngAfterViewInit() {
    this.tokenRefreshSubscription = this._headerTokenService.tokenRefreshed.subscribe(
      async (status: TokenRefreshed) => {
        if (status === 0) {
          const newAccessToken = await this._headerTokenService.getToken();
          this.updateAuthToken(newAccessToken);
          this._shareConfigService.setAccessToken(newAccessToken);
        }
      }
    );
    this._shareConfigService.setAgencyId('msi.rjs.fe.e2e.pd.dev');
  }

  ngOnDestroy() {
    if (this.tokenRefreshSubscription) {
      this.tokenRefreshSubscription.unsubscribe();
    }
    this._localStorage.remove('theme');
  }

  updateAuthToken(token: string) {
    settings.set('ACCESS_TOKEN', token);
    emm.settings.setOption('ACCESS_TOKEN', token);
  }
}
