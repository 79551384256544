/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Officer } from '../../../services/officer/Officer';
import { Email } from '../../../services/package/Email';

type TPros = Officer | Email;

const guestError =
  'Please remove or correct email addresses without an authenticated account.';
const valueError = 'One or more values are not correct';

class ProsecutorValidator {
  getInvalidEmails(emails: TPros[]): TPros[] {
    return emails.filter((item: TPros): boolean => !item.isValid());
  }

  getAuthModeGuestEmails(emails: TPros[]): TPros[] {
    return emails.filter((item: TPros) => item instanceof Email);
  }

  getAuthModeMessageByData(
    authMode: boolean,
    invalidEmail: TPros[],
    guestsEmails: TPros[]
  ): string {
    let message = '';

    if (authMode) {
      if (guestsEmails.length) {
        message = guestError;
      }
    } else {
      if (invalidEmail.length) {
        message = valueError;
      }
    }

    return message;
  }

  getAuthModeMessage(authMode: boolean, emails: TPros[]): string {
    return this.getAuthModeMessageByData(
      authMode,
      this.getInvalidEmails(emails),
      this.getAuthModeGuestEmails(emails)
    );
  }
}

export { guestError, ProsecutorValidator, TPros, valueError };
