/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { IPhone } from './Phone.interfaces';

class Phone {
  public id = '';
  public type = '';
  public number = '';
  public extension = '';

  constructor(props: IPhone) {
    this.id = props.id;
    this.type = props.type || '';
    this.number = props.number || '';
    this.extension = props.extension || '';
  }
}

export { Phone };
