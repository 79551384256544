/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AbstractControl } from '@angular/forms';
import { Chip } from '@msi/cobalt';

export function ValidateRecipients(control: AbstractControl) {
  const containInvalidEmails = control.value?.some(
    (recipient: Chip) => !recipient.isValid
  );

  return containInvalidEmails ? { containInvalidEmails: true } : null;
}
