/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalService, MsiModalConfig, MsiModalRef, ToastService } from '@msi/cobalt';
import { Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';

import { ISpecificTypeAssetCount } from '../../services/asset-entity/asset-entity.interface';
import { AssetVerificationService } from '../../services/asset-verification/asset-verification.service';
import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../services/elastic-apm/elastic-apm.decorators';
import { PackageContent } from '../../services/package/classes/package/PackageContent';
import {
  IFilesSharingInfo,
  IPackage,
  IPackageUpdatesResponse,
  ISearchResult,
} from '../../services/package/package.interfaces';
import { PackageService } from '../../services/package/package.service';
import { PackageUpdatesService } from '../../services/package/package-updates.service';
import { IPatchDiff } from '../../services/package-diff/package-diff.interfaces';
import { PackageDiffService } from '../../services/package-diff/package-diff.service';
import { ISettings } from '../../settings/settings.interface';

@Component({
  selector: 'pp-update-package-content',
  templateUrl: './update-package-content.component.html',
  styleUrls: ['./update-package-content.component.scss'],
})
@ApmTransactionComponent('update modal')
export class UpdatePackageContentComponent implements OnInit {
  @Input()
  loading = false;

  @Input()
  title = 'Send Updates?';

  @Output()
  done: EventEmitter<IPackage> = new EventEmitter();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter();

  @Output()
  send: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('updatePackageContentTemplate', { static: false })
  updatePackageContentTemplate: TemplateRef<any>;

  lawIncidentData: PackageContent;
  IFilesSharingInfo: IFilesSharingInfo;
  searchResult: ISearchResult;
  modalRef: MsiModalRef;
  sharing = false;
  packageDiff: string[];
  pkg: IPackage;
  noUpdates = false;
  incompleteAssets$: Observable<ISpecificTypeAssetCount>;
  directoryAssets$: Observable<ISpecificTypeAssetCount>;
  packageUpdatesFailed = false;

  config: MsiModalConfig = {
    disableClose: true,
    hasBackdrop: true,
    width: 610,
  };

  toastOptions: Record<string, any> = {
    closeButton: true,
    autoDismiss: this._settings.get<number>('AUTODISMISS'),
  };

  constructor(
    private _settings: Settings<ISettings>,
    private _modalService: ModalService,
    private _packageService: PackageService,
    private _packageUpdatesService: PackageUpdatesService,
    private _toastService: ToastService,
    private _packageDiffService: PackageDiffService,
    private _transloco: TranslocoService,
    private _assetVerificationService: AssetVerificationService
  ) {}

  ngOnInit(): void {
    this.incompleteAssets$ = this._assetVerificationService.incompleteAssets$;
    this.directoryAssets$ = this._assetVerificationService.directoryAssets$;
  }

  async open(pkg: IPackage): Promise<void> {
    if (!this.modalRef) {
      this.pkg = cloneDeep(pkg);

      await this.updatePackageDiff();

      this.noUpdates = this.packageDiff?.length === 0;

      if (!this.packageUpdatesFailed) {
        this.modalRef = this._modalService.open(
          this.updatePackageContentTemplate,
          this.config
        );
      }
    }
  }

  @HostListener('window:popstate', ['$event'])
  @ApmTransactionMethod('close update package')
  close(): void {
    if (this.modalRef) {
      this.modalRef.close();

      this.modalRef = undefined;
      this._assetVerificationService.resetAssetVerifications();
    }
  }

  onCancel(e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.close();
  }

  @ApmTransactionMethod('update package')
  async onSubmit(e: Event): Promise<void> {
    this.sharing = true;
    this.send.emit(true);

    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    let response: IPackage;

    if (this.searchResult) {
      this.pkg.content = this.searchResult.incidentReport;
      this.pkg.files = this.searchResult.lawIncidentFiles.items;
    }

    try {
      response = await this._packageService.updateContent(this.pkg);
    } catch (err) {
      this._toastService.error(
        this._transloco.translate('Package is not shared. Server error.'),
        void 0,
        this.toastOptions
      );
      this.sharing = false;
      return;
    }

    this._toastService.success(
      this._transloco.translate('Package content sent successfully'),
      void 0,
      this.toastOptions
    );

    this.close();
    this.sharing = false;
    this.send.emit(false);
    this.done.emit(response);
  }

  @ApmTransactionMethod('update package diff')
  async updatePackageDiff() {
    const response: IPackageUpdatesResponse =
      await this._packageUpdatesService.receivePackageUpdates(this.pkg.header.id);

    if (response.packageUpdatesFailed) {
      this.packageUpdatesFailed = response.packageUpdatesFailed;
      this._toastService.error(
        this._transloco.translate('Internal Server Error'),
        void 0,
        this.toastOptions
      );
      return;
    }

    if (response.searchResult) {
      const excludeIncompleteAssets = this._assetVerificationService.getShareableAssets(
        response.searchResult.lawIncidentFiles.items
      );
      response.searchResult.lawIncidentFiles.items = excludeIncompleteAssets;
    }

    this.searchResult = response.searchResult;

    const patchDiff = this._packageDiffService.countPackageDiff(response);

    const labels: Record<string, string> = {
      operation_add: 'new',
      operation_remove: 'removed',
      operation_replace: 'change(s) in',
      entity_header: 'header',
      entity_narrative: 'narratives',
      entity_person: 'persons',
      entity_vehicle: 'vehicles',
      entity_property: 'properties',
      entity_file: 'files',
      entity_organization: 'organizations',
      entity_overview: 'overview fields',
    };

    const packageDiff = patchDiff.map((diff: IPatchDiff) => {
      const operation = this._transloco.translate(labels['operation_' + diff.operation]);
      const entity = this._transloco.translate(labels['entity_' + diff.entityType]);

      return `${diff.count} ${operation} ${entity}`;
    });

    this.packageDiff = packageDiff;
  }
}
