/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { AssetEntity } from '@msi/emm-sdk';
import { Api, IResponse } from '@msi/js-sdk';

import {
  DerivedItem,
  IDerivedItemDownloadableResponse,
} from '../asset-entity/asset-entity.interface';
import { EPackageAccessType } from '../package/package.enums';

@Injectable({
  providedIn: 'root',
})
export class DownloableLinkService {
  constructor(private _api: Api) {}

  public getDownloadableLinkUrl(url: string, accessType: EPackageAccessType): string {
    if (accessType === EPackageAccessType.AUTH) {
      const splitUrl = url.split('?');
      const queryParam = splitUrl.pop();
      const baseUrl = splitUrl[0];

      return `${baseUrl}/downloadable-link?${queryParam}`;
    } else {
      return `${url}/downloadable-link`;
    }
  }

  public async getDownloadableLink(
    url: string,
    asset: AssetEntity | DerivedItem,
    accessType: EPackageAccessType
  ): Promise<string> {
    let derivedItem: IResponse<IDerivedItemDownloadableResponse>;

    try {
      if (accessType === EPackageAccessType.AUTH) {
        derivedItem = await this._api.post<
          IDerivedItemDownloadableResponse,
          AssetEntity | DerivedItem
        >(url, asset);
      } else {
        derivedItem = await this._api.get<IDerivedItemDownloadableResponse>(url);
      }
      return derivedItem.data.downloadableLink;
    } catch (err) {
      throw err;
    }
  }
}
