/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[ppTableScrollWidth]',
  exportAs: 'ppTableScrollWidth',
})
class TableScrollWidthDirective implements OnInit, OnDestroy {
  @Input('ppTableScrollWidth')
  headerTable: HTMLTableElement;

  private _observer: MutationObserver;

  constructor(private bodyTable: ElementRef) {}

  ngOnInit(): void {
    this._observer = new MutationObserver(() => this.fixWidth());

    this._observer.observe(this.bodyTable.nativeElement, {
      subtree: true,
      childList: true,
    });

    this.fixWidth();
  }

  ngOnDestroy() {
    this._observer.disconnect();

    this._observer = null;
  }

  fixWidth(): void {
    if (this.headerTable.offsetWidth > this.bodyTable.nativeElement.offsetWidth) {
      const diffWidth: number =
        this.headerTable.offsetWidth - this.bodyTable.nativeElement.offsetWidth;

      this.headerTable.style.width = `calc(100% - ${diffWidth}px`;
    }
  }
}

export { TableScrollWidthDirective };
