<ng-container *transloco="let t; read: 'ccs'">
  <section class="review-files">
    <main class="review-files__content">
      <section class="review-files__preview">
        <header class="review-files__video-header">
          <span>{{ t('Preview files for share') }}</span>
        </header>
        <section
          class="review-files__video"
          (click)="onResetAsset($event)">
          <section class="review-files__player">
            <ng-container *ngIf="activeAsset">
              <img
                alt="image {{ activeAsset.name }}"
                *ngIf="thumbnailUrl && !activeAsset.restricted"
                [src]="thumbnailUrl" />
              <msi-icon
                *ngIf="!thumbnailUrl || activeAsset.restricted"
                class="preview-icon"
                [name]="activeAsset.restricted ? 'ic_lock_closed' : (activeAsset | evidIconName)"
                [size]="100">
              </msi-icon>
            </ng-container>
            <ng-container *ngIf="!activeAsset">
              <msi-icon
                class="preview-icon"
                name="ic_share_manager"
                [size]="100">
              </msi-icon>
            </ng-container>
          </section>
          <ng-container *ngIf="!activeAsset">
            <section class="review-files__no-active-asset">
              <h6 *ngIf="assets?.length > 4">{{ t('N files selected', { count: assets?.length }) }}</h6>
              <h6 *ngIf="assets?.length === 1">{{ t('1 file selected', { count: assets?.length }) }}</h6>
              <h6 *ngIf="assets?.length >= 2 && assets?.length <= 4">{{
                t('2-4 files selected', { count: assets?.length })
              }}</h6>
              <span>{{ t('Click a file to preview and see details') }}</span>
            </section>
          </ng-container>
          <ng-container *ngIf="activeAsset">
            <section class="review-files__asset-info">
              <h6>{{ activeAsset.name }}</h6>
              <section class="review-files__device">
                <label for="device">{{ t('Device') }}</label>
                <div
                  id="device"
                  class="review-files__device-container">
                  <span>{{ activeAsset?.source?.name || '' }}</span>
                  <span msiTooltip="{{ activeAsset?.source?.sourceId }}">
                    ID: {{ activeAsset?.source?.sourceId || '' }}
                  </span>
                </div>
              </section>
              <section class="review-files__purge-data">
                <label for="purge-data">{{ t('Purge Date') }}</label>
                <div
                  id="purge-data"
                  class="review-files__purge-data-container">
                  <span>{{ (activeAsset?.retention?.purgeTime | date: 'MM/dd/yyyy') || '--' }}</span>
                </div>
              </section>
            </section>
          </ng-container>
        </section>
      </section>
      <section class="review-files__data">
        <header
          class="review-files__data-header"
          (click)="onResetAsset($event)">
          <span>{{ t('Selected files (N)', { count: assets?.length }) }}</span>
          <div class="review-files__divider-line"></div>
        </header>
        <section class="review-files__list">
          <ccs-assets-list-view
            [assets]="assets"
            [fullScreenEnabled]="true"
            [assetsLoading]="assetsLoading"
            (assetRemoved)="assetRemoved.emit($event)">
          </ccs-assets-list-view>
        </section>
      </section>
    </main>
  </section>
</ng-container>
