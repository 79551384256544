/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { DerivedItemType } from '@msi/emm-sdk';

import { EOperatorsType, ESharesType, ESortsType } from './filter.enums';

interface IPredicate {
  type: string;
  field: string;
  operator: EOperatorsType;
  arguments: string[];
}

export interface IFilter {
  predicates: IPredicate[];
}

export interface IFilters {
  groupId: string;
  expiration: ESharesType[];
}

export interface ISort {
  field: string;
  order: ESortsType;
}

export interface ISearchFilters {
  assetName?: string;
  assetFilter?: IAssetFilter;
  query?: string;
  filterType?: EFilterType;
}

export enum EFilterType {
  Name = 'name',
  AssetId = 'assetId',
}

interface IAssetFilterId {
  id: string;
  agencyId?: string;
  childItemPath?: string;
  derivedItemId?: string;
  derivedItemType?: DerivedItemType;
}

export interface IAssetFilter {
  assetId: IAssetFilterId;
}

export interface IListBody {
  query?: string;
  share?: ESharesType[];
  sort?: ISort;
  pageToken?: string;
  caseId?: string;
  searchId?: string;
  pageNumber?: number;
  assetFilter?: IAssetFilter;
}
