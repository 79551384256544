/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
export function generateStrongPassword(): string {
  const allNumbers = '1234567890';
  const allLowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const allUpperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const allSpecialCharacters = '!@#$%^&*()_+-=[]{}|;:,.<>/?';

  let password = '';
  let charIndex: number;

  const numbersArray = generateRandomNumbersBetween1And12ThatSumTo16();
  // Password is always generated in
  // <Uppercase><Special><Int><Lower> order

  for (let i = 0; i < numbersArray[1]; i++) {
    charIndex = Math.floor(getRandomNum() * allUpperCaseLetters.length);
    password += allUpperCaseLetters.charAt(charIndex);
  }

  for (let i = 0; i < numbersArray[2]; i++) {
    charIndex = Math.floor(getRandomNum() * allSpecialCharacters.length);
    password += allSpecialCharacters.charAt(charIndex);
  }

  for (let i = 0; i < numbersArray[0]; i++) {
    charIndex = Math.floor(getRandomNum() * allNumbers.length);
    password += allNumbers.charAt(charIndex);
  }

  for (let i = 0; i < numbersArray[3]; i++) {
    charIndex = Math.floor(getRandomNum() * allLowerCaseLetters.length);
    password += allLowerCaseLetters.charAt(charIndex);
  }

  return password;
}

function getRandomNum(): number {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);

  return array[0] / 2 ** 32; // Normalize to [0, 1)
}

function generateRandomNumbersBetween1And12ThatSumTo16(): number[] {
  const num_array = [];
  let sum = 0;
  for (let i = 0; i < 3; i++) {
    const remainingSum = 16 - sum - (3 - i);
    const maxNum = Math.min(remainingSum, 12);
    const num = Math.floor(getRandomNum() * maxNum) + 1;
    sum += num;
    num_array.push(num);
  }
  num_array.push(16 - sum);
  return num_array;
}
