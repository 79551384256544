/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

import { Organization } from '../../../services/package/classes/organization/Organization';
import { IPackage } from '../../../services/package/package.interfaces';
import { PackageService } from '../../../services/package/package.service';

@Component({
  selector: 'pp-detail-package-organizations',
  templateUrl: './detail-package-organizations.component.html',
  styleUrls: [
    './detail-package-organizations.component.scss',
    '../detail-package-print.scss',
  ],
})
export class DetailPackageOrganizationsComponent {
  @Input()
  pkg: IPackage;

  constructor(private _packageService: PackageService) {}

  informationPresent(organization: Organization): boolean {
    return this._packageService.informationSectionPresent(organization);
  }

  locationPresent(organization: Organization): boolean {
    return this._packageService.locationSectionPresent(organization);
  }

  alertPresent(organization: Organization): boolean {
    return this._packageService.alertSectionPresent(organization);
  }
}
