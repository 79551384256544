/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Utils } from '@msi/js-sdk';

import { EValidTag } from '../officer/Officer';

class Email {
  private _searchName: string;
  private _regEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  public id: string;
  public email: string;
  public valid: EValidTag;

  constructor(email: string) {
    this.id = Utils.uniqueId();
    this.email = email;
    this._searchName = email;
    this.valid = EValidTag.VALID;
  }

  setError(error: boolean): void {
    this.valid = error ? EValidTag.INVALID : EValidTag.VALID;
  }

  includes(query: string): boolean {
    return this._searchName.includes(query);
  }

  getTagName(): string {
    return this.email;
  }

  toSimple(): string {
    return this.email;
  }

  isValid(): boolean {
    return !!this.email.match(this._regEmail);
  }
}

export { Email };
