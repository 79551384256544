/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateTimeRange, QuickSelectionDateRange } from '@msi/cobalt';
import { EDateFormatterFormat } from '@msi/emm-sdk';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs, { Dayjs } from 'dayjs';
import { fromEvent, Subscription } from 'rxjs';

import { DatePickerService } from '../../services/date-picker/date-picker.service';
import { EExpiresDate } from '../../services/date-picker/expires-date.enum';

@UntilDestroy()
@Component({
  selector: 'pp-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() shortestRetentionDay: Dayjs;
  @Input() dateControl: FormControl;

  @ViewChild('msiCalendar', { read: ElementRef }) msiCalendarRef: ElementRef;

  isCalendarOpen = false;
  dateRanges: QuickSelectionDateRange[] = [];
  calendarDateFormat: EDateFormatterFormat = EDateFormatterFormat.MonthDayYear;
  startDate: NgbDate;
  endDate: NgbDate;
  dateTimeRange: DateTimeRange;
  currentValue: string;
  shortestRetentionTime: number;
  inputDateTouchedState: boolean;

  constructor(
    private _datePickerService: DatePickerService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.dateTimeRange) {
      this.dateChanged(simpleChanges.dateTimeRange.currentValue);
    }
  }

  ngOnInit(): void {
    this.dateTimeRange = new DateTimeRange();
    this.shortestRetentionTime = this.shortestRetentionDay.diff(dayjs(), 'd');
    this.startDate = this._datePickerService.getFirstAvaliableDate();
    this.endDate = this._datePickerService.getLastAvaliableDate(
      this.shortestRetentionDay
    );

    this.prepareDate();

    this._datePickerService.selectedDateValue$
      .pipe(untilDestroyed(this))
      .subscribe((date: string) => {
        this.currentValue = date;
      });
  }

  toggleCalendar(e?: Event): void {
    let clickOutside$: Subscription;

    if (e?.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.isCalendarOpen = !this.isCalendarOpen;

    if (e && this.isCalendarOpen) {
      if (this.msiCalendarRef.nativeElement) {
        this.msiCalendarRef.nativeElement.parentElement.insertAdjacentElement(
          'afterbegin',
          this.msiCalendarRef.nativeElement
        );
        this.disableOptions();
      }

      clickOutside$ = fromEvent(document, 'click')
        .pipe(untilDestroyed(this))
        .subscribe((el) => {
          if (!this.msiCalendarRef.nativeElement.contains(el.target)) {
            this.isCalendarOpen = false;
            clickOutside$.unsubscribe();
            this._changeDetectorRef.detectChanges();
          }
          this.setInputDateState(true);
        });
      return;
    }

    this.setInputDateState(true);
  }

  dateChanged(range: DateTimeRange): void {
    setTimeout(() => {
      this._datePickerService.formatValue(range);
    });

    if (
      !this.msiCalendarRef ||
      !range?.startDate ||
      !range?.startTime ||
      !range?.endDate ||
      !range?.endTime
    ) {
      return;
    }

    this.dateTimeRange = range;
    range.endDate.equals(
      this._datePickerService.getLastAvaliableDate(this.shortestRetentionDay)
    )
      ? this.dateControl.setValue(this.shortestRetentionDay)
      : this.dateControl.setValue(
          dayjs(
            new Date(range.endDate.year, range.endDate.month - 1, range.endDate.day + 1)
          )
        );
  }

  setPredefinedDate(dateRangeOption?: QuickSelectionDateRange): void {
    if (dateRangeOption) {
      this.selectQuickOption(dateRangeOption.text);
      if (dateRangeOption.text === EExpiresDate.Never) {
        this.dateControl.setValue(this._datePickerService.dateNever);
      } else {
        this.dateControl.setValue(dateRangeOption.dateTo(dayjs()));
      }
    }
  }

  private shouldBeDisable(label: string): boolean {
    if (this.shortestRetentionDay.isSame(this._datePickerService.dateNever)) {
      return false;
    } else if (label === EExpiresDate.Never) {
      return true;
    } else {
      return Number(label) > this.shortestRetentionTime;
    }
  }

  private disableOptions(): void {
    if (!this.msiCalendarRef) {
      return;
    }

    const radioButtons =
      this.msiCalendarRef.nativeElement.querySelector('.msi-radio-wrapper');

    if (radioButtons) {
      for (const quickOption of radioButtons.querySelectorAll('.msi-radio-button')) {
        const label = quickOption
          .querySelector('.msi-radio-button-text')
          .textContent.split(' ')[0];
        if (this.shouldBeDisable(label)) {
          quickOption.classList.add('msi-radio-button-disabled');
          quickOption.querySelector('input').setAttribute('disabled', 'true');
        }
      }
    }
  }

  private prepareDate(): void {
    this.dateRanges = this._datePickerService.getPredefineDates();
  }

  private selectQuickOption(option: string): void {
    if (!this.msiCalendarRef || !option) {
      return;
    }
    const radioButtons =
      this.msiCalendarRef.nativeElement.querySelector('.msi-radio-wrapper');

    for (const quickOption of radioButtons.querySelectorAll('msi-radio-button')) {
      if (
        quickOption.querySelector('.msi-radio-button-text').textContent.includes(option)
      ) {
        setTimeout(() => quickOption.click());
      }
    }
  }

  private setInputDateState(state: boolean): void {
    this.inputDateTouchedState = state;
    this._datePickerService.inputDateTouchedState = state;
  }
}
