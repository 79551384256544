/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { AppSpecificConfig } from './app.config.interface';
import { OriginSpecificConfig } from './origin-specific/app.config.origin';

const platformConfig = OriginSpecificConfig.getConfig();

export const appSpecificConfig = {
  scopes: [
    'CCAdmin',
    'email',
    'openid',
    'profile',
    'onerms_search',
    'onerms_package_manager',
    'onerms_lsm.get',
    'onerms_records.put',
    'onerms_records.get',
    'common_workflow.get',
    'common_workflow.put',
    'onerms_fe.put',
    'onerms_fe.get',
    'onerms_idgen.get',
    'cc_vault.read_file',
  ],
  clientId: 'prosecutorPortalUI',
  handleLegacyAdmin: false,
  developmentMode: false,
  singlePreprod: platformConfig.AZURE_ENV !== 'cccs-preprod',
};

export const APP_SPECIFIC_CONFIG: AppSpecificConfig = appSpecificConfig;
