/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Narrative } from '../narrative/Narrative';
import { INarrative } from '../narrative/Narrative.interfaces';
import { Organization } from '../organization/Organization';
import { IOrganization } from '../organization/Organization.interfaces';
import { Overview } from '../overview/Overview';
import { Person } from '../person/Person';
import { IPerson } from '../person/Person.interfaces';
import { Property } from '../property/Property';
import { IProperty } from '../property/Property.interfaces';
import { Vehicle } from '../vehicle/Vehicle';
import { IVehicle } from '../vehicle/Vehicle.interfaces';
import { IPackageContent } from './PackageContent.interafces';

class PackageContent {
  private _props: IPackageContent;
  public name: string;
  public narratives: Narrative[];
  public organizations: Organization[];
  public overview: Overview;
  public persons: Person[];
  public properties: IProperty[];
  public vehicles: IVehicle[];

  constructor(props: IPackageContent) {
    this._props = props;

    this.name = props.caseNumber;
    this.narratives = this._createNarratives(props.narratives || []);
    this.organizations = this._createOrganizations(props.organizations || []);
    this.overview = new Overview(props);
    this.persons = this._createPersons(props.persons || []);
    this.properties = this._createProperties(props.propertyItems || []);
    this.vehicles = this._createVehicles(props.vehicles || []);

    if (this._props.nature) {
      this.name = this.name + ' - ' + this._props.nature;
    }
  }

  private _createNarratives(narratives: INarrative[]): Narrative[] {
    return narratives.map((item: INarrative): Narrative => new Narrative(item));
  }

  private _createOrganizations(organizations: IOrganization[]): Organization[] {
    return organizations.map(
      (item: IOrganization): Organization => new Organization(item)
    );
  }

  private _createPersons(persons: IPerson[]): Person[] {
    return persons.map((item: IPerson): Person => new Person(item));
  }

  private _createProperties(properties: IProperty[]): Property[] {
    return properties.map((item: IProperty): Property => new Property(item));
  }

  private _createVehicles(vehicles: IVehicle[]): Vehicle[] {
    return vehicles.map((item: IVehicle): Vehicle => new Vehicle(item));
  }

  getProps(): IPackageContent {
    return this._props;
  }
}

export { PackageContent };
