/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { Settings } from '@msi/js-sdk';

import { ISettings } from '../../settings/settings.interface';
import { IListBody } from './filter.interfaces';

@Injectable()
export class FilterService {
  constructor(private _settings: Settings<ISettings>) {}

  getBdpListBody(options: IListBody = {}): Record<string, any> {
    let body: Record<string, any> = {
      pageSize: this._settings.get<number>('PAGE_SIZE'),
    };

    Object.entries(options).forEach((option: [string, any]) => {
      if (options.hasOwnProperty(option[0]) && options[option[0]]) {
        body = this.setBodyParam(body, option[0], options[option[0]]);
      }
    });

    return body;
  }

  private setBodyParam(
    body: Record<string, any>,
    key: string,
    value: any
  ): Record<string, any> {
    let bodyKey = key;

    if (key === 'query' || key === 'share') {
      bodyKey = key === 'query' ? 'searchText' : 'filters';
    }
    body[bodyKey] = value;

    return body;
  }
}
