<div>
  <msi-anchor-links>
    <div *msiInlineAnchorLinks>
      <a
        *ngIf="pkg.overview.hasOverview"
        [msiAnchorLink]="'overview'"
        title="Overview">
        {{ 'Overview' | transloco }}
      </a>
      <a
        *ngIf="pkg.narratives.length"
        [msiAnchorLink]="'narrative'"
        title="Narratives ({{ pkg.content.narratives.length }})">
        {{ 'Narratives ' | transloco }}({{ pkg.content.narratives.length }})
      </a>
      <a
        *ngIf="pkg.persons.length"
        [msiAnchorLink]="'persons'"
        title="Persons ({{ pkg.content.persons.length }})">
        {{ 'Persons ' | transloco }}({{ pkg.content.persons.length }})
      </a>
      <a
        *ngIf="pkg.organizations.length"
        [msiAnchorLink]="'organizations'"
        title="Organizations ({{ pkg.content.organizations.length }})">
        {{ 'Organizations ' | transloco }}({{ pkg.content.organizations.length }})
      </a>
      <a
        *ngIf="pkg.vehicles.length"
        [msiAnchorLink]="'vehicles'"
        title="Vehicles ({{ pkg.content.vehicles.length }})">
        {{ 'Vehicles ' | transloco }}({{ pkg.content.vehicles.length }})
      </a>
      <a
        *ngIf="pkg.properties.length"
        [msiAnchorLink]="'property'"
        title="Property & Evidence ({{ pkg.content.propertyItems.length }})"
        >{{ 'Property & Evidence ' | transloco }}({{ pkg.content.propertyItems.length }})
      </a>
      <a
        *ngIf="pkg.files.length"
        [msiAnchorLink]="'assets'"
        title="Digital Evidence ({{ assetEntities.length }})">
        {{ 'Digital Evidence ' | transloco }}({{ assetEntities.length }})
      </a>
    </div>
    <msi-select
      ngModel
      *msiSelectAnchorLinks>
      <msi-select-option
        [msiSelectLink]="'overview'"
        value="overview">
        {{ 'Overview' | transloco }}
      </msi-select-option>
      <msi-select-option
        [msiSelectLink]="'narrative'"
        value="narrative">
        {{ 'Narratives ' | transloco }}({{ pkg.content.narratives.length }})
      </msi-select-option>
      <msi-select-option
        [msiSelectLink]="'persons'"
        value="persons">
        {{ 'Persons ' | transloco }}({{ pkg.content.persons.length }})
      </msi-select-option>
      <msi-select-option
        [msiSelectLink]="'organizations'"
        value="organizations">
        {{ 'Organizations ' | transloco }}({{ pkg.content.organizations.length }})
      </msi-select-option>
      <msi-select-option
        [msiSelectLink]="'vehicles'"
        value="vehicles">
        {{ 'Vehicles ' | transloco }}({{ pkg.content.vehicles.length }})
      </msi-select-option>
      <msi-select-option
        [msiSelectLink]="'property'"
        value="property">
        {{ 'Property & Evidence ' | transloco }}({{ pkg.content.propertyItems.length }})
      </msi-select-option>
      <msi-select-option
        [msiSelectLink]="'assets'"
        value="assets">
        {{ 'Digital Evidence ' | transloco }}({{ assetEntities.length }}/{{ fullPackageAssetsLen }})
      </msi-select-option>
    </msi-select>
  </msi-anchor-links>
</div>
