<div
  *ngIf="pkg"
  class="pp-detail-package-subscribe">
  <button
    type="button"
    class="btn msi-btn"
    (click)="onClick()"
    [msiDropdownTriggerFor]="subscribeDropdown">
    {{ 'Subscribe' | transloco }}
  </button>

  <msi-dropdown
    #subscribeDropdown
    class="pp-detail-package-subscribe__dropdown"
    xPosition="left">
    <msi-modal-header>
      <span *ngIf="!subscribed">{{ 'Subscribe' | transloco }}</span>
      <span *ngIf="subscribed">{{ 'Subscribed!' | transloco }}</span>
      <button
        type="button"
        class="pp-detail-package-subscribe__close"
        (click)="onClose()">
        <msi-icon name="ic_remove"></msi-icon>
      </button>
    </msi-modal-header>

    <msi-modal-content *ngIf="subscribed">
      <p>{{ 'You should receive a confirmation' | transloco }}</p>
      <p>{{ 'email shortly.' | transloco }}</p>
    </msi-modal-content>

    <msi-modal-content
      *ngIf="!subscribed"
      class="pp-detail-package-subscribe__form">
      <form
        (ngSubmit)="onSubmit($event)"
        [formGroup]="form">
        <label
          class="pp-detail-package-subscribe__label"
          for="subscribe-email"
          >{{ 'Email' | transloco }}</label
        >
        <input
          id="subscribe-email"
          class="msi-input pp-detail-package-subscribe__input"
          autocomplete="off"
          placeholder="{{ 'Your email address' | transloco }}"
          required
          [formControl]="form.controls.email" />
        <span
          class="pp-detail-package-subscribe__error"
          *ngIf="form.controls?.email?.errors?.email">
          {{ 'Email must be a valid email address' | transloco }}
        </span>
        <span
          class="pp-detail-package-subscribe__error"
          *ngIf="error">
          {{ error }}
        </span>
        <span class="pp-detail-package-subscribe__descr">
          {{ 'Sign up for email updates for this case.' | transloco }}
        </span>
      </form>
    </msi-modal-content>

    <msi-modal-actions align="end">
      <button
        *ngIf="!subscribed"
        type="submit"
        class="msi-btn spinner-parent"
        [attr.disabled]="subscribing || form.invalid || undefined"
        (click)="onSubmit($event)">
        <ng-template [ngIf]="subscribing">{{ 'Sending...' | transloco }}</ng-template>
        <ng-template [ngIf]="!subscribing">{{ 'Submit' | transloco }}</ng-template>
        <msi-spinner
          *ngIf="subscribing"
          size="small"></msi-spinner>
      </button>
      <button
        *ngIf="subscribed"
        class="msi-btn"
        (click)="onClose()">
        {{ 'OK' | transloco }}
      </button>
    </msi-modal-actions>
  </msi-dropdown>
</div>
