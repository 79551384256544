/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { NgModule } from '@angular/core';
import { getMsiAssetViewerTranslocoIntl } from '@msi/msi-asset-viewer';
import { provideTransloco, TRANSLOCO_LOADER, TranslocoModule } from '@ngneat/transloco';

import { settings } from '../settings/settings';
import { missingKeyHandler } from './missing-key-handler';
import { TranslocoHttpLoader } from './transloco-loader';

export const SUPPORTED_LANGS: string[] = ['en-US', 'pl-PL', 'fr-CA', 'es-XL'];
export const REFERENCE_LANG = 'en-US';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: SUPPORTED_LANGS,
        defaultLang: REFERENCE_LANG,
        fallbackLang: REFERENCE_LANG,
        reRenderOnLangChange: true,
        prodMode: settings.get<string>('PLATFORM.AZURE_ENV' as any) === 'usgov',
      },
      loader: TranslocoHttpLoader,
    }),
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    missingKeyHandler,
    ...getMsiAssetViewerTranslocoIntl(),
  ],
})
export class TranslocoRootModule {}
