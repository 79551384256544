/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IListBody } from '../../../services/filter/filter.interfaces';
import { PackageItem } from '../../../services/package/classes/package/PackageItem';

export const loadReceivedPackages = createAction('[Packages] Load received packages');

export const loadSharedPackages = createAction('[Packages] Load shared packages');

export const loadPackages = createAction('[Packages] Load packages');

export const setReceivedPackages = createAction(
  '[Packages] Set received packages',
  props<{ packages: PackageItem[]; continuation: string | null }>()
);

export const setSharedPackages = createAction(
  '[Packages] Set shared packages',
  props<{ packages: PackageItem[]; continuation: string | null }>()
);

export const setMoreReceivedPackages = createAction(
  '[Packages] Set more received packages',
  props<{ packages: PackageItem[]; continuation: string | null }>()
);

export const setMoreSharedPackages = createAction(
  '[Packages] Set more shared packages',
  props<{ packages: PackageItem[]; continuation: string | null }>()
);

export const loadReceivedPackagesFailure = createAction(
  '[Packages] Load received packages failure',
  props<{ error: HttpErrorResponse }>()
);

export const loadSharedPackagesFailure = createAction(
  '[Packages] Load shared packages failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearReceivedPackages = createAction('[Packages] Clear received packages');

export const clearSharedPackages = createAction('[Packages] Clear shared packages');

export const searchPackages = createAction('[Packages] Search packages');

export const setIsReceived = createAction(
  '[Packages] Set isReceived',
  props<{ isReceived: boolean }>()
);

export const filterPackages = createAction('[Packages] Filter packages');

export const loadMorePackages = createAction('[Packages] Load more packages');

export const setOptions = createAction(
  '[Packages] Set options',
  props<{ options: IListBody }>()
);

export const setCalloutClosed = createAction('[Packages] Set callout closed');
