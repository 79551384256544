<div
  class="group-by"
  *ngIf="groupByConfig$ | async as groupByConfig">
  <msi-select
    [(ngModel)]="groupByConfig.property"
    (change)="updateGroupByProperty(groupByConfig.property)">
    <msi-select-trigger>
      {{ 'Group by' | transloco }}:
      <span class="selected-option">
        <ng-container
          *ngTemplateOutlet="
            groupByOptionTemplate;
            context: { $implicit: groupByConfig.property }
          "></ng-container>
      </span>
    </msi-select-trigger>
    <ng-container *ngFor="let option of groupByOption$ | async">
      <msi-select-option [value]="option">
        <ng-container
          *ngTemplateOutlet="groupByOptionTemplate; context: { $implicit: option }"></ng-container>
      </msi-select-option>
    </ng-container>
  </msi-select>
</div>

<ng-template
  #groupByOptionTemplate
  let-property>
  <ng-container [ngSwitch]="property">
    <ng-container *ngSwitchCase="groupPropertyEnum.None">{{ 'None' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="groupPropertyEnum.OfficerName">{{ 'Officer' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="groupPropertyEnum.Type">{{ 'Type' | transloco }}</ng-container>
  </ng-container>
</ng-template>
