/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Component, Input } from '@angular/core';
import { AssetEntity } from '@msi/emm-sdk';
import { Observable } from 'rxjs';

import {
  EDigitalEvidenceSortDirection,
  EDigitalEvidenceSortProperty,
  IDigitalEvidenceSortByConfig,
} from '../../services/digital-evidence-filter-options/digital-evidence-filter-options.interfaces';
import { DigitalEvidenceFilterOptionService } from '../../services/digital-evidence-filter-options/digital-evidence-filter-options.service';
import { ElasticApmService } from '../../services/elastic-apm';

@Component({
  selector: 'pp-search-sorting',
  templateUrl: './digital-evidence-sorting.component.html',
  styleUrls: ['./digital-evidence-sorting.component.scss'],
})
export class DigitalEvidenceSortingComponent {
  @Input()
  groupedAssets: Map<string, AssetEntity[]>;

  readonly sortByConfig$: Observable<IDigitalEvidenceSortByConfig> =
    this._digitalEvidenceFilterOption.sortByConfig$;
  readonly sortByOptions$: Observable<EDigitalEvidenceSortProperty[]> =
    this._digitalEvidenceFilterOption.sortingOptions$;
  readonly sortDirectionEnum = EDigitalEvidenceSortDirection;
  readonly sortPropertyEnum = EDigitalEvidenceSortProperty;

  constructor(
    private _digitalEvidenceFilterOption: DigitalEvidenceFilterOptionService,
    private _elasticApmService: ElasticApmService
  ) {}

  toggleDirection(): void {
    this._digitalEvidenceFilterOption.toggleSortDirection(this.groupedAssets);
  }

  updateSortByProperty(property: EDigitalEvidenceSortProperty): void {
    this._digitalEvidenceFilterOption.sortByProperty(this.groupedAssets, property);
    this._elasticApmService.track('sort property updated to ' + property.toString());
  }
}
