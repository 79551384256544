/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  CreatePackageComponent,
  ModifyPackageComponent,
  PasswordValidatorService,
  UpdatePackageContentComponent,
} from '@msi/pp-components';

import {
  filesSharingInfo,
  lawIncidentData,
  pkg,
} from '../../../../projects/pp-components/src/lib/services/package/mock-data';
import { contentWithData, items } from './mock-data';

@Component({
  selector: 'pp-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  private readonly router = inject(Router);

  @ViewChild('createSharePackage', { static: false })
  createSharePackage: CreatePackageComponent;

  @ViewChild('modifySharePackage', { read: ModifyPackageComponent })
  modifySharePackage: ModifyPackageComponent;

  @ViewChild('updateSharePackageContent', { static: false })
  updateSharePackageContent: UpdatePackageContentComponent;

  files = items;
  filesLoading = true;
  pkgContent = contentWithData;
  fullContent = true;
  additionalInfo = [
    {
      title: 'Mario66',
      body: 'The case package mario66  was shared with you on 09/30/2020 by Motorola OneRMS Krakow.',
    },
  ];
  pkg = pkg;
  lawIncidentData = lawIncidentData;
  filesSharingInfo = filesSharingInfo;
  guestMode = false;
  unauthAgencyId: string;
  unauthPkgId: string;

  dropdownData = [
    { id: 1, value: 'adfadfasdf' },
    { id: 2, value: 'xvxvbxvb' },
    { id: 3, value: 'fhfhgjfghj' },
    { id: 4, value: 'turtyrtuy' },
  ];

  constructor(private passwordValidator: PasswordValidatorService) {}

  async ngOnInit(): Promise<void> {
    setTimeout(() => (this.filesLoading = false), 2000);
  }

  onClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.createSharePackage.open();
  }

  onModifyClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.pkg.files = this.files;
    this.modifySharePackage.open(this.pkg);
  }

  onUpdateContentClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.updateSharePackageContent.open(this.pkg);
  }

  dataMapper(item) {
    return item.value;
  }

  onDropdownItemClick(item) {
    // eslint-disable-next-line no-console
    console.log('item ->', item);
  }

  onFilterPkgWithAsset(): void {
    this.router.navigate(['.'], {
      relativeTo: this.router.routerState.root,
      queryParams: {
        assetId: '123456789',
        assetAgencyId: 'msi.rjs.fe.e2e.pd.dev',
        assetDisplayName: 'Test asset long nameeeeee',
      },
      queryParamsHandling: 'merge',
    });
  }

  redirectToProtectedPackage(): void {
    let url: string;
    this.passwordValidator.packageId = this.unauthPkgId;

    if (this.unauthAgencyId) {
      url = `/protected/${this.unauthAgencyId}/${this.unauthPkgId}`;
    } else {
      url = `/protected/${this.unauthPkgId}`;
    }
    this.router.navigate([url]);
  }
}
