/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { PermissionInfo, ServiceInfo, UserInfo } from '@msi/commandcentral-common-header';

import {
  IGrantedPermission,
  IUserInfoCRV,
  IUserInfoPermissionCRV,
} from '../user/user.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  grantedUserPermissions: IGrantedPermission[] = [];

  hasPermission(systemName: string, permissionSystemName: string): boolean {
    let permission = false;

    this.grantedUserPermissions.forEach((userPermission: IGrantedPermission) => {
      if (
        userPermission.serviceName === systemName &&
        userPermission.permissionName === permissionSystemName
      ) {
        permission = true;
      }
    });

    return permission;
  }

  addPermission(premission: IGrantedPermission): void {
    this.grantedUserPermissions.push(premission);
  }

  addPermissions(premissions: IGrantedPermission[]): void {
    this.grantedUserPermissions.push(...premissions);
  }

  setGrantedPermissions(service: ServiceInfo): void {
    service.permissions.forEach((permission: PermissionInfo) => {
      this.addPermission({
        serviceName: service.systemName,
        permissionName: permission.systemName,
      });
    });
  }

  setGrantedPermissionsCRVUser(permissions: IUserInfoPermissionCRV[]): void {
    permissions.forEach((permission: IUserInfoPermissionCRV) => {
      this.addPermission({
        serviceName: permission.serviceSystemName,
        permissionName: permission.permissionSystemName,
      });
    });
  }

  isUserInfoCRV(userInfo: IUserInfoCRV | UserInfo): boolean {
    return 'permissions' in userInfo;
  }
}
