/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, MsiModalConfig, MsiModalRef } from '@msi/cobalt';

@Component({
  selector: 'pp-download-manager-confirm-close',
  templateUrl: './download-manager-confirm-close.component.html',
})
export class DownloadManagerConfirmCloseComponent {
  modalRef?: MsiModalRef;

  config: MsiModalConfig = {
    disableClose: true,
    hasBackdrop: true,
  };

  @ViewChild('modalTemplate')
  modalTemplate: TemplateRef<any>;

  private _resolve;
  private _reject;

  constructor(protected modalService: ModalService) {}

  async confirm(): Promise<boolean> {
    if (this._resolve && this._reject) {
      throw new Error(
        'DownloadManagerConfirmCloseComponent: you are able to call this method only one time'
      );
    }

    const data: Promise<boolean> = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;

      if (!this.modalRef) {
        this.modalRef = this.modalService.open(this.modalTemplate, this.config);
      }
    });

    return data;
  }

  private _close(): void {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = undefined;
    }

    this._resolve = null;
    this._reject = null;
  }

  onCancel() {
    this._resolve(false);
    this._close();
  }

  onConfirm() {
    this._resolve(true);
    this._close();
  }
}
