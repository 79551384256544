/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pp-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss'],
})
export class GuestComponent implements OnInit {
  id = '';
  agencyId: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.id = params.id;
      this.agencyId = params.agencyId || '';
    });
  }
}
