<ng-container *transloco="let t; read: 'ccs'">
  <section class="side-panel">
    <nav class="side-panel__nav">
      <header class="side-panel__nav-header">
        <span>{{ t('Share Information') }}</span>
      </header>
      <main class="side-panel__nav-body">
        <p
          [class.active]="reviewFilesTabActive"
          (click)="onSwitchTabs(shareInfoPropEnum.ReviewFiles)">
          {{ t('Review Files') }}
        </p>
        <p
          [class.active]="!reviewFilesTabActive"
          (click)="onSwitchTabs(shareInfoPropEnum.SharePackageSetup)">
          {{ t('Add Recipient Info') }}
        </p>
      </main>
    </nav>
    <footer class="side-panel__footer">
      <msi-callout
        *ngIf="packageForm ? !packageForm.value.authMode : false"
        class="side-panel__unauth_callout"
        [type]="'warning'"
        [closable]="false">
        <span calloutContent>
          {{
            packageForm.value.password
              ? t(
                  'By pressing ‘Share’ you are acknowledging that anyone with this link and password can view the material.'
                )
              : t(
                  'By pressing ‘Share’ you are acknowledging that anyone with this link can view the material.'
                )
          }}
        </span>
      </msi-callout>

      <section class="side-panel__footer-buttons">
        <button
          *ngIf="reviewFilesTabActive"
          class="msi-btn side-panel__next-button"
          (click)="onProceedToShare()">
          {{ t('Next') }}
        </button>
        <ng-container *ngIf="!reviewFilesTabActive || sharingPackage">
          <button
            type="submit"
            class="msi-btn side-panel__submit-button"
            [disabled]="!packageForm?.valid"
            msiTooltip="{{ t('Fill in all fields to share the files') }}"
            [tooltipDisabled]="packageForm?.valid"
            (click)="onSubmit()">
            <ng-container *ngIf="!sharingPackage">{{ t('Share') }}</ng-container>
            <msi-spinner
              *ngIf="sharingPackage"
              size="small"></msi-spinner>
          </button>
        </ng-container>
      </section>
    </footer>
  </section>
</ng-container>
