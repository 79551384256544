/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Event, ResolveEnd, Router, Scroll } from '@angular/router';
import { PopUpModalCreationData, PopUpModalData, PopUpModalService } from '@msi/cobalt';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';

import { IFeatureFlagsResponse } from '../../models/feature-flag.model';
import { IShareableAsset } from '../../models/share-data.models';
import { AssetVerificationService } from '../../services/asset-verification/asset-verification.service';
import { AssetsService } from '../../services/assets-items/assets.service';
import { DatePickerService } from '../../services/date-picker/date-picker.service';
import { FeatureFlagsService } from '../../services/feature-flag/feature-flags.service';
import { RecipientsListService } from '../../services/recipients-list/recipients-list.service';
import { ShareDataService } from '../../services/share-data/share-data.service';

@UntilDestroy()
@Component({
  selector: 'ccs-share-data',
  templateUrl: './share-data.component.html',
  styleUrls: ['./share-data.component.scss'],
})
export class ShareDataComponent implements OnInit {
  @Input()
  error: any;

  @Input()
  enableContentProjection = false;

  @ViewChild('shareData')
  shareModal: TemplateRef<ElementRef>;

  unauthPkgPasswordFeatureFlag: boolean;
  assets: IShareableAsset[];
  modalRef: PopUpModalData;
  config: PopUpModalCreationData = {
    size: 'large',
    maxWidth: 1042,
    maxHeight: 794,
  };
  assetsLoading = true;
  shortestRetentionTime: string;

  constructor(
    private popUpModalService: PopUpModalService,
    private _shareDataService: ShareDataService,
    private _datePickerService: DatePickerService,
    private _assetService: AssetsService,
    private _router: Router,
    private _recipientsListService: RecipientsListService,
    private _assetVerificationService: AssetVerificationService,
    private _featureFlagService: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    this._shareDataService.assetsLoading$
      .pipe(untilDestroyed(this))
      .subscribe((loading) => {
        this.assetsLoading = loading;
      });
    this._shareDataService.assetsAdded$.pipe(untilDestroyed(this)).subscribe(() => {
      this.assets = Array.from(this._shareDataService.assetsMap.values());
      this.shortestRetentionTime = this.calculateShortestRetentionTime();
    });

    this._router.events.pipe(untilDestroyed(this)).subscribe((event: Event) => {
      if (event instanceof Scroll || event instanceof ResolveEnd) {
        this.closePopUpModal();
      }
    });

    this._featureFlagService
      .getFeatureFlags()
      .pipe(
        untilDestroyed(this),
        map((response: IFeatureFlagsResponse) => {
          response.featureFlags.forEach((flag) => {
            if (flag.flagName === 'password-protected-unauth-pkg') {
              this.unauthPkgPasswordFeatureFlag = flag.enabled;
            }
          });
        })
      )
      .subscribe();
  }

  openOrTogglePopUpModal() {
    if (!this.modalRef) {
      this.modalRef = this.popUpModalService.open(this.shareModal, this.config);
    } else if (this.modalRef.minimized) {
      this.modalRef.toggleMinimized();
    }
  }

  closePopUpModal(): void {
    if (this.modalRef) {
      this._resetFormData();
      this.modalRef.close();
      this.modalRef = undefined;
      this._shareDataService.assetsMap.clear();
      this._shareDataService.resetActiveAsset();
    }
  }

  onToggleFullScreen(modal: PopUpModalData): void {
    modal.toggleFullscreen();
    if (!modal.fullscreen) {
      this._shareDataService.resetActiveAsset();
    }
    this._shareDataService.toggleFullscreen(modal.fullscreen);
  }

  onToggleMinimized(modal: PopUpModalData): void {
    modal.toggleMinimized();
    if (modal.minimized) {
      this._shareDataService.resetActiveAsset();
    }
    modal.fullscreen = false;
    this._shareDataService.toggleFullscreen(modal.fullscreen);
  }

  onAssetRemoved(asset: IShareableAsset): void {
    this._shareDataService.removeAsset(asset);
    if (this._assetVerificationService.notShareableAsset(asset.assetStorageType)) {
      this._assetVerificationService.updateNotShareableAssets(asset);
    }
  }

  private _resetFormData(): void {
    this._shareDataService.resetCreatePackageForm();
    this._datePickerService.resetSelectedDateValue();
    this._assetService.resetPackageContent();
    this._recipientsListService.clearCachedRecipients();
  }

  private calculateShortestRetentionTime(): string {
    return this.assets.map((asset) => asset.retention?.purgeTime).sort()[0];
  }
}
