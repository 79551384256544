/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { EPackageVersionType } from '../package/package.enums';

export enum EActionType {
  Print = 'print',
  PrintPreview = 'print_preview',
  Download = 'download',
  AssetDownload = 'asset_download',
  AssetViewed = 'view',
  AssetPlayed = 'play',
  AssetPrinted = 'asset_print',
}

export enum EActionOn {
  Assets = 'assets',
  AssetsAndMetadata = 'assetsAndMetadata',
}

export interface IAuditLogQueryParams {
  packageId: string;
  startDate: string;
  endDate: string;
  application: string;
}

export interface IAuditLogBody {
  fileId: string;
  fileAgencyId: string;
  packageId: string;
  packageAgencyId: string;
}

export interface IAuditLogResponse {
  fileContents: string;
  fileDownloadName: string;
}

export interface IAuditLogPackage {
  id: string;
  agencyId: string;
}

export interface IAuditLogAsset {
  id: string;
  agencyId: string;
  childItemPath: string;
  derivedItemId: string;
  derivedItemType: string;
}

export interface IAuditLogData {
  package: IAuditLogPackage;
  packageVersion: EPackageVersionType;
  actionOrigin?: string;
  asset?: IAuditLogAsset;
  assets?: IAuditLogAsset[];
  actionType?: EActionType;
  actionOn?: EActionOn;
}
