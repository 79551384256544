<pp-delete-package-modal
  #deletePackage
  (done)="onPackageChanged($event)"></pp-delete-package-modal>

<pp-regenerate-package-modal
  #regeneratePackage
  (done)="onPackageChanged($event)"></pp-regenerate-package-modal>

<pp-update-package-content
  #updatePackageContent
  (done)="onPackageChanged($event)"></pp-update-package-content>

<pp-modify-package
  #modifySharePackage
  (done)="onPackageChanged($event)"></pp-modify-package>

<div class="pp-packages-table">
  <div
    class="pp-packages-loading d-flex align-items-center justify-content-center"
    *ngIf="isModalLoading$ | async">
    <msi-spinner size="large"></msi-spinner>
  </div>
  <div
    *ngIf="loading"
    class="pp-packages-loading">
    <msi-spinner size="medium"></msi-spinner>
  </div>

  <div class="pp-packages-table_header">
    <table
      class="msi-table"
      #tableHeader>
      <thead>
        <tr>
          <th
            msi-table-header
            sortable
            [sortDirection]="fieldsName.title"
            class="pp-col-1"
            [class.landing]="mode === shareManagerTableMode.LANDING"
            [class.single]="mode === shareManagerTableMode.SINGLE"
            (sort)="onSort('title', $event)">
            {{ 'Title' | transloco }}
          </th>
          <th
            *ngIf="mode === shareManagerTableMode.LANDING"
            msi-table-header
            sortable
            class="pp-col-2 landing"
            [sortDirection]="fieldsName.description"
            (sort)="onSort('agencyRecord', $event)">
            {{ 'Description' | transloco }}
          </th>
          <th
            #receiversColumn
            msi-table-header
            class="pp-col-3"
            [class.landing]="mode === shareManagerTableMode.LANDING"
            [class.single]="mode === shareManagerTableMode.SINGLE">
            {{ 'Receivers' | transloco }}
          </th>
          <th
            msi-table-header
            class="pp-col-4">
            {{ '# Views' | transloco }}
          </th>
          <th
            msi-table-header
            sortable
            [sortDirection]="fieldsName.lastUpdated"
            (sort)="onSort('updated', $event)"
            class="pp-col-5">
            {{ 'Shared on' | transloco }}
          </th>
          <th
            msi-table-header
            class="pp-col-6">
            {{ 'Expires in' | transloco }}
          </th>
          <th
            msi-table-header
            class="pp-col-7">
            {{ 'New update available' | transloco }}
          </th>
          <th
            msi-table-header
            class="pp-col-8"></th>
        </tr>
      </thead>
    </table>
  </div>
  <div
    class="pp-packages-table_body"
    ppInfiniteScroll
    [ppISLoading]="loading"
    (ppISBottom)="onLoadMore()">
    <table
      class="msi-table"
      #tableBody
      [ppTableScrollWidth]="tableHeader">
      <tbody>
        <ng-container *ngFor="let row of packages">
          <tr
            [class.incident-removed]="row.incidentRemoved"
            class="msi-hover msi-focus"
            [class.hovered]="isDropdownOpenedOnTheSameRow(row.id)"
            (mouseover)="closeDropdownInUnfocusedRow(row.id)"
            msiTooltip="{{ 'Incident ' | transloco }}{{ row.caseNumber }}{{
              ' in this package has been deleted' | transloco
            }}"
            [tooltipDisabled]="!row.incidentRemoved">
            <td
              class="pp-col-1"
              [class.landing]="mode === shareManagerTableMode.LANDING"
              [class.single]="mode === shareManagerTableMode.SINGLE">
              <div class="text-badge">
                <a
                  *ngIf="isRedirectAvailable(row); else noLinkName"
                  class="trim-text msi-link"
                  [msiTooltip]="row.tooltipName"
                  href="{{ row | msiPackageDetailsRedirectUrl }}"
                  target="_blank">
                  <span
                    class="display-title"
                    [innerHTML]="row.name"></span>
                </a>
                <ng-template #noLinkName>
                  <span
                    class="trim-subtext"
                    msiTooltip="{{ row.tooltipName }}"
                    [tooltipDisabled]="row.incidentRemoved"
                    [innerHTML]="row.name">
                  </span>
                </ng-template>
              </div>
            </td>
            <td
              *ngIf="mode === shareManagerTableMode.LANDING"
              class="pp-col-2 landing">
              <div
                msiTooltip="{{ row.tooltipDescription }}"
                [tooltipDisabled]="row.incidentRemoved">
                <span
                  *ngIf="row.incidentRemoved"
                  class="trim-text"
                  [innerHTML]="row.description">
                </span>
                <a
                  *ngIf="!row.incidentRemoved"
                  class="trim-text msi-link"
                  [innerHTML]="row.description"
                  href="{{ row.caseNumber | msiIncidentRedirectUrl }}"
                  target="_blank">
                </a>
              </div>
            </td>
            <td
              class="pp-col-3"
              [class.landing]="mode === shareManagerTableMode.LANDING"
              [class.single]="mode === shareManagerTableMode.SINGLE">
              <a class="trim-text">
                <pp-more-details
                  [columnRef]="receiversColumn.elementRef"
                  [accessType]="row.accessType"
                  [details]="row.recipients">
                </pp-more-details>
              </a>
            </td>
            <td class="pp-col-4">
              <span class="viewed">
                <div
                  class="locked-icon"
                  *ngIf="row.accessLocked">
                  <msi-icon
                    name="ic_lock_closed"
                    size="18"
                    (click)="onRenew(row.getProps())"
                    position="right"
                    msiTooltip="{{
                      'Package has been locked from too many login attempts. Click to renew link and unlock  the package.'
                        | transloco
                    }}"></msi-icon>
                </div>

                {{ row.viewedBy }}</span
              >
            </td>
            <td class="pp-col-5">
              <span class="shared-on">{{ row.sharedOn }}</span>
            </td>
            <td class="pp-col-6">
              <span class="expires-in">
                {{ row.expireTime | transloco }}&nbsp;{{ row.timeIndicator | transloco }}
              </span>
            </td>
            <td class="pp-col-7">
              <button
                *ngIf="
                  row.needsUpdate && !this.isExpired(row.getProps().header.validTo) && !row.incidentRemoved
                "
                type="button"
                class="msi-btn msi-btn-action"
                onclick="this.blur()"
                (click)="onAction(['update', { id: 'update', title: 'Send updates' }, row])">
                {{ 'Send updates' | transloco }}
              </button>
            </td>
            <td class="pp-col-8">
              <pp-actions-dropdown
                #v2actionsDropdown
                *ngIf="row.isPackageVersionV2; else v1actions"
                [actions]="row.packageActions"
                [rowId]="row.id"
                [isShares]="true"
                (auditLog)="onViewAuditLog(row.getProps())"
                (deletePackage)="onDelete(row.getProps())"
                (patchPackage)="onModify(row.getProps())"
                (updatePackage)="onUpdateContent(row.getProps())"
                (regeneratePackageLink)="onRenew(row.getProps())"
                (copyId)="onCopyID(row.getProps())">
              </pp-actions-dropdown>
              <ng-template #v1actions>
                <pp-action-controls
                  #v1actionsDropdown
                  [incidentRemoved]="row.incidentRemoved"
                  [data]="row"
                  [lang]="lang"
                  [readAuditLogsPermission]="readAuditLogsPermission"
                  [options]="getActionOptions(row)"
                  (action)="onAction($event)">
                </pp-action-controls>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div
    *ngIf="!loading && !packages.length"
    class="pp-packages-empty">
    <msi-icon
      name="ic_search"
      class="pp-packages-empty_icon"
      size="36"></msi-icon>
    <span>{{ 'No shared packages meet filtering criteria' | transloco }}</span>
  </div>
</div>
