/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Chip } from '@msi/cobalt';
import { BehaviorSubject, catchError, delay, map, Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { settings } from '../../config/Settings';
import { IRecipient, IRecipientResponse } from './models/recipients.models';

@Injectable({
  providedIn: 'root',
})
export class RecipientsListService {
  authRecipients: Chip[] = [];
  selectedRecipients: Chip[] = [];
  private _cachedRecipients: Chip[] = [];
  private _inputRecipientsTouchedState = false;
  private fetchRecipientsErrorSubject = new BehaviorSubject<boolean>(false);

  readonly fetchRecipientsError$: Observable<boolean>;

  public get cachedRecipients(): Chip[] {
    return this._cachedRecipients;
  }

  public set cachedRecipients(recipients: Chip[]) {
    this._cachedRecipients = recipients;
  }

  public get inputRecipientsTouchedState(): boolean {
    return this._inputRecipientsTouchedState;
  }
  public set inputRecipientsTouchedState(value: boolean) {
    this._inputRecipientsTouchedState = value;
  }

  constructor(private _http: HttpClient) {
    this.fetchRecipientsError$ = this.fetchRecipientsErrorSubject.asObservable();
  }

  getRecipients(): Observable<Chip[]> {
    if (this.authRecipients.length) {
      return of(this.authRecipients).pipe(delay(200));
    }

    const url = settings.getUrl('PROSECUTORS');

    return this._http.get<IRecipientResponse>(url).pipe(
      map((response) => {
        const recipients = response.prosecutors.map((recipient) =>
          this._mapRecipientsToChipType(recipient)
        );
        this.authRecipients = [...recipients];
        return recipients;
      }),
      catchError(() => {
        this.setRecipientsError(true);
        return of([]);
      })
    );
  }

  getSelectedRecipients(): Chip[] {
    return this.selectedRecipients;
  }

  setRecipientsError(value: boolean): void {
    this.fetchRecipientsErrorSubject.next(value);
  }

  clearCachedRecipients(): void {
    this.cachedRecipients = [];
    this.inputRecipientsTouchedState = false;
  }

  private _mapRecipientsToChipType(recipient: IRecipient): Chip {
    return {
      id: uuidv4(),
      value: `${recipient.lastName}, ${recipient.firstName}`,
      email: recipient.email,
      agencyName: recipient.agencyName,
      agency: recipient.agency,
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      fullName: `${recipient.lastName}, ${recipient.firstName}`,
      oid: recipient.oid,
      user: recipient.user,
      isValid: true,
      readonly: false,
    };
  }
}
