/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { AssetEntity } from '@msi/emm-sdk';
import { createAction, props } from '@ngrx/store';

import { PackageItem } from '../../../services/package/classes/package/PackageItem';
import { EDetailPackageMode } from '../../../services/package/package.enums';
import { IPackage } from '../../../services/package/package.interfaces';

export const setAssetEntities = createAction(
  '[Package Details] Set asset entities',
  props<{ assetEntities: AssetEntity[] }>()
);

export const loadPackage = createAction(
  '[Package Details] Load package',
  props<{ packageId: string; mode: EDetailPackageMode; agencyId: string }>()
);

export const loadPackageFailure = createAction(
  '[Package Details] Load package failure',
  props<{ error: HttpErrorResponse }>()
);

export const setPackageAndPkgItem = createAction(
  '[Package Details] Set package item',
  props<{ package: IPackage; pkgItem: PackageItem }>()
);

export const setSelectedPackages = createAction(
  '[Package Details] Set selected packages',
  props<{ selectedPackages: PackageItem[] }>()
);

export const loadAssets = createAction('[Package Details] Load assets');

export const loadAssetsFailure = createAction('[Package Details] Load assets failure');

export const clearPackageDetails = createAction(
  '[Package Details] Clear package details'
);
