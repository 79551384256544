/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { CommonHeaderLanguageService } from '@msi/commandcentral-common-header';
import { Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ISettings } from '../../settings/settings.interface';
import { GlobalStore } from '../../stores/global/store';

@UntilDestroy()
@Injectable()
export class SystemService {
  translocoSubscription: Subscription;
  commonHeaderLanguageService: Subscription;

  constructor(
    private _settings: Settings<ISettings>,
    private _store: GlobalStore,
    private _transloco: TranslocoService,
    private _commonHeaderLanguageService: CommonHeaderLanguageService
  ) {
    this.initialize();
    this.initLanguageSupport();
  }

  private _isUnauthenticatedSharingEnabled(): void {
    if (this.isFedRamp()) {
      this._store.isUnauthSharingEnabled = false;
    } else {
      this._store.isUnauthSharingEnabled = true;
    }
  }

  private _setLanguage(lang: string): void {
    this._updateCurrentLang(lang);

    this._transloco.getTranslation(lang);
  }

  private _updateCurrentLang(lang: string): void {
    if (lang === 'en') {
      lang = 'en-US';
    }

    this._transloco.setActiveLang(lang);

    this._store.lang = lang;
  }

  initialize(): void {
    this._isUnauthenticatedSharingEnabled();
  }

  isDev(): boolean {
    return this._settings.get<string>('PLATFORM.AZURE_ENV' as any) === 'dev';
  }

  isProd(): boolean {
    return this._settings.get<string>('PLATFORM.AZURE_ENV' as any) === 'usgov';
  }

  isFedRamp(): boolean {
    const envs = ['fed', 'fed-stage', 'fed-stage-2', 'fed-prod', 'fed-prod-2'];

    return envs.includes(this._settings.get<string>('PLATFORM.AZURE_ENV' as any));
  }

  isStage(): boolean {
    return this._settings.get<string>('PLATFORM.AZURE_ENV' as any) === 'usgov-stage';
  }

  initLanguageSupport(activeLang?: string): void {
    // load translation files for every available language in the app
    this._transloco.config.availableLangs.forEach((lang) => {
      this._transloco.load(lang).pipe(untilDestroyed(this)).subscribe();
    });

    if (!this.commonHeaderLanguageService) {
      this.commonHeaderLanguageService =
        this._commonHeaderLanguageService.selectedLanguage$
          .pipe(
            distinctUntilChanged(),
            filter((language) => !!language),
            map((lang: string): string => {
              switch (lang) {
                case 'pl':
                  return 'pl-PL';
                case 'fr-CA':
                  return 'fr-CA';
                case 'es':
                  return 'es-XL';
                case 'en-US':
                default:
                  return 'en-US';
              }
            })
          )
          .subscribe((lang: string): void => {
            lang = activeLang ? activeLang : lang;

            this._updateCurrentLang(lang);
          });
    } else {
      this._setLanguage(activeLang);
    }

    if (!this.translocoSubscription) {
      this.translocoSubscription = this._transloco.langChanges$.subscribe();
    }
  }
}
