/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { createSelector } from '@ngrx/store';

import { IDetailPackageState } from '../reducers/detail-package.reducer';

export const getPackageDetailsStore = (store) =>
  store.detailPackage as IDetailPackageState;

export const getPackage = createSelector(
  getPackageDetailsStore,
  (store) => store.package
);

export const getPackageItem = createSelector(
  getPackageDetailsStore,
  (store) => store.pkgItem
);

export const isPackageLoading = createSelector(
  getPackageDetailsStore,
  (store) => store.packageLoading
);

export const getPackageLoadingError = createSelector(
  getPackageDetailsStore,
  (store) => store.packageError
);
