/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PopUpModalCreationData, PopUpModalData, PopUpModalService } from '@msi/cobalt';
import { Connect } from '@msi/js-sdk';

import { DownloadItemDTO } from '../../common/DownloadManager/DownloadItemDTO';
import { EDownloadStatus } from '../../common/DownloadManager/enums';
import { IDownloadManagerStore } from '../../stores/download-manager/download-manager.interfaces';
import { downloadManagerLocalStore } from '../../stores/download-manager/download-manager.store';
import { DownloadManagerConfirmCloseComponent } from './download-manager-confirm-close/download-manager-confirm-close.component';

@Component({
  selector: 'pp-download-manager-modal',
  templateUrl: './download-manager-modal.component.html',
  styleUrls: ['./download-manager-modal.component.scss'],
})
export class DownloadManagerModalComponent implements OnDestroy {
  private _popup: PopUpModalData | null = null;
  private _config: PopUpModalCreationData = {
    size: 'large',
    position: 'right',
  };

  @Input()
  streamingMetadataLoaded = false;

  @Output()
  action: EventEmitter<[string, DownloadItemDTO]> = new EventEmitter();

  @Output()
  resetDownloadSpinner: EventEmitter<void> = new EventEmitter();

  @Connect<IDownloadManagerStore>('downloadItems', downloadManagerLocalStore)
  downloadItems: DownloadItemDTO[];

  @Connect<IDownloadManagerStore>('cancelDownload', downloadManagerLocalStore)
  cancelDownload: boolean;

  @ViewChild('downloadModal')
  downloadModal: TemplateRef<any>;

  @ViewChild('downloadManagerConfirm', { static: false })
  downloadManagerConfirm: DownloadManagerConfirmCloseComponent;

  constructor(private _popUpModalService: PopUpModalService) {}

  ngOnDestroy(): void {
    this._destroy();
  }

  private async _destroy(): Promise<void> {
    downloadManagerLocalStore.downloadItems = [];
    downloadManagerLocalStore.cancelDownload = false;
    this.resetDownloadSpinner.emit();

    if (this._popup) {
      this._popup.close();

      this._popup = null;
    }
  }

  async onClose(): Promise<void> {
    const len: number = this.downloadItems.length;

    let found = false;

    for (let i = 0; i < len; i++) {
      const item: DownloadItemDTO = this.downloadItems[i];

      if (
        item.status === EDownloadStatus.IN_PROGRESS ||
        item.status === EDownloadStatus.NEW
      ) {
        found = true;

        break;
      }
    }

    if (found) {
      let confirm = false;
      const cancelDownload: boolean = downloadManagerLocalStore.cancelDownload;

      if (!cancelDownload) {
        confirm = await this.downloadManagerConfirm.confirm();
      }

      if (confirm || cancelDownload) {
        this.downloadItems.forEach((i: DownloadItemDTO) => i.cancel());

        this._destroy();
      } else {
        return;
      }
    } else {
      this._destroy();
    }
  }

  @Connect<IDownloadManagerStore>('cancelDownload', downloadManagerLocalStore)
  async close(): Promise<void> {
    this.onClose();
  }

  async open(): Promise<void> {
    if (
      !this._popUpModalService
        .getOpenedModals()
        .filter((modal) => modal.author === 'upload').length
    ) {
      const modal: PopUpModalData = this._popUpModalService.open(
        this.downloadModal,
        this._config
      );

      modal.author = 'upload';

      this._popup = modal;
    }
  }

  onAction(e: [string, DownloadItemDTO]): void {
    this.action.emit(e);
  }
}
