/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export const PROTECTED_PACKAGE_CONTEXT = new HttpContextToken(() => ({
  isProtected: false,
  packageId: '',
}));

@Injectable()
export class ProtectedPackageInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(PROTECTED_PACKAGE_CONTEXT).isProtected) {
      return this.addAuthorizationHeader(request, next);
    } else {
      return next.handle(request);
    }
  }

  addAuthorizationHeader(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const packageId = request.context.get(PROTECTED_PACKAGE_CONTEXT).packageId;

    if (!!sessionStorage.getItem(`PROTECTED_CONFIG_${packageId}`)) {
      const data = JSON.parse(
        sessionStorage.getItem(`PROTECTED_CONFIG_${packageId}`)
      ).authResult;
      const modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `${data.token_type} ${data?.access_token}`,
        },
      });
      return next.handle(modifiedRequest);
    } else {
      return next.handle(request);
    }
  }
}
