/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
// eslint-disable-next-line simple-import-sort/imports
import { HostListener, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { Chip } from '@msi/cobalt';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { debounceTime, Observable } from 'rxjs';

import { IShareableAsset } from '../../../../models/share-data.models';
import { AssetsService } from '../../../../services/assets-items/assets.service';
import { DatePickerService } from '../../../../services/date-picker/date-picker.service';
import { ShareDataService } from '../../../../services/share-data/share-data.service';
import { alphanumericValidator } from '../../../../utils/alphanumeric.validator';
import { caseValidator } from '../../../../utils/case.validator';
import { passwordLengthValidator } from '../../../../utils/password-length.validator';
import { ValidateRecipients } from '../../../../utils/recipients.validator';
import { specialCharacterValidator } from '../../../../utils/special-character.validator';

@UntilDestroy()
@Component({
  selector: 'ccs-share-package-setup',
  templateUrl: './share-package-setup.component.html',
  styleUrls: ['./share-package-setup.component.scss'],
})
export class SharePackageSetupComponent implements OnInit {
  readonly defaultMessage = 'Package is shared with you on {{todayDate}}';
  readonly todayDate = dayjs().format('MM/DD/YYYY');

  @Input() enablePasswordProtectionForUnauthPkg: boolean;
  @Input() shortestRetentionTime: string;

  defaultMessageAndTime: string;
  authMode = true;
  createPackageForm: FormGroup;
  packageContents$: Observable<Map<string, IShareableAsset[]>>;

  constructor(
    private _formBuilder: NonNullableFormBuilder,
    private _shareDataService: ShareDataService,
    private _datePickerService: DatePickerService,
    private _assetService: AssetsService,
    private _transloco: TranslocoService
  ) {}

  get emailsList(): AbstractControl {
    return this.createPackageForm.get('to');
  }

  get title(): AbstractControl {
    return this.createPackageForm.get('title');
  }

  get message(): AbstractControl {
    return this.createPackageForm.get('subject');
  }

  get expirationDate(): AbstractControl {
    return this.createPackageForm.get('validTo');
  }

  get name(): AbstractControl {
    return this.createPackageForm.get('name');
  }

  get emailPassword(): AbstractControl {
    return this.createPackageForm.get('sendEmailNotification');
  }

  get isGeneratedPasswordActive(): AbstractControl {
    return this.createPackageForm.get('isGeneratedPasswordActive');
  }

  ngOnInit(): void {
    this.defaultMessageAndTime = `${this._transloco.translate(
      `ccs.${this.defaultMessage}`,
      { todayDate: this.todayDate }
    )}`;
    this._initCreatePackageForm();
    this._initSubscriptions();
  }

  changeSecurityType(isAuthMode: boolean): void {
    this.authMode = isAuthMode;
    this.createPackageForm.patchValue({ authMode: this.authMode });
    this._shareDataService.setPackageForm(this.createPackageForm);
  }

  onUpdateRecipients(recipients: Chip[]): void {
    this.createPackageForm.patchValue({ to: recipients });
    this._shareDataService.setPackageForm(this.createPackageForm);
  }

  onUpdatePassword(password: string): void {
    this.createPackageForm.patchValue({ password: password });
    this._shareDataService.setPackageForm(this.createPackageForm);
  }

  private _initCreatePackageForm(): void {
    if (this._shareDataService.getCreatePackageFormValue()) {
      this.createPackageForm = this._shareDataService.getCreatePackageFormValue();
      this.authMode = this.createPackageForm.get('authMode').value;
    } else {
      this.createPackageForm = this._formBuilder.group({
        to: [undefined, [Validators.required, ValidateRecipients]],
        title: ['CommandCentral Evidence - Shared Package', [Validators.required]],
        name: [undefined, [Validators.required]],
        subject: [this.defaultMessageAndTime.toLowerCase(), [Validators.required]],
        validTo: [undefined, Validators.required],
        password: [
          '',
          [
            passwordLengthValidator(15),
            Validators.maxLength(32),
            alphanumericValidator(),
            caseValidator(),
            specialCharacterValidator(),
          ],
        ],
        sendEmailNotification: false,
        authMode: true,
        isGeneratedPasswordActive: false,
      });
    }
  }

  @HostListener('window:keydown', ['$event'])
  preventSwitchToAuthModeOnEnterClick(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  private _initSubscriptions(): void {
    this.createPackageForm.valueChanges
      .pipe(untilDestroyed(this), debounceTime(300))
      .subscribe(() => {
        this._shareDataService.setPackageForm(this.createPackageForm);
      });

    this._datePickerService.currentDateValue$
      .pipe(untilDestroyed(this), debounceTime(100))
      .subscribe((selectedDate: string) => {
        this.createPackageForm?.patchValue({ validTo: selectedDate });
        this._shareDataService.setPackageForm(this.createPackageForm);
      });

    this.packageContents$ = this._assetService.packageContents$;
  }
}
