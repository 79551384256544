/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
export enum EOperatorsType {
  CONTAINS = 'contains',
}

export enum ESortsType {
  ASC = 'asc',
  DESC = 'desc',
  DEC = 'dec',
}

export enum ESharesType {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  RESHARED = 'shared',
  UNREAD = 'unread',
  LOCKED = 'locked',
}
