<ng-container *transloco="let t; read: 'ccs'">
  <span
    class="password-creator"
    [msiDropdownTriggerFor]="passwordDropdown">
    <input
      class="msi-input password-creator__input"
      placeholder=" {{ t('Create a password') }}"
      maxlength="32"
      (input)="handleInput()"
      [type]="isVisible ? 'text' : 'password'"
      [formControl]="controlPassword" />
    <div
      class="password-creator__toggle_password input-option msi-hover"
      msiTooltip="{{ t('Change visibility') }}">
      <msi-icon
        name="{{ isVisible ? 'ic_visibility_off' : 'ic_visibility_on' }}"
        (click)="toggleVisibility()"
        [size]="18">
      </msi-icon>
    </div>
    <div
      class="password-creator__generate-button input-option msi-hover"
      msiTooltip="{{ t('Generate/Clear Password') }}"
      [ngClass]="{ 'generated-password-active': isGeneratedPasswordActive }">
      <msi-icon
        name="ic_key_rekey"
        (click)="handlePasswordOption()"
        [size]="18">
      </msi-icon>
    </div>
  </span>
  <msi-dropdown
    #passwordDropdown
    class="password-creator__dropdown">
    <div class="password-creator__dropdown_content">
      <strong>
        {{ t('Password must include') }}
      </strong>
      <span class="password-creator__password_requierment">
        <msi-icon
          [ngClass]="{
            'validation-passed': !hasMinLengthError() && !hasMaxLengthError() && controlPassword.value
          }"
          [name]="
            !hasMinLengthError() && !hasMaxLengthError() && controlPassword.value
              ? 'ic_status_available'
              : 'ic_progress_todo'
          "></msi-icon>
        <p> {{ t('Between 15 and 32 characters') }} </p>
      </span>
      <span class="password-creator__password_requierment">
        <msi-icon
          [ngClass]="{ 'validation-passed': !hasAlphanumericError() && controlPassword.value }"
          [name]="
            !hasAlphanumericError() && controlPassword.value ? 'ic_status_available' : 'ic_progress_todo'
          "></msi-icon>
        <p> {{ t('Alphanumeric (Abc, 123)') }} </p>
      </span>
      <span class="password-creator__password_requierment">
        <msi-icon
          [ngClass]="{ 'validation-passed': !hasCaseError() && controlPassword.value }"
          [name]="
            !hasCaseError() && controlPassword.value ? 'ic_status_available' : 'ic_progress_todo'
          "></msi-icon>
        <p> {{ t('Lower Case and Upper Case') }} </p>
      </span>
      <span class="password-creator__password_requierment">
        <msi-icon
          [ngClass]="{ 'validation-passed': !hasSpecialCharacterError() && controlPassword.value }"
          [name]="
            !hasSpecialCharacterError() && controlPassword.value ? 'ic_status_available' : 'ic_progress_todo'
          "></msi-icon>
        <p> {{ t('Special characters') }} </p>
      </span>
      <span
        class="password-creator__generate_password msi-hover"
        [ngClass]="{ 'generated-password-active': isGeneratedPasswordActive }"
        (click)="handlePasswordOption()">
        <msi-icon
          name="ic_key_rekey"
          [size]="18"></msi-icon>
        <strong>
          {{ isGeneratedPasswordActive ? t('Clear Generated Password') : t('Generate Password') }}
        </strong>
      </span>
    </div>
  </msi-dropdown>

  <div [class.cursor-not-allowed]="!controlPassword?.value || controlPassword?.value?.length === 0">
    <msi-checkbox
      #passwordCheckbox
      class="password-creator__to-all-option"
      [class.msi-checkbox-disabled]="!controlPassword?.value || controlPassword?.value?.length === 0"
      [class.disabled-click]="!controlPassword?.value || controlPassword?.value?.length === 0"
      for="password-to-all"
      [formControl]="controlEmailPassword">
      {{ t('Email password to all recipients') }}
    </msi-checkbox>
  </div>
</ng-container>
