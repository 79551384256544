/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { AssetEntity } from '@msi/emm-sdk';
import { ASSET_VIEWER_CONFIGURATION, MsiAssetViewerOptions } from '@msi/msi-asset-viewer';

@Component({
  selector: 'pp-detail-package-asset-list-view',
  templateUrl: './detail-package-asset-list-view.component.html',
  styleUrls: ['./detail-package-asset-list-view.component.scss'],
})
export class DetailPackageAssetListViewComponent implements OnChanges, OnInit {
  @Input()
  assetEntities: AssetEntity[];

  @Input()
  groupedAssets: Map<string, AssetEntity[]>;

  @Input()
  collapsedAssetsSection: Map<string, boolean>;

  @Input()
  printOptionEnabled: boolean;

  @Output()
  tileClick: EventEmitter<AssetEntity> = new EventEmitter();

  @Output()
  toggleSection: EventEmitter<string> = new EventEmitter();

  displayGroups = false;
  tagCategoryIncidentTypeId: string;

  constructor(
    @Inject(ASSET_VIEWER_CONFIGURATION) private _assetViewerOptions: MsiAssetViewerOptions
  ) {}

  ngOnChanges(): void {
    this.displayGroups = this.groupedAssets?.keys().next().value !== 'noGroupingSelected';
  }

  ngOnInit(): void {
    this.tagCategoryIncidentTypeId = this._assetViewerOptions.tagCategoryIncidentTypeId;
  }

  toggleCollapedSection(key: string): void {
    this.toggleSection.emit(key);
  }

  setCollapsedSection(key: string): boolean {
    return this.printOptionEnabled ? false : this.collapsedAssetsSection.get(key);
  }
}
