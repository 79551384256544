/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IShareableAsset } from '../../../../models/share-data.models';
import { ShareDataService } from '../../../../services/share-data/share-data.service';

@UntilDestroy()
@Component({
  selector: 'ccs-review-files',
  templateUrl: './review-files.component.html',
  styleUrls: ['./review-files.component.scss'],
})
export class ReviewFilesComponent implements OnInit {
  @Input()
  assets: IShareableAsset[];

  @Input()
  assetsLoading = true;

  @Output()
  assetRemoved: EventEmitter<IShareableAsset> = new EventEmitter();

  activeAsset: IShareableAsset;
  thumbnailUrl: string;

  constructor(private _shareDataService: ShareDataService) {}

  ngOnInit(): void {
    this._shareDataService.activeAsset$
      .pipe(untilDestroyed(this))
      .subscribe((asset: IShareableAsset) => {
        this.thumbnailUrl = asset?.services?.thumbnail?.link;
        this.activeAsset = asset;
      });
  }

  onResetAsset(e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    if (this.activeAsset) {
      this._shareDataService.resetActiveAsset();
    }
  }
}
