/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { InjectionToken } from '@angular/core';

import { EShareModel, IShareDataConfig } from '../../models/share-data.models';

export const SHARE_DATA_DEFAULT_CONFIG: IShareDataConfig = {
  shareModel: EShareModel.Evidence,
};

export const SHARE_DATA_CONFIGURATION = new InjectionToken<IShareDataConfig>(
  'SHARE_DATA_CONFIGURATION',
  { providedIn: 'root', factory: () => SHARE_DATA_DEFAULT_CONFIG }
);
