<ng-container *transloco="let t; read: 'ccs'">
  <section
    class="no-content"
    [class.full-screen]="fullScreenEnabled"
    [class.assets-loading]="assetsLoading">
    @if (assetsLoading) {
      <div
        class="ccs-assets-loading"
        [class.full-screen-assets-loading]="fullScreenEnabled">
        <msi-spinner size="medium"></msi-spinner>
      </div>
    } @else {
      <msi-icon
        name="ic_share_manager"
        [size]="120">
      </msi-icon>
      <p>{{ t('Select files to share') }}</p>
    }
  </section>
</ng-container>
