<ng-container *ngIf="assets">
  <ng-container *transloco="let t; read: 'ccs'">
    <div class="content">
      <header class="content__header">
        <span>{{ t('selectToReview') }}</span>
        <msi-icon
          class="content__help-icon"
          name="ic_help"
          msiTooltip="{{ t('Changing your search query will not affect your current selection') }}">
        </msi-icon>
      </header>
      <main
        class="content__data"
        [class.assets-loading]="assetsLoading">
        <ng-container *ngIf="!enableContentProjection">
          @if (assetsLoading) {
            <div class="ccs-assets-loading">
              <msi-spinner size="medium"></msi-spinner>
            </div>
          }
          <ccs-assets-list-view
            [assets]="assets"
            (assetRemoved)="assetRemoved.emit($event)"></ccs-assets-list-view>
        </ng-container>
        <ng-container *ngIf="enableContentProjection">
          <ccs-review-files-dynamic>
            <ng-content></ng-content>
          </ccs-review-files-dynamic>
        </ng-container>
      </main>
      <footer class="content__footer">
        <section class="content__footer-buttons">
          <button
            class="msi-btn content__preview-button"
            (click)="onPreview()">
            <span>{{ t('Review and share') }}</span>
          </button>
        </section>
      </footer>
    </div>
  </ng-container>
</ng-container>
