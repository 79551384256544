/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Pipe, PipeTransform } from '@angular/core';

import { IShareableAsset } from '../../models/share-data.models';
import { AssetVerificationService } from '../../services/asset-verification/asset-verification.service';

@Pipe({
  name: 'notSharableAsset',
})
export class NotSharableAssetPipe implements PipeTransform {
  constructor(private _assetVerificationService: AssetVerificationService) {}

  transform(asset: IShareableAsset): boolean {
    return this._assetVerificationService.notShareableAsset(asset.assetStorageType);
  }
}
