<div
  *ngIf="pkg.overview.hasOverview"
  class="pp-detail-package-overview">
  <h2
    id="pp-detail-package-overview"
    class="pp-detail-package-overview__header">
    {{ 'Overview' | transloco }}
  </h2>
  <div class="pp-detail-package-overview__info__row first">
    <ng-container
      *ngTemplateOutlet="
        rowItem;
        context: {
          $implicit: {
            title: 'Reported Time',
            value: pkg.overview.reportedTime
          }
        }
      "></ng-container>
    <ng-container
      *ngTemplateOutlet="
        rowItem;
        context: {
          $implicit: {
            title: 'Event Duration',
            value: pkg.overview.eventDuration
          }
        }
      "></ng-container>
  </div>
  <div class="pp-detail-package-overview__info__row">
    <div
      *ngIf="pkg.overview.reportedBy"
      class="pp-detail-package-overview__info__row__cell2">
      <h3>{{ 'Reported by:' | transloco }}</h3>
      <msi-avatar
        class="pp-detail-package-overview__avatar"
        [firstname]="pkg.overview.reportedBy"
        [size]="22"
        [hasInitials]="false">
      </msi-avatar>
      <span>{{ pkg.overview.reportedBy }}</span>
    </div>
    <div
      *ngIf="pkg.overview.respondingOfficers.length"
      class="pp-detail-package-overview__info__row__cell2">
      <h3>{{ 'Responding Officers:' | transloco }}</h3>
      <ng-template [ngIf]="pkg.overview.respondingOfficers.length">
        <ng-template
          ngFor
          let-item
          [ngForOf]="pkg.overview.respondingOfficers">
          <ng-container
            *ngTemplateOutlet="
              officerItem;
              context: { $implicit: { name: item, bold: true } }
            "></ng-container>
        </ng-template>
      </ng-template>
    </div>
  </div>
  <div class="pp-detail-package-overview__info__row">
    <ng-template [ngIf]="pkg.overview.area">
      <ng-container
        *ngTemplateOutlet="
          rowItem;
          context: {
            $implicit: {
              title: 'Area',
              value: pkg.overview.area
            }
          }
        "></ng-container>
    </ng-template>
    <ng-template [ngIf]="pkg.overview.address">
      <ng-container
        *ngTemplateOutlet="
          rowItem;
          context: {
            $implicit: {
              title: 'Address',
              value: pkg.overview.address
            }
          }
        "></ng-container>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="
        rowItem;
        context: {
          $implicit: {
            title: 'Disposition',
            value: pkg.overview.disposition
          }
        }
      "></ng-container>
    <ng-container
      *ngTemplateOutlet="
        rowItem;
        context: {
          $implicit: {
            title: 'Disposition date',
            value: pkg.overview.dispositionDate
          }
        }
      "></ng-container>
    <ng-template
      ngFor
      let-item
      [ngForOf]="pkg.overview.circumstances">
      <ng-container
        *ngTemplateOutlet="
          rowItem;
          context: { $implicit: { title: 'Circumstances', value: item } }
        "></ng-container>
    </ng-template>
  </div>
  <div class="pp-detail-package-overview__info__row">
    <ng-template [ngIf]="pkg.overview.clearance">
      <ng-container
        *ngTemplateOutlet="
          rowItem;
          context: {
            $implicit: {
              title: 'Clearance',
              value: pkg.overview.clearance
            }
          }
        "></ng-container>
    </ng-template>
    <ng-template [ngIf]="pkg.overview.clearanceDate">
      <ng-container
        *ngTemplateOutlet="
          rowItem;
          context: {
            $implicit: {
              title: 'Clearance Date',
              value: pkg.overview.clearanceDate
            }
          }
        "></ng-container>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="
        rowItem;
        context: {
          $implicit: {
            title: 'Exceptional Clearance',
            value: pkg.overview.exceptionalClearance
          }
        }
      "></ng-container>
    <ng-container
      *ngTemplateOutlet="
        rowItem;
        context: {
          $implicit: {
            title: 'Exceptional Clearance Date',
            value: pkg.overview.exceptionalClearanceDate
          }
        }
      "></ng-container>
    <ng-template [ngIf]="pkg.overview.judicialStatus">
      <ng-container
        *ngTemplateOutlet="
          rowItem;
          context: {
            $implicit: {
              title: 'Judicial Status',
              value: pkg.overview.judicialStatus
            }
          }
        "></ng-container>
    </ng-template>
    <ng-template [ngIf]="pkg.overview.judicialStatusDate">
      <ng-container
        *ngTemplateOutlet="
          rowItem;
          context: {
            $implicit: {
              title: 'Judicial Date:',
              value: pkg.overview.judicialStatusDate
            }
          }
        "></ng-container>
    </ng-template>
  </div>
  <ng-template [ngIf]="pkg.overview.modusOperandi.length">
    <h4>{{ 'Modus operandi:' | transloco }}</h4>
    <div class="pp-detail-package-overview__info__row">
      <ng-template
        ngFor
        let-item
        [ngForOf]="pkg.overview.modusOperandi">
        <ng-container
          *ngTemplateOutlet="
            rowItem;
            context: {
              $implicit: {
                title: item.factor,
                value: item.method
              }
            }
          "></ng-container>
      </ng-template>
    </div>
  </ng-template>
  <ng-template [ngIf]="pkg.overview.reportedOffense">
    <h4>{{ 'Reported Offense:' | transloco }}</h4>
    <div class="pp-detail-package-overview__info__row">
      <ng-container
        *ngTemplateOutlet="
          rowItemSmall;
          context: {
            $implicit: {
              title: 'Statute',
              value: pkg.overview.reportedOffense.statute
            }
          }
        "></ng-container>
      <ng-container
        *ngTemplateOutlet="
          rowItemSmall;
          context: {
            $implicit: {
              title: 'IBR Code',
              value: pkg.overview.reportedOffense.IBRCode
            }
          }
        "></ng-container>
      <ng-container
        *ngTemplateOutlet="
          rowItemSmall;
          context: {
            $implicit: {
              title: 'Offense Code',
              value: pkg.overview.reportedOffense.offenseCode
            }
          }
        "></ng-container>
      <ng-container
        *ngTemplateOutlet="
          rowItemSmall;
          context: {
            $implicit: {
              title: 'Description',
              value: pkg.overview.reportedOffense.description
            }
          }
        "></ng-container>
    </div>
  </ng-template>
  <ng-template [ngIf]="pkg.overview.offenses.length">
    <h4>{{ 'Observed Offenses:' | transloco }}</h4>
    <ng-template
      ngFor
      let-item
      [ngForOf]="pkg.overview.offenses">
      <div class="pp-detail-package-overview__info__row small-row">
        <ng-container
          *ngTemplateOutlet="
            rowItem;
            context: {
              $implicit: {
                title: 'Statute',
                value: item.statute
              }
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            rowItem;
            context: {
              $implicit: {
                title: 'IBR Code',
                value: item.IBRCode
              }
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            rowItem;
            context: {
              $implicit: {
                title: 'Offense Code',
                value: item.offenseCode
              }
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            rowItem;
            context: {
              $implicit: {
                title: 'Description',
                value: item.description
              }
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            rowItem;
            context: {
              $implicit: {
                title: 'Offense Attempted',
                value: item.offenseAttempted
              }
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            rowItem;
            context: {
              $implicit: {
                title: 'Cargo Theft',
                value: item.cargoTheft
              }
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            rowItem;
            context: {
              $implicit: {
                title: 'Location Type',
                value: item.locationType
              }
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            rowItem;
            context: {
              $implicit: {
                title: 'Method of entry',
                value: item.methodOfEntry
              }
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            rowItem;
            context: {
              $implicit: {
                title: 'Premises Entered',
                value: item.premisesEntered
              }
            }
          "></ng-container>
        <ng-template [ngIf]="item.offendersSuspectedOfUsing.length">
          <ng-container
            *ngTemplateOutlet="
              rowItem;
              context: {
                $implicit: {
                  title: 'Offenders Suspected Of Using',
                  value: item.offendersSuspectedOfUsing.join(', ')
                }
              }
            "></ng-container>
        </ng-template>
        <ng-template [ngIf]="item.typeOfCriminalActivity.length">
          <ng-container
            *ngTemplateOutlet="
              rowItem;
              context: {
                $implicit: {
                  title: 'Type Of Criminal Activity',
                  value: item.typeOfCriminalActivity.join(', ')
                }
              }
            "></ng-container>
        </ng-template>
        <ng-template [ngIf]="item.typeOfWeaponForce.length">
          <ng-container
            *ngTemplateOutlet="
              rowItem;
              context: {
                $implicit: {
                  title: 'Type Of Weapon Force',
                  value: item.typeOfWeaponForce.join(', ')
                }
              }
            "></ng-container>
        </ng-template>
      </div>
    </ng-template>
  </ng-template>
</div>

<ng-template
  #officerItem
  let-item>
  <div class="pp-detail-package-overview__officer">
    <msi-avatar
      class="pp-detail-package-overview__avatar"
      [firstname]="officer"
      [size]="22"
      [hasInitials]="false">
    </msi-avatar>
    <span
      class="pp-detail-package-overview__officer__name"
      [class.bold]="item.bold"
      >{{ item.name }}</span
    >
  </div>
</ng-template>

<ng-template
  #rowItem
  let-item>
  <div
    *ngIf="item.value"
    class="pp-detail-package-overview__info__row__cell2">
    <h3>{{ item.title | transloco }}:</h3>
    <span>{{ item.value }}</span>
  </div>
</ng-template>

<ng-template
  #rowItemSmall
  let-item>
  <div
    *ngIf="item.value"
    class="pp-detail-package-overview__info__row__cell">
    <h3>{{ item.title | transloco }}:</h3>
    <span>{{ item.value }}</span>
  </div>
</ng-template>
