/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { DateTimeRange, QuickSelectionDateRange } from '@msi/cobalt';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import dayjs, { Dayjs } from 'dayjs';
import { BehaviorSubject, Observable } from 'rxjs';

import { EExpiresDate } from './expires-date.enum';

@Injectable({
  providedIn: 'root',
})
export class DatePickerService {
  private selectedDateValueSubject = new BehaviorSubject<string>('');
  private _inputDateTouchedState = false;

  readonly selectedDateValue$: Observable<string>;
  readonly dateNever = dayjs('2999-12-31').endOf('day');

  public get inputDateTouchedState() {
    return this._inputDateTouchedState;
  }
  public set inputDateTouchedState(value) {
    this._inputDateTouchedState = value;
  }

  constructor() {
    this.selectedDateValue$ = this.selectedDateValueSubject.asObservable();
  }

  formatValue(dateTimeRange: DateTimeRange | null): string {
    if (!dateTimeRange?.startDate && !dateTimeRange?.endDate) {
      return '';
    }
    const endDate = this.getDateString(dateTimeRange.endDate);

    this.selectedDateValueSubject.next(endDate);
    return endDate;
  }

  getPredefineDates(): QuickSelectionDateRange[] {
    return [
      {
        text: EExpiresDate.ExpiresIn30Days,
        dateFrom: (today: Dayjs) => today.add(30, 'd'),
        dateTo: (today: Dayjs) => today.add(30, 'd'),
      },
      {
        text: EExpiresDate.ExpiresIn90Days,
        dateFrom: (today: Dayjs) => today.add(90, 'd'),
        dateTo: (today: Dayjs) => today.add(90, 'd'),
      },
      {
        text: EExpiresDate.ExpiresIn120Days,
        dateFrom: (today: Dayjs) => today.add(120, 'd'),
        dateTo: (today: Dayjs) => today.add(120, 'd'),
      },
      {
        text: EExpiresDate.ExpiresIn365Days,
        dateFrom: (today: Dayjs) => today.add(365, 'd'),
        dateTo: (today: Dayjs) => today.add(365, 'd'),
      },
      {
        text: EExpiresDate.Never,
        dateFrom: () => this.dateNever,
        dateTo: () => this.dateNever,
      },
    ];
  }

  getFirstAvaliableDate(): NgbDate {
    const firstDay = dayjs().add(1, 'd');

    return new NgbDate(firstDay.year(), firstDay.month() + 1, firstDay.date());
  }

  getLastAvaliableDate(shortestRetentionDay?: Dayjs): NgbDate {
    return new NgbDate(
      shortestRetentionDay.year(),
      shortestRetentionDay.month() + 1,
      shortestRetentionDay.date()
    );
  }

  resetSelectedDateValue(): void {
    setTimeout(() => {
      this.selectedDateValueSubject.next('');
      this.inputDateTouchedState = false;
    }, 300);
  }

  private getDateString(date: NgbDate): string {
    return date.equals(
      new NgbDate(
        this.dateNever.year(),
        this.dateNever.month() + 1,
        this.dateNever.date()
      )
    ) || !date
      ? EExpiresDate.Never
      : dayjs(`${date.year}-${date.month}-${date.day}`).format('YYYY-MM-DD');
  }
}
