/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { Utils } from '@msi/js-sdk';

@Injectable()
export class MFLoaderService {
  private _loaded: Map<string, boolean> = new Map();

  async load(src: string): Promise<boolean> {
    if (this._loaded.has(src)) {
      return true;
    }

    const suffix: string = (src.includes('?') ? '&' : '?') + Utils.uniqueId();

    const loaded: boolean = await Utils.loadScript(src + suffix);

    if (loaded) {
      this._loaded.set(src, true);
    }

    return loaded;
  }
}
