/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtils } from '@msi/js-sdk';

const EMPTY = '--';

@Pipe({ name: 'DateTimeUtils' })
export class DateTimeUtilsPipe implements PipeTransform {
  transform(
    val: string | void,
    format: string = DateTimeUtils.defaultLongTimeFormat
  ): string {
    return val ? DateTimeUtils.format(val, format) : EMPTY;
  }
}
