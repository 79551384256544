<div class="pp-container">
  <cc-common-header
    [search]="false"
    (initialized)="handleHeaderInitialized($event)">
    <cc-header-left-plugin-area
      class="msi-common-header-left-plugin-area"
      applicationName="CommandCentral Sharing"
      [applicationHomeUrl]="isBeta ? baseUrlBeta : baseUrl"
      [redirectLogoByRouter]="false">
    </cc-header-left-plugin-area>

    <cc-header-right-plugin-area class="msi-common-header-right-plugin-area">
      <div class="msi-common-header-plugin">
        <cc-application-switcher></cc-application-switcher>
      </div>

      <div class="msi-common-header-plugin">
        <cc-support-information *ngIf="appInfo">
          <p>{{ 'HELP' | transloco }}</p>
          <p>{{ 'For help, please contact Motorola Solutions support.' | transloco }}</p>
          <p>
            <msi-icon
              class="help-text-call-icon"
              [name]="'ic_call'"
              [size]="14"
              title="email">
            </msi-icon>
            800-323-9949
          </p>
          <p>
            <msi-icon
              class="help-text-email-icon"
              [name]="'ic_email'"
              [size]="14"
              title="email">
            </msi-icon>
            {{ 'support-commandcentral@motorolasolutions.com' | transloco }}
          </p>
          <br />
          <p>
            <span *ngIf="appInfo.appVersion">
              {{ 'Version: ' | transloco }}
              {{ appInfo.appVersion }}
            </span>
            <br />
            {{ 'Build Number: ' | transloco }}{{ appInfo.buildNumber }}
            <br />
            {{ 'Source Version: ' | transloco }}
            {{ appInfo.sourceVersion }}
          </p>
        </cc-support-information>
      </div>
      <cc-session-manager class="msi-session-manager">
        <a
          class="msi-common-header-dropdown-item sub-link user-action"
          href="https://www.motorolasolutions.com/en_us/about/terms-use.html"
          target="_blank">
          {{ 'Terms &amp; Conditions' | transloco }}
        </a>
        <a
          class="msi-common-header-dropdown-item sub-link user-action"
          href="https://learning.motorolasolutions.com/quick-start-guide/81089enus"
          target="_blank">
          {{ 'User Guide' | transloco }}
        </a>
        <a
          class="msi-common-header-dropdown-item sub-link user-action"
          href="https://www.motorolasolutions.com/en_us/about/privacy-policy.html#highlights"
          target="_blank">
          {{ 'Privacy Policy' | transloco }}
        </a>
        <a
          class="msi-common-header-dropdown-item sub-link user-action"
          href="#"
          (click)="toggleTheme($event)">
          {{ 'Switch theme' | transloco }}
        </a>
      </cc-session-manager>
    </cc-header-right-plugin-area>
  </cc-common-header>

  <pp-sidebar *ngIf="initialized && displaySidebar"></pp-sidebar>
  <!--  <pp-sidebar *ngIf="initialized"></pp-sidebar>-->

  <div
    class="main"
    [style.--margin-left]="marginLeft">
    <!--  <div class="main">-->
    <ng-content></ng-content>
  </div>
</div>
