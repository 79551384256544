/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function caseValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const valid = /^(?=.*[a-z])(?=.*[A-Z])/.test(control.value) || !control.value;

    return valid ? null : { case: { value: control.value } };
  };
}
