/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
export enum EPackageVersionType {
  Version_1 = 'v1',
  Version_2 = 'v2',
}

export enum EPackageAccessType {
  AUTH = 'accountLogin',
  GUEST = 'openLink',
}

export enum EDetailPackageMode {
  GUESTS = 'guests',
  SHARES = 'shares',
  PROTECTED = 'protected',
}

export enum EBdpSearchUrl {
  PUBLISHERS = 'BDP_PUBLISHERS_SEARCH',
  VIEWERS = 'BDP_VIEWERS_SEARCH',
}

export enum EItemEntityType {
  NARRATIVE = 'NARRATIVE',
  FILE = 'FILE',
  PROPERTY = 'PROPERTY',
  PERSON = 'PERSON',
  ORGANIZATION = 'ORGANIZATION',
  VEHICLE = 'VEHICLE',
}

export enum ESelectiveItemType {
  OVERVIEW = 'overview',
  NARRATIVE = 'narrative',
  ASSET = 'asset',
  PROPERTY = 'property',
  PERSON = 'person',
  ORGANIZATION = 'organization',
  VEHICLE = 'vehicle',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum DerivedItemType {
  CLIP = 'CLIP',
  SCREENSHOT = 'SCREENSHOT',
  EXPORTED_REDACTION = 'EXPORTED_REDACTION',
}
