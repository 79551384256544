/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@msi/cobalt';
import { Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, withLatestFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ApmTransactionMethod } from '../../services/elastic-apm';
import { ESharesType } from '../../services/filter/filter.enums';
import {
  EFilterType,
  IAssetFilter,
  IListBody,
  ISearchFilters,
  ISort,
} from '../../services/filter/filter.interfaces';
import { PackageItem } from '../../services/package/classes/package/PackageItem';
import { ISharePermissions, UserService } from '../../services/user/user.service';
import { ISettings } from '../../settings/settings.interface';
import { packagesActions } from '../+store/actions';
import {
  clearReceivedPackages,
  clearSharedPackages,
  filterPackages,
  loadMorePackages,
  loadPackages,
  loadReceivedPackages,
  loadSharedPackages,
  searchPackages,
  setCalloutClosed,
  setIsReceived,
  setOptions,
} from '../+store/actions/packages.actions';
import {
  getReceivedFilters,
  getReceivedPackages,
  getReceivedPackagesLoadingError,
  getReceivedQuery,
  getSharedFilters,
  getSharedPackages,
  getSharedPackagesLoadingError,
  getSharedQuery,
  getShouldDisplayCallout,
  isReceived,
  isReceivedPackagesLoading,
  isSharedPackagesLoading,
} from '../+store/selectors/packages.selectors';
import { EShareManagerTableMode } from '../share-manager/table/share-manager-table.enums';

@UntilDestroy()
@Component({
  selector: 'pp-main-packages',
  templateUrl: './main-packages.component.html',
  styleUrls: ['./main-packages.component.scss'],
})
export class MainPackagesComponent implements OnInit, OnDestroy {
  @Input()
  lang: string;

  receivedPackages$: Observable<PackageItem[]>;
  sharedPackages$: Observable<PackageItem[]>;
  loading = false;
  statusCode = 200;
  sharePermissions: ISharePermissions;
  mode: EShareManagerTableMode = EShareManagerTableMode.LANDING;
  isReceived$: Observable<boolean>;
  receivedPackagesLoading$: Observable<boolean>;
  sharedPackagesLoading$: Observable<boolean>;

  private _toastOptions: Record<string, any> = {
    closeButton: true,
    autoDismiss: 3000,
  };

  isReceivedError$: Observable<number>;
  isSharedError$: Observable<number>;
  readAuditLogsPermission: boolean;
  searchFilters: ISearchFilters;
  assetFilter: IAssetFilter;
  isAnySharedPackageLocked$: Observable<boolean>;

  constructor(
    private _settings: Settings<ISettings>,
    private _toastService: ToastService,
    private _transloco: TranslocoService,
    private _userService: UserService,
    private store: Store,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this._toastOptions.autoDismiss = this._settings.get<number>('AUTODISMISS');
  }

  ngOnInit(): void {
    this.prepareAssetFilter();
    this._userService.sharePermission$
      .pipe(untilDestroyed(this))
      .subscribe((permissions: ISharePermissions) => {
        this.sharePermissions = permissions;
        this.readAuditLogsPermission = this._userService.hasReadAuditLogsPermission();
        if (this.sharePermissions.publishShares && this.sharePermissions.viewShares) {
          this.loadReceivedPackages();
          this.loadSharedPackages();
        } else if (
          this.sharePermissions.publishShares &&
          !this.sharePermissions.viewShares
        ) {
          this.loadSharedPackages();
          this.store.dispatch(setIsReceived({ isReceived: false }));
        } else if (
          !this.sharePermissions.publishShares &&
          this.sharePermissions.viewShares
        ) {
          this.loadReceivedPackages();
        } else {
          this.sharePermissions = { viewShares: false, publishShares: false };
          this._toastService.error(
            this._transloco.translate('You don\'t have permissions'),
            void 0,
            this._toastOptions
          );
        }
      });
    this.isReceived$ = this.store.select(isReceived);
    this.receivedPackages$ = this.store.select(getReceivedPackages);
    this.sharedPackages$ = this.store.select(getSharedPackages);
    this.sharedPackagesLoading$ = this.store.select(isSharedPackagesLoading);
    this.receivedPackagesLoading$ = this.store.select(isReceivedPackagesLoading);
    this.isReceivedError$ = this.store.select(getReceivedPackagesLoadingError).pipe(
      filter((err) => err !== undefined),
      map((err) => {
        this.handleError(err);
        this.statusCode = err.status;
        return err.status;
      })
    );
    this.isSharedError$ = this.store.select(getSharedPackagesLoadingError).pipe(
      filter((err) => err !== undefined),
      map((err) => {
        this.handleError(err);
        this.statusCode = err.status;
        return err.status;
      })
    );
    this.isAnySharedPackageLocked$ = this.store.select(getShouldDisplayCallout);
  }

  @ApmTransactionMethod('get shared packages')
  loadSharedPackages() {
    const incidentId = this.route.snapshot.paramMap.get('id');
    if (incidentId || this.assetFilter) {
      const options = incidentId
        ? { query: incidentId }
        : { assetFilter: this.assetFilter };

      this.store.dispatch(setIsReceived({ isReceived: false }));
      this.store.dispatch(setOptions({ options }));
    }
    this.store.dispatch(loadSharedPackages());
  }

  @ApmTransactionMethod('get received packages')
  loadReceivedPackages() {
    if (this.assetFilter) {
      this.store.dispatch(setOptions({ options: { assetFilter: this.assetFilter } }));
    }
    this.store.dispatch(loadReceivedPackages());
  }

  @ApmTransactionMethod('quick filter buttons')
  onShare(share: ESharesType[]): void {
    this.scrollTop();

    this.setOptions({ share, pageNumber: 0, assetFilter: undefined });
    this.store.dispatch(filterPackages());
  }

  @ApmTransactionMethod('search bar')
  onSearch(filters: IListBody): void {
    const { query, assetFilter } = filters;
    this.assetFilter = assetFilter;
    const options = { query, pageNumber: 0, assetFilter: this.assetFilter };
    this.searchPackages(options);
  }

  onRemoveAssetFilter(): void {
    this.assetFilter = undefined;
    const options = { pageNumber: 0, assetFilter: this.assetFilter };
    this.removeAssetFilterQueryParams();
    this.searchPackages(options);
  }

  @ApmTransactionMethod('sort')
  onSort(sort: ISort): void {
    this.scrollTop();

    this.setOptions({ sort, pageNumber: 0 });
    this.store.dispatch(loadPackages());
  }

  onLoadMore(): void {
    this.store.dispatch(loadMorePackages());
  }

  onReload(): void {
    this.store.dispatch(loadPackages());
  }

  handleError(errResponse: HttpErrorResponse): void {
    if (errResponse) {
      if (errResponse.status === 403) {
        this._toastService.error(
          this._transloco.translate('You don\'t have permissions'),
          void 0,
          this._toastOptions
        );
      } else if (errResponse.status === 500) {
        this._toastService.error(
          this._transloco.translate('Internal Server Error'),
          void 0,
          this._toastOptions
        );
      } else if (errResponse.status === 424) {
        this._toastService.error(
          this._transloco.translate('Dependency Service Failed'),
          void 0,
          this._toastOptions
        );
      } else {
        this._toastService.error(
          this._transloco.translate('Something went wrong'),
          void 0,
          this._toastOptions
        );
      }
    }
  }

  switchTables(shouldDisplayReceived: boolean): void {
    this.setOptions({ query: '', assetFilter: this.assetFilter });
    this.store.dispatch(
      packagesActions.setIsReceived({
        isReceived: shouldDisplayReceived,
      })
    );
    this.onSearch({ query: '', assetFilter: this.assetFilter });
  }

  onRefresh(): void {
    this.setOptions({ pageNumber: 0 });

    this.store.dispatch(loadSharedPackages());
  }

  getQuery(receivedActive: boolean): Observable<string> {
    return receivedActive
      ? this.store.select(getReceivedQuery)
      : this.store.select(getSharedQuery);
  }

  provideFilters(): Observable<ESharesType[]> {
    return this.isReceived$.pipe(
      withLatestFrom(this.getReceivedFilters(), this.getSharedFilters()),
      map(([isCurrentlyReceived, receivedFilters, sharedFilters]) =>
        isCurrentlyReceived ? receivedFilters : sharedFilters
      )
    );
  }

  getReceivedFilters(): Observable<ESharesType[]> {
    return this.store.select(getReceivedFilters);
  }
  getSharedFilters(): Observable<ESharesType[]> {
    return this.store.select(getSharedFilters);
  }

  onCalloutClose(): void {
    this.store.dispatch(setCalloutClosed());
  }

  private setOptions(options: IListBody) {
    this.store.dispatch(setOptions({ options }));
  }

  private scrollTop(): void {
    // it is scrolling table into view in order to load only one page of packages after change of filtering
    // TODO: remove when infinite scrolling will be improved
    document.querySelector('tbody')?.scrollIntoView(true);
  }

  private searchPackages(options: IListBody) {
    this.scrollTop();

    this.setOptions(options);
    this.store.dispatch(searchPackages());
  }

  private prepareAssetFilter(): void {
    const id = this.route.snapshot.queryParams.assetId;
    if (id) {
      this.assetFilter = {
        assetId: {
          id,
          agencyId: this.route.snapshot.queryParams.assetAgencyId || '',
        },
      };

      this.searchFilters = this.prepareSeachFilters(this.assetFilter);
      this.setOptions({ assetFilter: this.assetFilter });
    }
  }

  private prepareSeachFilters(assetFilter: IAssetFilter): ISearchFilters {
    const assetName = this.route.snapshot.queryParams.assetDisplayName
      ? this.route.snapshot.queryParams.assetDisplayName
      : assetFilter.assetId.id;

    return {
      assetName,
      assetFilter,
      filterType: EFilterType.AssetId,
    };
  }

  private removeAssetFilterQueryParams(): void {
    const baseUrl = this.location.path().split('?')[0];

    this.location.replaceState(baseUrl);
  }

  protected readonly isReceived = isReceived;

  ngOnDestroy(): void {
    this.store.dispatch(clearSharedPackages());
    this.store.dispatch(clearReceivedPackages());
  }
}
