/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Settings } from '@msi/js-sdk';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';

import { TPros } from '../../components/common/prosecutors/prosecutor-validator';
import { ISettings } from '../../settings/settings.interface';
import { Email } from '../package/Email';
import { Officer } from './Officer';
import { IOfficersResponse } from './officer.interfaces';

@Injectable()
export class OfficerService {
  private _officers: Officer[] = [];
  private _previousRecipients: (string | Officer)[] = [];
  private _selectedRecipients: TPros[] = [];
  private isNewRecipintAddedSubject = new BehaviorSubject<boolean>(false);
  private recipientsErrorSubject = new BehaviorSubject<string>('');
  private selectedRecipientsSubject = new BehaviorSubject<TPros[]>([]);
  private loadingOfficersSubject = new BehaviorSubject<boolean>(false);
  readonly isNewRecipintAdded$: Observable<boolean>;
  readonly loadingOfficers$: Observable<boolean>;
  readonly recipientsError$: Observable<string>;
  readonly selectedRecipients$: Observable<TPros[]>;

  public get officers() {
    return this._officers;
  }

  public set officers(value) {
    this._officers = value;
  }

  public get previousRecipients() {
    return this._previousRecipients;
  }

  public set previousRecipients(value) {
    this._previousRecipients = value;
  }

  public get selectedRecipients() {
    return this._selectedRecipients;
  }

  public set selectedRecipients(value) {
    this._selectedRecipients = value;
  }

  constructor(
    private _settings: Settings<ISettings>,
    private _http: HttpClient
  ) {
    this.loadingOfficers$ = this.loadingOfficersSubject.asObservable();
    this.recipientsError$ = this.recipientsErrorSubject.asObservable();
    this.selectedRecipients$ = this.selectedRecipientsSubject.asObservable();
    this.isNewRecipintAdded$ = this.isNewRecipintAddedSubject.asObservable();
  }

  updateIsNewRecipientAdded(): void {
    const isNewRecipientAdded = this.selectedRecipients.some((recipient) => {
      return !this.previousRecipients.includes(recipient.email);
    });
    this.isNewRecipintAddedSubject.next(isNewRecipientAdded);
  }

  addSelectedOfficer(officer: Officer): void {
    this.updateIsNewRecipientAdded();
    this._selectedRecipients.push(officer);
    this.selectedRecipientsSubject.next(this._selectedRecipients);
  }

  addSelectedEmail(email: string): void {
    const existingEmail = this.selectedRecipients.find(
      (recipient) => recipient?.email === email
    );
    if (!existingEmail) {
      this.selectedRecipients.push(new Email(email));
    }
    this.selectedRecipientsSubject.next(this.selectedRecipients);
    this.updateIsNewRecipientAdded();
  }

  removeSelectedRecipients(item: TPros): void {
    const index = this.selectedRecipients.indexOf(item, 0);
    if (index > -1) {
      this.selectedRecipients.splice(index, 1);
    }
    this.selectedRecipientsSubject.next(this.selectedRecipients);
    this.updateIsNewRecipientAdded();
  }

  loadPreviousRecipients(recipients: TPros[]): void {
    recipients.forEach((item: TPros) => {
      if (item instanceof Officer) {
        this.addSelectedOfficer(item);
      } else {
        this.addSelectedEmail(item.email);
      }
    });
  }

  cleanSelectedRecipient(): void {
    this.selectedRecipients = [];
    this.selectedRecipientsSubject.next(this.selectedRecipients);
    this.updateIsNewRecipientAdded();
  }

  fetchOfficers(): Observable<Officer[]> {
    this.loadingOfficersSubject.next(true);

    if (this.officers.length) {
      this.loadingOfficersSubject.next(false);
      return of(this.officers);
    }

    const domain = this._settings.get<string>('PLATFORM.API' as any);
    const url = domain + this._settings.get<string>('GET_PROSECUTORS_URL');

    return this._http.get<IOfficersResponse>(url).pipe(
      map((response) => {
        this.officers = response.prosecutors.map(
          (officer: Officer) => new Officer(officer)
        );
        this.loadingOfficersSubject.next(false);
        this.recipientsErrorSubject.next('');
        return this.officers;
      }),
      catchError(() => {
        this.loadingOfficersSubject.next(false);
        this.recipientsErrorSubject.next('Cannot load list of officers');
        return of([]);
      })
    );
  }
}
