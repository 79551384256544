<ng-template
  #modalUnsubscribe
  let-ref="modalRef">
  <msi-modal>
    <msi-modal-header>{{ 'Unsubscribe' | transloco }}</msi-modal-header>
    <msi-modal-content>
      {{ 'Confirm your unsubscription to updates for this case.' | transloco }}
    </msi-modal-content>
    <msi-modal-actions align="end">
      <button
        id="confirm-modal-btn"
        type="button"
        class="msi-btn spinner-parent"
        (click)="onConfirm()">
        {{ 'Unsubscribe' | transloco }}
        <msi-spinner
          *ngIf="unsubscribePending"
          size="small"></msi-spinner>
      </button>
      <button
        id="cancel-modal-btn"
        type="button"
        class="msi-btn msi-btn-action"
        (click)="onCancel()">
        {{ 'Cancel' | transloco }}
      </button>
    </msi-modal-actions>
  </msi-modal>
</ng-template>

<ng-template
  #modalUnsubscribeSuccess
  let-ref="modalRef">
  <msi-modal>
    <msi-modal-header>{{ 'Unsubscribed' | transloco }}</msi-modal-header>
    <msi-modal-content>{{ 'Unsubscribed successfully!' | transloco }}</msi-modal-content>
    <msi-modal-actions align="end">
      <button
        class="msi-btn"
        (click)="ref.close()"
        >{{ 'OK' | transloco }}</button
      >
    </msi-modal-actions>
  </msi-modal>
</ng-template>
