/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AssetEntity, AssetStorageType, IRetentionData } from '@msi/emm-sdk';

import { IPackageActions } from '../../components/common/actions-dropdown/actions.interface';
import { IEmptyAssetEntity } from '../asset-entity/asset-entity.interface';
import { DerivedItemType, EPackageVersionType } from '../package/package.enums';
import { PatchEntityType, PatchOperation } from '../package-diff/package-diff.interfaces';
import { EAccessProtectionType } from './classes/accessProtection/AccessProtection.enum';
import { IAlert } from './classes/alert/Alert.interfaces';
import { IModusOperandi } from './classes/common.interfaces';
import { ILocation } from './classes/location/EntityLocation.interfaces';
import { Narrative } from './classes/narrative/Narrative';
import { INarrative } from './classes/narrative/Narrative.interfaces';
import { IOffence } from './classes/offense/Offense.interfaces';
import { Organization } from './classes/organization/Organization';
import { IOrganization } from './classes/organization/Organization.interfaces';
import { Overview } from './classes/overview/Overview';
import { IPackageContent } from './classes/package/PackageContent.interafces';
import { Person } from './classes/person/Person';
import { IPerson, ISubject } from './classes/person/Person.interfaces';
import { Property } from './classes/property/Property';
import { IProperty } from './classes/property/Property.interfaces';
import { Vehicle } from './classes/vehicle/Vehicle';
import { IVehicle } from './classes/vehicle/Vehicle.interfaces';
import { EPackageAccessType } from './package.enums';

export interface IPackageUpdate {
  operation: string;
  entityId: string;
  entityType: string;
}

export interface IPackageHeaderReshareDetails {
  id: string;
  title: string;
  sharedWith: string[];
  firstViewed?: string | null;
}

export interface IPackageHeader {
  updated: string;
  views?: number;
  sharedWithAuthenticatedString?: string;
  sharedWithString?: string;
  id?: string;
  groupId?: string;
  fullContent: boolean;
  accessType?: EPackageAccessType;
  accessProtectionType: EAccessProtectionType;
  agency?: string;
  agencyName: string;
  created: string;
  createdBy: string;
  createdByOid?: string;
  link?: string;
  title: string;
  validTo: string;
  sharedWith?: any[];
  sharedWithAuthenticated?: any[];
  resharedWith?: string[];
  reshareDetails?: IPackageHeaderReshareDetails;
  needsUpdate?: boolean;
  firstViewed?: string | null;
  hasOverview?: boolean;
  incidentRemoved?: boolean;
  wasReadByCurrentUser?: boolean;
  password?: string;
  packageVersion: EPackageVersionType;
  accessLocked?: boolean;
}

export interface IPackageFile {
  derivedItemId?: string;
  derivedItemType?: DerivedItemType;
  origin?: string;
  assetStorageType?: AssetStorageType | null;
  chainOfCustodyStatus?: boolean;
  enumeratedTags?: [];
  entityId: string;
  fileId: string;
  link?: string;
  publicShareId?: string;
  shareId?: string;
  agency?: string;
  created?: string;
  displayName?: string;
  duration?: number;
  externalLink?: string;
  importanceLevel?: string;
  mimeType?: string;
  name?: string;
  owner?: string;
  size?: number;
  sourceId?: string;
  sourceName?: string;
  state?: string;
  thumbnailUrl?: string;
  retention?: IRetentionData;
  restricted: boolean;
}

export interface IPackage {
  content: IPackageContent;
  files: IPackageFile[];
  header: IPackageHeader;
  updates: IPackageUpdate[];
  source?: string;
  assetEntities?: (AssetEntity | IEmptyAssetEntity)[];
  assetEntitiesLen?: number;
  incompletedFiles?: number;
  assetFiles?: IPackageFile[];
  narrativeUpdates?: IPackageUpdate[];
  fileUpdates?: IPackageUpdate[];
  personsUpdates?: IPackageUpdate[];
  organizationsUpdates?: IPackageUpdate[];
  propertyUpdates?: IPackageUpdate[];
  vehicleUpdates?: IPackageUpdate[];
  overview: Overview;
  narratives: Narrative[];
  persons: Person[];
  organizations: Organization[];
  vehicles: Vehicle[];
  properties: Property[];
  actions?: { packageActions: IPackageActions };
}

export interface IPackageBody {
  body: IPackage;
}

export interface IPackagesBody {
  continuation?: string;
  items: IPackage[];
}

export interface IBdpPackagesBody {
  searchId: string;
  items: IPackage[];
  totalCount: number;
}

export interface IPackageResponse {
  continuation: string | null;
  packages: IPackage[];
}

interface IChainOfCustodyModel {
  id?: string;
  agency?: string;
  propertyItemId?: string;
  evidenceId?: string;
  whenCustodyChangeOccurred?: string;
  custodian?: ISubject;
  receivedFrom?: ISubject;
  location?: {
    locationCode?: string;
  };
  reasonForCustodyChange?: string;
  transactionType?: string;

  errorState?: boolean;
}

export interface ISubjectWrapper {
  id?: string;
  subject?: ISubject;
}

export const subjectTypes = {
  victims: 'Victim',
  witnesses: 'Witness',
  offenders: 'Offender',
  others: 'Other',
};

export interface ILawIncidentModel {
  id?: string;
  sourceId?: string; // not part of schema
  agency?: string;
  workflow?: {
    status?: string;
    assignedTo?: string;
    assignedBy?: string;
    message?: string;
  };
  caseNumber?: string;
  agencyORI?: string;
  cargoTheft?: boolean;
  reportDateIndicator?: boolean;
  offenses?: IOffence[];
  reportedOffense?: IOffence;
  circumstances?: string[];
  clearanceCode?: string;
  clearanceDate?: string;
  exceptionalClearanceCode?: string;
  exceptionalClearanceDate?: string;
  complainant?: ISubject;
  disposition?: string;
  dispositionDate?: string;
  howReceived?: string;
  judicialStatus?: string;
  judicialStatusDate?: string;
  location?: ILocation;
  modusOperandi?: IModusOperandi[];
  nature?: string;
  occurredAfterDateTime?: string;
  occurredBeforeDateTime?: string;
  receivedBy?: string;
  respondingPersonnels?: string[];
  responsiblePersonnel?: string;
  whenReported?: string;
  narratives?: INarrative[];
  persons?: IPerson[];
  organizations?: IOrganization[];
  vehicles?: IVehicle[];
  societyIsVictim?: boolean;
  propertyItems?: IProperty[];
  nonePropertyDrugTypes?: string[];
  alerts?: IAlert[];
  chainOfCustody?: IChainOfCustodyModel[];
  tenant?: string;
  historicId?: string;
  [x: string]: any;
}

export interface IFilesSharingInfo {
  sharableFiles: IPackageFile[];
  unsharableFilesCount: number;
}

export interface ISearchFilesRequest {
  pageSize?: number;
  pageToken?: string;
  query?: string;
}

export interface IFileItems {
  items: IPackageFile[];
  continuation: string;
}

export interface ISubscribeResponse {
  subId: string;
}

export interface ISubscribe {
  mail: string;
}

export interface IUnsubscribe {
  packageId: string;
  subId: string;
}

export interface IPackageCalculationResponse {
  message: string;
  packageIds: string[];
}

export interface IPackageCalculationRequest {
  items: string[];
}

export interface IPackageUpdatesRequest {
  id: string;
}

export interface IPatchEntry {
  entityType: PatchEntityType;
  operation: PatchOperation;
  entityId: string;
}

export interface IFilePatchEntry {
  operation: PatchOperation;
  fileId: string;
  derivedItemId?: string;
  derivedItemType?: string;
  assetStorageType: AssetStorageType;
}

interface ILawIncidentFiles {
  items: IPackageFile[];
}
export interface ISearchResult {
  incidentReport: IPackageContent;
  lawIncidentFiles: ILawIncidentFiles;
}

export interface IPackageUpdatesResponse {
  message: string;
  updates: IPatchEntry[];
  fileUpdates: IFilePatchEntry[];
  searchResult?: ISearchResult;
  packageUpdatesFailed?: boolean;
}

export interface IProtectedPackage {
  packageId: string;
  agencyId?: string;
  isValid: boolean;
  packageVersion: EPackageVersionType;
  validationAttempts?: number;
  packageLocked: boolean;
}

export interface IProtectedPackageResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
}
