/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { settings } from '../../settings/settings';

@Pipe({
  name: 'msiIncidentRedirectUrl',
})
export class IncidentRedirectUrlPipe implements PipeTransform {
  transform(caseNumber?: string): string {
    const baseCaseUrl = settings.get<string>('PLATFORM.CRV_BASE_URL' as any);
    return caseNumber ? baseCaseUrl + '/case/' + encodeUriAll(caseNumber) : '';
  }
}

function encodeUriAll(value: string): string {
  return value.replace(
    /[^A-Za-z\d]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
  );
}
