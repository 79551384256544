/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { AssetEntity, AssetEntityItem } from '@msi/emm-sdk';
import { Api, IDownloadContent, Settings } from '@msi/js-sdk';
import { IVideoStream } from '@msi/msi-download-manager';
import { cloneDeep } from 'lodash-es';

import { DownloadItemDTO } from '../../common/DownloadManager/DownloadItemDTO';
import { IPackage } from '../../services/package/package.interfaces';
import { ISettings } from '../../settings/settings.interface';
import { IAllStreamingMetadata } from '../asset-entity/asset-entity.interface';
import { AssetEntityService } from '../asset-entity/asset-entity.service';
import { ElasticApmService } from '../elastic-apm/elastic-apm.service';
import {
  DerivedItemType,
  EDetailPackageMode,
  EPackageAccessType,
} from '../package/package.enums';
import { StreamingMetadataService } from '../streaming-metadata/streaming-metadata.service';
import { SystemService } from '../system-service/system.service';
import { StreamToDownload } from './stream-to-download';

@Injectable()
export class DetailPackageService {
  private _streamToDownload: StreamToDownload;

  constructor(
    private _assetEntityService: AssetEntityService,
    private _elasticApmService: ElasticApmService,
    private _systemService: SystemService,
    private _api: Api,
    private _settings: Settings<ISettings>,
    private _streamingMetadataService: StreamingMetadataService
  ) {
    this._streamToDownload = new StreamToDownload(
      this._api,
      this._settings,
      this._assetEntityService
    );
  }

  private _getPath(assetEntity: AssetEntity, assetItem: AssetEntityItem): string {
    let path = assetItem.path || '';

    if (assetEntity.isMultiFile()) {
      path = assetEntity.name + '/' + path;
    }

    return path;
  }

  private _defineLog(downloadItem: DownloadItemDTO): DownloadItemDTO {
    const log = (name): void => {
      if (name === 'done' || name === 'error' || name === 'cancel') {
        downloadItem.off('*', log);
      }

      this._elasticApmService.track('download evidence item - ' + name);
    };

    downloadItem.on('*', log);

    return downloadItem;
  }

  private async _updateLink(
    downloadItem: DownloadItemDTO,
    assetEntity: AssetEntity,
    assetItem: AssetEntityItem,
    pkg: IPackage
  ): Promise<void> {
    const updatedAssetEntity: AssetEntity =
      await this._assetEntityService.getAssetEntityByAsset(assetEntity, pkg);

    assetEntity.fileLinkExpireAt = updatedAssetEntity.fileLinkExpireAt;
    downloadItem.expiredAt = updatedAssetEntity.fileLinkExpireAt;

    if (updatedAssetEntity.isMultiFile()) {
      updatedAssetEntity.getItems().forEach((updated: AssetEntityItem) => {
        if (assetItem.fileId === updated.fileId && downloadItem.name === updated.name) {
          assetItem.fileLink = updated.fileLink;
          assetItem.inlineLink = updated.inlineLink;
          downloadItem.link = updated.fileLink;
        }
      });
    } else {
      const first = updatedAssetEntity.getFirstChild();

      if (!first) {
        throw new Error(
          'DetailPackageService._updateLink (SingleFile): assetItem is not found'
        );
      }
      assetItem.fileLink = first.fileLink;
      assetItem.inlineLink = first.inlineLink;
      downloadItem.link = first.fileLink;
    }
  }

  private _getMetadataDownloadItemDTO(
    assetEntity: AssetEntity,
    accessType: EPackageAccessType = EPackageAccessType.GUEST,
    stream?: IVideoStream
  ): DownloadItemDTO {
    const metadataDownloadName =
      assetEntity.isEvent() && stream
        ? this._streamToDownload.createStreamDownloadName(assetEntity, stream)
        : assetEntity.name.replace(/[/\\?%*:|"<>]/g, '_');

    const downloadItem: DownloadItemDTO = new DownloadItemDTO({
      accessType: accessType,
      name: metadataDownloadName + '-metadata.csv',
      path: assetEntity.isMultiFile() ? assetEntity.name : '',
      icon: 'ic_file',
      handlerGetContent: async (): Promise<IDownloadContent> => {
        const data: string = await this._assetEntityService.getMetadata(assetEntity);

        return { data };
      },
    });

    return this._defineLog(downloadItem);
  }

  private _getDownloadItemDTO(
    assetEntity: AssetEntity,
    assetItem: AssetEntityItem,
    pkg: IPackage
  ): DownloadItemDTO {
    const assetCopy = cloneDeep(assetEntity);
    const assetItemCopy = cloneDeep(assetItem);
    const downloadItem: DownloadItemDTO = new DownloadItemDTO({
      accessType: pkg.header.accessType,
      link: assetItem.inlineLink,
      name: assetEntity.isMultiFile()
        ? assetItem.name.replace(/[/\\?%*:|"<>]/g, '_')
        : assetEntity.name.replace(/[/\\?%*:|"<>]/g, '_'),
      path: this._getPath(assetEntity, assetItem),
      size: assetItem.size,
      icon: assetEntity.iconName,
      assetStorageType: assetEntity.assetStorageType,
      parentId: (assetEntity as any).parentFileId
        ? (assetEntity as any).parentFileId
        : assetEntity.fileId,
      expiredAt: assetEntity.fileLinkExpireAt,
      handlerRefreshLink: async () =>
        await this._updateLink(downloadItem, assetCopy, assetItemCopy, pkg),
    });
    (downloadItem as any).derivedItemId = (assetEntity as any).derivedItemId;
    (downloadItem as any).derivedItemType = (assetEntity as any).derivedItemType;

    return this._defineLog(downloadItem);
  }

  getDownloadItemsByAssets(
    assets: AssetEntity[],
    pkg: IPackage,
    mode: EDetailPackageMode = EDetailPackageMode.GUESTS,
    downloadType: string = 'all',
    allStreamingMetadata: IAllStreamingMetadata[]
  ): DownloadItemDTO[] {
    const downloadItems: DownloadItemDTO[] = [];

    assets.forEach((asset: AssetEntity): void => {
      if (asset.isActive() && !asset.isPurged()) {
        if (asset.isMultiFile()) {
          asset.getItems().forEach((assetItem: AssetEntityItem): void => {
            if (downloadType === 'all' || downloadType === 'evidence') {
              downloadItems.push(this._getDownloadItemDTO(asset, assetItem, pkg));
            }
          });

          if (downloadType === 'all' || downloadType === 'metadata') {
            downloadItems.push(
              this._getMetadataDownloadItemDTO(asset, pkg.header.accessType)
            );
          }
        } else if (asset.isSingleFile() || asset.isVaultStorageType()) {
          if (downloadType === 'all' || downloadType === 'evidence') {
            const item = this._getDownloadItemDTO(
              asset,
              asset.getFirstChild() as any,
              pkg
            );
            downloadItems.push(item);

            if (
              asset.derivedItemType === DerivedItemType.CLIP ||
              asset.derivedItemType === DerivedItemType.EXPORTED_REDACTION
            ) {
              const ssaVttSubtitles =
                this._streamingMetadataService.generateSsaVttSubtitles(
                  allStreamingMetadata,
                  item,
                  asset,
                  pkg.header.accessType
                );

              if (ssaVttSubtitles?.length) {
                downloadItems.push(...ssaVttSubtitles);
              }
            }
          }

          if (downloadType === 'all' || downloadType === 'metadata') {
            downloadItems.push(
              this._getMetadataDownloadItemDTO(asset, pkg.header.accessType)
            );
          }
        } else if (asset.isEvent()) {
          if (downloadType === 'all' || downloadType === 'evidence') {
            const items: DownloadItemDTO[] = this._streamToDownload.getDownloadItemsDTO(
              asset,
              pkg,
              downloadType,
              mode
            );
            items.forEach((item: DownloadItemDTO) => {
              downloadItems.push(item);
              const ssaVttSubtitles =
                this._streamingMetadataService.generateSsaVttSubtitles(
                  allStreamingMetadata,
                  item,
                  asset,
                  pkg.header.accessType
                );

              if (ssaVttSubtitles?.length) {
                downloadItems.push(...ssaVttSubtitles);
              }

              if (downloadType === 'all') {
                downloadItems.push(
                  this._getMetadataDownloadItemDTO(
                    asset,
                    pkg.header.accessType,
                    item.stream
                  )
                );
              }
            });
          }
        }
      }
    });

    return downloadItems;
  }
}
