/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Store } from '@msi/js-sdk';

import { DownloadItemDTO } from '../../common/DownloadManager/DownloadItemDTO';
import { IDownloadManagerStore } from './download-manager.interfaces';

class DownloadManagerLocalStore
  extends Store<IDownloadManagerStore>
  implements IDownloadManagerStore
{
  downloadItems: DownloadItemDTO[];
  cancelDownload: boolean;
}

const downloadManagerLocalStore = new DownloadManagerLocalStore({
  downloadItems: [],
  cancelDownload: false,
});

export { downloadManagerLocalStore };
