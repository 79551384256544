<div
  class="sort-by"
  *ngIf="sortByConfig$ | async as sortByConfig">
  <button
    class="msi-btn msi-btn-icon"
    (click)="toggleDirection()">
    <msi-icon
      [name]="
        sortByConfig.direction === sortDirectionEnum.Ascending ? 'ic_sort_ZA' : 'ic_sort_AZ'
      "></msi-icon>
  </button>
  <msi-select
    [(ngModel)]="sortByConfig.property"
    (change)="updateSortByProperty(sortByConfig.property)">
    <msi-select-trigger>
      {{ 'Sort by' | transloco }}:
      <span class="selected-option">
        <ng-container
          *ngTemplateOutlet="
            sortByOptionTemplate;
            context: { $implicit: sortByConfig.property }
          "></ng-container>
      </span>
    </msi-select-trigger>
    <ng-container *ngFor="let option of sortByOptions$ | async">
      <msi-select-option [value]="option">
        <ng-container *ngTemplateOutlet="sortByOptionTemplate; context: { $implicit: option }"></ng-container>
      </msi-select-option>
    </ng-container>
  </msi-select>
</div>

<ng-template
  #sortByOptionTemplate
  let-property>
  <ng-container [ngSwitch]="property">
    <ng-container *ngSwitchCase="sortPropertyEnum.CaptureDate">{{ 'Capture date' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="sortPropertyEnum.UploadDate">{{ 'Upload date' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="sortPropertyEnum.UploadedBy">{{ 'Uploaded by' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="sortPropertyEnum.Name">{{ 'Name' | transloco }}</ng-container>
  </ng-container>
</ng-template>
