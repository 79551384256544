/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalService, MsiModalConfig, MsiModalRef } from '@msi/cobalt';

import { IPackage } from '../../services/package/package.interfaces';

@Component({
  selector: 'pp-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  modalRef?: MsiModalRef;

  config: MsiModalConfig = {
    disableClose: true,
    hasBackdrop: true,
  };

  package: IPackage = null;

  @Input()
  confirmationButtonText = '';

  @Input()
  modalTitle = '';

  @Input()
  modalBody = '';

  @Input()
  showDisclaimer?: boolean;

  @Output()
  confirm: EventEmitter<IPackage> = new EventEmitter();

  @Output()
  modalOpen: EventEmitter<void> = new EventEmitter();

  @Output()
  modalClose: EventEmitter<void> = new EventEmitter();

  @ViewChild('modalTemplate')
  modalTemplate: TemplateRef<any>;

  constructor(protected modalService: ModalService) {}

  setBody(body: string): void {
    this.modalBody = body;
  }

  open(): void {
    if (!this.modalRef) {
      this.modalRef = this.modalService.open(this.modalTemplate, this.config);
      this.modalOpen.emit();
    }
  }

  @HostListener('window:popstate', ['$event'])
  close(): void {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = undefined;
      this.package = null;
      this.modalBody = '';
      this.modalClose.emit();
    }
  }

  onCancel() {
    this.close();
  }

  onConfirm() {
    this.confirm.emit(this.package);
    this.close();
  }
}
