/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { AssetStorageType, IRetentionData, RetentionState } from '@msi/emm-sdk';
import dayjs, { Dayjs } from 'dayjs';
import { BehaviorSubject, Observable } from 'rxjs';

import { ISpecificTypeAssetCount } from '../asset-entity/asset-entity.interface';
import { IPackageFile } from '../package/package.interfaces';

@Injectable()
export class AssetVerificationService {
  readonly incompleteAssets$: Observable<ISpecificTypeAssetCount>;
  readonly directoryAssets$: Observable<ISpecificTypeAssetCount>;
  readonly shortRetentionAssets$: Observable<ISpecificTypeAssetCount>;
  readonly restrictedAssets$: Observable<ISpecificTypeAssetCount>;
  readonly shortestRetentionDate$: Observable<Dayjs>;

  shareableAssets: IPackageFile[] = [];
  incompleteAssets: IPackageFile[] = [];
  directoryAssets: IPackageFile[] = [];
  restrictedAssets: IPackageFile[] = [];
  shortRetentionAssets: IPackageFile[] = [];
  incompleteAssetsCount = 0;
  directoryAssetsCount = 0;
  shortRetentionAssetsCount = 0;
  restrictedAssetsCount = 0;
  defaultSpecificTypeAssetCount: ISpecificTypeAssetCount = {
    count: 0,
    assets: [],
  };
  shortestRetentionDate: Dayjs = dayjs('2999-12-31').endOf('day');

  private incompleteAssetsSubject = new BehaviorSubject<ISpecificTypeAssetCount>(
    this.defaultSpecificTypeAssetCount
  );
  private directoryAssetsSubject = new BehaviorSubject<ISpecificTypeAssetCount>(
    this.defaultSpecificTypeAssetCount
  );
  private shortRetentionAssetsSubject = new BehaviorSubject<ISpecificTypeAssetCount>(
    this.defaultSpecificTypeAssetCount
  );
  private restrictedAssetsSubject = new BehaviorSubject<ISpecificTypeAssetCount>(
    this.defaultSpecificTypeAssetCount
  );
  private shortestRetentionTimeSubject = new BehaviorSubject<Dayjs>(
    this.shortestRetentionDate
  );

  constructor() {
    this.incompleteAssets$ = this.incompleteAssetsSubject.asObservable();
    this.directoryAssets$ = this.directoryAssetsSubject.asObservable();
    this.shortRetentionAssets$ = this.shortRetentionAssetsSubject.asObservable();
    this.restrictedAssets$ = this.restrictedAssetsSubject.asObservable();
    this.shortestRetentionDate$ = this.shortestRetentionTimeSubject.asObservable();
  }

  resetAssetVerifications(): void {
    this.shareableAssets = [];
    this.incompleteAssets = [];
    this.directoryAssets = [];
    this.restrictedAssets = [];
    this.shortRetentionAssets = [];
    this.incompleteAssetsCount = 0;
    this.directoryAssetsCount = 0;
    this.shortRetentionAssetsCount = 0;
    this.restrictedAssetsCount = 0;
    this.incompleteAssetsSubject.next(this.defaultSpecificTypeAssetCount);
    this.directoryAssetsSubject.next(this.defaultSpecificTypeAssetCount);
    this.shortRetentionAssetsSubject.next(this.defaultSpecificTypeAssetCount);
    this.restrictedAssetsSubject.next(this.defaultSpecificTypeAssetCount);
  }

  countIncompleteAssets(file: IPackageFile): void {
    const assetId = file.derivedItemId ? file.derivedItemId : file.fileId;

    if (
      this.isIncompleteAsset(file.assetStorageType) &&
      !this._hasIncompleteAsset(assetId)
    ) {
      this._setIncompleteAssetsCount(file);
    } else if (
      this.isDirectoryAsset(file.assetStorageType) &&
      !this._hasDirectoryAsset(assetId)
    ) {
      this._setDirectoryAssetsCount(file);
    } else {
      this.shareableAssets.push(file);
    }
  }

  countRestritedAssets(files: IPackageFile[]): void {
    files.forEach((file: IPackageFile) => {
      const assetId = file.derivedItemId ? file.derivedItemId : file.fileId;

      if (!this._hasRestrictedAsset(assetId) && file.restricted) {
        this._setRestrictedAssetsCount(file);
      }
    });
  }

  getShareableAssets(files: IPackageFile[]): IPackageFile[] {
    files.forEach((file: IPackageFile) => {
      this.countIncompleteAssets(file);
    });
    return this.shareableAssets;
  }

  isIncompleteAsset(assetStorageType: AssetStorageType): boolean {
    return assetStorageType?.toLowerCase() === AssetStorageType.EXTERNALLINK;
  }

  isDirectoryAsset(assetStorageType: AssetStorageType): boolean {
    return assetStorageType?.toLowerCase() === AssetStorageType.DIRECTORY;
  }

  notShareableAsset(assetStorageType: AssetStorageType): boolean {
    return (
      this.isIncompleteAsset(assetStorageType) || this.isDirectoryAsset(assetStorageType)
    );
  }

  checkAssetRetention(files: IPackageFile[], expirationDate: Dayjs): void {
    let shortRetentionAsset = false;
    let assetRetention: IRetentionData;
    let assetPurgeTime: Dayjs;
    this.shortRetentionAssets = [];
    this.shortRetentionAssetsCount = 0;

    this._resetShortestRetentionTime();
    files.forEach((file: IPackageFile) => {
      if (file.retention) {
        assetRetention = file.retention;
        assetPurgeTime = dayjs(assetRetention.purgeTime);

        if (file.derivedItemId) {
          assetRetention = this._getParentAssetRetention(files, file.fileId);
          assetPurgeTime = dayjs(assetRetention.purgeTime);
        }

        shortRetentionAsset = assetPurgeTime.isBefore(expirationDate);

        if (
          assetRetention.retentionState === RetentionState.AUTO &&
          shortRetentionAsset
        ) {
          this._setShortRetentionAssetsCount(file);
        }

        this._checkMinimalRetentionTime(assetPurgeTime);
      }
    });

    if (this.shortRetentionAssets.length === 0 && this.shortRetentionAssetsCount === 0) {
      this.shortRetentionAssetsSubject.next({
        count: this.shortRetentionAssetsCount,
        assets: this.shortRetentionAssets,
      });
    }
  }

  private _resetShortestRetentionTime(): void {
    this.shortestRetentionDate = dayjs('2999-12-31');
    this.shortestRetentionTimeSubject.next(this.shortestRetentionDate);
  }

  private _checkMinimalRetentionTime(newDate: Dayjs): void {
    if (this.shortestRetentionDate.isAfter(newDate)) {
      this.shortestRetentionDate = newDate;
      this.shortestRetentionTimeSubject.next(this.shortestRetentionDate);
    }
  }

  private _setShortRetentionAssetsCount(file: IPackageFile): void {
    this.shortRetentionAssets.push(file);
    this.shortRetentionAssetsCount = this.shortRetentionAssets.length;
    this.shortRetentionAssetsSubject.next({
      count: this.shortRetentionAssetsCount,
      assets: this.shortRetentionAssets,
    });
  }

  private _setIncompleteAssetsCount(file: IPackageFile): void {
    this.incompleteAssets.push(file);
    this.incompleteAssetsCount = this.incompleteAssets.length;
    this.incompleteAssetsSubject.next({
      count: this.incompleteAssetsCount,
      assets: this.incompleteAssets,
    });
  }

  private _setRestrictedAssetsCount(file: IPackageFile): void {
    this.restrictedAssets.push(file);
    this.restrictedAssetsCount = this.restrictedAssets.length;
    this.restrictedAssetsSubject.next({
      count: this.restrictedAssetsCount,
      assets: this.restrictedAssets,
    });
  }

  private _setDirectoryAssetsCount(file: IPackageFile): void {
    this.directoryAssets.push(file);
    this.directoryAssetsCount = this.directoryAssets.length;
    this.directoryAssetsSubject.next({
      count: this.directoryAssetsCount,
      assets: this.directoryAssets,
    });
  }

  private _getParentAssetRetention(
    files: IPackageFile[],
    parentId: string
  ): IRetentionData {
    let parentRetention: IRetentionData = null;

    files.forEach((file: IPackageFile) => {
      if (parentId === file.fileId && !file.derivedItemId) {
        parentRetention = file.retention;
      }
    });
    return parentRetention;
  }

  private _hasIncompleteAsset(id: string): boolean {
    return this.incompleteAssets.some((incompleteAsset: IPackageFile) => {
      const incompleteAssetId = incompleteAsset.derivedItemId
        ? incompleteAsset.derivedItemId
        : incompleteAsset.fileId;

      return incompleteAssetId === id;
    });
  }

  private _hasRestrictedAsset(id: string): boolean {
    return this.restrictedAssets.some((restrictedAsset: IPackageFile) => {
      const restrictedAssetId = restrictedAsset.derivedItemId
        ? restrictedAsset.derivedItemId
        : restrictedAsset.fileId;

      return restrictedAssetId === id;
    });
  }

  private _hasDirectoryAsset(id: string): boolean {
    return this.directoryAssets.some((directoryAsset: IPackageFile) => {
      const directoryAssetId = directoryAsset.derivedItemId
        ? directoryAsset.derivedItemId
        : directoryAsset.fileId;

      return directoryAssetId === id;
    });
  }
}
