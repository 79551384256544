/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Chip, ChipsComponent } from '@msi/cobalt';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, merge, Observable, startWith, Subject } from 'rxjs';

import { RecipientsListService } from '../../services/recipients-list/recipients-list.service';

@UntilDestroy()
@Component({
  selector: 'ccs-recipients-list',
  templateUrl: './recipients-list.component.html',
  styleUrls: ['./recipients-list.component.scss'],
})
export class RecipientsListComponent implements OnInit, OnChanges {
  @Input()
  authMode: boolean;

  @Output()
  recipients: EventEmitter<Chip[]> = new EventEmitter();

  private readonly emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  private fullRecipientsList: Chip[] = [];

  selectedRecipients: Chip[] = [];
  cachedRecipients: Chip[];
  authRecipientSearch = '';
  filteredImmutableRecipients: Chip[];
  fetchingRecipients = true;
  containInvalidEmails = false;
  immutabilityEnabled = true;
  fetchRecipientsFailed: boolean;
  inputRecipientsTouchedState: boolean;

  public filteredRecipients$ = new Observable<Chip[]>();
  private selectedImmutableRecipient = new Subject<Chip>();
  public selectedImmutableRecipient$ = this.selectedImmutableRecipient.asObservable();

  @ViewChild('recipientsImmutableChips', { static: false, read: ChipsComponent })
  recipientsImmutableChips!: ChipsComponent;

  isEmail = (item: string): boolean => {
    if (!this._isAuthRecipient(item)) {
      return this.emailRegex.test(item);
    }
  };

  constructor(private _recipientsListService: RecipientsListService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.authMode?.previousValue !== changes.authMode?.currentValue) {
      this.immutabilityEnabled = changes.authMode.currentValue;
      this.validateEmails();
      this._checkAndEmitSelectedRecipients();
    }
  }

  ngOnInit() {
    this.cachedRecipients = this._recipientsListService.cachedRecipients;
    this.inputRecipientsTouchedState =
      this._recipientsListService.inputRecipientsTouchedState;
    this._fetchAuthRecipients();

    if (this.cachedRecipients.length) {
      this.selectedRecipients = this.cachedRecipients;
    }

    this._recipientsListService.fetchRecipientsError$
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => {
        this.fetchRecipientsFailed = value;
      });
  }

  editEventHandler() {
    this._checkAndEmitSelectedRecipients();
  }

  addEventHandler() {
    this._checkAndEmitSelectedRecipients();
  }

  removeEventHandler() {
    this._checkAndEmitSelectedRecipients();
  }

  validateEmails(): void {
    if (this.recipientsImmutableChips) {
      this.recipientsImmutableChips.chips = this.recipientsImmutableChips?.chips.map(
        (chip: Chip) => {
          if (this.authMode && !this.fullRecipientsList.includes(chip)) {
            chip.isValid = false;
          } else if (!this.authMode && !this.fullRecipientsList.includes(chip)) {
            chip.isValid = this.emailRegex.test(chip.value);
          } else if (!this.authMode && this.fullRecipientsList.includes(chip)) {
            chip.isValid = this.emailRegex.test(chip.email);
          }
          return chip;
        }
      );
    }
  }

  sendImmutableValue(item: Chip): void {
    this.selectedImmutableRecipient.next(item);
  }

  onRetryRecipients(): void {
    this._recipientsListService.setRecipientsError(false);
    this._fetchAuthRecipients();
  }

  onBlur(): void {
    this.inputRecipientsTouchedState = true;
    this.setInputRecipientsState();
  }

  private _fetchAuthRecipients(): void {
    this._recipientsListService
      .getRecipients()
      .pipe(untilDestroyed(this))
      .subscribe((recipients: Chip[]) => {
        this.filteredImmutableRecipients = recipients;
        this.fullRecipientsList = recipients;
        this.fetchingRecipients = !recipients.length;
        this._filteredRecipients();
      });
  }

  private _checkAndEmitSelectedRecipients(): void {
    setTimeout(() => {
      this._checkEmailValidation();
      this._recipientsListService.cachedRecipients = this.selectedRecipients;
      this.recipients.emit(this.selectedRecipients);
      this.setInputRecipientsState();
    }, 100);
  }

  private _checkEmailValidation(): void {
    this.containInvalidEmails = this.selectedRecipients?.some(
      (recipient: Chip) => !recipient.isValid
    );
  }

  private _isAuthRecipient(value: string): boolean {
    return this.fullRecipientsList.some((recipient) => recipient.value === value);
  }

  private _filteredRecipients() {
    this.filteredRecipients$ = merge(
      this.recipientsImmutableChips?.focusedInputValueChanged,
      this.recipientsImmutableChips?.chipAdded,
      this.recipientsImmutableChips?.chipRemoved,
      this.recipientsImmutableChips?.chipEdited
    ).pipe(
      startWith(''),
      map((value) => {
        return !value || typeof value !== 'string'
          ? this.filteredImmutableRecipients
              .slice()
              .filter(
                (opt) => !this.recipientsImmutableChips.chips.some((o) => opt.id === o.id)
              )
          : this.filteredImmutableRecipients.filter(
              (opt) =>
                opt.value.toLowerCase().includes(value.toLowerCase()) &&
                !this.recipientsImmutableChips.chips.some((o) => opt.id === o.id)
            );
      }),
      map((options) => options.slice().sort())
    );
  }

  private setInputRecipientsState(): void {
    this._recipientsListService.inputRecipientsTouchedState =
      this.inputRecipientsTouchedState;
  }
}
