<div
  *ngIf="pkg.vehicles.length"
  class="pp-detail-package-vehicle">
  <h2
    id="pp-detail-package-vehicles"
    class="pp-detail-package-vehicle__header">
    {{ 'Vehicles ' | transloco }}({{ pkg.vehicles.length }})
  </h2>
  <ng-template
    ngFor
    let-item
    [ngForOf]="pkg.vehicles"
    let-i="index">
    <ng-container
      *ngTemplateOutlet="
        childItem;
        context: { $implicit: { vehicle: item, idx: i + 1, len: pkg.vehicles.length } }
      "></ng-container>
  </ng-template>
</div>

<ng-template
  #childItem
  let-item>
  <div class="pp-detail-package-vehicle__item">
    <div class="pp-row-item">
      <div class="pp-detail-package-vehicle__item__img">
        <div
          class="image"
          [class.image--default]="item.vehicle.isDefaultThumb"
          [ngStyle]="{ 'background-image': 'url(' + item.vehicle.thumb + ')' }"></div>
      </div>
      <div class="pp-detail-package-vehicle__item__descr">
        <div class="pp-detail-package-vehicle__item__descr__count"
          >{{ 'Vehicle ' | transloco }}{{ item.idx }}{{ ' of ' | transloco }}{{ item.len }}</div
        >
        <div
          *ngIf="item.vehicle.model"
          class="pp-detail-package-vehicle__item__descr__title"
          >{{ item.vehicle.model }}</div
        >
        <div
          *ngIf="item.vehicle.name"
          class="pp-detail-package-vehicle__item__descr__title"
          >{{ item.vehicle.name }}</div
        >
        <div
          *ngIf="item.vehicle.year"
          class="pp-detail-package-vehicle__item__descr__subtitle"
          >{{ item.vehicle.year }}</div
        >
      </div>
    </div>
  </div>
  <div
    *ngIf="informationPresent(item.vehicle)"
    class="pp-detail-package-vehicle__info">
    <h2>{{ 'Information' | transloco }}</h2>

    <div class="pp-detail-package-vehicle__info__row">
      <div
        *ngIf="item.vehicle.license"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'License Plate #:' | transloco }}</h3>
        <span>{{ item.vehicle.license }}</span>
      </div>
      <div
        *ngIf="item.vehicle.expirationDate"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'Expiration Date:' | transloco }}</h3>
        <span>{{ item.vehicle.expirationDate }}</span>
      </div>
      <div
        *ngIf="item.vehicle.type"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'Type:' | transloco }}</h3>
        <span>{{ item.vehicle.type }}</span>
      </div>
      <div
        *ngIf="item.vehicle.year"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'Year:' | transloco }}</h3>
        <span>{{ item.vehicle.year }}</span>
      </div>
      <div
        *ngIf="item.vehicle.name"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'Make:' | transloco }}</h3>
        <span>{{ item.vehicle.name }}</span>
      </div>
      <div
        *ngIf="item.vehicle.vin"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'VIN:' | transloco }}</h3>
        <span>{{ item.vehicle.vin }}</span>
      </div>
      <div
        *ngIf="item.vehicle.value"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'Value:' | transloco }}</h3>
        <span>{{ item.vehicle.value }}</span>
      </div>
      <div
        *ngIf="item.vehicle.door"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'Door:' | transloco }}</h3>
        <span>{{ item.vehicle.door }}</span>
      </div>
      <div
        *ngIf="item.vehicle.primaryColor"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'Primary Color:' | transloco }}</h3>
        <span>{{ item.vehicle.primaryColor }}</span>
      </div>
      <div
        *ngIf="item.vehicle.secondaryColor"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'Secondary Color:' | transloco }}</h3>
        <span>{{ item.vehicle.secondaryColor }}</span>
      </div>
      <div
        *ngIf="item.vehicle.dateRecovered"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'Date Recovered:' | transloco }}</h3>
        <span>{{ item.vehicle.dateRecovered }}</span>
      </div>
      <div
        *ngIf="item.vehicle.valueRecovered"
        class="pp-detail-package-vehicle__info__row__cell">
        <h3>{{ 'Value Recovered:' | transloco }}</h3>
        <span>{{ item.vehicle.valueRecovered }}</span>
      </div>
    </div>
  </div>

  <div
    *ngIf="statusPresent(item.vehicle)"
    class="pp-detail-package-vehicle__info last">
    <h2>{{ 'Status' | transloco }}</h2>

    <div class="pp-detail-package-vehicle__info__row">
      <div
        *ngIf="item.vehicle.urcStatus"
        class="pp-detail-package-vehicle__info__row__cell2">
        <h3>{{ 'UCR Status:' | transloco }}</h3>
        <span>{{ item.vehicle.urcStatus }}</span>
      </div>
      <div
        *ngIf="item.vehicle.urcStatusDate"
        class="pp-detail-package-vehicle__info__row__cell2">
        <h3>{{ 'UCR Status Date:' | transloco }}</h3>
        <span>{{ item.vehicle.urcStatusDate }}</span>
      </div>
    </div>

    <div class="pp-detail-package-vehicle__info__row">
      <div
        *ngIf="item.vehicle.localStatus"
        class="pp-detail-package-vehicle__info__row__cell2">
        <h3>{{ 'Local Status:' | transloco }}</h3>
        <span>{{ item.vehicle.localStatus }}</span>
      </div>
      <div
        *ngIf="item.vehicle.localSatusDate"
        class="pp-detail-package-vehicle__info__row__cell2">
        <h3>{{ 'Local Status Date:' | transloco }}</h3>
        <span>{{ item.vehicle.localSatusDate }}</span>
      </div>
    </div>

    <div class="pp-detail-package-vehicle__info__row">
      <div
        *ngIf="item.vehicle.agency"
        class="pp-detail-package-vehicle__info__row__cell2">
        <h3>{{ 'Agency:' | transloco }}</h3>
        <span>{{ item.vehicle.agency }}</span>
      </div>
    </div>
  </div>

  <pp-detail-package-chainofcustody
    [chainOfCustody]="item.vehicle.chainOfCustody"></pp-detail-package-chainofcustody>
</ng-template>
