/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

import { Property } from '../../../services/package/classes/property/Property';
import { IPackage } from '../../../services/package/package.interfaces';
import { PackageService } from '../../../services/package/package.service';

@Component({
  selector: 'pp-detail-package-properties',
  templateUrl: './detail-package-properties.component.html',
  styleUrls: [
    './detail-package-properties.component.scss',
    '../detail-package-print.scss',
  ],
})
export class DetailPackagePropertiesComponent {
  @Input()
  pkg: IPackage;

  constructor(private _packageService: PackageService) {}

  informationPresent(property: Property): boolean {
    return this._packageService.informationSectionPresent(property);
  }

  statusPresent(property: Property): boolean {
    return this._packageService.statusSectionPresent(property);
  }
}
