/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { AssetEntity, AssetStorageType, DerivedItemType } from '@msi/emm-sdk';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  EDigitalEvidenceGroupProperty,
  EDigitalEvidenceSortDirection,
  EDigitalEvidenceSortProperty,
  IDigitalEvidenceGroupByConfig,
  IDigitalEvidenceSortByConfig,
} from './digital-evidence-filter-options.interfaces';

@Injectable({
  providedIn: 'root',
})
export class DigitalEvidenceFilterOptionService {
  private readonly currentCollapsedAssetsSections: Map<string, boolean> = new Map();
  readonly sortByConfig$: Observable<IDigitalEvidenceSortByConfig>;
  readonly sortingOptions$: Observable<EDigitalEvidenceSortProperty[]>;
  readonly groupByConfig$: Observable<IDigitalEvidenceGroupByConfig>;
  readonly groupingOptions$: Observable<EDigitalEvidenceGroupProperty[]>;
  readonly groupedAssets$: Observable<Map<string, AssetEntity[]>>;
  readonly collapsedAssetsSection$: Observable<Map<string, boolean>>;

  private readonly sortingOptions: EDigitalEvidenceSortProperty[] = [
    EDigitalEvidenceSortProperty.CaptureDate,
    EDigitalEvidenceSortProperty.UploadDate,
    EDigitalEvidenceSortProperty.UploadedBy,
    EDigitalEvidenceSortProperty.Name,
  ];

  private readonly defaultSortByConfig: IDigitalEvidenceSortByConfig = {
    property: EDigitalEvidenceSortProperty.Name,
    direction: EDigitalEvidenceSortDirection.Ascending,
  };

  private readonly defaultGroupByConfig: IDigitalEvidenceGroupByConfig = {
    property: EDigitalEvidenceGroupProperty.None,
  };

  private readonly groupingOptions: EDigitalEvidenceGroupProperty[] = [
    EDigitalEvidenceGroupProperty.None,
    EDigitalEvidenceGroupProperty.OfficerName,
    EDigitalEvidenceGroupProperty.Type,
  ];

  private groupByConfigSubject = new BehaviorSubject<IDigitalEvidenceGroupByConfig>(
    this.defaultGroupByConfig
  );
  private groupingOptionsSubject = new BehaviorSubject<EDigitalEvidenceGroupProperty[]>(
    this.groupingOptions
  );
  private groupedAssetsSubject = new BehaviorSubject<Map<string, AssetEntity[]>>(
    new Map()
  );
  private collapsedAssetsSectionSubject = new BehaviorSubject<Map<string, boolean>>(
    this.currentCollapsedAssetsSections
  );
  private sortByConfigSubject = new BehaviorSubject<IDigitalEvidenceSortByConfig>(
    this.defaultSortByConfig
  );
  private sortingOptionsSubject = new BehaviorSubject<EDigitalEvidenceSortProperty[]>(
    this.sortingOptions
  );

  constructor() {
    this.sortByConfig$ = this.sortByConfigSubject.asObservable();
    this.sortingOptions$ = this.sortingOptionsSubject.asObservable();
    this.groupByConfig$ = this.groupByConfigSubject.asObservable();
    this.groupingOptions$ = this.groupingOptionsSubject.asObservable();
    this.groupedAssets$ = this.groupedAssetsSubject.asObservable();
    this.collapsedAssetsSection$ = this.collapsedAssetsSectionSubject.asObservable();
  }

  toggleSortDirection(groupedAssets: Map<string, AssetEntity[]>): void {
    const currentDirection: EDigitalEvidenceSortDirection = this.getSorting().direction;
    const newDirection: EDigitalEvidenceSortDirection =
      currentDirection === EDigitalEvidenceSortDirection.Ascending
        ? EDigitalEvidenceSortDirection.Descending
        : EDigitalEvidenceSortDirection.Ascending;

    this._setSortingDirection(newDirection);

    const grouped: Map<string, AssetEntity[]> = this.sortGroupedDigitalEvidence(
      groupedAssets,
      this.getSorting()
    );
    this.updateGroupedAssets(grouped);
  }

  sortByProperty(
    groupedAssets: Map<string, AssetEntity[]>,
    property: EDigitalEvidenceSortProperty
  ): void {
    this.setSortingProperty(property);

    const grouped = this.sortGroupedDigitalEvidence(groupedAssets, this.getSorting());
    this.updateGroupedAssets(grouped);
  }

  setSortingProperty(property: EDigitalEvidenceSortProperty): void {
    this.sortByConfigSubject.next({
      ...this.getSorting(),
      property,
    });
  }

  getSorting(): IDigitalEvidenceSortByConfig {
    return this.sortByConfigSubject.getValue();
  }

  sortGroupedDigitalEvidence(
    groupedAssets: Map<string, AssetEntity[]>,
    sortParams: IDigitalEvidenceSortByConfig
  ): Map<string, AssetEntity[]> {
    const sortedAssets: Map<string, AssetEntity[]> = new Map();

    if (sortParams.direction === EDigitalEvidenceSortDirection.Ascending) {
      groupedAssets?.forEach((assets, key) => {
        const sortedArray = this.sortDigitalEvidence(assets, {
          direction: EDigitalEvidenceSortDirection.Ascending,
          property: sortParams.property,
        });
        sortedAssets.set(key, sortedArray);
      });
    } else {
      groupedAssets?.forEach((assets, key) => {
        const sortedArray = this.sortDigitalEvidence(assets, {
          property: sortParams.property,
          direction: EDigitalEvidenceSortDirection.Descending,
        });
        sortedAssets.set(key, sortedArray);
      });
    }
    return sortedAssets;
  }

  sortDigitalEvidence(
    assetEntities: AssetEntity[] = [],
    sortParams: IDigitalEvidenceSortByConfig
  ): AssetEntity[] {
    let sortedAssets = assetEntities.slice();

    if (sortParams.direction === EDigitalEvidenceSortDirection.Ascending) {
      sortedAssets = assetEntities
        .slice()
        .sort((a, b) =>
          a[sortParams.property].toLowerCase() > b[sortParams.property].toLowerCase()
            ? 1
            : b[sortParams.property].toLowerCase() > a[sortParams.property].toLowerCase()
              ? -1
              : 0
        );
    } else {
      sortedAssets = assetEntities
        .slice()
        .sort((a, b) =>
          a[sortParams.property].toLowerCase() > b[sortParams.property].toLowerCase()
            ? -1
            : b[sortParams.property].toLowerCase() > a[sortParams.property].toLowerCase()
              ? 1
              : 0
        );
    }

    return sortedAssets;
  }

  groupByProperty(
    assetEntities: AssetEntity[],
    property: EDigitalEvidenceGroupProperty
  ): void {
    const groupAssetEntities: Map<string, AssetEntity[]> = this.groupAssets(
      assetEntities,
      property
    );
    const sorted = this.sortGroupedDigitalEvidence(groupAssetEntities, this.getSorting());
    this.updateGroupedAssets(sorted);
  }

  groupAssets(
    assetEntities: AssetEntity[],
    property: EDigitalEvidenceGroupProperty
  ): Map<string, AssetEntity[]> {
    this._clearCollapsedSections();
    const groupAssetEntities: Map<string, AssetEntity[]> = new Map();

    switch (property) {
      case EDigitalEvidenceGroupProperty.OfficerName:
        assetEntities.forEach((asset: AssetEntity) => {
          const officer =
            asset.ownerDisplayName === '' || !asset.ownerDisplayName
              ? 'Other'
              : asset.ownerDisplayName;

          this._addAssetToAGroup(groupAssetEntities, asset, officer);
        });
        break;
      case EDigitalEvidenceGroupProperty.Type:
        assetEntities.forEach((asset: AssetEntity) => {
          const type = this._prepateAssetType(asset);

          this._addAssetToAGroup(groupAssetEntities, asset, type);
        });
        break;
      default:
        groupAssetEntities.set(EDigitalEvidenceGroupProperty.None, assetEntities);
        break;
    }

    return groupAssetEntities;
  }

  updateGroupedAssets(groupAssetEntities: Map<string, AssetEntity[]>): void {
    this.groupedAssetsSubject.next(groupAssetEntities);
  }

  toggleCollapsedSections(sectionKey: string): void {
    const collapsedSections: Map<string, boolean> = this.currentCollapsedAssetsSections;

    if (collapsedSections.has(sectionKey)) {
      collapsedSections.delete(sectionKey);
    } else {
      collapsedSections.set(sectionKey, true);
    }

    this.collapsedAssetsSectionSubject.next(collapsedSections);
  }

  expandCollapsedSections(printEnabled: boolean): void {
    const collapsedSections: Map<string, boolean> = printEnabled
      ? new Map()
      : this.currentCollapsedAssetsSections;

    this.collapsedAssetsSectionSubject.next(collapsedSections);
  }

  private _addAssetToAGroup(
    groupedAssets: Map<string, AssetEntity[]>,
    asset: AssetEntity,
    key: string
  ): void {
    if (groupedAssets.has(key)) {
      groupedAssets.get(key).push(asset);
    } else {
      groupedAssets.set(key, [asset]);
    }
  }

  private _clearCollapsedSections(): void {
    this.collapsedAssetsSectionSubject.next(new Map());
  }

  private _setSortingDirection(direction: EDigitalEvidenceSortDirection): void {
    this.sortByConfigSubject.next({
      ...this.getSorting(),
      direction,
    });
  }

  private _prepateAssetType(asset: AssetEntity): string {
    let type = '';

    if (asset.derivedItemType) {
      type = this._setTypeForDerivedItem(asset.derivedItemType);
    } else if (asset.assetStorageType.toLocaleLowerCase() === AssetStorageType.EVENT) {
      type = 'Video';
    } else if (
      asset.assetStorageType.toLocaleLowerCase() === AssetStorageType.MULTIFILE ||
      asset.assetStorageType.toLocaleLowerCase() === AssetStorageType.LARGEMULTIFILE
    ) {
      type = 'Other';
    } else if (
      asset.assetStorageType.toLocaleLowerCase() === AssetStorageType.DIRECTORY
    ) {
      type = 'Other';
    } else if (asset.assetStorageType.toLocaleLowerCase() === AssetStorageType.LINK) {
      type = 'Other';
    } else {
      type = this._setTypeForSingleFile(asset.mimeType);
    }

    return type;
  }

  private _setTypeForDerivedItem(derivedItemType: DerivedItemType): string {
    switch (derivedItemType) {
      case DerivedItemType.CLIP:
        return 'Video';
      case DerivedItemType.EXPORTED_REDACTION:
        return 'Video';
      case DerivedItemType.SCREENSHOT:
        return 'Image';
    }
  }

  private _setTypeForSingleFile(mimeType: string): string {
    if (mimeType.includes('video')) {
      return 'Video';
    } else if (mimeType.includes('audio')) {
      return 'Audio';
    } else if (mimeType.includes('image')) {
      return 'Image';
    } else {
      return 'Other';
    }
  }
}
