/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DropdownComponent } from '@msi/cobalt';
import { Connect, Utils } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';

import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../../services/elastic-apm/elastic-apm.decorators';
import { IStore } from '../../../stores/global/store.interface';
import { EActionControls } from './action-controls.enums';
import { IActionControlOption } from './action-controls.interfaces';

@Component({
  selector: 'pp-action-controls',
  templateUrl: './action-controls.component.html',
  styleUrls: ['./action-controls.component.scss'],
})
@ApmTransactionComponent('quick package filter buttons')
export class ActionControlsComponent implements OnInit, OnChanges {
  @ViewChild('actionsDropdown') dropdown!: DropdownComponent;

  @Input()
  data: any;

  @Input()
  options: IActionControlOption[] = [];

  @Input()
  incidentRemoved = false;

  @Input()
  lang: string;

  @Input()
  readAuditLogsPermission: boolean;

  @Output()
  action: EventEmitter<[string, any, any[]]> = new EventEmitter();

  actionControls: IActionControlOption[];
  update: IActionControlOption;
  actions = EActionControls;

  constructor(private _transloco: TranslocoService) {}

  ngOnInit(): void {
    this.retrieve(this.options);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options.previousValue?.length !== changes.options.currentValue.length) {
      this.retrieve(changes.options.currentValue);
    }
  }

  dataMapper(option: IActionControlOption) {
    return option.title;
  }

  retrieve(options: IActionControlOption[] | void): void {
    if (options) {
      this.actionControls = options
        .map((item: IActionControlOption): IActionControlOption => {
          const option: IActionControlOption = {
            id: item.id,
            title: this._transloco.translate(item.title) || Utils.capitalize(item.id),
            active: item.active || (() => true),
          };

          if (item.id === EActionControls.UPDATE) {
            this.update = option;

            return null;
          }

          if (
            item.id === EActionControls.VIEW_AUDIT_LOG ||
            item.id === EActionControls.COPY_ID
          ) {
            option.active = this.readAuditLogsPermission ? () => true : () => false;
          }

          return option.active() ? option : null;
        })
        .filter((item: IActionControlOption | null): boolean => !!item);
    }
  }

  @Connect<IStore>('lang')
  onLang(): void {
    this.retrieve(this.options);
  }

  @ApmTransactionMethod('package dropdown menu')
  onAction(e: Event, option: IActionControlOption, isRemoved: boolean): void {
    if (isRemoved && option.id !== EActionControls.DELETE) {
      return;
    }

    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.action.emit([option.id, option, this.data]);
  }
}
