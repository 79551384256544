/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetEntity } from '@msi/emm-sdk';
import { Api, IResponse, Settings, Utils } from '@msi/js-sdk';
import { EUserAction, IDispatchedAssetViewerAction } from '@msi/msi-asset-viewer';
import {
  EUserDownloadAction,
  IDispatchedDownloadManagerAction,
} from '@msi/msi-download-manager';
import { lastValueFrom } from 'rxjs';

import { PROTECTED_PACKAGE_CONTEXT } from '../../interceptors/protected-package.interceptor';
import { ISettings } from '../../settings/settings.interface';
import { UrlSettings } from '../../settings/UrlSettings';
import { DerivedItemService } from '../derived-item/derived-item.service';
import { DerivedItemType, EDetailPackageMode } from '../package/package.enums';
import { IPackage, IPackageFile } from '../package/package.interfaces';
import {
  EActionOn,
  EActionType,
  IAuditLogAsset,
  IAuditLogBody,
  IAuditLogData,
  IAuditLogResponse,
} from './audit-log.interfaces';

@Injectable()
export class AuditLogService {
  constructor(
    private _settings: Settings<ISettings>,
    private _api: Api,
    private _derivedItemService: DerivedItemService,
    private _http: HttpClient
  ) {}

  async getContent(
    packageId: string,
    packageAgencyId: string,
    fileId: string,
    fileAgencyId: string
  ): Promise<IAuditLogResponse> {
    const domain: string = this._settings.get<string>('PLATFORM.API' as any);
    const url: string = Utils.format(this._settings.get('DOWNLOAD_AUDIT_LOG'), {
      domain,
    });

    const body: IAuditLogBody = {
      packageId,
      packageAgencyId,
      fileId,
      fileAgencyId,
    };

    let response: IResponse<IAuditLogResponse>;

    try {
      response = await this._api.post<IAuditLogResponse, IAuditLogBody>(url, body);
    } catch (err) {
      throw err;
    }

    return response.data;
  }

  async download(
    packageId: string,
    packageAgencyId: string,
    fileId: string,
    fileAgencyId: string
  ): Promise<void> {
    const content: IAuditLogResponse = await this.getContent(
      packageId,
      packageAgencyId,
      fileId,
      fileAgencyId
    );

    await Utils.downloadContent(
      content.fileDownloadName,
      content.fileContents,
      'text/csv'
    );
  }

  async log(
    auditLogData: IAuditLogData,
    mode: EDetailPackageMode,
    printAction?: EActionType
  ): Promise<void> {
    const auditLogUrl: string = UrlSettings.getAuditLogUrl(
      auditLogData.actionType,
      mode,
      printAction
    );

    if (auditLogData.asset && auditLogData.actionType === EActionType.AssetDownload) {
      auditLogData.actionType = EActionType.Download;
    } else if (
      auditLogData.asset &&
      auditLogData.actionType === EActionType.AssetPrinted
    ) {
      auditLogData.actionType = EActionType.Print;
    }

    return lastValueFrom(
      this._http.post<void>(auditLogUrl, auditLogData, {
        context: new HttpContext().set(PROTECTED_PACKAGE_CONTEXT, {
          isProtected: mode === EDetailPackageMode.PROTECTED,
          packageId: auditLogData.package.id,
        }),
      })
    );
  }

  prepareAuditLogData(
    pkg: IPackage,
    action: EActionType,
    downloadType: string = 'all'
  ): IAuditLogData {
    const agency = pkg.header.agency;
    let auditLogData: IAuditLogData = {
      package: {
        id: pkg.header.id,
        agencyId: agency,
      },
      packageVersion: pkg.header.packageVersion,
    };

    if (action === EActionType.Print || action === EActionType.PrintPreview) {
      auditLogData = {
        ...auditLogData,
        actionOrigin:
          action === EActionType.Print ? 'packageDetailsView' : 'packagePrintPreview',
      };
    }

    if (action === EActionType.Download) {
      auditLogData = {
        ...auditLogData,
        assets: this._mapAssetsForAuditLog(pkg.files, agency),
        actionType: EActionType.Download,
        actionOn: downloadType === 'all' ? EActionOn.AssetsAndMetadata : EActionOn.Assets,
      };
    }

    return auditLogData;
  }

  prepareAssetViewerAuditLogData(
    pkg: IPackage,
    action: IDispatchedAssetViewerAction | IDispatchedDownloadManagerAction
  ): IAuditLogData {
    const actionType = this._mapAssetViewerAction(action.userAction);
    const assets = pkg.assetEntities as AssetEntity[];

    const agency = pkg.header.agency;
    return {
      package: {
        id: pkg.header.id,
        agencyId: agency,
      },
      packageVersion: pkg.header.packageVersion,
      asset: this._getAssetForAuditLog(assets, agency, action),
      actionType,
    };
  }

  private _getAsset(
    assets: AssetEntity[],
    action: IDispatchedAssetViewerAction | IDispatchedDownloadManagerAction
  ): AssetEntity {
    let asset = assets.find(
      (currentAsset: AssetEntity) =>
        action.assetId === currentAsset.fileId &&
        action.derivedItemId === currentAsset.derivedItemId
    );

    if (!asset) {
      asset = assets.find(
        (currentAsset: AssetEntity) => action.rootAssetId === currentAsset.fileId
      );
    }

    return asset;
  }

  private _getAssetForAuditLog(
    assets: AssetEntity[],
    agency: string,
    action: IDispatchedAssetViewerAction | IDispatchedDownloadManagerAction
  ): IAuditLogAsset {
    const asset = this._getAsset(assets, action);

    const auditLogAssets = {
      id: action.derivedItemId ? action.rootAssetId : asset.fileId,
      agencyId: agency,
      childItemPath: action.path,
      derivedItemId: asset.derivedItemId,
      derivedItemType: this._derivedItemService.mapDerivedItemTypeToCamelCase(
        action.derivedItemType as DerivedItemType
      ),
    };

    return auditLogAssets;
  }

  private _mapAssetsForAuditLog(
    files: IPackageFile[],
    agency: string,
    action?: IDispatchedAssetViewerAction | IDispatchedDownloadManagerAction
  ): IAuditLogAsset[] {
    return files.map((file: IPackageFile) => {
      return {
        id: file.fileId,
        agencyId: agency,
        childItemPath: this._getPath(file, action) || '',
        derivedItemId: file.derivedItemId,
        derivedItemType: this._derivedItemService.mapDerivedItemTypeToCamelCase(
          file.derivedItemType
        ),
      };
    });
  }

  private _getPath(
    file: IPackageFile,
    action: IDispatchedAssetViewerAction | IDispatchedDownloadManagerAction
  ): string {
    let path = '';

    if (action?.assetId === file.fileId && action?.derivedItemId === file.derivedItemId) {
      path = action?.path;
    }

    return path;
  }

  private _mapAssetViewerAction(
    userAction: EUserAction | EUserDownloadAction
  ): EActionType {
    switch (userAction) {
      case EUserAction.AssetViewed:
        return EActionType.AssetViewed;
      case EUserAction.AssetPrinted:
        return EActionType.AssetPrinted;
      case EUserAction.VideoPlay:
        return EActionType.AssetPlayed;
      case EUserDownloadAction.DownloadAsset:
        return EActionType.AssetDownload;
    }
  }
}
