/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IShareableAsset } from '../../models/share-data.models';
import { AssetVerificationService } from '../../services/asset-verification/asset-verification.service';
import { ShareDataService } from '../../services/share-data/share-data.service';

@UntilDestroy()
@Component({
  selector: 'ccs-assets-list-view',
  templateUrl: './assets-list-view.component.html',
  styleUrls: ['./assets-list-view.component.scss'],
})
export class AssetsListViewComponent implements OnInit {
  @Input()
  assets: IShareableAsset[];

  @Input()
  fullScreenEnabled = false;

  @Input()
  assetsLoading = true;

  @Output()
  assetRemoved: EventEmitter<IShareableAsset> = new EventEmitter();

  activeAsset: IShareableAsset;
  userUnknown: string;
  assetNameUntitled: string;
  tagsUnassigned: string;

  constructor(
    private _shareDataService: ShareDataService,
    private _assetVerificationService: AssetVerificationService,
    private _transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this._transloco.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this.userUnknown = this._transloco.translate('ccs.Unknown');
      this.assetNameUntitled = this._transloco.translate('ccs.Untitled');
      this.tagsUnassigned = this._transloco.translate('ccs.Unassigned');
    });
    this._shareDataService.activeAsset$
      .pipe(untilDestroyed(this))
      .subscribe((asset: IShareableAsset) => (this.activeAsset = asset));
  }

  onRemove(asset: IShareableAsset): void {
    const activeAssetRemove = this._shareDataService.isActiveAsset(
      asset,
      this.activeAsset
    );
    if (activeAssetRemove) {
      this._shareDataService.resetActiveAsset();
    }
    this._shareDataService.assetsMap.delete(
      asset.derivedItemId ? asset.derivedItemId : asset.fileId
    );
    this.assetRemoved.emit(asset);
  }

  onClick(e: Event, asset: IShareableAsset): void {
    if (
      this.fullScreenEnabled &&
      !this._assetVerificationService.notShareableAsset(asset.assetStorageType)
    ) {
      e.preventDefault();
      e.stopPropagation();

      this._setActiveAsset(asset);
    }
  }

  private _setActiveAsset(asset: IShareableAsset): void {
    this._shareDataService.setActiveAsset(asset);
  }
}
