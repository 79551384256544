/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { GlobalConfig, PlatformSpecificVariables } from './app.config.interface';
import { APP_SPECIFIC_CONFIG } from './app-specific.config';
import { OriginSpecificConfig } from './origin-specific/app.config.origin';

const PLATFORM: PlatformSpecificVariables = OriginSpecificConfig.getConfig();
const logoutRedirectUrlEnvs: string[] = ['dev', 'usgov-stage', 'usgov'];
APP_SPECIFIC_CONFIG.languages = ['en-US', 'fr-CA'];

if (PLATFORM.AZURE_ENV === 'dev') {
  // change this when ES lang will be enabled for every agency
  APP_SPECIFIC_CONFIG.languages.push('es');
  APP_SPECIFIC_CONFIG.languages.push('pl');
}

if (logoutRedirectUrlEnvs.includes(PLATFORM.AZURE_ENV)) {
  APP_SPECIFIC_CONFIG.postLogoutRedirectUrl = PLATFORM.LOGOUT_REDIRECT_URL;
}

export const globalConfig: GlobalConfig = (window as any).globalConfig;
export const GLOBAL_CONFIG: GlobalConfig = Object.assign(
  {
    PLATFORM,
    BUILD_NUMBER: '20240822.1',
    SOURCE_VERSION: '5289e0a150ad5d50f876eb2a9c64d6879db4d2c7',
    SOURCE_BRANCH: 'refs/heads/master',
    APP_NAME: ' ',
    LOGIN_COOKIE_KEY: `com.commandcentral.${APP_SPECIFIC_CONFIG.clientId}.accessToken`,
    ADMIN_API_ENDPOINT: '/api/admin',
    ADMIN_API_USER_INFO_PATH: '/me',
    DOCUMENTATION_LINK_ONERMS_CRV: '', // TBD
    GET_CONFIGURATION_RETRY_INTERVAL: 100,
    GET_CONFIGURATION_RETRY_NUMBER: 0,
    LOGIN_ENDPOINT_TEMPLATE: 'LOGIN_ENDPOINT_TEMPLATE',
    LOGGING_LEVELS: ['error', 'info', 'warn', 'uiError'],
    SLAVE_WINDOW_URL_WHITE_LIST: ['/api/admin/', '/api/map-proxy'],
    API_FILES_ENDPOINT: '/api/search/v1.0/files',
    SEARCH_API_ENTITY_ENDPOINT: '/api/search/v1.0/{{ entity }}/.search',
    FREE_TEXT_SEARCH_API_ENDPOINT: '/api/search/v1.0/.search',
    JUDICIAL_CREATE_PACKAGE_API_ENDPOINT: '/package-manager/v1/packages',
    JUDICIAL_SHARE_PACKAGE_API_ENDPOINT: '/package-manager/v1/packages',
    GET_PROSECUTORS_URL: '/package-manager/v1/recipients/prosecutors',
    CREATE_PACKAGE_URL: '/package-manager/v1/packages',
    DELETE_V2_PACKAGE_URL:
      '/package-manager/v1/packages-v2/{{ agencyId }}/{{ packageId }}',
    REGENERATE_V2_PACKAGE_URL:
      '/package-manager/v1/packages-v2/{{ agencyId }}/{{ packageId }}/regenerate-link',
    SEARCH_PACKAGE_URL: '/package-manager/v1/packages/.search',
    SHARE_PACKAGE_URL: '/package-manager/v1/packages/.search-shared-with-me',
    SEARCH_SHARED_URL: '/package-manager/v1/packages/.search-shared',
    SEARCH_LAW_INCIDENTS_URL: '/api/search/v1.0/lawIncidents/.search',
    SEARCH_FILES_URL: '/api/search/v1.0/files/.search',
    USER_ASSIGNED_WORKFLOW_STATE_ENDPOINT: '/v1/WorkflowState/assignee',
    ENTITY_ASSIGNED_WORKFLOW_STATE_ENDPOINT: '/v1/WorkflowState/entity',
    INITIAL_WORKFLOW_STATE_ENDPOINT: '/v1/WorkflowState/initial',
    FLEX_URL: 'http://localhost:4079/LaunchScreen/?screen=lwmain&field=number&value=',
    PACKAGE_VIEWER_API_PACKAGE_ENDPOINT: '/pm-proxy/v1/package',
    PACKAGE_VIEWER_API_PACKAGE_PRIVATE_ENDPOINT:
      '{{ domain }}/package-manager/v1/packages/{{ packageId }}/private',
    PACKAGE_VIEWER_API_PACKAGE_PUBLIC_ENDPOINT:
      '{{ domain }}/package-manager/v1/packages/{{ packageId }}/public',
    EMM: {
      EVIDENCE_CONFIG_URL: PLATFORM.EMM.API_2_1 + '/api/vault/v2/config',
      CHAIN_OF_CUSTODY_URL:
        PLATFORM.EMM.API_2_1 + '/api/vault/v2/files/{{ assetId }}/chainOfCustody',
      UPLOAD_URL:
        PLATFORM.EMM.API_2_1 + '/api/vault/v2/files?uploadType={{ uploadType }}',
      VERIFY_URL: PLATFORM.EMM.API_2_1 + '/api/vault/v2/files/done?id={{ assetId }}',
      AGENCY_GROUPS: PLATFORM.EMM.API_2_1 + '/api/vault/v2/groups',
      GET_ASSET_URL: PLATFORM.EMM.API_2_1 + '/api/vault/v2/files/{{ assetId }}',
      ALLOWED_OPERATIONS:
        PLATFORM.EMM.API_2_1 + '/api/vault/v2/files/{{ assetId }}/operations',
      CANCEL_ASSET_URL:
        PLATFORM.EMM.API_2_1 + '/api/vault/v2/files/cancel?id={{ assetId }}',
      UPDATE_ASSET_URL:
        PLATFORM.EMM.API_2_1 + '/api/vault/v2/files/{{ assetId }}/properties/modify',
      AUDIT_VIEWER_URL:
        PLATFORM.EMM.AUDIT_VIEWER +
        '?object={{ assetId }}&startDate={{ startDate }}&endDate={{ endDate }}&application={{ application }}',
      APPLICATION_NAME: 'CCVault',
      AUDIT_LOG_APPLICATION_NAME: 'ccsharing',
      GET_OFFICERS_URL: PLATFORM.EMM.API_2_1 + '/vault/v2/officers',
    },
    SEARCH_API_PARAMS: {
      pageSize: 10,
      pageToken: '',
      resolveCodes: true,
    },
    FILES_API_PARAMS: {
      pageSize: 50,
      pageToken: '',
    },
    ARRESTS_SERVICE_NAME: 'records.arrest',
    ARRESTS_PERMISSION_NAME: 'arrest.create',
    CRV_SERVICE_NAME: 'records.crv',
    RMS_LITE_SERVICE_NAME: 'records.rmslite',
    CRV_ADMIN_SERVICE_NAME: 'records.crv.admin',
    JUDICIAL_SERVICE_NAME: 'records.judicial',
    SEARCH_SERVICE_NAME: 'records.search',
    BROWSER_UPLOAD: 'browser.upload',
    ASSISTED_NARRATIVE: 'assisted.narrative',
    PUBLIC_SHARES_PERMISSION_NAME: 'publish.shares',
    FREE_TEXT_SEARCH_PERMISSION_NAME: 'full_text_search',
    IMAGE_SEARCH_PERMISSION_NAME: 'image_search',
    RECORDS_FORMS_API_ENDPOINT: '/api/records/v1.0/forms/',
    BDP_ANALYTICS_CLICK_ENDPOINT: '/v1/analytics/click',
    TOAST_SHOW_DELAY: 3000,
    TOAST_SHOW_LONG_DELAY: 20000,
    ASSISTED_NARRATIVE_ENTITY_MENTIONS_ENDPOINT: '/v1/narrative/entityMentions',
    WALKME_DISABLED_PERMISSION_NAME: 'walkme.disabled',
    WKID_URL: 'http://wkid.dev.commandcentral.com/api/wkid/',
    VAULT_SHARE_URL: '/api/vault-public/v1/shares',
    RESHARE_PACKAGE_URL:
      '{{ domain }}/package-manager/v1/packages/{{ packageId }}/{{ ownerAgency }}/.reshare',
  },
  APP_SPECIFIC_CONFIG
);
