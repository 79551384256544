/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { AbstractControl, ValidatorFn } from '@angular/forms';

export function passwordLengthValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl) => {
    const valid = !control.value || control.value?.length >= minLength;

    return valid ? null : { passwordLength: { value: control.value } };
  };
}
