/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Connect } from '@msi/js-sdk';

import { ElasticApmService } from '../../../services/elastic-apm';
import { PackageItem } from '../../../services/package/classes/package/PackageItem';
import { IAdditionalInfo } from '../../../services/package/create-package.interfaces';
import { EDetailPackageMode } from '../../../services/package/package.enums';
import { IPackage } from '../../../services/package/package.interfaces';
import { PackageService } from '../../../services/package/package.service';
import { IDetailPackageStore } from '../../../stores/detail-package/detail-package.interfaces';
import { detailPackageLocalStore } from '../../../stores/detail-package/detail-package.store';
import { IStore } from '../../../stores/global/store.interface';
import { EActionControls } from '../../common/action-controls/action-controls.enums';
import { IActionControlOption } from '../../common/action-controls/action-controls.interfaces';
import { ResharePackageComponent } from '../../reshare-package/reshare-package.component';

@Component({
  selector: 'pp-detail-package-header',
  templateUrl: './detail-package-header.component.html',
  styleUrls: ['./detail-package-header.component.scss', '../detail-package-print.scss'],
})
export class DetailPackageHeaderComponent {
  @Input()
  mode: EDetailPackageMode = EDetailPackageMode.GUESTS;

  @Input()
  headerTitle = 'CommandCentral';

  @Input()
  pkg: IPackage;

  @Input()
  packageItem: PackageItem;

  @Input()
  isPackageOwnerPD: boolean;

  @Output()
  send: EventEmitter<boolean> = new EventEmitter();

  @Output()
  enablePrintPreview: EventEmitter<boolean> = new EventEmitter();

  @Output()
  enablePrint: EventEmitter<{ printEnabled: boolean; printPreviewEnabled: boolean }> =
    new EventEmitter();

  @ViewChild('reshareComponent', { static: false })
  reshareComponent: ResharePackageComponent;

  @Connect<IStore>('isUnauthSharingEnabled')
  isUnauthSharingEnabled: boolean;

  @Connect<IStore>('lang')
  lang: string;

  @Connect<IDetailPackageStore>('assetsLoaded', detailPackageLocalStore)
  assetsLoaded: boolean;

  actionControlsTypes = EActionControls;
  additionalInfo: IAdditionalInfo[];

  private isPrintPreviewOn = false;

  constructor(
    private _packageService: PackageService,
    private _elasticApmService: ElasticApmService
  ) {}

  getActionOptions(): IActionControlOption[] {
    const items: IActionControlOption[] = [
      { id: EActionControls.PRINT, title: 'Print' },
      { id: EActionControls.PRINT_PREVIEW, title: 'Print preview' },
    ];

    if (this.mode !== EDetailPackageMode.GUESTS) {
      if (this.packageItem.canReshare() && this.isUnauthSharingEnabled) {
        items.unshift({ id: EActionControls.RESHARE, title: 'Share package' });
      } else if (this.packageItem.canUpdateReshare() && !this.isPackageOwnerPD) {
        items.unshift({
          id: EActionControls.UPDATE_RESHARE,
          title: 'Modify reshared package',
        });
      }
    }

    if (this.isPrintPreviewOn) {
      items.pop();
      items.push({ id: EActionControls.CLASSIC_VIEW, title: 'Exit print preview' });
      items.shift();
    } else {
      items.pop();
      items.push({ id: EActionControls.PRINT_PREVIEW, title: 'Print preview' });
    }

    return items;
  }

  async onAction([name, , pkg]): Promise<void> {
    if (name === EActionControls.RESHARE || name === EActionControls.UPDATE_RESHARE) {
      this.additionalInfo =
        await this._packageService.getAdditionalInfoFromPackageForReshare(pkg.getProps());

      setTimeout(() => {
        this.reshareComponent.open();
      });
    } else if (name === EActionControls.PRINT) {
      this.enablePrint.emit({
        printEnabled: true,
        printPreviewEnabled: this.isPrintPreviewOn,
      });
    } else if (name === EActionControls.PRINT_PREVIEW) {
      this.enablePrintPreview.emit(true);
      this.isPrintPreviewOn = true;
    } else if (name === EActionControls.CLASSIC_VIEW) {
      this.enablePrintPreview.emit(false);
      this.isPrintPreviewOn = false;
    }

    this._elasticApmService.track('package header action - ' + name);
  }

  onReshareDone(e: boolean): void {
    this.send.emit(e);
  }
}
