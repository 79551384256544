/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component } from '@angular/core';

@Component({
  selector: 'ccs-review-files-dynamic',
  templateUrl: './review-files-dynamic.component.html',
})
export class ReviewFilesDynamicComponent {}
