/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { EMethodTypes } from './elastic-apm.enums';

function generateCustomMetadata(name: string, propertyKey: string, args: any): Labels {
  let customMetadata: Labels = {};

  if (
    (propertyKey === EMethodTypes.ON_ACTION && args[0].id === 'print') ||
    propertyKey === EMethodTypes.ON_SHARE ||
    propertyKey === EMethodTypes.ON_SCROLL_TO ||
    propertyKey === EMethodTypes.ON_CLICK ||
    propertyKey === EMethodTypes.ON_SUBMIT ||
    propertyKey === EMethodTypes.ON_ADD ||
    propertyKey === EMethodTypes.ON_SORT ||
    propertyKey === EMethodTypes.ON_CLICK_ACTION_MENU
  ) {
    const actionName = getActionName(name, propertyKey, args);
    customMetadata = { actionName };
  }

  if (propertyKey === EMethodTypes.ON_AUTH_MODE_CHANGED) {
    const authMode = args[0] === '1' ? 'authenticated access' : 'unautenticated access';
    customMetadata = { authMode };
  }

  if (propertyKey === EMethodTypes.ON_VALID_TO_CHANGED) {
    const expire = args[0] === 999999 ? 'never' : args[0].toString().concat(' days');
    customMetadata = { expire };
  }

  if (propertyKey === EMethodTypes.ON_DOWNLOAD) {
    const downloadType = args[0];

    customMetadata = { downloadType };
  }

  if (propertyKey === EMethodTypes.ON_ACTION && name === 'download action') {
    const downloadAction = getActionName(name, propertyKey, args);

    customMetadata = { downloadAction };
  }

  return customMetadata;
}

function getActionName(name: string, propertyKey: string, args: any): string {
  let action = '';

  if (propertyKey === EMethodTypes.ON_ACTION) {
    action =
      name === 'download action'
        ? args[0][0].concat(' button')
        : args[0].id.replace(/_|-/g, ' ').concat(' package');
  } else if (propertyKey === EMethodTypes.ON_CLICK_ACTION_MENU) {
    action = args[0][0].replace(/_|-/g, ' ').concat(' package');
  } else if (propertyKey === EMethodTypes.ON_CLICK) {
    action = args[0].id.replace(/_|-/g, ' ').concat(' button');
  } else if (
    propertyKey === EMethodTypes.ON_SUBMIT ||
    propertyKey === EMethodTypes.ON_ADD ||
    propertyKey === EMethodTypes.ON_SORT
  ) {
    action = name;
  } else {
    action = args[0] === 'assets' ? 'digital evidence button' : args[0].concat(' button');
  }

  return action;
}

export { generateCustomMetadata };
