/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { EventEmitter } from '@msi/js-sdk';

import { FS } from '../../../FS';
import { DownloadItemDTO } from '../../DownloadItemDTO';

class SaveFileTask extends EventEmitter {
  private _downloadItemDTO: DownloadItemDTO;
  private _fs: FS;
  private _writableStream!: FileSystemWritableFileStream;
  private _bytesLimit: number = 128 * 1024 * 1024;

  constructor(downloadItemDTO: DownloadItemDTO, fs: FS) {
    super();

    this._downloadItemDTO = downloadItemDTO;
    this._fs = fs;
  }

  async run(
    reader: ReadableStreamDefaultReader<Uint8Array> | string,
    last: boolean = false
  ): Promise<void> {
    let result: BlobPart[] = [];
    let byteLenght = 0;

    if (!this._writableStream) {
      this._writableStream = await this._fs.createWritableStream(
        this._downloadItemDTO.name,
        this._downloadItemDTO.path || ''
      );
    }

    if (typeof reader === 'string') {
      await this._writableStream.write({ type: 'write', data: new Blob([reader]) });
      await this._writableStream.close();
    } else {
      const loop = true;

      while (loop) {
        const { done, value } = await reader.read();

        if (done) {
          if (result.length) {
            await this._writableStream.write({ type: 'write', data: new Blob(result) });
          }
          break;
        }
        byteLenght += value.byteLength;

        if (byteLenght >= this._bytesLimit) {
          try {
            await this._writableStream.write({ type: 'write', data: new Blob(result) });
          } catch (error) {
            await this._writableStream.write({ type: 'write', data: new Blob(result) });
          }

          result = [];
          byteLenght = 0;
        }
        result.push(value as BlobPart);
      }

      if (last) {
        await this._writableStream.close();
      }
    }
  }
}

export { SaveFileTask };
