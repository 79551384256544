/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { GlobalStore } from '../../stores/global/store';
import { ISidebarItem } from './sidebar.interfaces';

@Injectable()
export class SidebarService {
  private _router$: Subscription;

  constructor(
    private _router: Router,
    private _store: GlobalStore
  ) {
    this._store.on('topSidebarItems', () => this.onChange());
    this._store.on('bottomSidebarItems', () => this.onChange());
    this._router$ = this._router.events.subscribe(() => this.onChange());
  }

  apply(items: ISidebarItem[]) {
    items.forEach((item: ISidebarItem) => (item.tooltipDisabled = true));

    this._store.topSidebarItems = items;
  }

  click(item: ISidebarItem): void {
    if (item.click) {
      item.click();
    }

    this._store.emit('sidebar/click', item);

    if (item.hasOwnProperty('link')) {
      this._router.navigate([item.link]);
    }
  }

  onChange(): void {
    const topBottom = [this._store.topSidebarItems, this._store.bottomSidebarItems];

    topBottom.forEach((items: ISidebarItem[]): void => {
      items.forEach((item: ISidebarItem): void => {
        if (item.link === this._router.url) {
          item.selected = true;
        }

        setTimeout(() => (item.tooltipDisabled = false), 100);
      });
    });
  }
}
