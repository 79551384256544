/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

interface ISettings {
  AUTODISMISS?: number;
  USER_ID?: string;
  AGENCY_NAME?: string;
  APPLICATION_NAME?: string;
  AUDIT_LOG_APPLICATION_NAME?: string;
  EVIDENCE_CONFIG_URL?: string;
  UPLOAD_URL?: string;
  VERIFY_URL?: string;
  AGENCY_GROUPS?: string;
  GET_ASSET_URL?: string;
  CHAIN_OF_CUSTODY_URL?: string;
  ALLOWED_OPERATIONS?: string;
  CANCEL_ASSET_URL?: string;
  UPDATE_ASSET_URL?: string;
  AUDIT_VIEWER_URL?: string;
  SEARCH_URL?: string;
  GET_OFFICERS_URL?: string;
  GET_PROSECUTORS_URL?: string;
  GET_SINGLE_ASSET_BY_ID_URL?: string;
  CREATE_PACKAGE_URL?: string;
  DELETE_V2_PACKAGE_URL?: string;
  REGENERATE_V2_PACKAGE_URL?: string;
  AUDIT_LOG_URL?: string;
  TOKEN_TYPE?: string;
  ACCESS_TOKEN?: string;
  LIMIT?: number;
  JUDICIAL_CREATE_PACKAGE_API_ENDPOINT?: string;
  JUDICIAL_SHARE_PACKAGE_API_ENDPOINT?: string;
  PLATFORM?: {
    API?: string;
    CRV_BASE_URL?: string;
    AZURE_ENV?: string;
    EVIDENCE_URL?: string;
  };
  SEARCH_PACKAGE_URL?: string;
  SHARE_PACKAGE_URL?: string;
  RESHARE_PACKAGE_URL?: string;
  SEARCH_SHARED_URL?: string;
  PAGE_SIZE?: number;
  PAGE_SIZE_FOR_RECENT_CASES?: number;
  SOURCE_BRANCH?: string;
  BUILD_NUMBER?: string;
  SOURCE_VERSION?: string;
  BASE_URL?: string;
  BASE_URL_BETA?: string;
  RECORDS_URL?: string;
  NEVER_ID?: number;
  NEVER_DATE?: string;
  PM_API_PACKAGE_ENDPOINT?: string;
  PM_API_PACKAGE_V2_ENDPOINT?: string;
  PM_API_FEATURE_FLAGS_ENDPOINT?: string;
  SUBSCRIBE_UNAUTH_PACKAGE_API_ENDPOINT?: string;
  SUBSCRIBE_AUTH_PACKAGE_API_ENDPOINT?: string;
  UNSUBSCRIBE_UNAUTH_PACKAGE_API_ENDPOINT?: string;
  UNSUBSCRIBE_AUTH_PACKAGE_API_ENDPOINT?: string;
  PM_API_PACKAGE_PRIVATE_ENDPOINT?: string;
  PM_API_PACKAGE_V2_PRIVATE_ENDPOINT?: string;
  PM_API_PACKAGE_PUBLIC_ENDPOINT?: string;
  PM_API_PACKAGE_V2_PUBLIC_ENDPOINT?: string;
  VAULT_SHARE_URL?: string;
  EMM?: Record<string, any>;
  SEARCH_LAW_INCIDENTS_URL?: string;
  SEARCH_FILES_URL?: string;
  CRV_SERVICE_NAME?: string;
  SEARCH_API_ENTITY_ENDPOINT?: string;
  CALCULATE_PACKAGE_CHANGES?: string;
  DOWNLOAD_AUDIT_LOG?: string;
  PM_AUDIT_LOG_ENDPOINT?: string;
  PROXY_AUDIT_LOG_ENDPOINT?: string;
  BDP_PUBLISHERS_SEARCH?: string;
  BDP_VIEWERS_SEARCH?: string;
  CRV_SETTINGS?: string;
  CREATE_CASE_API_ENDPOINT?: string;
  RECENT_CASES_API_ENDPOINT?: string;
  ADD_TO_CASE_API_ENDPOINT?: string;
  LAST_CASE_API_ENDPOINT?: string;
  DEVOPS_GET_FEATURE_FLAG?: string;
  DEVOPS_GET_FEATURE_FLAGS?: string;
  PROTECTED_PACKAGE_AUTHORIZATION_URL?: string;
}

export { ISettings };
