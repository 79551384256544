<ng-template #modalTemplate>
  <msi-modal>
    <msi-modal-header>{{ 'Cancel downloads' | transloco }}</msi-modal-header>
    <msi-modal-content>
      {{ 'Download assets are in progress. Do you want to cancel downloads?' | transloco }}
    </msi-modal-content>
    <msi-modal-actions align="end">
      <button
        id="cancel-modal-btn"
        type="button"
        class="msi-btn msi-btn-action"
        (click)="onCancel()">
        {{ 'No' | transloco }}
      </button>
      <button
        id="confirm-modal-btn"
        (click)="onConfirm()"
        type="button"
        class="msi-btn">
        {{ 'Confirm' | transloco }}
      </button>
    </msi-modal-actions>
  </msi-modal>
</ng-template>
