/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { ShareSettingsService } from '../services/share-settings/share-settings.service';

@Injectable()
export class AuthHeadersInterceptor implements HttpInterceptor {
  private ccUrlPattern = /https:\/\/\S+\.(commandcentral|motorolasolutions)\.(com|ca)/;
  private urlsWithAuthHeaderNotNeededOrProvided = [
    '.commandcentral.com/as/token.oauth2',
    '.sec.commandcentral.ca/as/token.oauth2',
    '.commandcentral.com/idp/userinfo.openid',
    '.sec.commandcentral.ca/idp/userinfo.openid',
    '.motorolasolutions.com/idp/userinfo.openid',
    '.commandcentral.com:443/idp/userinfo.openid',
    '.sec.commandcentral.ca:443/idp/userinfo.openid',
    '.motorolasolutions.com:443/idp/userinfo.openid',
  ];

  constructor(private _shareSettingsService: ShareSettingsService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this._shareSettingsService.accessToken;
    if (this._authorizationHeaderNeeded(request.url)) {
      return from(this._addAuthorizationHeader(request, token, next));
    } else {
      return next.handle(request);
    }
  }

  private _addAuthorizationHeader(
    request: HttpRequest<unknown>,
    token: string,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!!token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request);
  }

  private _authorizationHeaderNeeded(requestUrl: string): boolean {
    return (
      this.urlsWithAuthHeaderNotNeededOrProvided.every(
        (url) => !requestUrl.includes(url)
      ) && this.ccUrlPattern.test(requestUrl)
    );
  }
}
