/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
export enum EKeyCode {
  TAB = 'Tab',
  ENTER = 'Enter',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ESC = 'Escape',
  ARROW_TOP = 'ArrowUp',
  ARROW_BOTTOM = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
}
