<ng-container *transloco="let t; read: 'ccs'">
  <ng-template
    #shareData
    let-modal>
    <msi-pop-up-modal-header>
      <msi-pop-up-modal-header-title
        class="share-data-header-title"
        (click)="onToggleMinimized(modal)">
        <msi-icon
          [name]="'ic_share_manager'"
          size="24"
          color="white"></msi-icon>
        <span class="share-data-header-title__info">{{ t('Share files') }}</span>
        <span
          *ngIf="!modal.minimized"
          class="share-data-header-title__count"
          >({{ assets.length }})</span
        >
        <ng-container *ngIf="modal.minimized">
          <msi-tag
            value="primary"
            [filled]="true"
            >{{ assets.length }}</msi-tag
          >
        </ng-container>
      </msi-pop-up-modal-header-title>
      <msi-pop-up-modal-header-buttons>
        <button
          type="button"
          class="msi-btn msi-btn-action msi-btn-compact"
          [class.header-buttons-padding]="!modal.fullscreen"
          (click)="onToggleMinimized(modal)">
          <msi-icon
            [name]="modal.minimized ? 'ic_arrow_up' : 'ic_arrow_down'"
            size="24"
            color="white">
          </msi-icon>
        </button>
        <button
          type="button"
          class="msi-btn msi-btn-action msi-btn-compact"
          [class.header-buttons-padding]="!modal.fullscreen"
          (click)="onToggleFullScreen(modal)">
          <msi-icon
            [name]="modal.fullscreen ? 'ic_view_collapse' : 'ic_view_expand'"
            size="24"
            color="white">
          </msi-icon>
        </button>
        <button
          type="button"
          class="msi-btn msi-btn-action msi-btn-compact"
          [class.header-buttons-padding]="!modal.fullscreen"
          (click)="closePopUpModal()">
          <msi-icon
            name="ic_remove"
            size="24"
            color="white">
          </msi-icon>
        </button>
      </msi-pop-up-modal-header-buttons>
    </msi-pop-up-modal-header>
    <msi-pop-up-modal-content
      class="share-data-content"
      [modalData]="modal">
      <ng-container *ngIf="error || !assets?.length">
        <ccs-no-content
          [fullScreenEnabled]="modal.fullscreen"
          [assetsLoading]="assetsLoading"></ccs-no-content>
      </ng-container>
      <ng-container *ngIf="!error && !!assets?.length">
        <ng-container *ngIf="!modal.fullscreen">
          <ccs-modal-content
            class="share-data-content__modal"
            [assets]="assets"
            [assetsLoading]="assetsLoading"
            [enableContentProjection]="enableContentProjection"
            (toggleModalFullScreen)="onToggleFullScreen(modal)"
            (assetRemoved)="onAssetRemoved($event)">
            <ng-container *ngIf="enableContentProjection">
              <ng-container *ngTemplateOutlet="ccsDynamicReviewFiles"></ng-container>
            </ng-container>
          </ccs-modal-content>
        </ng-container>
        <ng-container *ngIf="modal.fullscreen">
          <ccs-full-screen-modal-content
            class="share-data-content__full-screen-modal"
            [assets]="assets"
            [shortestRetentionTime]="shortestRetentionTime"
            [assetsLoading]="assetsLoading"
            [enablePasswordProtectionForUnauthPkg]="unauthPkgPasswordFeatureFlag"
            [enableContentProjection]="enableContentProjection"
            (assetRemoved)="onAssetRemoved($event)"
            (closeModal)="closePopUpModal()">
            <ng-container *ngIf="enableContentProjection">
              <ng-container *ngTemplateOutlet="ccsDynamicReviewFiles"></ng-container>
            </ng-container>
          </ccs-full-screen-modal-content>
        </ng-container>
      </ng-container>
    </msi-pop-up-modal-content>
  </ng-template>
  <ng-template #ccsDynamicReviewFiles>
    <ng-content></ng-content>
  </ng-template>
</ng-container>
