/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserInfo } from '@msi/commandcentral-common-header';
import { LocalStorage, Settings } from '@msi/js-sdk';
import { ISettings, ISidebarItem, SidebarService, UserService } from '@msi/pp-components';
import { ElasticApmService } from '@msi/pp-components';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ThemeMode } from './enums';

@UntilDestroy()
@Component({
  selector: 'pp-header-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class HeaderAuthComponent implements OnInit {
  @Input()
  displaySidebar?: boolean = true;

  userInfo: UserInfo;
  appInfo: Record<string, string> = {};
  baseUrl: string;
  baseUrlBeta: string;
  settingsUrl: string;
  initialized = false;
  theme: string;
  marginLeft = '0';
  isBeta: boolean;

  constructor(
    private _settings: Settings<ISettings>,
    private _userService: UserService,
    private _sidebarService: SidebarService,
    private _localStorage: LocalStorage,
    private _elasticApmService: ElasticApmService,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.baseUrl = this._settings.get<string>('BASE_URL');
    this.baseUrlBeta = this._settings.get<string>('BASE_URL_BETA');
    this.settingsUrl = this._settings.get<string>('CRV_SETTINGS');
    this.isBeta = this.router.snapshot.routeConfig.path.includes('beta');

    this.appInfo = {
      appVersion: this._settings
        .get<string>('SOURCE_BRANCH')
        .split('releases/release-')[1],
      buildNumber: this._settings.get<string>('BUILD_NUMBER'),
      sourceVersion: this._settings.get<string>('SOURCE_VERSION').substr(0, 8),
    };

    if (this.displaySidebar) {
      this.marginLeft = '48px';
    } else {
      this.marginLeft = '24px';
    }

    this._setTheme();
  }

  applySidebar(): void {
    const env: string = this._settings.get<string>('PLATFORM.AZURE_ENV' as any);
    const sidebarItems: ISidebarItem[] = [
      {
        id: 'home',
        title: 'Home',
        icon: 'ic_home',
        selected: false,
        tooltipDisabled: true,
        link: '/',
      },
    ];

    if (env === 'dev') {
      sidebarItems.push(
        ...[
          {
            id: 'share_manager',
            title: 'Share Manager',
            icon: 'ic_share_manager',
            selected: false,
            link: '/sm',
          },
          {
            id: 'sandbox-test',
            title: 'Sandbox test page',
            icon: 'ic_repair',
            selected: false,
            link: '/test',
          },
          {
            id: 'mf-sm',
            title: 'Sandbox MF Share Manager page',
            icon: 'ic_settings',
            selected: false,
            link: '/mf-sm',
          },
          {
            id: 'mf-ssm',
            title: 'Sandbox MF Single Share Manager page',
            icon: 'ic_settings',
            selected: false,
            link: '/mf-ssm',
          },
          {
            id: 'mf-create-component',
            title: 'Sandbox MF Create Package page',
            icon: 'ic_settings',
            selected: false,
            link: '/mf-create-package',
          },
          {
            id: 'single-view',
            title: 'Sandbox Single View',
            icon: 'ic_migration',
            selected: false,
            link: '/beta',
          },
          {
            id: 'commandcentral-share-data',
            title: 'Sandbox CommandCentral Share Data',
            icon: 'ic_booking',
            selected: false,
            link: '/share-data-lib',
          },
        ]
      );
    }

    this._sidebarService.apply(sidebarItems);
  }

  async handleHeaderInitialized(userInfo: UserInfo) {
    this._userService.setUserInfo(userInfo);
    this._elasticApmService.initialize();
    const enabledServices = this._userService.checkEnabledServices(userInfo);
    this._elasticApmService.track(enabledServices);

    this.initialized = true;
    this.userInfo = userInfo;

    this.applySidebar();
  }

  async toggleTheme(e: Event): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    this.theme = this.theme === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT;

    await this._localStorage.set('theme', this.theme);

    document
      .querySelector('body')
      .classList.add(this.theme === ThemeMode.LIGHT ? ThemeMode.LIGHT : ThemeMode.DARK);
    document
      .querySelector('body')
      .classList.remove(
        this.theme === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT
      );
  }

  private async _setTheme(): Promise<void> {
    this.theme = (await this._localStorage.get('theme')) || ThemeMode.LIGHT;

    if (this.theme === ThemeMode.DARK) {
      document.querySelector('body').classList.add(ThemeMode.DARK);
    } else {
      document.querySelector('body').classList.add(ThemeMode.LIGHT);
    }

    this._localStorage.set('theme', this.theme);
  }
}
