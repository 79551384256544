/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService, MsiModalConfig, MsiModalRef, ToastService } from '@msi/cobalt';
import { Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';

import { ISpecificTypeAssetCount } from '../../services/asset-entity/asset-entity.interface';
import { AssetVerificationService } from '../../services/asset-verification/asset-verification.service';
import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../services/elastic-apm/elastic-apm.decorators';
import { ElasticApmService } from '../../services/elastic-apm/elastic-apm.service';
import { Officer } from '../../services/officer/Officer';
import { OfficerService } from '../../services/officer/officer.service';
import { IPackageContent } from '../../services/package/classes/package/PackageContent.interafces';
import {
  IAdditionalInfo,
  ICreatePackageForm,
} from '../../services/package/create-package.interfaces';
import { IPackage, IPackageFile } from '../../services/package/package.interfaces';
import { PackageService } from '../../services/package/package.service';
import { SystemService } from '../../services/system-service/system.service';
import { ISettings } from '../../settings/settings.interface';
import { alphanumericValidator } from '../../utils/validators/alphanumeric.validator';
import { caseValidator } from '../../utils/validators/case.validator';
import { passwordLengthValidator } from '../../utils/validators/password-length.validator';
import { specialCharacterValidator } from '../../utils/validators/special-character.validator';
import { getFeatureFlag } from '../+store/selectors/feature-flags.selector';
import { EActionControls } from '../common/action-controls/action-controls.enums';
import { ProsecutorValidator, TPros } from '../common/prosecutors/prosecutor-validator';

const toastMessages: Record<string, string> = {
  success:
    'The system is processing and sending the package. This can take a few minutes.',
  error: 'Package is not shared. Server error.',
};

@UntilDestroy()
@Component({
  selector: 'pp-create-package',
  templateUrl: './create-package.component.html',
  styleUrls: ['./create-package.component.scss'],
})
@ApmTransactionComponent('create package modal')
export class CreatePackageComponent implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() filesLoading = false;
  @Input() title = 'Share';
  @Input() files: IPackageFile[];
  @Input() content: IPackageContent;
  @Input() fullContent = true;
  @Input() hasOverview = true;
  @Input() additionalInfo: IAdditionalInfo[];
  @Input() retrying = false;
  @Output() done: EventEmitter<IPackage> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() send: EventEmitter<boolean> = new EventEmitter();
  @Output() retry: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createPackageTemplate', { static: false })
  createPackageTemplate: TemplateRef<any>;
  isUnauthSharingEnabled = true;
  isGeneratedPasswordUsed = false;
  modalRef: MsiModalRef;
  form: UntypedFormGroup;
  authMode = true;
  customSubject = false;
  sendEmailNotification = true;
  sharing = false;
  isEmptyAgency = false;
  incompleteAssets$: Observable<ISpecificTypeAssetCount>;
  directoryAssets$: Observable<ISpecificTypeAssetCount>;
  shortRetentionAssets$: Observable<ISpecificTypeAssetCount>;
  restrictedAssets$: Observable<ISpecificTypeAssetCount>;
  officers: Officer[] = [];
  secureAccessTooltipText =
    'Share with your agency partner who have CommandCentral access.';
  enablePasswordProtectionForUnauthPkg: boolean;
  enableSelectingDateWithCalendar: boolean;
  purgeDate: Dayjs;
  packageName = '';
  config: MsiModalConfig = {
    disableClose: true,
    hasBackdrop: true,
    width: 610,
  };

  readonly createAction: EActionControls = EActionControls.CREATE;
  private _formValue: any;
  private _pv: ProsecutorValidator = new ProsecutorValidator();

  constructor(
    private _settings: Settings<ISettings>,
    private _modalService: ModalService,
    private _packageService: PackageService,
    private _toastService: ToastService,
    private _officerService: OfficerService,
    private _elasticApmService: ElasticApmService,
    private _systemService: SystemService,
    private _transloco: TranslocoService,
    private _assetVerificationService: AssetVerificationService,
    private _store: Store
  ) {}

  ngOnChanges(): void {
    this.files = this.files.filter((file: IPackageFile) => {
      return !this._assetVerificationService.notShareableAsset(file.assetStorageType)
        ? file
        : this._assetVerificationService.countIncompleteAssets(file);
    });
    const packageExpiration = this.form?.get('validTo')?.value;
    this._assetVerificationService.countRestritedAssets(this.files);
    this._assetVerificationService.checkAssetRetention(this.files, packageExpiration);
  }

  ngOnInit() {
    this.isUnauthSharingEnabled = this._systemService.isFedRamp() ? false : true;
    this.incompleteAssets$ = this._assetVerificationService.incompleteAssets$;
    this.directoryAssets$ = this._assetVerificationService.directoryAssets$;
    this.shortRetentionAssets$ = this._assetVerificationService.shortRetentionAssets$;
    this.restrictedAssets$ = this._assetVerificationService.restrictedAssets$;
    this.additionalInfo = this.additionalInfo || [];

    const validator: any = (to: UntypedFormControl) => {
      const prosecutors: TPros[] = to.value;

      if (!prosecutors?.length) {
        return 'Please fill "to" field';
      }

      return this._pv.getAuthModeMessage(this.authMode, prosecutors);
    };

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.packageName),
      authMode: new UntypedFormControl(this.authMode ? '1' : ''),
      subject: new UntypedFormControl('CommandCentral Record - Shared Package'),
      validTo: new UntypedFormControl(null, [Validators.required]),
      to: new UntypedFormControl([], validator),
      password: new UntypedFormControl('', [
        passwordLengthValidator(15),
        Validators.maxLength(32),
        alphanumericValidator(),
        caseValidator(),
        specialCharacterValidator(),
      ]),
      sendEmailNotification: new UntypedFormControl(false),
    });

    this._assetVerificationService.checkAssetRetention(
      this.files,
      this.form.get('validTo').value
    );
    this.form.valueChanges.subscribe((data: any) => this.onFormValueChanged(data));
    this.setAuthMode();

    this._assetVerificationService.shortestRetentionDate$
      .pipe(untilDestroyed(this))
      .subscribe((date: Dayjs) => {
        this.purgeDate = date;
      });
  }

  onFormValueChanged(data: ICreatePackageForm): void {
    if (this.packageName !== data.name) {
      this.packageName = data.name.trim().length === 0 ? '' : data.name;
      this.form.controls.name.setValue(this.packageName);
    }

    if (this._formValue && data.validTo !== this._formValue?.validTo) {
      this.onValidToChanged(data.validTo);
      this._assetVerificationService.checkAssetRetention(this.files, data.validTo);
    }

    this._formValue = data;
  }

  onValidToChanged(expirationDate: Dayjs): void {
    this._assetVerificationService.checkAssetRetention(this.files, expirationDate);
  }

  setAuthMode() {
    if (!this.authMode) {
      this.form.controls.authMode.setValue('');
    } else if (!this.officers.length) {
      this.authMode = false;
      this.form.controls.authMode.setValue('');
      this.isEmptyAgency = true;
    } else if (this.officers.length) {
      this.isEmptyAgency = false;
    }
  }

  open(): void {
    if (!this.modalRef) {
      this.fetchRecipients();
      this.fetchFeatureFlags();
      this.modalRef = this._modalService.open(this.createPackageTemplate, this.config);
      this._formValue = this.form.value;
      this._assetVerificationService.checkAssetRetention(
        this.files,
        this._formValue.validTo
      );

      if (!this._systemService.isProd()) {
        setTimeout(() => {
          (window as any).PP_DEBUG_CREATE_PACKAGE = {
            authMode: this.authMode,
            form: this.form,
            officers: this.officers,
            self: this,
          };
        });
      }
    }
  }

  @HostListener('window:popstate', ['$event'])
  @ApmTransactionMethod('close create package modal')
  close(): void {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = undefined;
      this._assetVerificationService.resetAssetVerifications();
      this.cancel.emit();
    }
  }

  onChangeAuthMode(e): void {
    this.authMode = !!e.value;
  }

  onChangeSubject(e): void {
    this.customSubject = !!e.value;
  }

  onClickNotifications(): void {
    this.sendEmailNotification = !this.sendEmailNotification;
  }

  onCancel(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.close();
  }

  onUpdateSelectedProsecutors(prosecutors: TPros[]): void {
    this.form.controls.to.setValue(prosecutors);
  }

  async onSubmit(e?: Event): Promise<void> {
    if (this.retrying) {
      return;
    }

    const authmode: string = this.authMode ? 'authenticated' : 'unauthenticated';
    if (authmode === 'unauthenticated') {
      this._elasticApmService.track_number(
        'number of people to receive unauth package',
        this.form.value.to.length
      );
    }

    this.sharing = true;
    this.send.emit(true);
    e?.preventDefault();
    e?.stopPropagation();

    const toastOptions: Record<string, any> = {
      closeButton: true,
      autoDismiss: this._settings.get<number>('AUTODISMISS'),
    };

    const data: ICreatePackageForm = {
      name: this.form.value.name.trim(),
      authMode: this.authMode,
      subject: this.form.value.subject,
      validTo: this.form.value.validTo,
      to: this.form.value.to,
      password: this.form.value.password,
      sendEmailNotification: this.form.value.sendEmailNotification,
    };

    let pkg: IPackage;

    try {
      pkg = await this._packageService.create(
        data,
        this.files,
        this.content,
        this.fullContent,
        this.hasOverview
      );
    } catch (err) {
      const toastMessage = this._transloco.translate(toastMessages.error);
      this._toastService.error(toastMessage, void 0, toastOptions);
      this.sharing = false;
      return;
    }
    const message = this._transloco.translate(toastMessages.success);
    this._elasticApmService.track(`send created package in ${authmode} mode`);
    this._elasticApmService.track(`Package valid for ${this.form.value.validTo}`);

    this._toastService.success(message, void 0, toastOptions);
    this.close();

    this.sharing = false;
    this.send.emit(false);
    this.done.emit(pkg);
  }

  private fetchRecipients(): void {
    this._officerService
      .fetchOfficers()
      .pipe(untilDestroyed(this))
      .subscribe((officers: Officer[]) => {
        this.officers = officers;
        this.authMode = this.officers.length > 0;
        this.setAuthMode();
      });
  }

  private fetchFeatureFlags(): void {
    this._store
      .select(getFeatureFlag('password-protected-unauth-pkg'))
      .subscribe((value: boolean) => {
        this.enablePasswordProtectionForUnauthPkg = value;
      });

    this._store
      .select(getFeatureFlag('selecting-date-with-calendar'))
      .subscribe((value: boolean) => {
        this.enableSelectingDateWithCalendar = value;
        if (this.enableSelectingDateWithCalendar) {
          this.form.controls.validTo.setValue(null);
        }
      });
  }
}
