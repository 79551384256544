/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Chip } from '@msi/cobalt';
import { Observable } from 'rxjs';

import { settings } from '../../config/Settings';
import { IShareableAsset } from '../../models/share-data.models';
import {
  EPackageAccessType,
  EPackageVersion,
  ICreatePackage,
  ICreatePackageForm,
  ICreatePackageHeader,
  IPackageFile,
  IPackageResponse,
} from '../../models/share-data.models';
import { DatePickerService } from '../date-picker/date-picker.service';

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  constructor(
    private http: HttpClient,
    private datePickerService: DatePickerService
  ) {}

  create(
    packageForm: ICreatePackageForm,
    assets: IShareableAsset[]
  ): Observable<IPackageResponse> {
    const packageHeaders = this._preparePackageHeaders(packageForm);
    const files = this._preparePackageFiles(assets);
    const pkg: ICreatePackage = {
      header: packageHeaders,
      files,
    };
    const url = settings.getUrl('CREATE_PACKAGE');
    return this.http.post<IPackageResponse>(url, pkg);
  }

  private _preparePackageHeaders(packageForm: ICreatePackageForm): ICreatePackageHeader {
    const headers: ICreatePackageHeader = {
      title: packageForm.name,
      validTo:
        packageForm.validTo === 'Never'
          ? this.datePickerService.formatNeverDate()
          : packageForm.validTo,
      accessType: EPackageAccessType.AUTH,
      packageVersion: EPackageVersion.Version_2,
    };

    if (packageForm.authMode) {
      headers.sharedWithAuthenticated = this._preparePackageRecipients(
        packageForm.authMode,
        packageForm.to
      ) as Chip[];
    } else {
      headers.accessType = EPackageAccessType.GUEST;
      headers.sharedWith = this._preparePackageRecipients(
        packageForm.authMode,
        packageForm.to
      ) as string[];
      headers.passwordCreationData = {
        password: packageForm.password,
        sendPassword: packageForm.sendEmailNotification,
      };
    }

    return headers;
  }

  private _preparePackageRecipients(
    accessType: boolean,
    recipients: (string | Chip)[]
  ): (string | Chip)[] {
    return accessType
      ? recipients
      : recipients.map((recipient: Chip) =>
          recipient.email ? recipient.email : recipient.value
        );
  }

  private _preparePackageFiles(assets: IShareableAsset[]): IPackageFile[] {
    return assets.map((asset: IShareableAsset) => ({
      fileId: asset.fileId,
      fileAgency: (asset as any).agencyId || undefined,
      derivedItemId: asset.derivedItemId || undefined,
      derivedItemType: asset.derivedItemType || undefined,
    }));
  }
}
