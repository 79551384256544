/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { HeaderTokenService } from '@msi/commandcentral-common-header';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, first, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { IFeatureFlags } from '../../../models/feature-flags/feature-flags.model';
import { FeatureFlagsService } from '../../../services/feature-flags/feature-flags.service';
import {
  loadFeatureFlags,
  loadFeatureFlagsFailure,
  setFeatureFlags,
} from '../actions/feature-flags.actions';

@Injectable()
export class FeatureFlagsEffects {
  constructor(
    private _appActions$: Actions,
    private _featureFlagsService: FeatureFlagsService,
    private _headerTokenService: HeaderTokenService,
    public _store: Store
  ) {}

  public loadFeatureFlags$ = createEffect(() =>
    this._appActions$.pipe(
      ofType(loadFeatureFlags),
      switchMap(() =>
        this._headerTokenService.tokenReady.pipe(
          first((tokenReady) => tokenReady),
          switchMap(() =>
            this._featureFlagsService.getFeatureFlags().pipe(
              map((response) => {
                const featureFlags: IFeatureFlags = {};
                response.featureFlags.map((flag) => {
                  featureFlags[flag.flagName] = flag.enabled;
                });
                return setFeatureFlags({ flags: featureFlags });
              }),
              catchError(() => of(loadFeatureFlagsFailure()))
            )
          )
        )
      )
    )
  );
}
