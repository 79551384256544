/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { Api, IResponse, Settings, Utils } from '@msi/js-sdk';

import { ISettings } from '../../settings/settings.interface';
import { ChainOfCustody } from '../package/classes/chainOfCustody/ChainOfCustody';
import { IChainOfCustody } from '../package/classes/chainOfCustody/ChainOfCustody.interfaces';
import {
  IChainOfCustodies,
  IChainOfCustodyResponse,
} from './chain-of-custody.interfaces';

@Injectable()
export class ChainOfCustodyService {
  constructor(
    private _settings: Settings<ISettings>,
    private _api: Api
  ) {}

  async getByIds(ids: string[]): Promise<IChainOfCustodies> {
    const entity = 'chainofcustody';
    const domain: string = this._settings.get<string>('PLATFORM.API' as any);
    const link: string = this._settings.get<string>('SEARCH_API_ENTITY_ENDPOINT');
    const url: string = domain + Utils.format(link, { entity });

    const chainOfCustodies: IChainOfCustodies = {};

    const promises: Promise<void>[] = ids.map(async (id: string): Promise<void> => {
      const body: Record<string, string> = { query: `propertyItemId='${id}'` };

      let response: IResponse<IChainOfCustodyResponse>;

      try {
        response = await this._api.post<IChainOfCustodyResponse, Record<string, string>>(
          url,
          body
        );
      } catch (err) {
        throw err;
      }

      if (response.data?.items?.length) {
        chainOfCustodies[id] = response.data.items.map(
          (item: IChainOfCustody) => new ChainOfCustody(item)
        );
      }
    });

    await Promise.all(promises);

    return chainOfCustodies;
  }
}
