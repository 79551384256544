<ng-template
  #downloadModal
  let-modal>
  <div class="pp-downloadmanager-modal">
    <msi-pop-up-modal-header>
      <msi-pop-up-modal-header-title
        (click)="modal.toggleMinimized()"
        [style.height.px]="24"
        >{{ 'Download Manager' | transloco }}</msi-pop-up-modal-header-title
      >
      <msi-pop-up-modal-header-buttons>
        <button
          type="button"
          class="msi-btn msi-btn-action msi-btn-compact"
          (click)="modal.toggleMinimized()"
          [style.height.px]="24">
          <msi-icon
            [name]="modal.minimized ? 'ic_arrow_up' : 'ic_arrow_down'"
            size="24"
            color="white"></msi-icon>
        </button>
        <button
          type="button"
          [disabled]="!streamingMetadataLoaded"
          class="msi-btn msi-btn-action msi-btn-compact"
          (click)="onClose()"
          [style.height.px]="24">
          <msi-icon
            name="ic_remove"
            size="24"
            color="white"></msi-icon>
        </button>
      </msi-pop-up-modal-header-buttons>
    </msi-pop-up-modal-header>
    <msi-pop-up-modal-content class="pp-downloadmanager-wrapper">
      <div
        class="pp-downloadmanager-content"
        [class.metadataLoading]="!streamingMetadataLoaded">
        <div
          *ngIf="!streamingMetadataLoaded"
          class="pp-downloadmanager-content__loader">
          <msi-spinner size="medium"></msi-spinner>
        </div>
        <pp-download-item
          *ngFor="let item of downloadItems"
          [item]="item"
          (action)="onAction($event)"></pp-download-item>
      </div>
    </msi-pop-up-modal-content>
  </div>
</ng-template>

<pp-download-manager-confirm-close #downloadManagerConfirm></pp-download-manager-confirm-close>
