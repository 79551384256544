/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Pipe, PipeTransform } from '@angular/core';

import { IShareableAsset } from '../../models/share-data.models';

@Pipe({
  name: 'ownerDisplayName',
})
export class OwnerDisplayNamePipe implements PipeTransform {
  transform(asset: IShareableAsset, userUnknown: string): string {
    return asset.ownerDisplayName && asset.ownerDisplayName !== 'Unknown'
      ? asset.ownerDisplayName
      : userUnknown;
  }
}
