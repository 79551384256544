/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AssetEntity } from '@msi/emm-sdk';
import { DateTimeUtils } from '@msi/js-sdk';

import { IAddress } from '../address/Address.interfaces';
import { ChainOfCustody } from '../chainOfCustody/ChainOfCustody';
import { IChainOfCustody } from '../chainOfCustody/ChainOfCustody.interfaces';
import { IPhone } from '../phone/Phone.interfaces';
import { IPerson } from './Person.interfaces';

class Person {
  private _props: IPerson = {};
  private _defaultThumb = 'assets/icons/ic_contacts_people.svg';

  public isDefaultThumb = true;
  public hasChainOfCustody = false;
  public thumb = '';
  public fullName = '';
  public kind = '';
  public dob = '';
  public location = '';
  public aliases: string[] = [];
  public scarsMarksTattoos: string[] = [];
  public age = '';

  public driverLicense = '';
  public expirationDate = '';
  public ssn = '';
  public stateId = '';
  public localId = '';

  public fbiNumber = '';
  public race = '';
  public ethnicity = '';
  public sex = '';

  public eyes = '';
  public hair = '';
  public height = '';
  public weight = '';

  public phones: IPhone[] = [];
  public chainOfCustody: ChainOfCustody[] = [];

  constructor(props: IPerson) {
    this._props = props;

    this.age = this._props.age || '';
    this.thumb = this._defaultThumb;
    this.fullName = this.getFullName();
    this.kind = this.getKind();
    this.dob = this.getDateOfBirthday();
    this.location = this.getLocation();
    this.aliases = this.getAliases();
    this.scarsMarksTattoos = this.getScarsMarksTattoos();
    this.driverLicense = this._props.driverLicense?.number || '';
    this.expirationDate = this._props.driverLicense?.expirationDate || '';
    this.ssn = this._props.socialSecurityNumber || '';

    this.localId = this.getLocalId();
    this.stateId = this._props.stateId || '';
    this.fbiNumber = this._props.fbiNumber || '';

    this.race = this._props.race || '';
    this.ethnicity = this._props.ethnicity || '';
    this.sex = this._props.sex || '';

    this.eyes = this._props.eyeColor || '';
    this.hair = this._props.hairColor || '';
    this.height = this._props.heightInInches
      ? this._props.heightInInches.toString() + '"'
      : '';
    this.weight = this._props.weightInPounds
      ? this._props.weightInPounds.toString() + 'lbs'
      : '';

    if (this._props.phones && Array.isArray(this._props.phones)) {
      this.phones = [...this._props.phones];
    }
  }

  get(name: keyof IPerson): IPerson[keyof IPerson] {
    return this._props[name];
  }

  getId(): string {
    return this._props.id;
  }

  getFullName(): string {
    return (
      this._props.lastName + ', ' + this._props.firstName + ' ' + this._props.middleName
    );
  }

  getKind(): string {
    if (this._props.incidentContext?.hasOwnProperty('victim')) {
      return 'Victim';
    } else if (this._props.incidentContext?.hasOwnProperty('offender')) {
      return 'Offender';
    } else if (this._props.incidentContext?.hasOwnProperty('otherRelationships')) {
      const otherRelationships = this._props.incidentContext.otherRelationships;

      if (Array.isArray(otherRelationships) && otherRelationships[0]) {
        return otherRelationships[0].relationship || 'Unknown';
      } else {
        return 'Unknown';
      }
    } else {
      return 'Unknown';
    }
  }

  getDateOfBirthday(): string {
    const date = DateTimeUtils.format(this._props.birthDate);

    return this.age ? `${date} (${this.age})` : date;
  }

  getLocation(): string {
    if (this._props.locations && this._props.locations[0]) {
      const address: IAddress = this._props.locations[0].address;

      if (address) {
        return [
          address.streetFullText || '',
          address.city || '',
          address.state || '',
          address.zip || '',
        ]
          .filter((el) => el)
          .join('. ');
      }
    }

    return '';
  }

  getLocalId(): string {
    if (
      this._props.otherIds &&
      this._props.otherIds[0] &&
      this._props.otherIds[0].value
    ) {
      return this._props.otherIds[0].value;
    } else {
      return '';
    }
  }

  getAliases(): string[] {
    if (this._props.monikers && Array.isArray(this._props.monikers)) {
      return [...this._props.monikers];
    } else {
      return [];
    }
  }

  getScarsMarksTattoos(): string[] {
    if (this._props.scarsMarksTattoos) {
      this._props.scarsMarksTattoos.forEach((item) => item.commentText);
    } else {
      return [];
    }
  }

  setChainOfCustody(chainOfCustody: IChainOfCustody[]): void {
    this.chainOfCustody = chainOfCustody.map(
      (item: IChainOfCustody) => new ChainOfCustody(item)
    );

    if (this.chainOfCustody.length) {
      this.hasChainOfCustody = true;
    }
  }

  async setAssetEntity(assetEntityPromise: Promise<AssetEntity>): Promise<void> {
    const assetEnity: AssetEntity = await assetEntityPromise;

    if (assetEnity) {
      this.isDefaultThumb = false;
      this.thumb = assetEnity.getThumb();
    }
  }
}

export { Person };
