/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { EActionControls } from '../../components/common/action-controls/action-controls.enums';
import { DigitalEvidenceFilterOptionService } from '../digital-evidence-filter-options/digital-evidence-filter-options.service';
import { PackageItem } from '../package/classes/package/PackageItem';
import { IPrintOptions } from './print.interfaces';

@Injectable()
export class PrintService {
  private readonly _onBeforePrintListener: (...args: any[]) => any;
  private readonly _onAfterPrintListener: (...args: any[]) => any;
  private readonly defaultPrintOptions: IPrintOptions = {
    printEnabled: false,
    printPreviewEnabled: false,
  };

  ctrlKeyActive = false;
  printKeyActive = false;
  pkgItem: PackageItem;
  lang: any;
  printModeSubject = new BehaviorSubject<boolean>(false);
  isPrintModeEnabled$: Observable<boolean>;
  printOptionSubject = new BehaviorSubject<IPrintOptions>(this.defaultPrintOptions);
  isPrintOptionEnabled$: Observable<IPrintOptions>;

  constructor(
    private _digitalEvidenceFilterOptionService: DigitalEvidenceFilterOptionService
  ) {
    this.isPrintModeEnabled$ = this.printModeSubject.asObservable();
    this.isPrintOptionEnabled$ = this.printOptionSubject.asObservable();
    this._onBeforePrintListener = (e: KeyboardEvent) => this.onBeforePrint(e);
    this._onAfterPrintListener = () => this.onAfterPrint();
    window.addEventListener('afterprint', this._onAfterPrintListener);
    window.addEventListener('keydown', this._onBeforePrintListener);
  }

  onBeforePrint(e: KeyboardEvent): void {
    this._onButtonChanges(e);
    this._onPrintReady(e);
  }

  onAfterPrint(): void {
    this.setPrintOption(false);
    this._digitalEvidenceFilterOptionService.expandCollapsedSections(false);
  }

  print(): void {
    this.printModeSubject.next(true);
    this.setPrintOption(true);
    this._digitalEvidenceFilterOptionService.expandCollapsedSections(true);
    setTimeout(() => window.print());
  }

  setPrintOptions(printInfo: IPrintOptions): void {
    this.printOptionSubject.next(printInfo);
  }

  setPrintOption(
    status: boolean,
    printOption: EActionControls = EActionControls.PRINT
  ): void {
    const curretPrintOptions: IPrintOptions = this._getPrintOptions();
    let updatedProntOptions: IPrintOptions;

    if (printOption === EActionControls.PRINT_PREVIEW) {
      updatedProntOptions = {
        ...curretPrintOptions,
        printPreviewEnabled: status,
      };
    } else {
      updatedProntOptions = {
        ...curretPrintOptions,
        printEnabled: status,
      };
    }

    this.setPrintOptions(updatedProntOptions);
  }

  private _getPrintOptions(): IPrintOptions {
    return this.printOptionSubject.getValue();
  }

  private _onButtonChanges(e: KeyboardEvent): void {
    if (e.key === 'Control' || e.key === 'Command') {
      this.ctrlKeyActive = true;
      window.setTimeout(() => (this.ctrlKeyActive = false), 300);
    }

    if (e.key.toLocaleLowerCase() === 'p' && e.ctrlKey) {
      this.printKeyActive = true;
    }
  }

  private async _onPrintReady(e: KeyboardEvent): Promise<void> {
    if (this._isKeybordPrintOption()) {
      e.preventDefault();
      e.stopPropagation();

      setTimeout(() => {
        this.print();
        this._resetKeysValue();
      });
    }
  }

  private _resetKeysValue(): void {
    this.ctrlKeyActive = false;
    this.printKeyActive = false;
  }

  private _isKeybordPrintOption(): boolean {
    const keybordPrintshortcut = this.ctrlKeyActive && this.printKeyActive;
    if (keybordPrintshortcut) {
      this.printModeSubject.next(true);
    }
    return keybordPrintshortcut;
  }
}
