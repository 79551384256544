/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AssetEntity } from '@msi/emm-sdk';
import { Store } from '@msi/js-sdk';

import { IEmptyAssetEntity } from '../../services/asset-entity/asset-entity.interface';
import { PackageItem } from '../../services/package/classes/package/PackageItem';
import { IPackage } from '../../services/package/package.interfaces';
import { IDetailPackageStore } from './detail-package.interfaces';

class DetailPackageLocalStore
  extends Store<IDetailPackageStore>
  implements IDetailPackageStore
{
  pkgItem: PackageItem;
  pkg: IPackage;
  loading: boolean;
  assetsLoading: boolean;
  assetsLoaded: boolean;
  imgAssetsLoaded: boolean;
  assetEntities: (AssetEntity | IEmptyAssetEntity)[];
  fullPackageAssetsLen: number;
  thumbAssetsCount: number;
}

const detailPackageLocalStore = new DetailPackageLocalStore({
  pkgItem: null,
  pkg: null,
  loading: false,
  assetsLoading: false,
  assetsLoaded: false,
  imgAssetsLoaded: false,
  assetEntities: [],
  fullPackageAssetsLen: 0,
  thumbAssetsCount: 0,
});

export { detailPackageLocalStore };
