/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { EShareModel, IShareDataConfig } from '../models/share-data.models';
import { environments } from './environments';
import { IShareEnvironment } from './models/config.interfaces';

class SharingUrls {
  BASE_URL: string;
  PROSECUTORS: string;
  CREATE_PACKAGE: string;
  FEATURE_FLAGS: string;

  constructor(baseUrl: string) {
    this.BASE_URL = baseUrl;
    this.PROSECUTORS = `${baseUrl}/recipients/prosecutors`;
    this.CREATE_PACKAGE = `${baseUrl}/packages-v2`;
    this.FEATURE_FLAGS = `${baseUrl}/configuration/flags`;
  }
}

export class Settings {
  private urls: SharingUrls;
  shareModel: EShareModel = EShareModel.Evidence;
  agencyId: string;

  getUrl(name: keyof SharingUrls): string {
    return this.urls[name];
  }

  initSettings(shareConfig: IShareDataConfig): void {
    this.shareModel = shareConfig.shareModel;
    this._setAppEnvironment();
  }

  setAgencyId(agencyId: string): void {
    this.agencyId = agencyId;
  }

  private _getEnvDomain(host: string): string {
    const prefix = host.includes('-preprod.sec.commandcentral.ca') ? '-preprod.' : '';

    return `${prefix}${host.split('.').splice(1).join('.')}`;
  }

  private _setAppEnvironment(): void {
    const envDomain = this._getEnvDomain(window.location.host);
    let selectedEnv = environments.find((environment: IShareEnvironment) => {
      return environment.domain.includes(envDomain);
    });

    if (!selectedEnv) {
      selectedEnv = this._getDefaultEnv();
    }
    this.urls = new SharingUrls(selectedEnv.baseUrl);
  }

  private _getDefaultEnv(): IShareEnvironment {
    return environments[0];
  }
}

export const settings = new Settings();
