/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
export enum ETransactionTypes {
  COMPONENT = 'component',
  SERVICE = 'service',
  DIRECTIVE = 'directive',
  MODULE = 'module',
  UTILS = 'utils',
  METHOD = 'method',
  ACTION = 'action',
  E2E = 'e2e',
}

export enum ESpanTypes {
  COMPONENT_LOAD = 'component.load',
  API_REQUEST = 'api.request',
  CALL_METHOD = 'call.method',
}

export enum EMethodTypes {
  ON_ACTION = 'onAction',
  ON_SHARE = 'onShare',
  ON_SCROLL_TO = 'onScrollTo',
  ON_CLICK = 'onClick',
  ON_CLICK_ACTION_MENU = 'onClickActionMenu',
  ON_VALID_TO_CHANGED = 'onValidToChanged',
  ON_AUTH_MODE_CHANGED = 'onAuthModeChanged',
  ON_CANCEL = 'onCancel',
  ON_DOWNLOAD = 'onDownload',
  ON_SUBMIT = 'onSubmit',
  ON_ADD = 'onAdd',
  ON_SORT = 'onSort',
}
