/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IShareableAsset } from '../../../models/share-data.models';
import { EShareInfoProperty } from '../../../models/share-data.models';
import { ShareDataService } from '../../../services/share-data/share-data.service';

@UntilDestroy()
@Component({
  selector: 'ccs-full-screen-modal-content',
  templateUrl: './full-screen-modal-content.component.html',
  styleUrls: ['./full-screen-modal-content.component.scss'],
})
export class FullScreenModalContentComponent implements OnInit {
  @Input()
  assets: IShareableAsset[];

  @Input()
  enableContentProjection = false;

  @Input()
  enablePasswordProtectionForUnauthPkg = false;

  @Input()
  assetsLoading = true;

  @Input()
  shortestRetentionTime: string;

  @Output()
  assetRemoved: EventEmitter<IShareableAsset> = new EventEmitter();

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  readonly shareInfoPropEnum = EShareInfoProperty;
  reviewFilesTabActive: boolean;
  activeAsset: IShareableAsset;
  isGeneratedPasswordActive: false;

  constructor(private _shareDataService: ShareDataService) {}

  ngOnInit(): void {
    this._shareDataService.reviewFilesActive$
      .pipe(untilDestroyed(this))
      .subscribe((active: boolean) => {
        this.reviewFilesTabActive = active;
      });
    this._shareDataService.activeAsset$
      .pipe(untilDestroyed(this))
      .subscribe((asset: IShareableAsset) => (this.activeAsset = asset));
  }

  onResetAsset(e: Event): void {
    e.preventDefault();

    if (this.activeAsset) {
      this._shareDataService.resetActiveAsset();
    }
  }
}
