<div
  *ngIf="chainOfCustody && chainOfCustody.length"
  class="pp-detail-package-chainofcustody">
  <table
    class="pp-detail-package-chainofcustody__table"
    msi-table
    [class.msi-table-condensed]="condensed">
    <thead class="pp-detail-package-chainofcustody__table-header">
      <tr>
        <th
          msi-table-header
          sortable
          (sort)="sortBy('name', $event)">
          {{ 'Date' | transloco }}
        </th>
        <th
          msi-table-header
          sortable
          (sort)="sortBy('desc', $event)">
          {{ 'Type' | transloco }}
        </th>
        <th
          msi-table-header
          sortable
          (sort)="sortBy('desc', $event)">
          {{ 'Whom to / from' | transloco }}
        </th>
        <th
          msi-table-header
          sortable
          (sort)="sortBy('desc', $event)">
          {{ 'Custodian' | transloco }}
        </th>
        <th
          msi-table-header
          sortable
          (sort)="sortBy('time', $event)">
          {{ 'Reason' | transloco }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of chainOfCustody">
        <tr *ngIf="displayRow(row)">
          <td>{{ row.date }}</td>
          <td>{{ row.type }}</td>
          <td>{{ row.from }}</td>
          <td>{{ row.custodian }}</td>
          <td>{{ row.reason }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
