/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';

import { settings } from '../../config/Settings';
import { IShareDataConfig } from '../../models/share-data.models';

@Injectable({
  providedIn: 'root',
})
export class ShareSettingsService {
  accessToken: string;

  initSettings(shareConfig: IShareDataConfig): void {
    settings.initSettings(shareConfig);
  }

  setAgencyId(agencyId: string): void {
    settings.setAgencyId(agencyId);
  }

  setAccessToken(token: string): void {
    this.accessToken = token;
  }
}
