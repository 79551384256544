/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastService } from '@msi/cobalt';
import { AssetEntity, emm } from '@msi/emm-sdk';
import { Connect } from '@msi/js-sdk';
import { EmmAssetViewerService, MsiEmmAssetViewerComponent } from '@msi/msi-asset-viewer';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';

import { DigitalEvidenceFilterOptionService } from '../../../services/digital-evidence-filter-options/digital-evidence-filter-options.service';
import {
  EDetailPackageMode,
  EPackageVersionType,
} from '../../../services/package/package.enums';
import { PrintService } from '../../../services/print/print.service';
import { IDetailPackageStore } from '../../../stores/detail-package/detail-package.interfaces';
import { detailPackageLocalStore } from '../../../stores/detail-package/detail-package.store';

@UntilDestroy()
@Component({
  selector: 'pp-detail-package-assets',
  templateUrl: './detail-package-assets.component.html',
  styleUrls: ['./detail-package-assets.component.scss', '../detail-package-print.scss'],
})
export class DetailPackageAssetsComponent implements OnInit, OnDestroy {
  @Input()
  mode: EDetailPackageMode;

  @Input()
  shouldDisplayList: Observable<boolean>;

  @Input()
  hasFiles: boolean;

  @Input()
  printOptionEnabled: boolean;

  @Input()
  packageVersion: EPackageVersionType;

  @Output()
  download: EventEmitter<string> = new EventEmitter();

  @Connect<IDetailPackageStore>('assetEntities', detailPackageLocalStore)
  assetEntities: AssetEntity[];

  @Connect<IDetailPackageStore>('assetsLoading', detailPackageLocalStore)
  assetsLoading: boolean;

  @Connect<IDetailPackageStore>('assetsLoaded', detailPackageLocalStore)
  assetsLoaded: boolean;

  @Connect<IDetailPackageStore>('fullPackageAssetsLen', detailPackageLocalStore)
  fullPackageAssetsLen: number;

  @ViewChild('assetViewerPopup', { read: MsiEmmAssetViewerComponent, static: false })
  assetViewerPopup: MsiEmmAssetViewerComponent;

  isReady = false;
  detailPackageMode = EDetailPackageMode;
  useGalleryGrid = true;
  groupedAssets: Map<string, AssetEntity[]>;
  collapsedAssetsSection: any;

  private _closeAssetViewer: Subscription;

  constructor(
    private toastService: ToastService,
    private printService: PrintService,
    private assetViewerService: EmmAssetViewerService,
    private _transloco: TranslocoService,
    private _digitalEvidenceFilterOptionService: DigitalEvidenceFilterOptionService
  ) {}

  async ngOnInit(): Promise<void> {
    this._closeAssetViewer = this.assetViewerService
      .observeCloseAssetViewer()
      .subscribe(() => setTimeout(async () => await this.updateAssetViewerCache()));

    if (this?.assetEntities) {
      await this.onChange();
    }

    this.shouldDisplayList.pipe(untilDestroyed(this)).subscribe((shouldDisplayList) => {
      this.useGalleryGrid = !shouldDisplayList;
    });

    this.printService.isPrintModeEnabled$
      .pipe(untilDestroyed(this))
      .subscribe((printMode: boolean) => {
        this.useGalleryGrid = !printMode;
      });

    this._digitalEvidenceFilterOptionService.groupedAssets$
      .pipe(untilDestroyed(this))
      .subscribe((assets: Map<string, AssetEntity[]>) => {
        this.groupedAssets = assets;
      });

    this._digitalEvidenceFilterOptionService.collapsedAssetsSection$
      .pipe(untilDestroyed(this))
      .subscribe((collapsedSections: any) => {
        this.collapsedAssetsSection = collapsedSections;
      });
  }

  ngOnDestroy(): void {
    this._closeAssetViewer.unsubscribe();
    detailPackageLocalStore.assetEntities = [];
  }

  @Connect<IDetailPackageStore>('assetEntities', detailPackageLocalStore)
  async onChange(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    if (this.assetEntities) {
      await this.updateAssetViewerCache();
    }

    if (this.assetEntities.length === this.fullPackageAssetsLen) {
      this.isReady = true;
    }
  }

  // Set assetEntities to AssetViewer cache to prevent sending the same second request
  // from AssetViewer that always is finished by error
  // for now, it is a dirty hack
  async updateAssetViewerCache(): Promise<void> {
    const assetViewer: any = emm.getAssetViewer();

    await assetViewer.cache.clear();

    await Promise.all(
      this.assetEntities.map((assetEntity: any) => {
        assetViewer.cache.set(assetEntity.fileId, assetEntity);
      })
    );
  }

  async onClick(assetEntity: AssetEntity): Promise<void> {
    if (assetEntity instanceof AssetEntity && this.assetsLoaded) {
      if (assetEntity.isPurged()) {
        this.toastService.error(
          this._transloco.translate('File was purged or removed'),
          undefined,
          { closeButton: true }
        );
      } else {
        const assetId = (assetEntity as any).derivedItemId
          ? (assetEntity as any).derivedItemId
          : assetEntity.fileId;
        this.assetViewerPopup.open(assetId);
        this.assetViewerService.setAssetsLoading(false);
      }
    }
  }

  onDownload(downloadType: string): void {
    this.download.emit(downloadType);
  }

  changeGridView(isGridViewPicked: boolean): void {
    this.useGalleryGrid = isGridViewPicked;
  }

  toggleSection(sectionKey: string): void {
    this._digitalEvidenceFilterOptionService.toggleCollapsedSections(sectionKey);
  }
}
