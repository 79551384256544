<ng-container *ngIf="packageLoadingError$ | async as error">
  <pp-error-block [statusCode]="error?.status"></pp-error-block>
</ng-container>

<div
  class="pp-detail-package d-flex align-items-center justify-content-center"
  *ngIf="packageLoading$ | async">
  <msi-spinner
    class="pp-detail-package-loader"
    size="large"></msi-spinner>
</div>

<ng-container *ngIf="(packageLoading$ | async) === false">
  <div
    *ngIf="pkg$; async as pkg"
    class="pp-detail-package"
    [class.error-displayed]="packageLoadingError$ | async"
    [class.print-preview-wrapper]="isPrintPreviewOn$ | async">
    <div
      class="pp-detail-package-wrapper"
      [class.print-preview]="isPrintPreviewOn$ | async">
      <div class="pp-detail-package-wrapper__header">
        <div
          *ngIf="isPrintPreviewOn$ | async"
          class="pp-detail-package-wrapper__print-preview-icon">
          <msi-icon
            [size]="24"
            name="ic_emsignia"></msi-icon>
          <div class="separator"></div>
          <h5 class="pp-detail-package-wrapper__title">{{ headerTitle }}</h5>
        </div>
        <ng-container *ngIf="pkg$ | async as pkg">
          <ng-container *ngIf="packageItem$ | async as packageItem">
            <pp-detail-package-header
              [mode]="mode"
              [headerTitle]="headerTitle"
              [pkg]="pkg"
              [packageItem]="packageItem"
              [isPackageOwnerPD]="isPackageOwnerPD"
              (send)="onReshareDone()"
              (enablePrintPreview)="handlePrintPreview($event)"
              (enablePrint)="handlePrint($event)">
            </pp-detail-package-header>
          </ng-container>
          <pp-detail-package-menu
            *ngIf="pkg.header.packageVersion === 'v1'"
            class="pp-nav-menu-wrapper"
            [pkg]="pkg"></pp-detail-package-menu>
        </ng-container>
      </div>
      <div
        class="pp-detail-package-wrapper__content"
        msiScrollSpyContainer
        [smoothScroll]="true">
        <ng-container *ngIf="pkg$ | async as pkg">
          <pp-detail-package-overview
            [pkg]="pkg"
            [msiScrollSpyArea]="'overview'">
          </pp-detail-package-overview>
          <pp-detail-package-narrative
            [pkg]="pkg"
            [msiScrollSpyArea]="'narrative'">
          </pp-detail-package-narrative>
          <pp-detail-package-persons
            [pkg]="pkg"
            [msiScrollSpyArea]="'persons'">
          </pp-detail-package-persons>
          <pp-detail-package-organizations
            [pkg]="pkg"
            [msiScrollSpyArea]="'organizations'">
          </pp-detail-package-organizations>
          <pp-detail-package-vehicle
            [pkg]="pkg"
            [msiScrollSpyArea]="'vehicles'">
          </pp-detail-package-vehicle>
          <pp-detail-package-properties
            [pkg]="pkg"
            [msiScrollSpyArea]="'property'">
          </pp-detail-package-properties>
          <pp-detail-package-assets
            [packageVersion]="pkg.header.packageVersion"
            [msiScrollSpyArea]="'assets'"
            [mode]="mode"
            [hasFiles]="!!pkg.files.length"
            [shouldDisplayList]="shouldDisplayAssetsInList$"
            [printOptionEnabled]="printOptionEnabled"
            (download)="onDownload($event)"
            (passElementPosition)="updateFocusedField($event)">
          </pp-detail-package-assets>
          <pp-download-manager-modal
            #downloadManagerModal
            [streamingMetadataLoaded]="streamingMetadataLoaded"
            (action)="onAction($event)"
            (resetDownloadSpinner)="onResetDownloadSpinner()">
          </pp-download-manager-modal>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<pp-detail-package-unsubscribe
  *ngIf="!isPackageOwnerPD"
  #detailPackageUnsubscribe
  (confirm)="onUnsubscribe()">
</pp-detail-package-unsubscribe>
