/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DropdownComponent } from '@msi/cobalt';

import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../../services/elastic-apm/elastic-apm.decorators';
import { EDetailPackageMode } from '../../../services/package/package.enums';
import { IPackage } from '../../../services/package/package.interfaces';
import { PackageService } from '../../../services/package/package.service';

@Component({
  selector: 'pp-detail-package-subscribe',
  templateUrl: './detail-package-subscribe.component.html',
  styleUrls: [
    './detail-package-subscribe.component.scss',
    '../detail-package-print.scss',
  ],
})
@ApmTransactionComponent('subscription modal')
export class DetailPackageSubscribeComponent implements OnInit {
  @Input()
  mode: EDetailPackageMode = EDetailPackageMode.GUESTS;

  @Input()
  pkg: IPackage;

  @ViewChild('subscribeDropdown', { static: false })
  subscribeDropdown: DropdownComponent;

  subscribed = false;
  subscribing = false;
  error = '';
  form: UntypedFormGroup;

  constructor(private _packageService: PackageService) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
  }

  onClick(): void {
    this.error = '';
    this.subscribed = false;
    this.form.reset({ email: '' });

    setTimeout(() => {
      const list: HTMLCollectionOf<Element> = document.getElementsByClassName(
        'pp-detail-package-subscribe__dropdown'
      );

      if (list.length && list[1]) {
        const $el = list[1].parentElement;

        $el.style.width = '300px';
      }
    });
  }

  onClose(): void {
    this.subscribeDropdown.close();
  }

  @ApmTransactionMethod('subscribed to case package')
  async onSubmit(e: Event) {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.subscribing = true;

    const email: string = this.form.value.email;

    try {
      await this._packageService.subscribe(this.pkg.header.link, email, this.mode);
    } catch (err) {
      this.error = 'Server error. Please try again.';
      this.subscribed = false;
      this.subscribing = false;

      return;
    }

    this.subscribed = true;
    this.subscribing = false;
  }
}
