/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import type { AgentConfigOptions } from '@elastic/apm-rum';
import { ApmBase } from '@elastic/apm-rum';
import { ApmService } from '@elastic/apm-rum-angular';
import { UserInfo } from '@msi/commandcentral-common-header';
import { Settings } from '@msi/js-sdk';

import { ISettings } from '../../settings/settings.interface';
import { store } from '../../stores/global/store';
import { IUserInfoCRV } from '../user/user.interface';
import { UserService } from '../user/user.service';
import { register } from './elastic-apm.decorators';
import { ETransactionTypes } from './elastic-apm.enums';

@Injectable()
export class ElasticApmService {
  private _userInfo: UserInfo;
  private _apm: ApmBase;
  private _initialized = false;
  private _supported = false;
  private _config: AgentConfigOptions = {
    environment: 'UNDEFINED',
    serviceName: 'JudicialSharingFE',
    serverUrl: 'https://observability-v2.dev.commandcentral.com/apm-server/',
    propagateTracestate: false,
    breakdownMetrics: false,
  };
  private _done = false;
  private _attempts = 3;

  constructor(
    private _settings: Settings<ISettings>,
    private _userService: UserService,
    private _apmService: ApmService
  ) {}

  // Method was created to solve Common-Header issue that
  // userInfoService.getUserInfo() method never complete for unauth user
  private _retryGetUser(): Promise<UserInfo> {
    let count = 0;
    let userInfo: UserInfo | IUserInfoCRV;

    const retry = (resolve, reject) => {
      if (count > this._attempts) {
        reject();
      } else {
        if (userInfo) {
          resolve(userInfo);
        } else {
          count++;

          setTimeout(async () => {
            userInfo = this._userService.getUserInfo();
          });
          setTimeout(() => retry(resolve, reject), 500);
        }
      }
    };

    return new Promise((resolve, reject) => retry(resolve, reject));
  }

  private async _init() {
    const env: string = this._settings.get<string>('PLATFORM.AZURE_ENV' as any);

    if (env !== 'qa') {
      try {
        this._userInfo = await this._retryGetUser();
      } catch (err) {
        console.error(err);
      }

      let email = 'guest';
      let agency = '';

      if (this._userInfo) {
        if (this._userInfo?.email) {
          email = this._userInfo.email.toLowerCase();
        }

        if (this._userInfo?.agency?.name) {
          agency = this._userInfo.agency.name.toLocaleLowerCase();
        }
      }

      this._apm = this._apmService.init(this._config);

      this._apm.setUserContext({ email });
      this._apm.addLabels({ agency });

      register(this._apm);

      this._done = true;
    }
  }

  private async _checkReady(): Promise<void> {
    const repeat = (resolve) => {
      if (this._done && this._initialized) {
        resolve();
      } else {
        setTimeout(() => repeat(resolve), 300);
      }
    };

    return new Promise((resolve) => repeat(resolve));
  }

  initialize(): void {
    this._supported = !this.isExternalEnvironment();

    if (!this._supported) {
      return;
    }

    this._config.environment = this._settings.get<string>('PLATFORM.APM_ENV' as any);

    this._config.serverUrl = this._settings.get<string>(
      'PLATFORM.ELASTIC_APM_URL' as any
    );

    this._initialized = true;
    setTimeout(() => this._init());
  }

  private isExternalEnvironment() {
    const externalEnvs = [
      'fed',
      'fed-stage',
      'fed-stage-2',
      'fed-prod',
      'fed-prod-2',
      'cccs-prod',
      'cccs-preprod',
    ];
    const currentEnv = this._settings.get<string>('PLATFORM.AZURE_ENV' as any);
    return externalEnvs.includes(currentEnv);
  }

  async track(
    name: string,
    type: ETransactionTypes = ETransactionTypes.ACTION
  ): Promise<void> {
    if (!this._supported) {
      return Promise.resolve();
    }

    await this._checkReady();
    const transaction: any = this._apm.startTransaction(name, type);
    await this._displayElasticApmLogs();
    setTimeout(() => transaction.end(), 100);
  }

  async track_number(
    name: string,
    value: number,
    type: ETransactionTypes = ETransactionTypes.ACTION
  ): Promise<void> {
    if (!this._supported) {
      return Promise.resolve();
    }

    await this._checkReady();
    this._apm.addLabels({ numeric_value: value });
    const transaction: any = this._apm.startTransaction(name, type);
    await this._displayElasticApmLogs();
    setTimeout(() => transaction.end(), 100);
  }

  private async _displayElasticApmLogs(): Promise<void> {
    if (store.displayElasticApmLog) {
      // eslint-disable-next-line no-console
      console.log('apm config:', this._config);
      // eslint-disable-next-line no-console
      console.log('apm user info:', this._userInfo);
    }
  }
}
