<ng-template #updatePackageContentTemplate>
  <msi-modal>
    <msi-spinner
      size="large"
      *ngIf="loading"></msi-spinner>
    <form
      *ngIf="!loading"
      (ngSubmit)="onSubmit($event)">
      <msi-modal-header>{{ title | transloco }}</msi-modal-header>
      <msi-modal-content class="form-contents">
        <div class="row">
          <div class="col">
            {{
              'All the updates on this package will be shared with people who have access to this package.'
                | transloco
            }}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="info">{{ 'Subject' | transloco }}</label>
            <div>{{ pkg.header.title }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="info">{{ 'Body' | transloco }}</label>
            <div
              >{{ 'New content is updated to the case package' | transloco }} "{{ pkg.header.title }}":</div
            >
            <ul class="msi-list">
              <ng-template
                ngFor
                let-item
                [ngForOf]="packageDiff"
                let-i="index">
                <li>{{ item }}</li>
              </ng-template>
            </ul>
          </div>
        </div>
      </msi-modal-content>

      <pp-asset-warning
        [incompleteAssetsCount]="(incompleteAssets$ | async)?.count"
        [directoryAssetsCount]="(directoryAssets$ | async)?.count">
      </pp-asset-warning>

      <msi-modal-actions align="end">
        <button
          type="button"
          class="msi-btn msi-btn-action"
          onfocus="this.blur()"
          [attr.disabled]="sharing || loading || undefined"
          (click)="onCancel($event)">
          {{ 'Cancel' | transloco }}
        </button>
        <button
          type="submit"
          class="msi-btn spinner-parent"
          [attr.disabled]="sharing || loading || noUpdates || undefined">
          {{ loading || sharing ? 'Sending...' : ('Send' | transloco) }}
        </button>
      </msi-modal-actions>
    </form>
  </msi-modal>
</ng-template>
