/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AssetEntity,
  AssetViewer,
  EAllowedOperation,
  emm,
  IPackageFile,
  SharedApiDriver,
} from '@msi/emm-sdk';
import {
  ASSET_VIEWER_CONFIGURATION,
  MsiAssetViewerOptions,
  PlayableLinkService,
  UnauthenticatedDriver,
} from '@msi/msi-asset-viewer';
import {
  DOWNLOAD_MANAGER_CONFIGURATION,
  DownloadManagerOptions,
  DownloadManagerUnauthenticatedDriver,
  MsiDownloadStreamsService,
} from '@msi/msi-download-manager';

import { IEmptyAssetEntity } from '../asset-entity/asset-entity.interface';
import { AuditLogService } from '../audit-log/audit-log.service';
import { EvidenceService } from '../evidence-service/evidence.service';
import { EDetailPackageMode } from '../package/package.enums';
import { IPackage } from '../package/package.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AssetViewerConfigurationService {
  assetViewerConfig: Partial<MsiAssetViewerOptions>;
  private readonly defaultassetViewerConfig: Partial<MsiAssetViewerOptions> = {
    forceAgency: '',
    detailsShowFullBtn: false,
    downloadable: true,
    downloadWithMetadata: false,
    deletable: false,
    viewDetails: true,
    viewImportanceBtn: false,
    viewAuditLogBtn: {
      active: false,
    },
    isAuthMode: false,
    packageShareId: '',
    forceAllowedOperations: [
      { name: EAllowedOperation.Expunge, isAllowed: false, reason: [] },
      { name: EAllowedOperation.Edit, isAllowed: false, reason: [] },
      { name: EAllowedOperation.Download, isAllowed: true, reason: [] },
      { name: EAllowedOperation.ViewAuditLog, isAllowed: true, reason: [] },
    ],
  };

  constructor(
    private _pls: PlayableLinkService,
    private _http: HttpClient,
    private _streamsService: MsiDownloadStreamsService,
    private _auditLog: AuditLogService,
    @Inject(ASSET_VIEWER_CONFIGURATION) private assetViewerOptions: MsiAssetViewerOptions,
    @Inject(DOWNLOAD_MANAGER_CONFIGURATION)
    private downloaderOptions: DownloadManagerOptions,
    private evidenceService: EvidenceService
  ) {}

  configureEmmComponents(agency: string, pkg: IPackage, mode: EDetailPackageMode) {
    const assetsViewer = emm.getAssetViewer();
    this.assetViewerConfig = this._setUnauthAssetViewerConfig(agency, pkg.files);

    if (mode === EDetailPackageMode.SHARES) {
      const authAssetViewerConfig: Partial<MsiAssetViewerOptions> =
        this._setAuthAssetViewerConfig(pkg);

      Object.assign(this.assetViewerConfig, authAssetViewerConfig);
      this.evidenceService.setAuthApiMode(agency, pkg);
    } else {
      this.evidenceService.setUnauthApiMode(agency, pkg);
      this._setUnauthDriver(assetsViewer, pkg);
    }

    Object.assign(this.assetViewerOptions, this.assetViewerConfig);
    this.downloaderOptions.forceAgency = agency;
  }

  async downloadAuditLog(assetId: string, pkg: IPackage): Promise<void> {
    const assets: (AssetEntity | IEmptyAssetEntity)[] = pkg.assetEntities;
    const asset: AssetEntity | IEmptyAssetEntity = assets.find(
      (item: AssetEntity | IEmptyAssetEntity): boolean => item.fileId === assetId
    );

    if (asset) {
      const packageId = pkg.header.id;
      const packageAgencyId = pkg.header.agency;
      const fileId = asset.fileId;
      const fileAgencyId: string = (asset as any).ppAgencyId || pkg.header.agency;

      await this._auditLog.download(packageId, packageAgencyId, fileId, fileAgencyId);
    }
  }

  private _getPackageShareId(files: IPackageFile[]): string {
    return files?.find((file: IPackageFile) => file?.shareId)?.shareId || '';
  }

  private _setUnauthAssetViewerConfig(
    agency: string,
    files: IPackageFile[]
  ): Partial<MsiAssetViewerOptions> {
    return {
      ...this.defaultassetViewerConfig,
      forceAgency: agency,
      packageShareId: this._getPackageShareId(files),
    };
  }

  private _setAuthAssetViewerConfig(pkg: IPackage): Partial<MsiAssetViewerOptions> {
    return {
      viewDetails: true,
      viewAuditLogBtn: {
        active: true,
        label: 'Download audit log',
        redirect: (assetId) => this.downloadAuditLog(assetId, pkg),
      },
      isAuthMode: true,
    };
  }

  private _setUnauthDriver(assetsViewer: AssetViewer, pkg: IPackage): void {
    this._pls.setApiDriver(new UnauthenticatedDriver(() => pkg, this._http));
    assetsViewer.setApiDriver(new SharedApiDriver(() => pkg));
    assetsViewer.setShareIds(pkg.files);
    this._streamsService.setApiDriver(
      new DownloadManagerUnauthenticatedDriver(
        () => pkg,
        this._http,
        this.downloaderOptions
      )
    );
  }
}
