/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MsiDownloadManagerModule } from '@msi/msi-download-manager';
import { PublicEventsService } from 'angular-auth-oidc-client';

import { SharedModule } from '../shared/shared.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot([], {}),
    MsiDownloadManagerModule.forRoot(),
    SharedModule,
  ],
  providers: [PublicEventsService],
  bootstrap: [AppComponent],
})
export class AppModule {}
