<msi-sidebar #sidebar>
  <msi-sidebar-top *ngIf="topSidebarItems && topSidebarItems.length">
    <br />
    <ng-template
      ngFor
      let-item
      [ngForOf]="topSidebarItems">
      <msi-sidebar-item
        [class.selected]="item.selected"
        [icon]="item.icon"
        [description]="item.title | transloco"
        [tooltipDisabled]="item.tooltipDisabled"
        (click)="onClick(item)">
      </msi-sidebar-item>
    </ng-template>
  </msi-sidebar-top>

  <msi-sidebar-bottom *ngIf="bottomSidebarItems && bottomSidebarItems.length">
    <ng-template
      ngFor
      let-item
      [ngForOf]="bottomSidebarItems">
      <msi-sidebar-item
        [class.selected]="item.selected"
        [icon]="item.icon"
        [description]="item.title | transloco"
        [tooltipDisabled]="item.tooltipDisabled"
        (click)="onClick(item)">
      </msi-sidebar-item>
    </ng-template>
  </msi-sidebar-bottom>
</msi-sidebar>
