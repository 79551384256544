/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';

import { ESharesType } from '../../../services/filter/filter.enums';
import { IListBody } from '../../../services/filter/filter.interfaces';
import { PackageItem } from '../../../services/package/classes/package/PackageItem';
import { packagesActions } from '../actions';

export interface IPackagesState {
  receivedPackages: PackageItem[];
  sharedPackages: PackageItem[];
  packagesLoading: {
    shared: boolean;
    received: boolean;
  };
  selectedPackages: PackageItem[];
  packagesError: {
    shared?: HttpErrorResponse;
    received?: HttpErrorResponse;
  };
  isReceived: boolean;
  receivedContinuation: string | null;
  sharedContinuation: string | null;
  receivedOptions: IListBody;
  sharedOptions: IListBody;
  isAnySharedPackageLocked: boolean;
  calloutClosedByUser: boolean;
}

export const initialPackagesState: IPackagesState = {
  receivedPackages: [],
  sharedPackages: [],
  packagesLoading: { received: false, shared: false },
  selectedPackages: [],
  isReceived: true,
  receivedContinuation: null,
  sharedContinuation: null,
  packagesError: {
    shared: undefined,
    received: undefined,
  },
  receivedOptions: {
    query: '',
    share: [ESharesType.ACTIVE],
    sort: null,
    pageNumber: 0,
  },
  sharedOptions: {
    query: '',
    share: [ESharesType.ACTIVE],
    sort: null,
    pageNumber: 0,
  },
  isAnySharedPackageLocked: false,
  calloutClosedByUser: false,
};

export const packagesReducer = createReducer(
  initialPackagesState,
  on(packagesActions.clearReceivedPackages, (state) => ({
    ...state,
    receivedPackages: [],
  })),
  on(packagesActions.clearSharedPackages, (state) => ({
    ...state,
    sharedPackages: [],
    isAnySharedPackageLocked: false,
    calloutClosedByUser: false,
  })),
  on(packagesActions.loadReceivedPackages, (state) => ({
    ...state,
    packagesLoading: { ...state.packagesLoading, received: true },
    packagesError: {
      ...state.packagesError,
      received: undefined,
    },
  })),
  on(packagesActions.loadSharedPackages, (state) => ({
    ...state,
    packagesLoading: { ...state.packagesLoading, shared: true },
    packagesError: {
      ...state.packagesError,
      shared: undefined,
    },
  })),
  on(packagesActions.loadPackages, (state) => ({
    ...state,
    packagesLoading: { received: state.isReceived, shared: !state.isReceived },
  })),
  on(packagesActions.setReceivedPackages, (state, action) => ({
    ...state,
    packagesLoading: { ...state.packagesLoading, received: false },
    receivedPackages: action.packages,
    receivedContinuation: action.continuation,
  })),
  on(packagesActions.setSharedPackages, (state, action) => ({
    ...state,
    packagesLoading: { ...state.packagesLoading, shared: false },
    sharedPackages: action.packages,
    sharedContinuation: action.continuation,
    isAnySharedPackageLocked: action.packages.some((p) => p.accessLocked),
  })),
  on(packagesActions.setMoreReceivedPackages, (state, action) => ({
    ...state,
    packagesLoading: { ...state.packagesLoading, received: false },
    receivedPackages: [...state.receivedPackages, ...action.packages],
    receivedContinuation: action.continuation,
    receivedOptions: {
      ...state.receivedOptions,
      pageNumber: state.receivedOptions.pageNumber + 1,
    },
  })),
  on(packagesActions.setMoreSharedPackages, (state, action) => ({
    ...state,
    packagesLoading: { ...state.packagesLoading, shared: false },
    sharedPackages: [...state.sharedPackages, ...action.packages],
    sharedContinuation: action.continuation,
    sharedOptions: {
      ...state.sharedOptions,
      pageNumber: state.sharedOptions.pageNumber + 1,
    },
    isAnySharedPackageLocked: state.isAnySharedPackageLocked
      ? true
      : action.packages.some((p) => p.accessLocked),
  })),
  on(packagesActions.loadReceivedPackagesFailure, (state, action) => ({
    ...state,
    packagesLoading: { ...state.packagesLoading, received: false },
    packagesError: { ...state.packagesError, received: action.error },
  })),
  on(packagesActions.loadSharedPackagesFailure, (state, action) => ({
    ...state,
    packagesLoading: { ...state.packagesLoading, shared: false },
    packagesError: { ...state.packagesError, shared: action.error },
  })),
  on(packagesActions.setIsReceived, (state, action) => ({
    ...state,
    isReceived: action.isReceived,
    receivedOptions: initialPackagesState.receivedOptions,
    sharedOptions: initialPackagesState.sharedOptions,
  })),
  on(packagesActions.searchPackages, (state) => ({
    ...state,
    packagesLoading: {
      shared: !state.isReceived,
      received: state.isReceived,
    },
  })),
  on(packagesActions.filterPackages, (state) => ({
    ...state,
    packagesLoading: {
      shared: !state.isReceived,
      received: state.isReceived,
    },
  })),
  on(packagesActions.loadMorePackages, (state) => ({
    ...state,
    packagesLoading: {
      shared: !state.isReceived && !!state.sharedContinuation,
      received: state.isReceived && !!state.receivedContinuation,
    },
  })),
  on(packagesActions.setOptions, (state, action) => ({
    ...state,
    sharedOptions: !state.isReceived
      ? { ...state.sharedOptions, ...action.options }
      : state.sharedOptions,
    receivedOptions: state.isReceived
      ? { ...state.receivedOptions, ...action.options }
      : state.receivedOptions,
  })),
  on(packagesActions.setCalloutClosed, (state) => ({
    ...state,
    calloutClosedByUser: true,
  }))
);
