/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { AssetEntity } from '@msi/emm-sdk';
import { Api, IResponse } from '@msi/js-sdk';

import { DerivedItem } from '../asset-entity/asset-entity.interface';
import { DownloableLinkService } from '../downloadable-link/downloable-link.service';
import { DerivedItemType, EPackageAccessType } from '../package/package.enums';
import { PlayableLinkService } from '../playable-link/playable-link.service';

@Injectable()
export class DerivedItemService {
  constructor(
    private _api: Api,
    private _downloadableService: DownloableLinkService,
    private _playableService: PlayableLinkService
  ) {}

  private _getDerivedItemIcon(derivedItemType: string): string {
    let icon: string;

    switch (derivedItemType) {
      case DerivedItemType.CLIP:
        icon = 'ic_scissors';
        break;
      case DerivedItemType.EXPORTED_REDACTION:
        icon = 'ic_video_redaction';
        break;
      case DerivedItemType.SCREENSHOT:
        icon = 'ic_camera';
        break;
    }

    return icon;
  }

  private _getDerivedItemId(data: any, derivedItemType: string): string {
    switch (derivedItemType) {
      case DerivedItemType.CLIP:
        return data.clipId;
      case DerivedItemType.EXPORTED_REDACTION:
        return data.exportedRedactionId;
      case DerivedItemType.SCREENSHOT:
        return data.screenshotId;
    }
  }

  public async getDerivedItemData(url: string): Promise<DerivedItem> {
    let response: IResponse<DerivedItem>;

    try {
      response = await this._api.get<DerivedItem>(url);

      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public mapDerivedItemToAsset(
    asset: AssetEntity,
    derivedItemType: DerivedItemType,
    currentDerivedItem: DerivedItem,
    fileId: string
  ): AssetEntity {
    (asset as any).derivedItemId = this._getDerivedItemId(
      currentDerivedItem,
      derivedItemType
    );
    (asset as any).derivedItemType = derivedItemType;
    asset.humanSize = '--';
    asset.displayName =
      (asset as any).derivedItemType === DerivedItemType.SCREENSHOT
        ? currentDerivedItem.displayName + '.jpeg'
        : currentDerivedItem.displayName + '.mp4';
    asset.name =
      (asset as any).derivedItemType === DerivedItemType.SCREENSHOT
        ? currentDerivedItem.displayName + '.jpeg'
        : currentDerivedItem.displayName + '.mp4';
    asset.mimeType =
      derivedItemType === DerivedItemType.SCREENSHOT ? 'image/jpeg' : 'm4e-video';
    asset.fileId = (asset as any).derivedItemId;
    asset.startTime = currentDerivedItem.creationTime;
    (asset as any).parentFileId = fileId;
    (asset as any).creationTime = (currentDerivedItem as any).creationTime;
    (asset as any).assetType = 'derived-asset';
    asset.iconName = this._getDerivedItemIcon(derivedItemType);
    asset.watermark = currentDerivedItem.watermark;

    return asset;
  }

  public getDerivedItemDisplayLinkUrl(
    derivedItemType: DerivedItemType,
    url: string,
    accessType: EPackageAccessType
  ): string {
    return this._playableService.getDerivedItemDisplayLinkUrl(
      derivedItemType,
      url,
      accessType
    );
  }

  public getDerivedItemDownloadableLinkUrl(
    url: string,
    accessType: EPackageAccessType
  ): string {
    return this._downloadableService.getDownloadableLinkUrl(url, accessType);
  }

  public async getDerivedItemPlayableLink(
    url: string,
    response: DerivedItem,
    accessType: EPackageAccessType
  ): Promise<string> {
    return this._playableService.getDerivedItemPlayableLink(url, response, accessType);
  }

  public async getDerivedItemDownloadableLink(
    url: string,
    response: DerivedItem,
    accessType: EPackageAccessType
  ): Promise<string> {
    return this._downloadableService.getDownloadableLink(url, response, accessType);
  }

  public async updateAssetEntityItem(
    assetEntity: AssetEntity,
    playableLink: string,
    downloadableLink: string
  ): Promise<void> {
    const assetEntityItem = assetEntity.getFirstChild();
    const name =
      (assetEntity as any).derivedItemType === DerivedItemType.SCREENSHOT
        ? assetEntity.name
        : assetEntity.name + '.mp4';

    assetEntityItem.name = assetEntityItem.displayName = name;
    assetEntityItem.fileId = (assetEntity as any).parentFileId
      ? (assetEntity as any).parentFileId
      : assetEntity.fileId;
    (assetEntityItem as any).derivedItemId = (assetEntity as any).derivedItemId;
    (assetEntityItem as any).derivedItemType = (assetEntity as any).derivedItemType;

    if (
      (assetEntity as any).derivedItemType === DerivedItemType.CLIP ||
      (assetEntity as any).derivedItemType === DerivedItemType.EXPORTED_REDACTION
    ) {
      assetEntityItem.isAudio = true;
      assetEntityItem.fileLink = downloadableLink;
      assetEntityItem.inlineLink = downloadableLink;
      assetEntity.inlineLink = playableLink;
    } else if ((assetEntity as any).derivedItemType === DerivedItemType.SCREENSHOT) {
      assetEntityItem.isImage = true;
      assetEntityItem.fileLink = `${downloadableLink}/thumb?quality=1`;
      assetEntityItem.inlineLink = `${downloadableLink}/thumb?quality=1`;
      assetEntity.inlineLink = `${downloadableLink}/thumb?quality=1`;
      assetEntity.fileLink = `${downloadableLink}/thumb?quality=1`;
    }
  }

  public mapDerivedItemTypeToCamelCase(derivedItemType: DerivedItemType): string {
    switch (derivedItemType) {
      case DerivedItemType.CLIP:
        return 'clip';
      case DerivedItemType.SCREENSHOT:
        return 'screenshot';
      case DerivedItemType.EXPORTED_REDACTION:
        return 'exportedRedaction';
    }
  }
}
