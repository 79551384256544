/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, from, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { PackageItem } from '../../../services/package/classes/package/PackageItem';
import { PackageService } from '../../../services/package/package.service';
import { packageDetailsActions } from '../actions';

@Injectable()
export class PackageDetailsEffects {
  constructor(
    private packageService: PackageService,
    private appActions$: Actions,
    public store: Store
  ) {}

  public loadPackage$ = createEffect(() =>
    this.appActions$.pipe(
      ofType(packageDetailsActions.loadPackage),
      switchMap((action) =>
        this.packageService
          .getPackage(action.packageId, action.mode, action.agencyId)
          .pipe(
            map((promise) => from(promise)),
            switchMap((observ) =>
              observ.pipe(
                map((response) =>
                  packageDetailsActions.setPackageAndPkgItem({
                    package: response,
                    pkgItem: new PackageItem(response),
                  })
                ),
                catchError((error) =>
                  of(packageDetailsActions.loadPackageFailure({ error }))
                )
              )
            ),
            catchError((error) => of(packageDetailsActions.loadPackageFailure({ error })))
          )
      ),
      catchError((error) => of(packageDetailsActions.loadPackageFailure({ error })))
    )
  );
}
