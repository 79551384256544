/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PasswordChangeService {
  private isPasswordChangeConfirmed: boolean;
  private isPasswordChangeConfirmedSubject: BehaviorSubject<boolean>;
  public isPasswordChangeConfirmed$: Observable<boolean>;

  constructor() {
    this.isPasswordChangeConfirmed = false;
    this.isPasswordChangeConfirmedSubject = new BehaviorSubject<boolean>(
      this.isPasswordChangeConfirmed
    );
    this.isPasswordChangeConfirmed$ =
      this.isPasswordChangeConfirmedSubject.asObservable();
  }

  toggleConfirmation(): void {
    this.isPasswordChangeConfirmed = !this.isPasswordChangeConfirmed;
    this.isPasswordChangeConfirmedSubject.next(this.isPasswordChangeConfirmed);
  }
}
