/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Pipe, PipeTransform } from '@angular/core';

import { IShareableAsset } from '../../models/share-data.models';
import { EAssetType } from '../../models/share-data.models';
import { ShareDataService } from '../../services/share-data/share-data.service';

@Pipe({
  name: 'assetType',
})
export class AssetTypePipe implements PipeTransform {
  constructor(private _shareDataService: ShareDataService) {}

  transform(asset: IShareableAsset): EAssetType {
    return this._shareDataService.getAssetType(asset);
  }
}
