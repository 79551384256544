/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckboxComponent } from '@msi/cobalt';

import { generatePassword } from '../../utils/password-generator';

@Component({
  selector: 'ccs-password-creator',
  templateUrl: './password-creator.component.html',
  styleUrls: ['./password-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordCreatorComponent {
  @Input() controlPassword: FormControl;
  @Input() controlEmailPassword: FormControl;
  @Input() isGeneratedPasswordActive: boolean;
  @Output() isGeneratedPasswordActiveChange = new EventEmitter<boolean>();
  @ViewChild('passwordCheckbox', { static: false }) passwordCheckbox: CheckboxComponent;

  isVisible = false;

  hasMinLengthError() {
    return this.controlPassword.hasError('passwordLength');
  }

  hasMaxLengthError() {
    return this.controlPassword.hasError('maxlength');
  }

  hasAlphanumericError() {
    return this.controlPassword.hasError('alphanumeric');
  }

  hasCaseError() {
    return this.controlPassword.hasError('case');
  }

  hasSpecialCharacterError() {
    return this.controlPassword.hasError('specialCharacter');
  }

  handlePasswordOption(): void {
    this.isGeneratedPasswordActive
      ? this.controlPassword.setValue('')
      : this.controlPassword.setValue(generatePassword());

    this.isGeneratedPasswordActive = !this.isGeneratedPasswordActive;
    this.isGeneratedPasswordActiveChange.emit(this.isGeneratedPasswordActive);
  }

  handleInput(): void {
    this.isGeneratedPasswordActive = false;
    this.isGeneratedPasswordActiveChange.emit(this.isGeneratedPasswordActive);

    if (this.controlPassword?.value.length === 0) {
      this.passwordCheckbox.writeValue(false);
    }
  }

  toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }
}
