/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Settings } from '@msi/js-sdk';

import { ISettings } from './settings.interface';

const settings: Settings<ISettings> = new Settings<ISettings>({
  AUTODISMISS: 3000,
  USER_ID: '',
  AGENCY_NAME: '',
  APPLICATION_NAME: 'CCVault',
  AUDIT_LOG_APPLICATION_NAME: 'ccsharing',
  EVIDENCE_CONFIG_URL: 'https://evidence.dev.commandcentral.com/api/vault/v2/config',
  UPLOAD_URL:
    'https://evidence.dev.commandcentral.com/api/vault/v2/files?uploadType={{ uploadType }}',
  VERIFY_URL:
    'https://evidence.dev.commandcentral.com/api/vault/v2/files/done?id={{ assetId }}',
  AGENCY_GROUPS: 'https://evidence.dev.commandcentral.com/api/vault/v2/groups',
  GET_ASSET_URL:
    'https://evidence.dev.commandcentral.com/api/vault/v2/files/{{ assetId }}',
  CHAIN_OF_CUSTODY_URL:
    'https://evidence.dev.commandcentral.com/api/vault/v2/files/{{ assetId }}/chainOfCustody',
  ALLOWED_OPERATIONS:
    'https://evidence.dev.commandcentral.com/api/vault/v2/files/{{ assetId }}/operations',
  CANCEL_ASSET_URL:
    'https://evidence.dev.commandcentral.com/api/vault/v2/files/cancel?id={{ assetId }}',
  UPDATE_ASSET_URL:
    'https://evidence.dev.commandcentral.com/api/vault/v2/files/{{ assetId }}/properties/modify',
  AUDIT_VIEWER_URL:
    'https://audit-viewer.dev.commandcentral.com/?object={{ assetId }}&startDate={{ startDate }}&endDate={{ endDate }}&application={{ application }}',
  SEARCH_URL: 'https://evidence.dev.commandcentral.com/api/vault/v2/files/search',
  GET_OFFICERS_URL: 'https://evidence-api.dev.commandcentral.com/api/vault/v2/officers',
  GET_SINGLE_ASSET_BY_ID_URL:
    'https://evidence.dev.commandcentral.com/api/vault/v2/files/{{ assetId }}',
  AUDIT_LOG_URL: 'https://audit.pi.commandcentral.com',
  TOKEN_TYPE: 'Bearer',
  ACCESS_TOKEN: '',
  LIMIT: 20,
  JUDICIAL_CREATE_PACKAGE_API_ENDPOINT: '/package-manager/v1/packages',
  JUDICIAL_SHARE_PACKAGE_API_ENDPOINT: '/package-manager/v1/packages',
  GET_PROSECUTORS_URL: '/package-manager/v1/recipients/prosecutors',
  CREATE_PACKAGE_URL: '/package-manager/v1/packages',
  DELETE_V2_PACKAGE_URL: '/package-manager/v1/packages-v2/{{ agencyId }}/{{ packageId }}',
  REGENERATE_V2_PACKAGE_URL:
    '/package-manager/v1/packages-v2/{{ agencyId }}/{{ packageId }}/regenerate-link',
  SEARCH_PACKAGE_URL: '/package-manager/v1/packages/.search',
  SHARE_PACKAGE_URL: '/package-manager/v1/packages/.search-shared-with-me',
  SEARCH_SHARED_URL: '/package-manager/v1/packages/.search-shared',
  RESHARE_PACKAGE_URL:
    '{{ domain }}/package-manager/v1/packages/{{ packageId }}/{{ ownerAgency }}/.reshare',
  CALCULATE_PACKAGE_CHANGES: '/package-manager/v1/package-changes',
  SEARCH_LAW_INCIDENTS_URL: '/api/search/v1.0/lawIncidents/.search',
  SEARCH_FILES_URL: '/api/search/v1.0/files/.search',
  PAGE_SIZE: 30,
  BUILD_NUMBER: '__Build.BuildNumber__',
  SOURCE_VERSION: '__Build.SourceVersion__',
  SOURCE_BRANCH: '__Build.SourceBranch__',
  BASE_URL: '/',
  BASE_URL_BETA: '/shares/beta',
  RECORDS_URL: '/',
  NEVER_ID: 999999,
  NEVER_DATE: '3000-01-01',
  PM_API_PACKAGE_ENDPOINT: '/pm-proxy/v1/package',
  PM_API_PACKAGE_V2_ENDPOINT: '/pm-proxy/v1/package-v2',
  PM_API_FEATURE_FLAGS_ENDPOINT: '/package-manager/v1/configuration/flags',
  SUBSCRIBE_UNAUTH_PACKAGE_API_ENDPOINT:
    '{{ domain }}/pm-proxy/v1/package/{{ packageId }}/public/subscriptions',
  SUBSCRIBE_AUTH_PACKAGE_API_ENDPOINT:
    '{{ domain }}/package-manager/v1/packages/{{ packageId }}/public/subscriptions',
  UNSUBSCRIBE_UNAUTH_PACKAGE_API_ENDPOINT:
    '{{ domain }}/pm-proxy/v1/package/{{ packageId }}/public/subscriptions/{{ subId }}',
  UNSUBSCRIBE_AUTH_PACKAGE_API_ENDPOINT:
    '{{ domain }}/package-manager/v1/packages/{{ packageId }}/public/subscriptions/{{ subId }}',
  PM_API_PACKAGE_PRIVATE_ENDPOINT:
    '{{ domain }}/package-manager/v1/packages/{{ packageId }}/private',
  PM_API_PACKAGE_V2_PRIVATE_ENDPOINT:
    '{{ domain }}/package-manager/v1/packages-v2/{{ agencyId }}/{{ packageId }}',
  PM_API_PACKAGE_PUBLIC_ENDPOINT:
    '{{ domain }}/package-manager/v1/packages/{{ packageId }}/public',
  PM_API_PACKAGE_V2_PUBLIC_ENDPOINT:
    '{{ domain }}/package-manager/v1/packages-v2/public/{{ agencyId}}/{{ packageId }}',
  VAULT_SHARE_URL: '/api/vault-public/v1/shares',
  CRV_SERVICE_NAME: 'records.crv',
  SEARCH_API_ENTITY_ENDPOINT: '/api/search/v1.0/{{ entity }}/.search',
  DOWNLOAD_AUDIT_LOG: '{{ domain }}/package-manager/v1/chain-of-custody/.download',
  PM_AUDIT_LOG_ENDPOINT: '{{ domain }}/package-manager/v1/audit/log',
  PROXY_AUDIT_LOG_ENDPOINT: '{{ domain }}/pm-proxy/v1/audit/log',
  BDP_PUBLISHERS_SEARCH: '{{ domain }}/package-manager/v1/bdp-search/.publishers-search',
  BDP_VIEWERS_SEARCH: '{{ domain }}/package-manager/v1/bdp-search/.viewers-search',
  CRV_SETTINGS: '/settings/codes/',
  CREATE_CASE_API_ENDPOINT: '{{ domain }}/package-manager/v1/cases',
  ADD_TO_CASE_API_ENDPOINT: '{{ domain }}/package-manager/v1/cases',
  RECENT_CASES_API_ENDPOINT: '{{ domain }}/package-manager/v1/cases/.search',
  DEVOPS_GET_FEATURE_FLAG:
    '{{ domain }}/api/records/v1.0/featureflag/GetFeatureFlag/{{ name }}',
  DEVOPS_GET_FEATURE_FLAGS: '{{ domain }}/api/records/v1.0/featureflag/GetFeatureFlags',
  PROTECTED_PACKAGE_AUTHORIZATION_URL: '/pm-proxy/v1/auth/token',
});

export { settings };
