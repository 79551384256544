/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ModalService, MsiModalConfig, MsiModalRef, ToastService } from '@msi/cobalt';
import { Connect, Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';

import { ElasticApmService } from '../../services/elastic-apm';
import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../services/elastic-apm/elastic-apm.decorators';
import { ISimpleOfficer } from '../../services/officer/officer.interfaces';
import { PackageItem } from '../../services/package/classes/package/PackageItem';
import {
  IAdditionalInfo,
  IResharePackageForm,
} from '../../services/package/create-package.interfaces';
import { Email } from '../../services/package/Email';
import { IPackage } from '../../services/package/package.interfaces';
import { PackageService } from '../../services/package/package.service';
import { ISettings } from '../../settings/settings.interface';
import { IStore } from '../../stores/global/store.interface';
import { TPros } from '../common/prosecutors/prosecutor-validator';

@Component({
  selector: 'pp-reshare-package',
  templateUrl: './reshare-package.component.html',
  styleUrls: ['./reshare-package.component.scss'],
})
@ApmTransactionComponent('reshare modal')
export class ResharePackageComponent implements OnInit {
  @Input()
  packageItem: PackageItem;

  @Input()
  title = 'Modify package';

  @Input()
  additionalInfo: IAdditionalInfo[];

  @Output()
  done: EventEmitter<IPackage> = new EventEmitter();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter();

  @Output()
  send: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('resharePackageTemplate', { static: false })
  resharePackageTemplate: TemplateRef<any>;

  @Connect<IStore>('sending')
  sending: boolean;

  @Connect<IStore>('isUnauthSharingEnabled')
  isUnauthSharingEnabled: boolean;

  modalRef: MsiModalRef;
  form: UntypedFormGroup;
  packageName = '';
  sentRecipients: (ISimpleOfficer | string)[] = [];

  config: MsiModalConfig = {
    disableClose: true,
    hasBackdrop: true,
    width: 610,
  };

  constructor(
    private _settings: Settings<ISettings>,
    private _modalService: ModalService,
    private _packageService: PackageService,
    private _toastService: ToastService,
    private _elasticApmService: ElasticApmService,
    private _transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    const validator: any = (to: UntypedFormControl) => {
      const prosecutors: TPros[] = to.value;

      if (!prosecutors?.length) {
        return 'Please fill "to" field';
      }
    };

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.packageName),
      to: new UntypedFormControl([], validator),
    });

    this.form.valueChanges.subscribe((data: any) => {
      if (this.packageName !== data.name) {
        this.packageName = data.name.trim().length === 0 ? '' : data.name;
        this.form.controls.name.setValue(this.packageName);
      }
    });
  }

  open(): void {
    if (!this.modalRef) {
      const defData = { name: '', to: [] };

      if (this.packageItem.canUpdateReshare()) {
        defData.name = this.packageItem.resharedTitle;
        defData.to = this.packageItem.resharedWith.map((item: string) => new Email(item));
        this.sentRecipients = this.packageItem.resharedWith;
      }

      this.form.reset(defData);

      this.modalRef = this._modalService.open(this.resharePackageTemplate, this.config);
    }
  }

  @HostListener('window:popstate', ['$event'])
  @ApmTransactionMethod('close reshare package')
  close(): void {
    if (this.modalRef) {
      this.modalRef.close();

      this.modalRef = undefined;
    }
  }

  @Connect<IStore>('sending')
  onSending(sending: boolean): void {
    this.send.emit(sending);
  }

  onCancel(e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.close();
  }

  onUpdateSelectedProsecutors(prosecutors: TPros[]): void {
    this.form.controls.to.setValue(prosecutors);
  }

  async onSubmit(e: Event): Promise<void> {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    const toastOptions: Record<string, any> = {
      closeButton: true,
      autoDismiss: this._settings.get<number>('AUTODISMISS'),
    };

    const data: IResharePackageForm = {
      name: this.form.value.name.trim(),
      to: this.form.value.to,
    };

    let pkg: IPackage;
    let successMessage = 'Reshare Package sent successfully';
    let trackMessage = 'reshare package sent';

    try {
      if (this.packageItem.canUpdateReshare()) {
        pkg = await this._packageService.updateReshare(
          this.packageItem.getReshareId(),
          data
        );
        successMessage = 'Reshare Package was updated successfully';
        trackMessage = 'reshare package updated';
      } else {
        pkg = await this._packageService.reshare(
          this.packageItem.id,
          this.packageItem.agency,
          data
        );
      }

      this._elasticApmService.track(trackMessage);
    } catch (err) {
      if (err.status === 409) {
        this._toastService.error(
          this._transloco.translate('You are able to create just a single reshare'),
          void 0,
          toastOptions
        );
      } else {
        this._toastService.error(
          this._transloco.translate('Package is not reshared. Server error.'),
          void 0,
          toastOptions
        );
      }

      return;
    }

    this._toastService.success(
      this._transloco.translate(successMessage),
      void 0,
      toastOptions
    );

    this.close();
    this.done.emit(pkg);
  }
}
