/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

import { Person } from '../../../services/package/classes/person/Person';
import { IPackage } from '../../../services/package/package.interfaces';
import { PackageService } from '../../../services/package/package.service';

@Component({
  selector: 'pp-detail-package-persons',
  templateUrl: './detail-package-persons.component.html',
  styleUrls: ['./detail-package-persons.component.scss', '../detail-package-print.scss'],
})
export class DetailPackagePersonsComponent {
  @Input()
  pkg: IPackage;

  constructor(private _packageService: PackageService) {}

  informationPresent(person: Person): boolean {
    return this._packageService.informationSectionPresent(person);
  }
}
