/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Pipe, PipeTransform } from '@angular/core';

import { IShareableAsset } from '../../models/share-data.models';

@Pipe({
  name: 'assetDisplayName',
})
export class AssetDisplayNamePipe implements PipeTransform {
  // the value tagCategoryIncidentTypeId is hardcoded otherwise it is need to be fetch from AssetViever lib config
  private readonly tagCategoryIncidentTypeId = 'incident-type';

  transform(
    asset: IShareableAsset,
    assetNameUntitled: string,
    tagsUnassigned: string
  ): string {
    const assetDisplayName = asset.displayName ? asset.displayName : assetNameUntitled;
    const tagsCombinedName =
      asset.derivedItemType || !asset.enumeratedTags
        ? ''
        : ` - ${this._getCategoryTags(asset, tagsUnassigned)}`;

    return `${assetDisplayName}${tagsCombinedName}`;
  }

  private _getCategoryTags(asset: IShareableAsset, tagsUnassigned: string): string {
    const enumeratedTag = asset.enumeratedTags?.find(
      (tag) => tag.tagCategoryId === this.tagCategoryIncidentTypeId
    );
    const enumeratedTagsDisplayNames =
      enumeratedTag?.values.map((tag) => tag.displayName) || [];

    return `${enumeratedTag ? enumeratedTagsDisplayNames.join(', ') : tagsUnassigned}`;
  }
}
