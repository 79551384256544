/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

import { ChainOfCustody } from '../../../services/package/classes/chainOfCustody/ChainOfCustody';

@Component({
  selector: 'pp-detail-package-chainofcustody',
  templateUrl: './detail-package-chainofcustody.component.html',
  styleUrls: ['./detail-package-chainofcustody.component.scss'],
})
export class DetailPackageChainOfCustodyComponent {
  @Input()
  chainOfCustody: ChainOfCustody[];

  displayRow(chainOfCustody: ChainOfCustody): boolean {
    return (
      !!chainOfCustody.date ||
      !!chainOfCustody.type ||
      !!chainOfCustody.from ||
      !!chainOfCustody.custodian ||
      !!chainOfCustody.reason
    );
  }
}
