/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AssetEntity, DerivedItemType, IEnumeratedTags } from '@msi/emm-sdk';
import { Connect, DateTimeUtils } from '@msi/js-sdk';
import {
  ASSET_VIEWER_CONFIGURATION,
  AssetDisplayNamePipe,
  MsiAssetViewerOptions,
  OwnerDisplayNamePipe,
} from '@msi/msi-asset-viewer';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IDetailPackageStore } from '../../../stores/detail-package/detail-package.interfaces';
import { detailPackageLocalStore } from '../../../stores/detail-package/detail-package.store';

@UntilDestroy()
@Component({
  selector: 'pp-asset-entity-tile',
  templateUrl: './asset-entity-tile.component.html',
  styleUrls: ['./asset-entity-tile.component.scss'],
})
export class AssetEntityTileComponent implements OnInit {
  @Input()
  assetEntity: AssetEntity;

  @Output()
  tileClick: EventEmitter<AssetEntity> = new EventEmitter();

  @Connect<IDetailPackageStore>('assetsLoaded', detailPackageLocalStore)
  assetsLoaded: boolean;

  tileTitle: string;
  tileOwner: string;
  thumb: string;
  displayAssetStartTime: string;

  constructor(
    private assetDisplayNamePipe: AssetDisplayNamePipe,
    private ownerDisplayNamePipe: OwnerDisplayNamePipe,
    private transloco: TranslocoService,
    @Inject(ASSET_VIEWER_CONFIGURATION) private options: MsiAssetViewerOptions
  ) {}

  ngOnInit(): void {
    this.thumb = this.assetEntity.getThumb();
    this.displayAssetStartTime = this._setAssetStartTime();
    this.transloco.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this._prepareTileAssetData();
    });
  }

  onClick(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.tileClick.emit(this.assetEntity);
  }

  onImageError(): void {
    this.thumb = '';
  }

  private _setAssetStartTime(): string {
    return DateTimeUtils.format(
      this.assetEntity.startTime,
      DateTimeUtils.defaultLongTimeFormat
    );
  }

  private _prepareTileAssetData(): void {
    this.tileOwner = this.ownerDisplayNamePipe.transform(this.assetEntity);
    this.tileTitle = this.assetDisplayNamePipe.transform(
      this.assetEntity as {
        asset?: AssetEntity;
        enumeratedTags?: IEnumeratedTags[];
        derivedItemType?: DerivedItemType;
      },
      this.options.tagCategoryIncidentTypeId
    );
  }
}
