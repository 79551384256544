/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { createSelector } from '@ngrx/store';

import { IPackagesState } from '../reducers/packages.reducer';

export const getPackagesStore = (store) => store.packages as IPackagesState;

export const getReceivedPackages = createSelector(
  getPackagesStore,
  (store) => store.receivedPackages
);

export const getSharedPackages = createSelector(
  getPackagesStore,
  (store) => store.sharedPackages
);

export const isReceivedPackagesLoading = createSelector(
  getPackagesStore,
  (store) => store.packagesLoading.received
);

export const isSharedPackagesLoading = createSelector(
  getPackagesStore,
  (store) => store.packagesLoading.shared
);

export const getReceivedPackagesLoadingError = createSelector(
  getPackagesStore,
  (store) => store.packagesError.received
);

export const getSharedPackagesLoadingError = createSelector(
  getPackagesStore,
  (store) => store.packagesError.shared
);

export const isReceived = createSelector(getPackagesStore, (store) => store.isReceived);

export const getReceivedContinuation = createSelector(
  getPackagesStore,
  (store) => store.receivedContinuation
);

export const getSharedContinuation = createSelector(
  getPackagesStore,
  (store) => store.sharedContinuation
);

export const getSharedPageNumber = createSelector(
  getPackagesStore,
  (store) => store.sharedOptions.pageNumber
);

export const getReceivedPageNumber = createSelector(
  getPackagesStore,
  (store) => store.receivedOptions.pageNumber
);

export const getReceivedOptions = createSelector(
  getPackagesStore,
  (store) => store.receivedOptions
);

export const getSharedOptions = createSelector(
  getPackagesStore,
  (store) => store.sharedOptions
);
export const getReceivedQuery = createSelector(
  getPackagesStore,
  (store) => store.receivedOptions.query
);
export const getSharedQuery = createSelector(
  getPackagesStore,
  (store) => store.sharedOptions.query
);
export const getReceivedFilters = createSelector(
  getPackagesStore,
  (store) => store.receivedOptions.share
);
export const getSharedFilters = createSelector(
  getPackagesStore,
  (store) => store.sharedOptions.share
);

export const getShouldDisplayCallout = createSelector(
  getPackagesStore,
  (store) => store.isAnySharedPackageLocked && !store.calloutClosedByUser
);
