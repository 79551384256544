/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SHARE_DATA_CONFIGURATION } from '../../components/share-data/share-data.config';
import { IShareDataConfig } from '../../models/share-data.models';
import { ShareDataService } from '../share-data/share-data.service';
import { ShareSettingsService } from '../share-settings/share-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ShareConfigService {
  constructor(
    @Inject(SHARE_DATA_CONFIGURATION) private _shareConfig: IShareDataConfig,
    private _shareSettingsService: ShareSettingsService,
    private _shareDataService: ShareDataService
  ) {
    this._setAppSettings(this._shareConfig);
  }

  get shareModalFullscreen$(): Observable<boolean> {
    return this._shareDataService.fullscreen$;
  }

  setAccessToken(token: string): void {
    this._shareSettingsService.setAccessToken(token);
  }

  setAgencyId(agencyId: string): void {
    this._shareSettingsService.setAgencyId(agencyId);
  }

  private _setAppSettings(shareConfig: IShareDataConfig): void {
    this._shareSettingsService.initSettings(shareConfig);
  }
}
