/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Utils } from '@msi/js-sdk';

class FS {
  private _root!: FileSystemDirectoryHandle;
  private _options: FileSystemGetDirectoryOptions = { create: true };
  private _isReady = false;
  private _directoryPickerCanceled = false;
  private _maxStep = 2;
  private _step = 0;

  get directoryPickerCanceled(): boolean {
    return this._directoryPickerCanceled;
  }

  get root(): FileSystemDirectoryHandle {
    return this._root!;
  }

  private _validateIsReady() {
    if (!this._isReady) {
      throw new Error('FS: method "initialize" was not called');
    }
  }

  private _getChildrenByPath(path: string = ''): string[] {
    return path.split('/').filter((s) => !!s);
  }

  public async removeEntry(fileName: string, path: string): Promise<void> {
    const dirHandle: FileSystemDirectoryHandle = this._root;
    const fileExist: boolean = await this._isFileExist(fileName, path);
    const crSwapFileExist: boolean = await this._isFileExist(`${fileName}.crswap`, path);

    if (fileExist) {
      await dirHandle.removeEntry(fileName);
    }

    if (crSwapFileExist) {
      await dirHandle.removeEntry(`${fileName}.crswap`);
    }
  }

  public async _getChildDirHandle(path: string = ''): Promise<FileSystemDirectoryHandle> {
    if (path) {
      const dirs: string[] = this._getChildrenByPath(path);

      if (dirs.length) {
        let dirHandle: FileSystemDirectoryHandle = this._root;

        for (let i = 0; dirs[i]; i++) {
          dirHandle = await dirHandle.getDirectoryHandle(dirs[i], this._options);
        }
        return dirHandle;
      }
    }

    return this._root;
  }

  public async _checkNameInsideDir(
    name: string,
    dirHandle: FileSystemDirectoryHandle
  ): Promise<boolean> {
    for await (const [key, value] of dirHandle.entries()) {
      if (
        key &&
        value.kind === 'file' &&
        value.name.toLowerCase() === name.toLowerCase()
      ) {
        return true;
      }
    }

    return false;
  }

  public async _isFileExist(name: string, path: string = ''): Promise<boolean> {
    const dirs: string[] = this._getChildrenByPath(path);
    let dirHandle: FileSystemDirectoryHandle = this._root;

    if (dirs.length) {
      for (let i = 0; dirs[i]; i++) {
        let isFound = false;

        for await (const [key, value] of dirHandle.entries()) {
          if (
            key &&
            value.kind === 'directory' &&
            value.name.toLowerCase() === dirs[i].toLowerCase()
          ) {
            isFound = true;

            break;
          }
        }

        if (!isFound) {
          return false;
        }

        dirHandle = await dirHandle.getDirectoryHandle(dirs[i]);

        if (i === dirs.length - 1) {
          const isFound = await this._checkNameInsideDir(name, dirHandle);

          if (isFound) {
            return true;
          }
        }
      }
    } else {
      const isFound = await this._checkNameInsideDir(name, dirHandle);

      if (isFound) {
        return true;
      }
    }

    return false;
  }

  private _splitName(name: string): [string, string] {
    return Utils.splitName(name);
  }

  private async _getFixedName(name: string, path: string = ''): Promise<string> {
    const isExist: boolean = await this._isFileExist(name, path);

    if (isExist) {
      let [onlyName, ext] = this._splitName(name);
      let numbers = '';

      if (!isNaN(Number(onlyName))) {
        return await this._getFixedName(onlyName + '_1' + (ext ? '.' : '') + ext, path);
      }

      // TS error
      ext = ext + '';

      for (let i = onlyName.length - 1; i >= 0; i--) {
        const last: number = onlyName.length - 1;
        const c: string = onlyName.substr(last, 1);

        if (isNaN(Number(c)) || c === ' ') {
          if (c === '_') {
            onlyName = onlyName.substr(0, last);
          }

          break;
        }

        numbers = c + numbers;

        onlyName = onlyName.substr(0, last);
      }

      if (numbers) {
        return await this._getFixedName(
          onlyName + '_' + (parseInt(numbers, 10) + 1) + (ext ? '.' : '') + ext,
          path
        );
      } else {
        return await this._getFixedName(onlyName + '_1' + (ext ? '.' : '') + ext, path);
      }
    }

    return name;
  }

  public async createWritableStream(
    name: string,
    path: string = ''
  ): Promise<FileSystemWritableFileStream> {
    if (!name) {
      throw new Error('FS.createFile: name is not defined');
    }

    this._validateIsReady();

    name = await this._getFixedName(name, path);

    const dirHandle: FileSystemDirectoryHandle = await this._getChildDirHandle(path);
    const fileHandle: FileSystemFileHandle = await dirHandle.getFileHandle(
      name,
      this._options
    );

    return await fileHandle.createWritable({ keepExistingData: true });
  }

  public getRootHandle(): FileSystemDirectoryHandle {
    this._validateIsReady();

    return this._root;
  }

  public async initialize(): Promise<FS> {
    if (!this._isReady) {
      try {
        this._root = await showDirectoryPicker();
        await this._root.queryPermission({ mode: 'readwrite' });
      } catch (error) {
        this._directoryPickerCanceled = true;

        if (error.name === 'AbortError' || error.name === 'NotAllowedError') {
          return this;
        } else {
          throw error;
        }
      }

      this._isReady = true;
    }

    return this;
  }
}

export { FS };
