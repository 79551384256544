/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { ApmSpan } from './ApmSpan';
import { ESpanTypes } from './elastic-apm.enums';
import { ITransaction } from './elastic-apm.interfaces';

class ApmTransaction implements ITransaction {
  private _transaction;
  private _spans: ApmSpan[] = [];

  constructor(transaction: any) {
    this._transaction = transaction;
  }

  end(): void {
    if (this._transaction) {
      this._spans.forEach((span: ApmSpan) => span.end());
      this._spans = [];

      this._transaction.end();
      this._transaction = null;
    }
  }

  span(name: string, type: ESpanTypes): ApmSpan {
    const apmSpan = this._transaction.startSpan(name, type);
    const span = new ApmSpan(apmSpan);

    this._spans.push(span);

    return span;
  }
}

export { ApmTransaction };
