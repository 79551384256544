<ng-container *transloco="let t; read: 'ccs'">
  <main class="list-view">
    <table
      class="msi-table list-view__wrapper"
      [class.msi-table-condensed]="true">
      <thead>
        <tr *ngIf="fullScreenEnabled">
          <th class="list-view__file-name-header msi-table-header">{{ t('File Name') }}</th>
          <th class="list-view__type-header msi-table-header">{{ t('Type') }}</th>
          <th class="list-view__owner-header msi-table-header">{{ t('Officer') }}</th>
          <th class="list-view__created-header msi-table-header">{{ t('Date & Time') }}</th>
        </tr>
      </thead>

      <tbody>
        <section
          [class.assets-loading]="assetsLoading && fullScreenEnabled"
          [ngClass]="fullScreenEnabled ? 'list-view__wrapper-with-hover' : 'list-view__data-wrapper'">
          @if (assetsLoading && fullScreenEnabled) {
            <div class="ccs-assets-loading">
              <msi-spinner size="medium"></msi-spinner>
            </div>
          }
          <ng-container *ngFor="let asset of assets">
            <tr
              class="list-view__row"
              [class.active]="asset | activeAsset: activeAsset">
              <div
                class="list-view__row-container"
                [class.not-sharable-asset]="asset | notSharableAsset"
                (click)="onClick($event, asset)">
                <span
                  class="list-view__file-name"
                  [class.regular-size]="!fullScreenEnabled"
                  msiTooltip="{{ asset | assetDisplayName: this.assetNameUntitled : this.tagsUnassigned }}"
                  position="left">
                  {{ asset | assetDisplayName: this.assetNameUntitled : this.tagsUnassigned }}
                </span>
                <section class="list-view__type">
                  <span class="list-view__icon">
                    <msi-icon
                      [class.not-sharable-icon]="asset | notSharableAsset"
                      name="{{ asset | evidIconName }}"></msi-icon>
                  </span>
                  <span
                    class="list-view__type-name"
                    msiTooltip="{{ asset | assetType }}"
                    position="left">
                    {{ asset | assetType }}
                  </span>
                  <span
                    *ngIf="asset.assetStorageType === 'externalLink'"
                    class="share-warning">
                    <msi-icon
                      name="ic_share_warning"
                      size="18"
                      class="share-warning__icon"
                      msiTooltip="{{ t('Incomplete assets cannot be shared') }}"></msi-icon>
                  </span>
                </section>
                <span
                  *ngIf="asset.ownerDisplayName"
                  class="list-view__owner"
                  [class.regular-size-owner]="!fullScreenEnabled"
                  msiTooltip="{{ asset | ownerDisplayName: userUnknown }}"
                  position="left">
                  {{ asset | ownerDisplayName: userUnknown }}
                </span>
                <span
                  *ngIf="!asset.ownerDisplayName"
                  class="list-view__owner"
                  [class.regular-size-owner]="!fullScreenEnabled">
                  {{ asset | ownerDisplayName: userUnknown }}
                </span>
                <section class="list-view__created">
                  <span
                    msiTooltip="{{ (asset.startTime | date: 'MM/dd/yyyy') || '--' }}"
                    position="left">
                    {{ (asset.startTime | date: 'MM/dd/yyyy') || '--' }}
                  </span>
                  <span
                    *ngIf="asset.assetStorageType === 'directory'"
                    class="share-warning">
                    <msi-icon
                      name="ic_share_warning"
                      size="18"
                      class="share-warning__icon"
                      msiTooltip="{{ t('File is too large to share') }}"></msi-icon>
                  </span>
                </section>
              </div>
              <span class="list-view__remove">
                <msi-icon
                  name="ic_remove"
                  (click)="onRemove(asset)">
                </msi-icon>
              </span>
            </tr>
          </ng-container>
        </section>
      </tbody>
    </table>
  </main>
</ng-container>
