/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
export enum EActionControls {
  CREATE = 'create',
  UPDATE = 'update',
  MODIFY = 'modify',
  RENEW = 'renew',
  DELETE = 'delete',
  RESHARE = 'reshare',
  UPDATE_RESHARE = 'update_reshare',
  PRINT = 'print',
  ADD_TO_CASE = 'add_to_case',
  PRINT_PREVIEW = 'print_preview',
  CLASSIC_VIEW = 'classic_view',
  VIEW_AUDIT_LOG = 'view_audit_log',
  DOWNLOAD = 'download',
  COPY_ID = 'copy_id',
}
