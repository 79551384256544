/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  IShareableAsset,
  ShareDataComponent,
  ShareDataService,
} from '@msi/commandcentral-share-data';
import { ShareConfigService } from '@msi/commandcentral-share-data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { additionalItems, items } from '../test/mock-data';

@UntilDestroy()
@Component({
  selector: 'ccs-share-data-sandbox',
  templateUrl: './sandbox-share-data.component.html',
  styleUrls: ['./sandbox-share-data.component.scss'],
})
export class ShareDataSandboxComponent implements OnInit {
  private assets: IShareableAsset[] = items.map((item) => {
    const updatedAsset: IShareableAsset = item;
    (updatedAsset as any).agencyId = item.agencyId || '';

    return updatedAsset;
  });
  guestMode = false;
  error: any;
  shareModalFullscreenEnabled: boolean;

  @ViewChild('shareDataModal')
  shareDataComponent: ShareDataComponent;

  constructor(
    private ccsService: ShareDataService,
    private shareConfigService: ShareConfigService
  ) {}

  ngOnInit(): void {
    this.shareConfigService.shareModalFullscreen$
      .pipe(untilDestroyed(this))
      .subscribe((fullscreen: boolean) => {
        this.shareModalFullscreenEnabled = fullscreen;
      });
  }

  onOpenShareModal(): void {
    this.shareDataComponent.openOrTogglePopUpModal();
    this.ccsService.setAssets(this.assets);
  }

  addMoreAssets(): void {
    this.shareDataComponent.openOrTogglePopUpModal();
    this.ccsService.setAssets(additionalItems);
  }
}
