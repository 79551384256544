/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ToastService } from '@msi/cobalt';
import { Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, EMPTY } from 'rxjs';

import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../services/elastic-apm/elastic-apm.decorators';
import { IPackage } from '../../services/package/package.interfaces';
import { PackageService } from '../../services/package/package.service';
import { ISettings } from '../../settings/settings.interface';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@UntilDestroy()
@Component({
  selector: 'pp-regenerate-package-modal',
  templateUrl: './regenerate-package-modal.component.html',
  styleUrls: ['./regenerate-package-modal.component.scss'],
})
@ApmTransactionComponent('renew modal')
export class RegeneratePackageModalComponent {
  @ViewChild('regeneratePackageModal', { static: true })
  regeneratePackageModal: ConfirmationModalComponent;

  @Output()
  done: EventEmitter<IPackage> = new EventEmitter();

  package: IPackage = null;
  modalBody =
    'Renewing to new link will disable the old link. Are you sure you want to continue?';

  constructor(
    private _settings: Settings<ISettings>,
    private _packageService: PackageService,
    private _toastService: ToastService,
    private _transloco: TranslocoService
  ) {}

  @ApmTransactionMethod('renew package')
  onRegeneratePackageConfirmed(): void {
    const toastOptions: Record<string, number | boolean> = {
      closeButton: true,
      autoDismiss: this._settings.get<number>('AUTODISMISS'),
    };
    this._packageService
      .regenerate(this.package)
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this._toastService.error(
            this._transloco.translate(
              'Unauthenticated package link was not generated. Server error.'
            ),
            void 0,
            toastOptions
          );
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.done.emit(this.package);
      });
  }

  open(pkg: IPackage): void {
    this.package = pkg;
    this.regeneratePackageModal.setBody(this.modalBody);
    this.regeneratePackageModal.open();
  }
}
