<div class="pp-packages-table">
  <div
    *ngIf="loading"
    class="pp-packages-loading">
    <msi-spinner size="medium"></msi-spinner>
  </div>

  <div class="pp-packages-table_header">
    <table
      class="msi-table"
      #tableHeader>
      <thead>
        <tr>
          <th
            msi-table-header
            sortable
            [sortDirection]="fieldsName.title"
            (sort)="onSort('title', $event)"
            class="pp-col-2">
            {{ 'Title' | transloco }}
          </th>
          <th
            msi-table-header
            sortable
            [sortDirection]="fieldsName.agencyRecord"
            (sort)="onSort('agencyRecord', $event)"
            class="pp-col-3">
            {{ 'Description' | transloco }}
          </th>
          <th
            msi-table-header
            sortable
            [sortDirection]="fieldsName.agencyName"
            (sort)="onSort('agencyName', $event)"
            class="pp-col-4">
            {{ 'From' | transloco }}
          </th>
          <th
            msi-table-header
            sortable
            [sortDirection]="fieldsName.updated"
            (sort)="onSort('updated', $event)"
            class="pp-col-5 text-right">
            {{ 'Received on' | transloco }}
          </th>
          <th
            #sharedWithColumn
            msi-table-header
            class="pp-col-6">
            {{ 'Shared with' | transloco }}
          </th>
          <th
            msi-table-header
            class="pp-col-7 text-right">
            {{ 'Viewed' | transloco }}
          </th>
          <th
            msi-table-header
            sortable
            [sortDirection]="fieldsName.validTo"
            (sort)="onSort('validTo', $event)"
            class="pp-col-8 text-right">
            {{ 'Expires in' | transloco }}
          </th>
          <th
            msi-table-header
            class="pp-col-9"></th>
        </tr>
      </thead>
    </table>
  </div>
  <div
    class="pp-packages-table_body"
    ppInfiniteScroll
    [ppISLoading]="loading"
    (ppISBottom)="onLoadMore()">
    <table
      class="msi-table"
      [ppTableScrollWidth]="tableHeader">
      <tbody>
        <ng-template
          ngFor
          let-row
          [ngForOf]="packages"
          let-i="index">
          <tr
            [class.vieved-by-user]="!row.wasReadByCurrentUser"
            class="msi-hover msi-focus"
            [class.hovered]="isDropdownOpenedOnTheSameRow(row.id)"
            (mouseover)="closeDropdownInUnfocusedRow(row.id)">
            <td class="pp-col-2">
              <a
                class="trim-text msi-link"
                [routerLink]="defineRouterLink(row)">
                <span
                  msiTooltip="{{ row.tooltipName }}"
                  [innerHTML]="row.name"></span>
              </a>
            </td>
            <td class="pp-col-3">
              <a
                class="trim-text msi-link"
                [routerLink]="defineRouterLink(row)">
                <span
                  msiTooltip="{{ row.tooltipAgencyRecord }}"
                  [innerHTML]="row.agencyRecord"></span>
              </a>
            </td>
            <td class="pp-col-4">
              <a
                class="trim-text msi-link"
                [routerLink]="defineRouterLink(row)">
                <span
                  msiTooltip="{{ row.tooltipAgencyName }}"
                  [innerHTML]="row.agencyName"></span>
              </a>
            </td>
            <td class="pp-col-5 text-right">
              <a
                class="trim-text msi-link"
                [routerLink]="defineRouterLink(row)">
                <span [innerHTML]="row.created"></span>
              </a>
            </td>
            <td class="pp-col-6">
              <a
                class="trim-text-only msi-link"
                [routerLink]="defineRouterLink(row)">
                <pp-more-details
                  [columnRef]="sharedWithColumn.elementRef"
                  [accessType]="row.accessType"
                  [details]="row.resharedWith"></pp-more-details>
              </a>
            </td>

            <td class="pp-col-7 text-right">
              <a
                class="trim-text msi-link"
                [routerLink]="defineRouterLink(row)">
                <span
                  *ngIf="row.firstViewed"
                  msiTooltip="{{ 'Viewed' | transloco }}&nbsp;{{ row.firstViewed }}">
                  <msi-icon name="ic_check"></msi-icon>
                </span>
              </a>
            </td>
            <td class="pp-col-8 text-right">
              <a
                class="trim-text msi-link"
                [routerLink]="defineRouterLink(row)">
                {{ row.expireTime }}&nbsp;{{ row.timeIndicator | transloco }}
              </a>
            </td>
            <td class="pp-col-9">
              <pp-actions-dropdown
                #v2actionsDropdown
                *ngIf="row.isPackageVersionV2; else v1actions"
                [actions]="row.packageActions"
                (auditLog)="onViewAuditLog(row)">
              </pp-actions-dropdown>
              <ng-template #v1actions>
                <pp-action-controls
                  #v1actionsDropdown
                  [data]="row"
                  [options]="getActionOptions(row)"
                  [readAuditLogsPermission]="readAuditLogsPermission"
                  (action)="onAction($event)"></pp-action-controls>
              </ng-template>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>

  <div
    *ngIf="!loading && !packages.length"
    class="pp-packages-empty">
    <msi-icon
      name="ic_search"
      class="pp-packages-empty_icon"
      size="36"></msi-icon>
    <span>{{ 'No received packages meet filtering criteria' | transloco }}</span>
  </div>
</div>

<ng-template [ngIf]="selectedPackageItem && additionalInfo">
  <pp-reshare-package
    #reshareComponent
    [packageItem]="selectedPackageItem"
    [additionalInfo]="additionalInfo"
    (done)="onReshareDone($event)"></pp-reshare-package>
</ng-template>
