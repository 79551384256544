/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { IOffence } from './Offense.interfaces';
class Offence {
  private _props: IOffence;

  public statute: string;
  public IBRCode: string;
  public offenseCode: string;
  public description: string;

  public offenseAttempted: string;
  public cargoTheft: string;
  public locationType: string;
  public methodOfEntry: string;
  public premisesEntered: string;
  public offendersSuspectedOfUsing: string[];
  public typeOfCriminalActivity: string[];
  public typeOfWeaponForce: string[];

  constructor(props: IOffence) {
    this._props = props;

    this.statute = this.getAttribute('statute') || '';
    this.IBRCode = this.getAttribute('IBRCode') || this.getAttribute('ucrCode') || '';
    this.offenseCode = this._props.code || '';
    this.description = this._props._code_codeDetails?.description || '';

    this.offenseAttempted = this._props.attempted || '';
    this.cargoTheft = this._props.cargoTheft || '';
    this.locationType = this._props.locationType || '';
    this.methodOfEntry = this._props.methodOfEntry || '';
    this.premisesEntered = this._props.premisesEntered?.toString() || '';
    this.offendersSuspectedOfUsing = this._props.offenderSuspectedOfUsing || [];
    this.typeOfCriminalActivity = this._props.typeOfCriminalActivity || [];
    this.typeOfWeaponForce = this._props.typeOfWeaponForce || [];
  }

  getAttribute(name: string): string {
    let value = '';

    if (this._props._code_codeDetails?.attributes?.length) {
      const len: number = this._props._code_codeDetails.attributes.length;

      for (let i = 0; i < len; i++) {
        if (this._props._code_codeDetails.attributes[i].name === name) {
          value = this._props._code_codeDetails.attributes[i].value;

          break;
        }
      }
    }

    return value;
  }
}

export { Offence };
