/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
export function generatePassword(): string {
  const numbers = '0123456789';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const specialCharacters = '!@#$%^&*()_+-=[]{}|;:,.<>/?';

  let randomPassword = '';
  let characterIndex: number;

  const arrayOfNumbers = generateRandomNumbersBetween1And12ThatSumUpTo16();
  // Password is always generated in
  // <Uppercase><Special><Int><Lower> order

  for (let i = 0; i < arrayOfNumbers[1]; i++) {
    characterIndex = Math.floor(getRandom() * upperCaseLetters.length);
    randomPassword += upperCaseLetters.charAt(characterIndex);
  }

  for (let i = 0; i < arrayOfNumbers[2]; i++) {
    characterIndex = Math.floor(getRandom() * specialCharacters.length);
    randomPassword += specialCharacters.charAt(characterIndex);
  }

  for (let i = 0; i < arrayOfNumbers[0]; i++) {
    characterIndex = Math.floor(getRandom() * numbers.length);
    randomPassword += numbers.charAt(characterIndex);
  }

  for (let i = 0; i < arrayOfNumbers[3]; i++) {
    characterIndex = Math.floor(getRandom() * lowerCaseLetters.length);
    randomPassword += lowerCaseLetters.charAt(characterIndex);
  }

  return randomPassword;
}

function getRandom(): number {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);

  return array[0] / 2 ** 32; // Normalize to [0, 1)
}

function generateRandomNumbersBetween1And12ThatSumUpTo16(): number[] {
  const numbers = [];
  let sum = 0;
  for (let i = 0; i < 3; i++) {
    const remainingSum = 16 - sum - (3 - i);
    const maxNum = Math.min(remainingSum, 12);
    const number = Math.floor(getRandom() * maxNum) + 1;
    sum += number;
    numbers.push(number);
  }
  numbers.push(16 - sum);
  return numbers;
}
