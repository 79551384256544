/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from '@msi/js-sdk';

import { DownloadItemDTO } from '../../../common/DownloadManager/DownloadItemDTO';
import { EDownloadStatus } from '../../../common/DownloadManager/enums';

@Component({
  selector: 'pp-download-item',
  templateUrl: './download-item.component.html',
  styleUrls: ['./download-item.component.scss'],
})
export class DownloadItemComponent implements OnInit {
  @Input()
  item: DownloadItemDTO;

  @Output()
  action: EventEmitter<[string, DownloadItemDTO]> = new EventEmitter();

  name = '';
  downloadStatus = EDownloadStatus;
  maxLen = 40;

  mapDownloadStatus: Record<string, string> = {
    [EDownloadStatus.NEW]: 'New',
    [EDownloadStatus.IN_PROGRESS]: 'In progress',
    [EDownloadStatus.PENDING]: 'Pending',
    [EDownloadStatus.ERROR]: 'Error',
    [EDownloadStatus.CANCEL]: 'Canceled',
    [EDownloadStatus.DONE]: 'Done',
    [EDownloadStatus.RESUME]: 'Resume',
  };

  ngOnInit(): void {
    this.name = Utils.truncate(this.item.path + this.item.name, this.maxLen);
  }

  onAction(action: string, item: DownloadItemDTO): void {
    this.action.emit([action, item]);
  }
}
