/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ApmModule } from '@elastic/apm-rum-angular';
import { MsiCommonModule, ToastService } from '@msi/cobalt';
import { Api, LocalStorage, Settings } from '@msi/js-sdk';
import { EmmAssetViewerModule, MsiAssetViewerModule } from '@msi/msi-asset-viewer';
import { STREAMING_METADATA_API_CONFIGURATION } from '@msi/msi-download-manager';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { PackageDetailsEffects } from './components/+store/effects/detail-package.effects';
import { FeatureFlagsEffects } from './components/+store/effects/feature-flags.effects';
import { PackagesEffects } from './components/+store/effects/packages.effects';
import { detailPackageReducer } from './components/+store/reducers/detail-package.reducer';
import { featureFlagsReducer } from './components/+store/reducers/feature-flags.reducer';
import { packagesReducer } from './components/+store/reducers/packages.reducer';
import { AssetWarningComponent } from './components/asset-warning/asset-warning.component';
import { AccessMethodSelectComponent } from './components/common/access-method-select/access-method-select.component';
import { ActionControlsComponent } from './components/common/action-controls/action-controls.component';
import { ActionsDropdownComponent } from './components/common/actions-dropdown/actions-dropdown.component';
import { AssetEntityTileComponent } from './components/common/asset-entity-tile/asset-entity-tile.component';
import { ErrorBlockComponent } from './components/common/error-block/error-block.component';
import { ExpiresComponent } from './components/common/expires/expires.component';
import { MoreDetailsComponent } from './components/common/more-details/more-details.component';
import { ProsecutorsComponent } from './components/common/prosecutors/prosecutors.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { CreatePackageComponent } from './components/create-package/create-package.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DeletePackageModalComponent } from './components/delete-package-modal/delete-package-modal.component';
import { DetailPackageAssetGalleryViewComponent } from './components/detail-package/detail-package-assets/detail-package-asset-gallery-view/detail-package-asset-gallery-view.component';
import { DetailPackageAssetListViewComponent } from './components/detail-package/detail-package-assets/detail-package-asset-list-view/detail-package-asset-list-view.component';
import { DetailPackageAssetsComponent } from './components/detail-package/detail-package-assets/detail-package-assets.component';
import { DetailPackageChainOfCustodyComponent } from './components/detail-package/detail-package-chainofcustody/detail-package-chainofcustody.component';
import { DetailPackageHeaderComponent } from './components/detail-package/detail-package-header/detail-package-header.component';
import { DetailPackageMenuComponent } from './components/detail-package/detail-package-menu/detail-package-menu.component';
import { DetailPackageNarrativeComponent } from './components/detail-package/detail-package-narrative/detail-package-narrative.component';
import { DetailPackageOrganizationsComponent } from './components/detail-package/detail-package-organizations/detail-package-organizations.component';
import { DetailPackageOverviewComponent } from './components/detail-package/detail-package-overview/detail-package-overview.component';
import { DetailPackagePersonsComponent } from './components/detail-package/detail-package-persons/detail-package-persons.component';
import { DetailPackagePropertiesComponent } from './components/detail-package/detail-package-properties/detail-package-properties.component';
import { DetailPackageProviderComponent } from './components/detail-package/detail-package-provider/detail-package-provider.component';
import { DetailPackageSubscribeComponent } from './components/detail-package/detail-package-subscribe/detail-package-subscribe.component';
import { DetailPackageUnsubscribeComponent } from './components/detail-package/detail-package-unsubscribe/detail-package-unsubscribe.component';
import { DetailPackageVehicleComponent } from './components/detail-package/detail-package-vehicle/detail-package-vehicle.component';
import { DigitalEvidenceSortingComponent } from './components/digital-evidence-sorting/digital-evidence-sorting.component';
import { DownloadItemComponent } from './components/download-manager-modal/download-item/download-item.component';
import { DownloadManagerConfirmCloseComponent } from './components/download-manager-modal/download-manager-confirm-close/download-manager-confirm-close.component';
import { DownloadManagerModalComponent } from './components/download-manager-modal/download-manager-modal.component';
import { FilterPackagesComponent } from './components/filter-packages/filter-packages.component';
import { GroupDigitalEvidenceComponent } from './components/group-digital-evidence/group-digital-evidence.component';
import { MainPackagesComponent } from './components/main-packages/main-packages.component';
import { ModifyPackageComponent } from './components/modify-package/modify-package.component';
import { PackagesHeaderComponent } from './components/packages/header/header.component';
import { PackagesComponent } from './components/packages/packages/packages.component';
import { PackagesSearchComponent } from './components/packages/search/search.component';
import { PackagesTableComponent } from './components/packages/table/table.component';
import { PasswordCreatorComponent } from './components/password-creator/password-creator.component';
import { PasswordValidatorComponent } from './components/password-validator/password-validator.component';
import { RegeneratePackageModalComponent } from './components/regenerate-package-modal/regenerate-package-modal.component';
import { ResharePackageComponent } from './components/reshare-package/reshare-package.component';
import { PackagesShareManagerComponent } from './components/share-manager/packages-share-manager/packages-share-manager.component';
import { ShareManagerTableComponent } from './components/share-manager/table/share-manager-table.component';
import { SingleShareManagerComponent } from './components/single-share-manager/single-share-manager.component';
import { UpdatePackageContentComponent } from './components/update-package-content/update-package-content.component';
import { InfiniteScrollDirective } from './directives/infinite-scroll/infinite-scroll.directive';
import { TableScrollWidthDirective } from './directives/table-scroll-width/table-scroll-width.directive';
import { info } from './info';
import { ProtectedPackageInterceptor } from './interceptors/protected-package.interceptor';
import { DateTimeUtilsPipe } from './pipes/datatimeutils/datetimeutils.pipe';
import { IncidentRedirectUrlPipe } from './pipes/incident-redirect-url/incident-redirect-url.pipe';
import { PackageDetailsRedirectUrlPipe } from './pipes/package-details-redirect-url/package-details-redirect-url.pipe';
import { AntiThrottleService } from './services/anti-throttle/anti-throttle.service';
import { AssetEntityService } from './services/asset-entity/asset-entity.service';
import { AssetVerificationService } from './services/asset-verification/asset-verification.service';
import { AssetViewerConfigurationService } from './services/asset-viewer-config/asset-viewer-config.service';
import { AuditLogService } from './services/audit-log/audit-log.service';
import { ChainOfCustodyService } from './services/chain-of-custody/chain-of-custody.service';
import { PasswordChangeService } from './services/change-password/change-password.service';
import { DerivedItemService } from './services/derived-item/derived-item.service';
import { DetailPackageService } from './services/detail-package/detail-package.service';
import { DownloableLinkService } from './services/downloadable-link/downloable-link.service';
import { ElasticApmService } from './services/elastic-apm/elastic-apm.service';
import { EvidenceService } from './services/evidence-service/evidence.service';
import { FeatureFlagsService } from './services/feature-flags/feature-flags.service';
import { FilterService } from './services/filter/filter.service';
import { LawIncidentService } from './services/law-incident/law-incident.service';
import { MFLoaderService } from './services/mf-loader/mf-loader.service';
import { OfficerService } from './services/officer/officer.service';
import { PackageService } from './services/package/package.service';
import { PackageUpdatesService } from './services/package/package-updates.service';
import { PackageDiffService } from './services/package-diff/package-diff.service';
import { PlayableLinkService } from './services/playable-link/playable-link.service';
import { PrintService } from './services/print/print.service';
import { SidebarService } from './services/sidebar/sidebar.service';
import { StreamingMetadataService } from './services/streaming-metadata/streaming-metadata.service';
import { SystemService } from './services/system-service/system.service';
import { UserService } from './services/user/user.service';
import { settings } from './settings/settings';
import { GlobalStore, store } from './stores/global/store';
import { TranslocoRootModule } from './transloco/transloco-root.module';

(window as any).PP_COMPONENTS_INFO = info;

@NgModule({
  declarations: [
    InfiniteScrollDirective,
    TableScrollWidthDirective,
    ExpiresComponent,
    ProsecutorsComponent,
    AssetEntityTileComponent,
    ActionControlsComponent,
    MoreDetailsComponent,
    SidebarComponent,
    CreatePackageComponent,
    ResharePackageComponent,
    ModifyPackageComponent,
    UpdatePackageContentComponent,
    PackagesHeaderComponent,
    PackagesSearchComponent,
    PackagesTableComponent,
    PackagesComponent,
    DetailPackageSubscribeComponent,
    DetailPackageUnsubscribeComponent,
    DetailPackageHeaderComponent,
    DetailPackageMenuComponent,
    DetailPackageOverviewComponent,
    DetailPackageNarrativeComponent,
    DetailPackageVehicleComponent,
    DetailPackagePersonsComponent,
    DetailPackageOrganizationsComponent,
    DetailPackageChainOfCustodyComponent,
    DetailPackagePropertiesComponent,
    DetailPackageAssetsComponent,
    DetailPackageProviderComponent,
    ShareManagerTableComponent,
    PackagesShareManagerComponent,
    ConfirmationModalComponent,
    DeletePackageModalComponent,
    RegeneratePackageModalComponent,
    SingleShareManagerComponent,
    DateTimeUtilsPipe,
    IncidentRedirectUrlPipe,
    ErrorBlockComponent,
    DownloadManagerModalComponent,
    DownloadItemComponent,
    DownloadManagerConfirmCloseComponent,
    AssetWarningComponent,
    DetailPackageAssetGalleryViewComponent,
    DetailPackageAssetListViewComponent,
    DigitalEvidenceSortingComponent,
    PackageDetailsRedirectUrlPipe,
    GroupDigitalEvidenceComponent,
    MainPackagesComponent,
    FilterPackagesComponent,
    ActionsDropdownComponent,
    PasswordCreatorComponent,
    PasswordValidatorComponent,
    AccessMethodSelectComponent,
    DatePickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    MsiCommonModule,
    MsiAssetViewerModule,
    EmmAssetViewerModule,
    ApmModule,
    TranslocoRootModule,
    ClipboardModule,
    RouterModule,
    StoreModule.forRoot({
      detailPackage: detailPackageReducer,
      featureFlags: featureFlagsReducer,
      packages: packagesReducer,
    }),
    EffectsModule.forRoot([PackageDetailsEffects, FeatureFlagsEffects, PackagesEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
  ],
  exports: [
    InfiniteScrollDirective,
    TableScrollWidthDirective,
    ExpiresComponent,
    ProsecutorsComponent,
    AssetEntityTileComponent,
    ActionControlsComponent,
    MoreDetailsComponent,
    SidebarComponent,
    CreatePackageComponent,
    ResharePackageComponent,
    ModifyPackageComponent,
    UpdatePackageContentComponent,
    PackagesHeaderComponent,
    PackagesSearchComponent,
    PackagesTableComponent,
    PackagesComponent,
    DetailPackageSubscribeComponent,
    DetailPackageUnsubscribeComponent,
    DetailPackageHeaderComponent,
    DetailPackageMenuComponent,
    DetailPackageOverviewComponent,
    DetailPackageNarrativeComponent,
    DetailPackageVehicleComponent,
    DetailPackagePersonsComponent,
    DetailPackageOrganizationsComponent,
    DetailPackageChainOfCustodyComponent,
    DetailPackagePropertiesComponent,
    DetailPackageAssetsComponent,
    DetailPackageProviderComponent,
    ShareManagerTableComponent,
    PackagesShareManagerComponent,
    ConfirmationModalComponent,
    DeletePackageModalComponent,
    RegeneratePackageModalComponent,
    SingleShareManagerComponent,
    DateTimeUtilsPipe,
    IncidentRedirectUrlPipe,
    ErrorBlockComponent,
    DownloadManagerModalComponent,
    DownloadItemComponent,
    DownloadManagerConfirmCloseComponent,
    AssetWarningComponent,
    PackageDetailsRedirectUrlPipe,
    FilterPackagesComponent,
    MainPackagesComponent,
    PasswordValidatorComponent,
  ],
  providers: [
    UserService,
    OfficerService,
    AssetEntityService,
    AssetVerificationService,
    DerivedItemService,
    PlayableLinkService,
    DownloableLinkService,
    PackageService,
    PackageUpdatesService,
    FilterService,
    PackageDiffService,
    DatePipe,
    ToastService,
    LawIncidentService,
    ChainOfCustodyService,
    PrintService,
    ElasticApmService,
    AuditLogService,
    SidebarService,
    MFLoaderService,
    SystemService,
    AntiThrottleService,
    DetailPackageService,
    StreamingMetadataService,
    AssetViewerConfigurationService,
    FeatureFlagsService,
    PasswordChangeService,
    {
      provide: Api,
      useValue: new Api(),
    },
    {
      provide: LocalStorage,
      useValue: new LocalStorage(),
    },
    {
      provide: GlobalStore,
      useValue: store,
    },
    {
      provide: Settings,
      useValue: settings,
    },
    {
      provide: STREAMING_METADATA_API_CONFIGURATION,
      useExisting: EvidenceService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProtectedPackageInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PPComponentsModule {
  constructor(
    private _elasticApm: ElasticApmService,
    private _systemService: SystemService
  ) {}
}
