<ng-template #modalTemplate>
  <msi-modal>
    <msi-modal-header>{{ modalTitle | transloco }}</msi-modal-header>
    <msi-modal-content>
      {{ modalBody | transloco }}
    </msi-modal-content>
    <msi-modal-actions align="end">
      <button
        id="cancel-modal-btn"
        type="button"
        class="msi-btn msi-btn-action"
        (click)="onCancel()">
        {{ 'Cancel' | transloco }}
      </button>
      <button
        id="confirm-modal-btn"
        (click)="onConfirm()"
        type="button"
        class="msi-btn spinner-parent">
        {{ confirmationButtonText }}
      </button>
    </msi-modal-actions>
    <div
      *ngIf="showDisclaimer"
      class="disclaimer">
      <strong>{{ 'Warning' | transloco }}</strong>
      {{
        'By pressing ‘Send’ you are acknowledging that anyone with this link can view the material.'
          | transloco
      }}
    </div>
  </msi-modal>
</ng-template>
