/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { IShareEnvironment } from './models/config.interfaces';

export const environments: IShareEnvironment[] = [
  {
    domain: ['local.commandcentral.com', 'dev.commandcentral.com'],
    env: 'dev',
    baseUrl: 'https://sharing-api.dev.commandcentral.com/package-manager/v1',
  },
  {
    domain: ['usgov-stage.commandcentral.com', 'stage.commandcentral.com'],
    env: 'usgov-stage',
    baseUrl: 'https://sharing-api.stage.commandcentral.com/package-manager/v1',
  },
  {
    domain: ['usgov.commandcentral.com', 'commandcentral.com'],
    env: 'usgov',
    baseUrl: 'https://sharing-api.commandcentral.com/package-manager/v1',
  },
  {
    domain: ['fed-stage.commandcentral.com'],
    env: 'fed-stage',
    baseUrl: 'https://sharing-api.fed-stage.commandcentral.com/package-manager/v1',
  },
  {
    domain: ['fed.commandcentral.com'],
    env: 'fed',
    baseUrl: 'sharing-api.fed-prod.commandcentral.com/package-manager/v1',
  },
  {
    domain: ['sec.commandcentral.ca'],
    env: 'cccs-prod',
    baseUrl: 'https://sharing-api.sec.commandcentral.ca/package-manager/v1',
  },
  {
    domain: ['-preprod.sec.commandcentral.ca'],
    env: 'cccs-preprod',
    baseUrl: 'https://sharing-api-preprod.sec.commandcentral.ca/package-manager/v1',
  },
];
