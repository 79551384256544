/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Utils } from '@msi/js-sdk';

import {
  EActionType,
  IAuditLogQueryParams,
} from '../services/audit-log/audit-log.interfaces';
import { EDetailPackageMode } from '../services/package/package.enums';
import { settings } from './settings';

export type EnvNames =
  | 'local'
  | 'dev'
  | 'usgov'
  | 'usgov-stage'
  | 'stage'
  | 'prod'
  | 'fed-stage'
  | 'canada-prod'
  | 'canada-preprod'
  | string;

export class UrlSettings {
  static getAuditViewerUrl(env: string, params: IAuditLogQueryParams): string {
    const queryParams = `?object=${params.packageId}&startDate=${params.startDate}&endDate=${params.endDate}&application=${params.application}`;

    return `${UrlSettings.getBaseAuditLogUrl(env)}/auditviewer/${queryParams}`;
  }

  static getBaseAuditLogUrl(env: EnvNames): string {
    switch (env) {
      case 'usgov':
        return 'https://observability-usva.usgov.commandcentral.com';
      case 'cccs-preprod':
        return 'https://observability-cacentral-preprod.sec.commandcentral.ca';
      case 'cccs-prod':
        return 'https://observability-cacentral.sec.commandcentral.ca';
      default:
        return 'https://observability.pi.commandcentral.com';
    }
  }

  static getAuditLogUrl(
    action: EActionType,
    mode: EDetailPackageMode,
    printAction?: EActionType
  ): string {
    const currentAction = printAction ? printAction : action;
    const domain: string = settings.get<string>('PLATFORM.API' as any);
    const auditLogUri = this.prepareAuditLogUri(currentAction);
    let auditLogUrl: string = Utils.format(settings.get('PM_AUDIT_LOG_ENDPOINT'), {
      domain,
    });

    if (mode === EDetailPackageMode.GUESTS || mode === EDetailPackageMode.PROTECTED) {
      auditLogUrl = Utils.format(settings.get('PROXY_AUDIT_LOG_ENDPOINT'), { domain });
    }
    return `${auditLogUrl}/${auditLogUri}`;
  }

  static prepareAuditLogUri(action: EActionType): string {
    switch (action) {
      case EActionType.Print:
        return 'package-print';
      case EActionType.PrintPreview:
        return 'package-print';
      case EActionType.Download:
        return 'package-assets-bulk-action';
      default:
        return 'package-asset-action';
    }
  }
}
