/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { ESharesType } from '../../services/filter/filter.enums';
import { getFeatureFlag } from '../+store/selectors/feature-flags.selector';
import { IPackageFilter } from './filter.interface';

@Component({
  selector: 'pp-filter-packages',
  templateUrl: './filter-packages.component.html',
  styleUrls: ['./filter-packages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPackagesComponent implements OnChanges, OnInit {
  @Input() current: ESharesType[];
  @Input() isReceived: boolean;
  @Output() changed: EventEmitter<Array<ESharesType>> = new EventEmitter();

  extendedPackageFiltersFlag = false;
  filters: Array<ESharesType> = [ESharesType.ACTIVE];
  baseOptions: Array<IPackageFilter> = [
    { id: ESharesType.ACTIVE, name: 'Active' },
    { id: ESharesType.EXPIRED, name: 'Expired' },
    { id: ESharesType.RESHARED, name: 'Reshared' },
  ];
  optionsReceived: Array<IPackageFilter> = [...this.baseOptions];
  optionsShared: Array<IPackageFilter> = [...this.baseOptions];

  constructor(private _store: Store) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.filters.toString() !== changes.current.currentValue.toString()) {
      this.filters = changes.current.currentValue;
      this.changed.emit(this.filters);
    }
  }

  ngOnInit(): void {
    this._store
      .select(getFeatureFlag('extended-package-filters'))
      .subscribe((value: boolean) => {
        this.extendedPackageFiltersFlag = value;
        if (this.extendedPackageFiltersFlag) {
          this.prepareOptions();
        }
      });
  }

  changeFilter(option: IPackageFilter): void {
    if (this.filters.includes(option.id)) {
      this.filters = this.filters.filter((optionID) => optionID != option.id);
    } else {
      this.filters = this.filters.concat(option.id);
    }
    this.changed.emit(this.filters);
  }

  private prepareOptions() {
    if (this.extendedPackageFiltersFlag) {
      this.optionsReceived.push({ id: ESharesType.UNREAD, name: 'Unread' });
      this.optionsShared.push({ id: ESharesType.LOCKED, name: 'Locked' });
    }
  }
}
