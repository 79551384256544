/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

export enum EExpiresDate {
  ExpiresIn30Days = '30 days',
  ExpiresIn90Days = '90 days',
  ExpiresIn120Days = '120 days',
  ExpiresIn365Days = '365 days',
  Never = 'Never',
}
