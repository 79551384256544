/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AutocompleteComponent } from '@msi/cobalt';
import { Utils } from '@msi/emm-sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { EKeyCode } from '../../../common/enums';
import { Officer } from '../../../services/officer/Officer';
import { ISimpleOfficer } from '../../../services/officer/officer.interfaces';
import { OfficerService } from '../../../services/officer/officer.service';
import { Email } from '../../../services/package/Email';
import { ProsecutorValidator, TPros } from './prosecutor-validator';
@UntilDestroy()
@Component({
  selector: 'pp-prosecutors',
  templateUrl: './prosecutors.component.html',
  styleUrls: ['./prosecutors.component.scss'],
})
export class ProsecutorsComponent implements OnInit, OnChanges, OnDestroy {
  private _pv: ProsecutorValidator = new ProsecutorValidator();

  @Input() sentRecipients: (ISimpleOfficer | string)[];
  @Input() authMode = true;
  @Input() isReshareModal = false;
  @Output() updateSelectedProsecutors: EventEmitter<TPros[]> = new EventEmitter();
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('list', { static: false }) list: AutocompleteComponent;

  loadingOfficers = true;
  serviceError: string;
  allProsecutors: Officer[] = [];
  filteredProsecutors: Officer[] = [];
  selectedProsecutors: TPros[];
  errorField = '';
  search = '';
  debounceFilter: (value?: string) => void;

  constructor(private _officerService: OfficerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.list?.requestClose) {
      this.list.requestClose.emit();
      this.markErrors(changes.authMode.currentValue);
    }
  }

  ngOnInit() {
    this.debounceFilter = Utils.debounce((value: string) => this.onFilter(value));
    if (this.isReshareModal) {
      // reshare can be only performed in unauthenticated mode; no need to fetch recipients
      this.loadPreviousRecipients();
    } else {
      this.fetchRecipients();
    }

    this._officerService.loadingOfficers$
      .pipe(untilDestroyed(this))
      .subscribe((loading: boolean) => {
        this.loadingOfficers = loading;
      });

    this._officerService.recipientsError$
      .pipe(untilDestroyed(this))
      .subscribe((error: string) => {
        this.serviceError = error;
      });

    this._officerService.selectedRecipients$
      .pipe(untilDestroyed(this))
      .subscribe((recipients: TPros[]) => {
        this.selectedProsecutors = recipients;
        this.updateProsecutors();
      });

    this.markErrors(this.authMode);
  }

  ngOnDestroy(): void {
    this.serviceError = '';
    this.debounceFilter = null;
    this._officerService.cleanSelectedRecipient();
  }

  onRetry(e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.serviceError = '';
    this.fetchRecipients();
  }

  markErrors(authMode: boolean): void {
    if (this.selectedProsecutors) {
      const invalidEmail: TPros[] = this._pv.getInvalidEmails(this.selectedProsecutors);
      let guestsEmails: TPros[] = [];

      if (authMode) {
        guestsEmails = this._pv.getAuthModeGuestEmails(this.selectedProsecutors);
      }
      this.selectedProsecutors.forEach((item: TPros) => {
        if (invalidEmail.includes(item) || guestsEmails.includes(item)) {
          item.setError(true);
        } else {
          item.setError(false);
        }
      });
      this.errorField = this._pv.getAuthModeMessageByData(
        authMode,
        invalidEmail,
        guestsEmails
      );
    }
  }

  addEmail(email: string): void {
    this._officerService.addSelectedEmail(email);
    this.markErrors(this.authMode);
    this.updateProsecutors();
  }

  addOfficer(prosecutor: Officer): void {
    this._officerService.addSelectedOfficer(prosecutor);
    this.markErrors(this.authMode);
    this.debounceFilter();
    this.updateProsecutors();
  }

  removeTag(prosecutor: TPros): void {
    this._officerService.removeSelectedRecipients(prosecutor);
    this.markErrors(this.authMode);
    this.debounceFilter();
    this.updateProsecutors();
  }

  onFilter(query: string): void {
    const prosId = this.selectedProsecutors.map((pros: Officer) => pros.oid);
    this.filteredProsecutors = this.allProsecutors.filter((officer: Officer) => {
      if (prosId.includes(officer.oid)) {
        return false;
      } else if (query) {
        this.search = query;
        return officer.includes(query.toLowerCase());
      } else {
        query = '';
        this.search = query;
        return officer.includes(query.toLowerCase());
      }
    });
  }

  onOptionSelected(id: string): void {
    const $input: HTMLInputElement = this.tagInput.nativeElement;
    const prosecutor: Officer = this.filteredProsecutors.find(
      (item: Officer): boolean => item.id === id
    );

    if (prosecutor) {
      this.addOfficer(prosecutor);
    }

    $input.value = '';
    $input.focus();
    this.debounceFilter();
  }

  onKeyDown(e: KeyboardEvent): void {
    const $input: HTMLInputElement = this.tagInput.nativeElement;
    const email: string = $input.value;

    if (e.code === EKeyCode.TAB || e.code === EKeyCode.ENTER) {
      if (!this.authMode && email) {
        e.preventDefault();
        e.stopPropagation();

        this.addEmail(email);
        $input.value = '';
      }
    } else if (e.code === EKeyCode.BACKSPACE || e.code === EKeyCode.DELETE) {
      if (email) {
        if (this.authMode) {
        }
      } else {
        const last: TPros = this.selectedProsecutors[this.selectedProsecutors.length - 1];

        if (last) {
          e.preventDefault();
          e.stopPropagation();

          this.removeTag(last);
          this.list.requestClose.emit();
        }
      }
    }
    this.debounceFilter(email);
  }

  onKeyUp(e: KeyboardEvent): void {
    const $input: HTMLInputElement = this.tagInput.nativeElement;
    const email: string = $input.value;
    if (this.authMode && e) {
      this.debounceFilter(email);
    }
  }

  onBlur(): void {
    if (!this.authMode) {
      const $input: HTMLInputElement = this.tagInput.nativeElement;
      const email: string = $input.value;

      if (email) {
        this.addEmail(email);
        $input.value = '';
      }
    }
  }

  updateProsecutors(): void {
    this.updateSelectedProsecutors.emit(this.selectedProsecutors);
  }

  loadPreviousRecipients(): void {
    const mappedRecipiets = this.getMappedRecipients();
    this._officerService.loadPreviousRecipients(mappedRecipiets);
    this.updateProsecutors();
  }

  fetchRecipients(): void {
    this._officerService
      .fetchOfficers()
      .pipe(untilDestroyed(this))
      .subscribe((officers: Officer[]) => {
        this.allProsecutors = officers;
        this.filteredProsecutors = [...this.allProsecutors];
        this.loadPreviousRecipients();
      });
  }

  getMappedRecipients(): TPros[] {
    const mappedRecipients: TPros[] = [];

    this.sentRecipients?.forEach((item: ISimpleOfficer | string) => {
      if (this.authMode) {
        const newOfficer = this.allProsecutors.find((officer: Officer) =>
          this._compareOfficers(item as ISimpleOfficer, officer)
        );
        this.filteredProsecutors = this.filteredProsecutors.filter(
          (prosecutor: Officer) => prosecutor.id !== newOfficer?.id
        );
        mappedRecipients.push(new Officer(newOfficer));
      } else {
        mappedRecipients.push(new Email(item as string));
      }
    });
    return mappedRecipients;
  }

  private _compareOfficers(simpleOfficer: ISimpleOfficer, officer: Officer): boolean {
    if (simpleOfficer.user) {
      return officer.user.toLowerCase() === simpleOfficer.user.toLowerCase();
    } else if (officer.user && !simpleOfficer.user) {
      return officer.user.toLowerCase() === simpleOfficer.email.toLowerCase();
    } else {
      return officer.email.toLowerCase() === simpleOfficer.email.toLowerCase();
    }
  }
}
