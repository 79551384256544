<ng-template #modifyPackageTemplate>
  <msi-modal class="pp-modify-package">
    <msi-spinner
      size="large"
      *ngIf="loading"></msi-spinner>
    <form
      *ngIf="!loading"
      (ngSubmit)="onSubmit($event)"
      [formGroup]="form">
      <msi-modal-header>{{ title | transloco }}</msi-modal-header>

      <msi-modal-content class="form-contents">
        <div class="row">
          <div class="col-left">
            <label for="package-name">{{ 'Package name/ Reference label' | transloco }}</label>
            <input
              id="package-name"
              class="msi-input"
              autocomplete="off"
              placeholder="{{ 'Enter name here' | transloco }}"
              required
              [formControl]="form.controls['name']" />
          </div>
          <div class="col-right">
            <label for="expiration-date">{{ 'Expires in' | transloco }}</label>
            <pp-expires
              id="expiration-date"
              [pkgAction]="modifyAction"
              [dateControl]="form.controls['validTo']"
              [oldDate]="pkg.header.validTo"></pp-expires>
          </div>
        </div>

        <div
          *ngIf="isUnauthSharingEnabled"
          class="row">
          <div class="col-left">
            <label for="access-method">{{ 'Access method' | transloco }}</label>
            <div
              *ngIf="authMode"
              class="readonly-text"
              >{{ 'Authenticated (Requires email login)' | transloco }}</div
            >
            <div
              *ngIf="!authMode && !enablePasswordProtectionForUnauthPkg"
              class="readonly-text"
              >{{ 'Unauthenticated (Open link)' | transloco }}</div
            >
            <pp-access-method-select
              *ngIf="!authMode && enablePasswordProtectionForUnauthPkg"
              [accessMethodControl]="form.controls['accessProtectionType']">
            </pp-access-method-select>
          </div>

          <div
            *ngIf="
              !authMode &&
              enablePasswordProtectionForUnauthPkg &&
              form.controls['accessProtectionType'].value === passwordProtectionType.PASSWORD
            "
            class="col-right">
            <section class="pp-password-toggle">
              <label
                for="password"
                class="pp-password-toggle__label">
                {{
                  (pkg.header.accessProtectionType === passwordProtectionType.PASSWORD
                    ? 'Change current password'
                    : 'Password'
                  ) | transloco
                }}
              </label>
              <msi-toggle
                *ngIf="pkg.header.accessProtectionType === passwordProtectionType.PASSWORD"
                class="pp-password-toggle__switch"
                [checked]="isPasswordChangedConfirmed"
                (change)="isPasswordChangedConfirmed = !isPasswordChangedConfirmed">
              </msi-toggle>
            </section>

            <pp-password-creator
              *ngIf="
                isPasswordChangedConfirmed || pkg.header.accessProtectionType === passwordProtectionType.NONE
              "
              [controlPassword]="form.controls['password']"
              [controlEmailPassword]="form.controls['sendEmailNotification']">
            </pp-password-creator>
          </div>
        </div>

        <div class="asset-retention-warrning">
          <pp-asset-warning
            [filesLoading]="filesLoading"
            [shortRetentionAssetsCount]="(shortRetentionAssets$ | async)?.count">
          </pp-asset-warning>
        </div>

        <msi-callout
          *ngIf="
            enablePasswordProtectionForUnauthPkg &&
            isNewRecipintAdded &&
            form.controls['accessProtectionType'].value === passwordProtectionType.PASSWORD
          "
          [type]="'info'"
          title="{{ 'Adding additional recipients' | transloco }}"
          [closable]="true"
          class="new-recipients-callout">
          <div calloutContent>
            {{
              'Use the current password  or create a new password for all recipients. Only a new password can be shared via email.'
                | transloco
            }}
          </div>
        </msi-callout>

        <div class="row">
          <div class="col">
            <label for="to">{{ 'To' | transloco }}</label>
            <pp-prosecutors
              id="to"
              [authMode]="authMode"
              [sentRecipients]="sentRecipients"
              (updateSelectedProsecutors)="onUpdateSelectedProsecutors($event)"></pp-prosecutors>
          </div>
        </div>

        <ng-template
          ngFor
          let-item
          [ngForOf]="additionalInfo"
          let-i="index">
          <div class="row">
            <div class="col">
              <label class="info">{{ 'Subject' | transloco }}</label>
              <div>{{ item.title }}</div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label class="info">{{ 'Body' | transloco }}</label>
              <div>{{ item.body }}</div>
            </div>
          </div>
        </ng-template>

        <div
          *ngIf="isUnauthSharingEnabled && (isEmptyAgency || !authMode)"
          class="disclaimer-footer">
          <strong>{{ 'Warning' | transloco }}</strong>
          {{
            form.controls['accessProtectionType'].value === passwordProtectionType.PASSWORD
              ? ('By pressing ‘Apply changes’ you are acknowledging that anyone with this link and password can view the material.'
                | transloco)
              : ('By pressing ‘Apply changes’ you are acknowledging that anyone with this link can view the material.'
                | transloco)
          }}
        </div>
      </msi-modal-content>

      <msi-modal-actions class="modify-package-modal__modal-action">
        <div
          class="modify-package-modal__checkbox-wrapper"
          [class.cursor-not-allowed]="sharing">
          <msi-checkbox
            *ngIf="authMode && !isEmptyAgency"
            [class.msi-checkbox-disabled]="sharing"
            [class.disabled-click]="sharing"
            [checked]="form.controls['sendEmailNotification'].value"
            [formControl]="form.controls['sendEmailNotification']">
            {{ 'Send email notification' | transloco }}
          </msi-checkbox>
        </div>
        <div class="modify-package-modal__buttons-wrapper">
          <button
            type="button"
            class="msi-btn msi-btn-action"
            [attr.disabled]="sharing || loading || undefined"
            (click)="close()">
            {{ 'Cancel' | transloco }}
          </button>
          <button
            type="submit"
            class="msi-btn spinner-parent"
            [attr.disabled]="
              sharing ||
              loading ||
              form.invalid ||
              (!form.controls['password'].value &&
                isPasswordChangedConfirmed &&
                form.controls['accessProtectionType'].value === passwordProtectionType.PASSWORD) ||
              undefined
            ">
            {{ loading || sharing ? 'Applying...' : ('Apply changes' | transloco) }}
          </button>
        </div>
      </msi-modal-actions>
    </form>
  </msi-modal>
</ng-template>
