<ng-container *transloco="let t; read: 'pp'">
  <span
    class="password-creator"
    [msiDropdownTriggerFor]="passwordDropdown">
    <input
      class="msi-input password-creator__input"
      placeholder="{{ 'Create a password' | transloco }}"
      maxlength="32"
      (input)="handleInput()"
      [type]="isPasswordVisible ? 'text' : 'password'"
      [formControl]="controlPassword" />
    <div
      class="password-creator__toggle_password input-option msi-hover"
      [class.disabled-click]="isInputDisabled"
      [class.cursor-not-allowed]="isInputDisabled"
      msiTooltip="{{ 'Change visibility' | transloco }}">
      <msi-icon
        name="{{ isPasswordVisible ? 'ic_visibility_off' : 'ic_visibility_on' }}"
        (click)="changeVisibility($event)"
        [size]="18">
      </msi-icon>
    </div>
    <div
      class="password-creator__generate-button input-option msi-hover"
      [class.disabled-click]="isInputDisabled"
      [class.cursor-not-allowed]="isInputDisabled"
      [class.generated-password-active]="isGeneratedPasswordUsed"
      msiTooltip="{{ 'Generate/Clear Password' | transloco }}">
      <msi-icon
        name="ic_key_rekey"
        (click)="handleGeneratePasswordClick($event)"
        [size]="18">
      </msi-icon>
    </div>
  </span>
  <msi-dropdown
    #passwordDropdown
    class="password-creator__dropdown">
    <div class="password-creator__dropdown_content">
      <strong>
        {{ 'Password must include' | transloco }}
      </strong>
      <span class="password-creator__password_requierment">
        <msi-icon
          [ngClass]="{
            'validation-passed': !isMinLengthError() && !isMaxLengthError() && controlPassword?.value
          }"
          [name]="
            !isMinLengthError() && !isMaxLengthError() && controlPassword?.value
              ? 'ic_status_available'
              : 'ic_progress_todo'
          "></msi-icon>
        <p> {{ 'Between 15 and 32 characters' | transloco }} </p>
      </span>
      <span class="password-creator__password_requierment">
        <msi-icon
          [ngClass]="{ 'validation-passed': !isAlphanumericError() && controlPassword?.value }"
          [name]="
            !isAlphanumericError() && controlPassword?.value ? 'ic_status_available' : 'ic_progress_todo'
          "></msi-icon>
        <p> {{ 'Alphanumeric (Abc, 123)' | transloco }} </p>
      </span>
      <span class="password-creator__password_requierment">
        <msi-icon
          [ngClass]="{ 'validation-passed': !isCaseError() && controlPassword?.value }"
          [name]="
            !isCaseError() && controlPassword?.value ? 'ic_status_available' : 'ic_progress_todo'
          "></msi-icon>
        <p> {{ 'Lower Case and Upper Case' | transloco }} </p>
      </span>
      <span class="password-creator__password_requierment">
        <msi-icon
          [ngClass]="{ 'validation-passed': !isSpecialCharacterError() && controlPassword?.value }"
          [name]="
            !isSpecialCharacterError() && controlPassword?.value ? 'ic_status_available' : 'ic_progress_todo'
          "></msi-icon>
        <p> {{ 'Special characters' | transloco }} </p>
      </span>
      <span
        class="password-creator__generate_password msi-hover"
        [ngClass]="{ 'generated-password-active': isGeneratedPasswordUsed }"
        (click)="handleGeneratePasswordClick($event)">
        <msi-icon
          name="ic_key_rekey"
          [size]="18"></msi-icon>
        <strong>
          {{ (isGeneratedPasswordUsed ? 'Clear Generated Password' : 'Generate Password') | transloco }}
        </strong>
      </span>
    </div>
  </msi-dropdown>

  <div [class.cursor-not-allowed]="!controlPassword?.value || controlPassword?.value?.length === 0">
    <msi-checkbox
      #passwordCheckbox
      class="password-creator__to-all-option"
      [class.msi-checkbox-disabled]="
        isInputDisabled || !controlPassword?.value || controlPassword?.value?.length === 0
      "
      [class.disabled-click]="
        isInputDisabled || !controlPassword?.value || controlPassword?.value?.length === 0
      "
      for="password-to-all"
      [formControl]="controlEmailPassword">
      {{ 'Email password to all recipients' | transloco }}
    </msi-checkbox>
  </div>
</ng-container>
