/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Utils } from '@msi/js-sdk';
import dayjs from 'dayjs';

import { IPackageActions } from '../../../../components/common/actions-dropdown/actions.interface';
import { EPackageAccessType } from '../../package.enums';
import { IPackage } from '../../package.interfaces';
import { EAccessProtectionType } from '../accessProtection/AccessProtection.enum';

const EMPTY = '';

class PackageItem {
  private _props: IPackage;

  public id: string;
  public agency: string;
  public caseNumber: string;
  public fullContent: boolean;
  public nature: string;
  public name: string;
  public description: string;
  public recipients: string[];
  public viewedBy: string;
  public sharedOn: string;
  public expireTime: string;
  public timeIndicator: string;
  public agencyName: string;
  public agencyRecord: string;
  public created: string;
  public resharedWith: string[] = [];
  public link: string;
  public isExpired = false;
  public resharedTitle = '';
  public needsUpdate: boolean;
  public firstViewed = '';
  public incidentRemoved: boolean;
  public wasReadByCurrentUser: boolean;

  public tooltipName: string;
  public tooltipAgencyRecord: string;
  public tooltipAgencyName: string;
  public tooltipDescription: string;
  public accessType: EPackageAccessType;

  public isPackageVersionV2 = false;
  public packageActions?: IPackageActions;
  public accessLocked?: boolean;

  constructor(props: IPackage, query?: string) {
    this._props = props;

    this.id = props.header.id || EMPTY;
    this.agency = props.header.agency || EMPTY;
    this.caseNumber = props.content.caseNumber || EMPTY;
    this.fullContent = props.header.fullContent || true;
    this.nature = props.content.nature || EMPTY;
    this.name = props.header.title;
    this.isPackageVersionV2 = props.header.packageVersion === 'v2';
    this.packageActions = props?.actions?.packageActions || undefined;
    this.accessLocked = props.header.accessLocked;

    this.agencyRecord =
      props.header.groupId ||
      props.content.caseNumber ||
      props.content.sourceId ||
      props.content.name ||
      EMPTY;

    if (props.content.nature) {
      this.agencyRecord = this.agencyRecord + ' - ' + props.content.nature;
    }

    this.agencyName = props.header.agencyName || EMPTY;

    if (props.content.nature) {
      this.description = props.content.caseNumber + ' - ' + props.content.nature;
    } else {
      this.description = props.content.caseNumber || EMPTY;
    }

    this.tooltipName = this.name;
    this.tooltipAgencyRecord = this.agencyRecord;
    this.tooltipAgencyName = this.agencyName;
    this.tooltipDescription = this.description;
    this.accessType = props.header.accessType;

    if (props.header.accessType === 'accountLogin') {
      if (props.header.sharedWithAuthenticated?.length) {
        this.recipients = props.header.sharedWithAuthenticated.map((x: any): any =>
          x.agencyName === undefined ? x.email : x.email + ' (' + x.agencyName + ')'
        );
      }
    } else {
      if (props.header.sharedWith?.length) {
        this.recipients = props.header.sharedWith;
      }
    }

    if (props.header.reshareDetails?.sharedWith?.length > 0) {
      this.resharedWith = props.header.reshareDetails.sharedWith;
    }

    if (props.header.reshareDetails?.title) {
      this.resharedTitle = props.header.reshareDetails.title;
    }

    if (props.header.views) {
      this.viewedBy = props.header.views.toString();
    } else {
      this.viewedBy = '-';
    }

    this.sharedOn = dayjs(props.header.updated).format('MM/DD/YYYY');
    this.created = dayjs(props.header.created).format('MM/DD/YYYY');
    if (dayjs(props.header.validTo).isAfter(dayjs('2999-12-30').startOf('d'))) {
      this.expireTime = '';
      this.timeIndicator = 'Never';
    } else if (dayjs(props.header.validTo).isBefore(dayjs())) {
      this.expireTime = '';
      this.timeIndicator = 'Expired';
      this.isExpired = true;
    } else if (dayjs(props.header.validTo).diff(dayjs(), 'd') === 0) {
      this.expireTime = '';
      this.timeIndicator = 'Today';
    } else {
      const daysToExpire = dayjs(props.header.validTo).diff(dayjs(), 'd');
      this.expireTime = daysToExpire.toString();
      this.timeIndicator = daysToExpire === 1 ? 'day' : 'days';
    }

    this.link = props.header.link || '';

    if (query) {
      this.name = Utils.highlightText(this.name, query);
      this.agencyRecord = Utils.highlightText(this.agencyRecord, query);
      this.agencyName = Utils.highlightText(this.agencyName, query);
      this.description = Utils.highlightText(this.description, query);
    }

    this.needsUpdate = !!props.header.needsUpdate || false;
    this.incidentRemoved = props.header.incidentRemoved || false;

    if (props.header.reshareDetails && props.header.reshareDetails.firstViewed) {
      this.firstViewed = dayjs(props.header.reshareDetails.firstViewed).format(
        'MM/DD/YYYY HH:mm'
      );
    }

    this.wasReadByCurrentUser = props.header.wasReadByCurrentUser;
  }

  getProps(): IPackage {
    return this._props;
  }

  getReshareId(): string {
    return this._props.header.reshareDetails?.id || '';
  }

  canReshare(): boolean {
    return (
      !this.resharedWith.length &&
      !this.isExpired &&
      this._props.actions.packageActions.resharePackage
    );
  }

  canUpdateReshare(): boolean {
    return this.resharedWith.length > 0 && !this.isExpired;
  }

  isOpenLink(): boolean {
    return this._props.header.accessType === EPackageAccessType.GUEST;
  }

  isAccountLogin(): boolean {
    return this._props.header.accessType === EPackageAccessType.AUTH;
  }

  isCreatedByUser(userEmail: string, userGuid: string): boolean {
    return (
      this._props.header.createdBy === userEmail ||
      this._props.header.createdByOid === userGuid
    );
  }

  isPasswordProtected(): boolean {
    return this._props.header.accessProtectionType === EAccessProtectionType.PASSWORD;
  }
}

export { PackageItem };
