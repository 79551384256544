/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { DateTimeUtils, Utils } from '@msi/js-sdk';

import { INarrative } from './Narrative.interfaces';
import { expressions } from './replace_expr';

class Narrative {
  private _props: INarrative;

  public title = '';
  public titleType = '';
  public created = '';
  public body = '';

  constructor(props: INarrative) {
    this._props = props;

    this.created = this.getCreated();
    this.titleType = this.getTitleType();
    this.title = this.getTitle();
    this.body = this.getBody();
  }

  get(name: keyof INarrative): INarrative[keyof INarrative] {
    return this._props[name];
  }

  getId(): string {
    return this._props.id;
  }

  getCreated(): string {
    return this._props.whenCreated ? DateTimeUtils.formatTZ(this._props.whenCreated) : '';
  }

  getTitle(): string {
    return this._props.author ? this._props.author : '';
  }

  getTitleType(): string {
    const text = this._props.author ? 'Narrative' : '';

    return this._props.type ? Utils.capitalize(this._props.type) + ' Narrative' : text;
  }

  getBody(): string {
    let body: string = this._props.text || '';

    expressions.forEach((item: Record<string, any>) => {
      const replaceText = `<span class="pp-detail-package-narrative__body__redacted">${item.display}</span>`;

      body = body.replace(item.expresion, replaceText);
    });

    return body;
  }
}

export { Narrative };
