<ng-container *transloco="let t; read: 'ccs'">
  <section class="recipients">
    <msi-chips
      #recipientsImmutableChips
      width="100%"
      [class.error]="containInvalidEmails || (selectedRecipients.length === 0 && inputRecipientsTouchedState)"
      [activateNumber]="0"
      [connectedTo]="recipientsImmutableChips"
      [immutableChips]="immutabilityEnabled"
      [isComplexChips]="true"
      [strongValidate]="authMode ? true : isEmail"
      [msiAutocomplete]="recipientOptions"
      [selectedChipObj$]="selectedImmutableRecipient$"
      [disabled]="fetchingRecipients && authMode"
      [(ngModel)]="selectedRecipients"
      (chipAdded)="addEventHandler()"
      (chipEdited)="editEventHandler()"
      (chipRemoved)="removeEventHandler()"
      (focusedInputValueChanged)="authRecipientSearch = $event"
      (blur)="onBlur()">
    </msi-chips>
  </section>
  <ng-container *ngIf="containInvalidEmails || inputRecipientsTouchedState || cachedRecipients.length">
    <span
      *ngIf="selectedRecipients.length === 0"
      class="recipients__error">
      {{ t('You cannot proceed without defining a recipients list') }}
    </span>
    <span
      *ngIf="containInvalidEmails"
      class="recipients__error">
      {{ t('Input a valid recipient(s) email') }}
    </span>
  </ng-container>
  <section class="recipients__spinner">
    <ng-container *ngIf="authMode && fetchRecipientsFailed">
      <span>{{ t('Failed to load recipient list') }}</span>
      <button
        class="msi-btn msi-btn-small msi-btn-action"
        (click)="onRetryRecipients()">
        {{ t('Retry') }}
      </button>
    </ng-container>
    <ng-container *ngIf="authMode && fetchingRecipients && !fetchRecipientsFailed">
      <msi-spinner size="small"></msi-spinner>
      <span>{{ t('Fetching recipients...') }}</span>
    </ng-container>
  </section>

  <msi-autocomplete
    #recipientOptions
    class="auth-recipients-autocomplete-list">
    <ng-container *ngIf="authMode">
      <msi-autocomplete-option
        *ngFor="let recipient of filteredRecipients$ | async"
        class="recipients__autocomplete-option"
        [value]="recipient.value"
        (selectionChange)="sendImmutableValue(recipient)">
        <section class="recipients__info">
          <span
            class="recipients__full-name"
            [innerHTML]="recipient.value | highlightSearchTerm: highlightedTerm"></span>
          <span
            class="recipients__email"
            [innerHTML]="recipient.email | highlightSearchTerm: highlightedTerm"></span>
          <span
            class="recipients__agency-name"
            [innerHTML]="recipient.agencyName | highlightSearchTerm: highlightedTerm"></span>
        </section>
      </msi-autocomplete-option>
    </ng-container>
  </msi-autocomplete>
</ng-container>
