/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastService } from '@msi/cobalt';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, throwError } from 'rxjs';

import { IShareableAsset } from '../../../../models/share-data.models';
import { EShareInfoProperty } from '../../../../models/share-data.models';
import { AssetVerificationService } from '../../../../services/asset-verification/asset-verification.service';
import { PackageService } from '../../../../services/package-actions/package.service';
import { ShareDataService } from '../../../../services/share-data/share-data.service';

@UntilDestroy()
@Component({
  selector: 'ccs-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit {
  @Input()
  reviewFilesTabActive: boolean;

  @Input()
  assets: IShareableAsset[];

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  private readonly toastMessages: Record<string, string> = {
    success:
      'The system is processing and sending the package. This can take a few minutes.',
    error: 'Package is not shared. Server error.',
  };
  readonly shareInfoPropEnum = EShareInfoProperty;
  packageForm: FormGroup;
  sharingPackage = false;

  constructor(
    private _shareDataService: ShareDataService,
    private _assetVerificationService: AssetVerificationService,
    private _packageService: PackageService,
    private _toastService: ToastService,
    private _transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this._shareDataService.createPackageForm$
      .pipe(untilDestroyed(this))
      .subscribe((form: FormGroup) => (this.packageForm = form));
  }

  onSubmit(): void {
    const shareableAssets = this._assetVerificationService.getShareableAssets(
      this.assets
    );

    this.packageForm?.patchValue({ name: this.packageForm?.value.name?.trim() });
    this.sharingPackage = true;
    this._packageService
      .create(this.packageForm.value, shareableAssets)
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this._dispayErrorToastOnTop();
          this._toastService.error(
            this._transloco.translate(`ccs.${this.toastMessages.error}`),
            undefined,
            { autoDismiss: 3000, closeButton: false }
          );
          this.sharingPackage = false;
          return throwError(() => err);
        })
      )
      .subscribe(() => {
        this._toastService.success(
          this._transloco.translate(`ccs.${this.toastMessages.success}`),
          undefined,
          { autoDismiss: 3000, closeButton: false }
        );
        this._shareDataService.resetActiveAsset();
        this.closeModal.emit();
        this.sharingPackage = false;
      });
  }

  onProceedToShare(): void {
    this.onSwitchTabs(EShareInfoProperty.SharePackageSetup);
  }

  onSwitchTabs(activeTab: EShareInfoProperty): void {
    const reviewFilesTabActive = activeTab === EShareInfoProperty.ReviewFiles;
    this._shareDataService.displayReviewFilesTab(reviewFilesTabActive);
  }

  private _dispayErrorToastOnTop(): void {
    const toastEl = document.querySelector('msi-toast-container');
    toastEl.parentElement.setAttribute('style', 'z-index: 99999;');
  }
}
