/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AssetEntity } from '@msi/emm-sdk';

import { Alert } from '../alert/Alert';
import { IAlert } from '../alert/Alert.interfaces';
import { ChainOfCustody } from '../chainOfCustody/ChainOfCustody';
import { IChainOfCustody } from '../chainOfCustody/ChainOfCustody.interfaces';
import { EntityComment } from '../comment/EntityComment';
import { IComment } from '../comment/EntityComment.interfaces';
import { EntityLocation } from '../location/EntityLocation';
import { ILocation } from '../location/EntityLocation.interfaces';
import { Phone } from '../phone/Phone';
import { IPhone } from '../phone/Phone.interfaces';
import { IOrganization } from './Organization.interfaces';

class Organization {
  private _defaultThumb = 'assets/icons/ic_property.svg';

  public id = '';
  public name = '';
  public type = '';
  public thumb = '';
  public locations: EntityLocation[] = [];
  public phones: Phone[] = [];
  public alerts: Alert[] = [];
  public comments?: EntityComment[] = [];
  public chainOfCustody: ChainOfCustody[] = [];
  public hasChainOfCustody = false;
  public isDefaultThumb = true;

  constructor(props: IOrganization) {
    this.id = props.id || '';
    this.name = props.name || '';
    this.type = props.type || '';
    this.locations = this._getLocations(props);
    this.phones = this._getPhones(props);
    this.alerts = this._getAlerts(props);
    this.comments = this._getComments(props);
    this.thumb = this._defaultThumb;
  }

  private _getLocations(props: IOrganization): EntityLocation[] {
    return props.locations?.length
      ? props.locations.map((location: ILocation) => new EntityLocation(location))
      : [];
  }

  private _getPhones(props: IOrganization): Phone[] {
    return props.phones?.length
      ? props.phones.map((phone: IPhone) => new Phone(phone))
      : [];
  }

  private _getAlerts(props: IOrganization): Alert[] {
    return props.alerts?.length
      ? props.alerts.map((alert: IAlert) => new Alert(alert))
      : [];
  }

  private _getComments(props: IOrganization): EntityComment[] {
    return props.comments?.length
      ? props.comments.map((comment: IComment) => new EntityComment(comment))
      : [];
  }

  getId(): string {
    return this.id;
  }

  setChainOfCustody(chainOfCustody: IChainOfCustody[]): void {
    this.chainOfCustody = chainOfCustody.map(
      (item: IChainOfCustody) => new ChainOfCustody(item)
    );

    if (this.chainOfCustody.length) {
      this.hasChainOfCustody = true;
    }
  }

  async setAssetEntity(assetEntityPromise: Promise<AssetEntity>): Promise<void> {
    const assetEnity: AssetEntity = await assetEntityPromise;

    if (assetEnity) {
      this.isDefaultThumb = false;
      this.thumb = assetEnity.getThumb();
    }
  }
}

export { Organization };
