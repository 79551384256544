/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { Api, IResponse } from '@msi/js-sdk';

import {
  DerivedItem,
  IDerivedItemPlayableResponse,
} from '../asset-entity/asset-entity.interface';
import { DerivedItemType, EPackageAccessType } from '../package/package.enums';

@Injectable({
  providedIn: 'root',
})
export class PlayableLinkService {
  constructor(private _api: Api) {}

  public getDerivedItemDisplayLinkUrl(
    derivedItemType: DerivedItemType,
    url: string,
    accessType: EPackageAccessType
  ): string {
    if (accessType === EPackageAccessType.AUTH) {
      const splitUrl = url.split('?');
      const queryParam = splitUrl.pop();
      const baseUrl = splitUrl[0];

      if (derivedItemType === DerivedItemType.SCREENSHOT) {
        return `${baseUrl}/downloadable-link?${queryParam}`;
      }
      return `${baseUrl}/playable-link?${queryParam}`;
    } else {
      if (derivedItemType === DerivedItemType.SCREENSHOT) {
        return `${url}/downloadable-link`;
      }
      return `${url}/playable-link`;
    }
  }

  public async getDerivedItemPlayableLink(
    url: string,
    response: DerivedItem,
    accessType: EPackageAccessType
  ): Promise<string> {
    let derivedItem: IResponse<IDerivedItemPlayableResponse>;

    try {
      if (accessType === EPackageAccessType.AUTH) {
        derivedItem = await this._api.post<IDerivedItemPlayableResponse, DerivedItem>(
          url,
          response
        );
      } else {
        derivedItem = await this._api.get<IDerivedItemPlayableResponse>(url);
      }

      return derivedItem.data.playableLink;
    } catch (err) {
      throw err;
    }
  }
}
