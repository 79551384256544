/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
export enum EDownloadStatus {
  NEW = 'new',
  IN_PROGRESS = 'in-progress',
  PENDING = 'pending',
  ERROR = 'error',
  RESUME = 'resume',
  CANCEL = 'cancel',
  DONE = 'done',
  REMOVE = 'remove',
}
