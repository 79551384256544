/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
enum ThemeMode {
  DARK = 'dark',
  LIGHT = 'light',
}

export { ThemeMode };
