/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ToastService } from '@msi/cobalt';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, of, switchMap, withLatestFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { EBdpSearchUrl } from '../../../services/package/package.enums';
import { IPackageItems, PackageService } from '../../../services/package/package.service';
import { packagesActions } from '../actions';
import { setMoreSharedPackages } from '../actions/packages.actions';
import {
  getReceivedContinuation,
  getReceivedOptions,
  getReceivedPackagesLoadingError,
  getSharedContinuation,
  getSharedOptions,
  getSharedPackagesLoadingError,
  isReceived,
} from '../selectors/packages.selectors';

@Injectable()
export class PackagesEffects {
  loadingMsg = 'An error occurred while loading packages. Please try again later';
  loadingMoreMsg =
    'An error occurred while loading more packages. Please try again later';
  filteringMsg = 'An error occurred while filtering packages. Please try again later';
  searchingMsg = 'An error occurred while searching packages. Please try again later';
  constructor(
    private packageService: PackageService,
    private appActions$: Actions,
    private toastService: ToastService,
    private transloco: TranslocoService,
    public store: Store
  ) {}

  public loadReceivedPackages$ = createEffect(() =>
    this.appActions$.pipe(
      ofType(packagesActions.loadReceivedPackages),
      withLatestFrom(
        this.store.select(getReceivedContinuation),
        this.store.select(getReceivedOptions)
      ),
      switchMap(([, continuation, options]) =>
        this.packageService
          .bdpSearchHttpClient(
            { ...options, searchId: continuation },
            EBdpSearchUrl.VIEWERS
          )
          .pipe(
            map((response: IPackageItems) => {
              return packagesActions.setReceivedPackages({
                packages: response.packages,
                continuation: response.continuation,
              });
            }),
            catchError((error) => {
              this.toastService.error(
                this.transloco.translate(this.loadingMsg),
                undefined
              );
              return of(packagesActions.loadReceivedPackagesFailure({ error }));
            })
          )
      ),
      catchError((error) => {
        this.toastService.error(this.transloco.translate(this.loadingMsg), undefined);
        return of(packagesActions.loadReceivedPackagesFailure({ error }));
      })
    )
  );

  public loadSharedPackages$ = createEffect(() =>
    this.appActions$.pipe(
      ofType(packagesActions.loadSharedPackages),
      withLatestFrom(
        this.store.select(getSharedContinuation),
        this.store.select(getSharedOptions)
      ),
      switchMap(([, continuation, options]) =>
        this.packageService
          .bdpSearchHttpClient(
            { ...options, searchId: continuation },
            EBdpSearchUrl.PUBLISHERS
          )
          .pipe(
            map((response: IPackageItems) => {
              return packagesActions.setSharedPackages({
                packages: response.packages,
                continuation: response.continuation,
              });
            }),
            catchError((error) => {
              this.toastService.error(
                this.transloco.translate(this.loadingMsg),
                undefined
              );
              return of(packagesActions.loadSharedPackagesFailure({ error }));
            })
          )
      ),
      catchError((error) => of(packagesActions.loadSharedPackagesFailure({ error })))
    )
  );

  public searchPackages$ = createEffect(() =>
    this.appActions$.pipe(
      ofType(packagesActions.searchPackages),
      withLatestFrom(
        this.store.select(isReceived),
        this.store.select(getSharedOptions),
        this.store.select(getReceivedOptions)
      ),
      switchMap(([, received, sharedOptions, receivedOptions]) =>
        this.packageService
          .bdpSearchHttpClient(
            received
              ? { ...receivedOptions, pageNumber: 0 }
              : { ...sharedOptions, pageNumber: 0 },
            received ? EBdpSearchUrl.VIEWERS : EBdpSearchUrl.PUBLISHERS
          )
          .pipe(
            map((response: IPackageItems) => {
              if (received) {
                return packagesActions.setReceivedPackages({
                  packages: response.packages,
                  continuation: response.continuation,
                });
              }
              return packagesActions.setSharedPackages({
                packages: response.packages,
                continuation: response.continuation,
              });
            }),
            catchError((error) => {
              this.toastService.error(
                this.transloco.translate(this.searchingMsg),
                undefined
              );

              return received
                ? of(packagesActions.loadReceivedPackagesFailure({ error }))
                : of(packagesActions.loadSharedPackagesFailure({ error }));
            })
          )
      ),
      catchError((error) => of(packagesActions.loadSharedPackagesFailure({ error })))
    )
  );

  public loadPackages$ = createEffect(() =>
    this.appActions$.pipe(
      ofType(packagesActions.loadPackages),
      withLatestFrom(this.store.select(isReceived)),
      map(([, received]) =>
        received
          ? packagesActions.loadReceivedPackages()
          : packagesActions.loadSharedPackages()
      )
    )
  );

  public switchTables$ = createEffect(() =>
    this.appActions$.pipe(
      ofType(packagesActions.setIsReceived),
      withLatestFrom(
        this.store.select(getReceivedPackagesLoadingError),
        this.store.select(getSharedPackagesLoadingError)
      ),
      filter(
        ([action, received, shared]) =>
          (action.isReceived && !!received) || (!action.isReceived && !!shared)
      ),
      map(([action, received]) => {
        if (action.isReceived && !!received) {
          return packagesActions.loadReceivedPackages();
        } else {
          return packagesActions.loadSharedPackages();
        }
      })
    )
  );

  public filterPackages$ = createEffect(() =>
    this.appActions$.pipe(
      ofType(packagesActions.filterPackages),
      withLatestFrom(
        this.store.select(isReceived),
        this.store.select(getReceivedOptions),
        this.store.select(getSharedOptions)
      ),
      switchMap(([, received, receivedOptions, sharedOptions]) =>
        this.packageService
          .bdpSearchHttpClient(
            received ? { ...receivedOptions } : { ...sharedOptions },
            received ? EBdpSearchUrl.VIEWERS : EBdpSearchUrl.PUBLISHERS
          )
          .pipe(
            map((response: IPackageItems) => {
              if (received) {
                return packagesActions.setReceivedPackages({
                  packages: response.packages,
                  continuation: response.continuation,
                });
              }
              return packagesActions.setSharedPackages({
                packages: response.packages,
                continuation: response.continuation,
              });
            }),
            catchError((error) => {
              this.toastService.error(
                this.transloco.translate(this.filteringMsg),
                undefined
              );

              return received
                ? of(packagesActions.loadReceivedPackagesFailure({ error }))
                : of(packagesActions.loadSharedPackagesFailure({ error }));
            })
          )
      )
    )
  );

  public loadMorePackages$ = createEffect(() =>
    this.appActions$.pipe(
      ofType(packagesActions.loadMorePackages),
      withLatestFrom(
        this.store.select(getSharedContinuation),
        this.store.select(getReceivedContinuation),
        this.store.select(isReceived),
        this.store.select(getSharedOptions),
        this.store.select(getReceivedOptions)
      ),
      filter(
        ([, sharedContinuation, receivedContinuation, received]) =>
          (received && !!receivedContinuation) || (!received && !!sharedContinuation)
      ),
      switchMap(
        ([
          ,
          sharedContinuation,
          receivedContinuation,
          received,
          sharedOptions,
          receivedOptions,
        ]) =>
          this.packageService
            .bdpSearchHttpClient(
              received
                ? {
                    ...receivedOptions,
                    pageNumber: receivedOptions.pageNumber + 1,
                    searchId: receivedContinuation,
                  }
                : {
                    ...sharedOptions,
                    pageNumber: sharedOptions.pageNumber + 1,
                    searchId: sharedContinuation,
                  },
              received ? EBdpSearchUrl.VIEWERS : EBdpSearchUrl.PUBLISHERS
            )
            .pipe(
              map((response: IPackageItems) => {
                if (received) {
                  return packagesActions.setMoreReceivedPackages({
                    packages: response.packages,
                    continuation: response.continuation,
                  });
                } else {
                  return setMoreSharedPackages({
                    packages: response.packages,
                    continuation: response.continuation,
                  });
                }
              }),
              catchError((error) => {
                this.toastService.error(
                  this.transloco.translate(this.loadingMoreMsg),
                  undefined
                );
                return received
                  ? of(packagesActions.loadReceivedPackagesFailure({ error }))
                  : of(packagesActions.loadSharedPackagesFailure({ error }));
              })
            )
      )
    )
  );
}
