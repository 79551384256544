/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';

import { AssetVerificationService } from '../asset-verification/asset-verification.service';
import {
  IFilePatchEntry,
  IPackageUpdatesResponse,
  IPatchEntry,
} from './../package/package.interfaces';
import { IPatchDiff, PatchEntityType } from './package-diff.interfaces';

@Injectable({
  providedIn: 'root',
})
export class PackageDiffService {
  constructor(private _assetVerificationService: AssetVerificationService) {}

  countPackageDiff(response: IPackageUpdatesResponse): IPatchDiff[] {
    const packageDiff: IPatchDiff[] = [];

    const entities: PatchEntityType[] = [
      'header',
      'narrative',
      'person',
      'organization',
      'personnel',
      'vehicle',
      'property',
      'file',
      'overview',
      'unknown',
    ];

    for (const entity of entities) {
      const entityValues: IPatchDiff[] = this.getPatchDiffs(response.updates, entity);

      for (const entityValue of entityValues) {
        packageDiff.push(entityValue);
      }
    }

    response.fileUpdates = response.fileUpdates.filter((file: IFilePatchEntry) => {
      return !this._assetVerificationService.notShareableAsset(file.assetStorageType);
    });

    const filesDiff: IPatchDiff[] = this.getFilePatchDiffs(response.fileUpdates);
    for (const diffType of filesDiff) {
      packageDiff.push(diffType);
    }

    return packageDiff;
  }

  private getPatchDiffs(
    entries: IPatchEntry[],
    patchEntityType: PatchEntityType
  ): IPatchDiff[] {
    const patchDiffs: IPatchDiff[] = [];

    let addedCount = 0;
    let removedCount = 0;
    let replacedCount = 0;

    for (const entry of entries) {
      if (patchEntityType === entry.entityType.toLowerCase()) {
        if (entry.operation.toLowerCase() === 'add') {
          addedCount += 1;
        }
        if (entry.operation.toLowerCase() === 'remove') {
          removedCount += 1;
        }
        if (entry.operation.toLowerCase() === 'replace') {
          replacedCount += 1;
        }
      }
    }

    if (addedCount > 0) {
      const patchDiff: IPatchDiff = {
        entityType: patchEntityType,
        operation: 'add',
        count: addedCount,
      };

      patchDiffs.push(patchDiff);
    }

    if (removedCount > 0) {
      const patchDiff: IPatchDiff = {
        entityType: patchEntityType,
        operation: 'remove',
        count: removedCount,
      };

      patchDiffs.push(patchDiff);
    }

    if (replacedCount > 0) {
      const patchDiff: IPatchDiff = {
        entityType: patchEntityType,
        operation: 'replace',
        count: replacedCount,
      };

      patchDiffs.push(patchDiff);
    }

    return patchDiffs;
  }

  private getFilePatchDiffs(entries: IFilePatchEntry[]): IPatchDiff[] {
    const patchDiffs: IPatchDiff[] = [];

    if (entries == null) {
      return patchDiffs;
    }

    let addedCount = 0;
    let removedCount = 0;

    for (const entry of entries) {
      if (entry.operation.toLowerCase() === 'add') {
        addedCount += 1;
      }
      if (entry.operation.toLowerCase() === 'remove') {
        removedCount += 1;
      }
    }

    if (addedCount > 0) {
      const patchDiff: IPatchDiff = {
        entityType: 'file',
        operation: 'add',
        count: addedCount,
      };

      patchDiffs.push(patchDiff);
    }

    if (removedCount > 0) {
      const patchDiff: IPatchDiff = {
        entityType: 'file',
        operation: 'remove',
        count: removedCount,
      };

      patchDiffs.push(patchDiff);
    }

    return patchDiffs;
  }
}
