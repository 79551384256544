/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';

import { IPackage } from '../../../services/package/package.interfaces';

@Component({
  selector: 'pp-detail-package-overview',
  templateUrl: './detail-package-overview.component.html',
  styleUrls: ['./detail-package-overview.component.scss', '../detail-package-print.scss'],
})
export class DetailPackageOverviewComponent {
  @Input()
  pkg: IPackage;
}
