/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { ServiceInfo, UserInfo } from '@msi/commandcentral-common-header';
import { Subject } from 'rxjs';

import { PermissionsService } from '../permissions/permissions.service';
import { IUserInfoCRV } from './user.interface';

export interface ISharePermissions {
  viewShares: boolean;
  publishShares: boolean;
}

@Injectable()
export class UserService {
  private _userInfo: UserInfo | IUserInfoCRV;
  private _userEmail: string;
  private _userGuid: string;
  private _servicesMatrix: Record<string, any> = {};
  private sharePermission = new Subject<ISharePermissions>();
  public sharePermission$ = this.sharePermission.asObservable();

  constructor(private permissionsService: PermissionsService) {}

  setUserInfo(userInfo: IUserInfoCRV | UserInfo): void {
    const isUserInfoCRV = this.permissionsService.isUserInfoCRV(userInfo);
    this._userEmail = userInfo.email;
    this._userInfo = userInfo;

    if (isUserInfoCRV) {
      this.permissionsService.setGrantedPermissionsCRVUser(
        (userInfo as IUserInfoCRV).permissions
      );
    } else {
      this._servicesMatrix =
        (userInfo as UserInfo).services &&
        (userInfo as UserInfo).services.reduce((matrix, service) => {
          if (service.systemName) {
            matrix[service.systemName] = service;
            this.permissionsService.setGrantedPermissions(service);
          }
          return matrix;
        }, {});
      //  TODO add type "UserInfo" when updated in CH
      this._userGuid = (userInfo as any).guid;
    }
    this._servicesMatrix = userInfo.services;

    this._servicesMatrix.forEach((service) => {
      if (service.permissions?.length) {
        this.permissionsService.addPermissions(service.permissions);
      }
    });

    this.sharePermission.next({
      viewShares: this.hasViewSharesPermission(),
      publishShares: this.hasPublishSharesPermission(),
    });
  }

  checkEnabledServices(userInfo: IUserInfoCRV | UserInfo): string {
    const services = { records_judicial2: false, ccc_digital_evidence: false };
    ((userInfo as UserInfo).services as ServiceInfo[]).forEach((service) => {
      if (service.systemName === 'records.judicial2') {
        services.records_judicial2 = true;
      } else if (service.systemName === 'ccc_digital_evidence') {
        services.ccc_digital_evidence = true;
      }
    });
    return `judical: ${services.records_judicial2}, ccc: ${services.ccc_digital_evidence}`;
  }

  hasViewSharesPermission(): boolean {
    return this.permissionsService.hasPermission('records.judicial2', 'view.shares');
  }

  hasPublishSharesPermission(): boolean {
    return this.permissionsService.hasPermission('records.judicial2', 'publish.shares');
  }

  hasReadAuditLogsPermission(): boolean {
    return this.permissionsService.hasPermission('audit', 'customer_read_audit_logs');
  }

  isAuthorized(): boolean {
    return !!this._userInfo;
  }

  getUserEmail(): string {
    return this._userEmail;
  }

  getUserGuid(): string {
    return this._userGuid;
  }

  getUserInfo(): UserInfo | IUserInfoCRV {
    return this._userInfo;
  }
}
