<ng-container *transloco="let t; read: 'ccs'">
  <ng-container *ngIf="notShareableAssets">
    <section
      class="asset-warnings__wrapper"
      *ngFor="let notSharableAsset of notShareableAssets | keyvalue">
      <ng-container *ngIf="notSharableAsset.key === notShareableType.Incomplete">
        <ng-container *ngIf="displayIncompleteAssetsWarning">
          <div class="asset-warnings__warning">
            <msi-icon name="ic_information"></msi-icon>
            <span> {{ t('Incomplete') }}&nbsp;{{ t('assets will not be shared') }} </span>
            <msi-icon
              class="asset-warnings__remove-warning"
              name="ic_remove"
              (click)="onCloseWarning(notShareableType.Incomplete)">
            </msi-icon>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="notSharableAsset.key === notShareableType.Directory">
        <ng-container *ngIf="displayDirectoryAssetsWarning">
          <div class="asset-warnings__warning">
            <msi-icon name="ic_information"></msi-icon>
            <span>{{ t('Large') }}&nbsp;{{ t('assets will not be shared') }}</span>
            <msi-icon
              class="asset-warnings__remove-warning"
              name="ic_remove"
              (click)="onCloseWarning(notShareableType.Directory)">
            </msi-icon>
          </div>
        </ng-container>
      </ng-container>
    </section>
  </ng-container>
</ng-container>
