/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckboxComponent } from '@msi/cobalt';

import { generateStrongPassword } from '../../utils/password-generator';
@Component({
  selector: 'pp-password-creator',
  templateUrl: './password-creator.component.html',
  styleUrls: ['./password-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordCreatorComponent {
  @Input() controlPassword: FormControl;
  @Input() controlEmailPassword: FormControl;
  @Input() isGeneratedPasswordUsed: boolean;
  @Output() isGeneratedPasswordUsedChange = new EventEmitter<boolean>();
  @ViewChild('passwordCheckbox', { static: false }) passwordCheckbox: CheckboxComponent;

  isPasswordVisible = false;

  isMinLengthError() {
    return this.controlPassword.hasError('passwordLength');
  }

  isMaxLengthError() {
    return this.controlPassword.hasError('maxlength');
  }

  isAlphanumericError() {
    return this.controlPassword.hasError('alphanumeric');
  }

  isCaseError() {
    return this.controlPassword.hasError('case');
  }

  isSpecialCharacterError() {
    return this.controlPassword.hasError('specialCharacter');
  }

  handleGeneratePasswordClick(e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.isGeneratedPasswordUsed
      ? this.controlPassword.setValue('')
      : this.controlPassword.setValue(generateStrongPassword());

    this.isGeneratedPasswordUsed = !this.isGeneratedPasswordUsed;
    this.isGeneratedPasswordUsedChange.emit(this.isGeneratedPasswordUsed);
  }

  handleInput(): void {
    this.isGeneratedPasswordUsed = false;
    this.isGeneratedPasswordUsedChange.emit(this.isGeneratedPasswordUsed);

    if (this.controlPassword?.value.length === 0) {
      this.passwordCheckbox.writeValue(false);
    }
  }

  changeVisibility(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
