/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { DateTimeUtils } from '@msi/js-sdk';

import { IAddress } from '../address/Address.interfaces';
import { IModusOperandi } from '../common.interfaces';
import { Offence } from '../offense/Offense';
import { IOffence } from '../offense/Offense.interfaces';
import { IPackageContent } from '../package/PackageContent.interafces';

class Overview {
  private _props: IPackageContent;

  public hasOverview = true;
  public reportedTime = '';
  public eventDuration = '';
  public reportedBy = '';
  public responsibleOfficer: string[];
  public area = '';
  public address = '';
  public disposition = '';
  public dispositionDate = '';
  public circumstances: string[];
  public respondingOfficers: string[];

  public clearance = '';
  public clearanceDate = '';
  public exceptionalClearance = '';
  public exceptionalClearanceDate = '';
  public judicialStatus = '';
  public judicialStatusDate = '';

  public modusOperandi: IModusOperandi[];
  public offenses: Offence[];
  public reportedOffense: Offence;

  public reportedByFirstName: string;
  public reportedByLastName: string;

  constructor(props: IPackageContent, hasOverview: boolean = true) {
    this._props = props;

    this.hasOverview = hasOverview;
    this.reportedTime = this._props.whenReported
      ? DateTimeUtils.formatTZ(this._props.whenReported)
      : '';
    this.eventDuration = this.getEventDuration();
    this.reportedBy = this._props.responsiblePersonnel || '';
    this.area = this.getArea();
    this.address = this.getAddress();
    this.disposition = this._props.disposition || '';
    this.dispositionDate = this._props.dispositionDate
      ? DateTimeUtils.formatTZ(props.dispositionDate)
      : '';
    this.circumstances = this._props.circumstances || [];
    this.respondingOfficers = this._props.respondingPersonnels || [];

    this.clearance = this._props.clearanceCode || '';
    this.clearanceDate = this._props.clearanceDate
      ? DateTimeUtils.formatTZ(props.clearanceDate)
      : '';
    this.exceptionalClearance = this._props.exceptionalClearanceCode || '';
    this.exceptionalClearanceDate = this._props.exceptionalClearanceDate
      ? DateTimeUtils.formatTZ(props.exceptionalClearanceDate)
      : '';
    this.judicialStatus = this._props.judicialStatus || '';
    this.judicialStatusDate = this._props.judicialStatusDate
      ? DateTimeUtils.formatTZ(props.judicialStatusDate)
      : '';

    this.modusOperandi = this._props.modusOperandi || [];
    this.reportedOffense = this._props.reportedOffense
      ? new Offence(this._props.reportedOffense)
      : null;

    if (this._props.offenses?.length) {
      this.offenses = this._props.offenses.map(
        (offence: IOffence) => new Offence(offence)
      );
    } else {
      this.offenses = [];
    }
  }

  getEventDuration(): string {
    let evDuration: string;

    if (this._props.occurredAfterDateTime) {
      evDuration = DateTimeUtils.formatTZ(this._props.occurredAfterDateTime);
    } else {
      evDuration = '';
    }

    if (this._props.occurredBeforeDateTime) {
      evDuration =
        evDuration + ' - ' + DateTimeUtils.formatTZ(this._props.occurredBeforeDateTime);
    } else {
      evDuration = evDuration ? `${evDuration} - ${''}` : '';
    }

    return evDuration;
  }

  getArea(): string {
    if (
      this._props.location?.address?.zones &&
      this._props.location?.address?.zones[0].zone
    ) {
      return this._props.location?.address?.zones[0].zone;
    } else {
      return '';
    }
  }

  getAddress(): string {
    let val = '';

    if (this._props.location?.address) {
      const address: IAddress = this._props.location.address;

      val = [address.streetFullText, address.city, address.state]
        .filter((i) => !!i)
        .join(', ');

      if (address.zip) {
        val = val + ' ' + address.zip;
      }
    }

    return val;
  }
}

export { Overview };
