/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Store } from '@msi/js-sdk';

import { AssetItem } from '../../services/asset-entity/AssetItem';
import { PackageContent } from '../../services/package/classes/package/PackageContent';
import { ISelectivePackageLocalStore } from './selective-package.interfaces';

class SelectivePackageLocalStore
  extends Store<ISelectivePackageLocalStore>
  implements ISelectivePackageLocalStore
{
  files: AssetItem[];
  content: PackageContent;
  countPackageItems: number;
  isSelectiveMode: boolean;
  isSelectAll: boolean;
  isLocalCheckbox: boolean;
}

const selectivePackageLocalStore = new SelectivePackageLocalStore({
  files: null,
  content: null,
  countPackageItems: 0,
  isSelectiveMode: true,
  isSelectAll: false,
  isLocalCheckbox: true,
});

export { selectivePackageLocalStore };
