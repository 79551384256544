<ng-container *ngIf="!isAuthenticated && !isLoggedIn">
  <pp-password-validator
    [packageId]="id"
    [agencyId]="agencyId"
    [packageVersion]="packageVersion"></pp-password-validator>
</ng-container>
<ng-container *ngIf="isAuthenticated && !isLoggedIn">
  <pp-header-guest>
    <pp-detail-package-provider
      *ngIf="id"
      [packageId]="id"
      [mode]="'protected'"
      [agencyId]="
        packageVersion === packageVersionType.Version_2 ? agencyId : ''
      "></pp-detail-package-provider>
  </pp-header-guest>
</ng-container>
<ng-container *ngIf="isLoggedIn">
  <pp-header-auth>
    <pp-detail-package-provider
      *ngIf="id"
      [packageId]="id"
      [mode]="'protected'"
      [agencyId]="
        packageVersion === packageVersionType.Version_2 ? agencyId : ''
      "></pp-detail-package-provider>
  </pp-header-auth>
</ng-container>
