/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ToastService } from '@msi/cobalt';
import { Settings, Utils } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError } from 'rxjs';

import {
  ApmTransactionComponent,
  ApmTransactionMethod,
} from '../../services/elastic-apm/elastic-apm.decorators';
import { IPackage } from '../../services/package/package.interfaces';
import { PackageService } from '../../services/package/package.service';
import { ISettings } from '../../settings/settings.interface';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@UntilDestroy()
@Component({
  selector: 'pp-delete-package-modal',
  templateUrl: './delete-package-modal.component.html',
  styleUrls: ['./delete-package-modal.component.scss'],
})
@ApmTransactionComponent('delete modal')
export class DeletePackageModalComponent {
  constructor(
    private _settings: Settings<ISettings>,
    private _packageService: PackageService,
    private _toastService: ToastService,
    private _transloco: TranslocoService
  ) {}

  @ViewChild('deletePackageModal', { static: true })
  deletePackageModal: ConfirmationModalComponent;

  @Output()
  done: EventEmitter<IPackage> = new EventEmitter();

  package: IPackage = null;

  @ApmTransactionMethod('delete package')
  onDeletePackageConfirmed(): void {
    const toastOptions: Record<string, number | boolean> = {
      closeButton: true,
      autoDismiss: this._settings.get<number>('AUTODISMISS'),
    };
    this._packageService
      .delete(
        this.package.header.id,
        this.package?.header?.agency ?? '',
        this.package.header.packageVersion
      )
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this._toastService.error(
            this._transloco.translate('Packge is not deleted. Server error.'),
            void 0,
            toastOptions
          );
          return [];
        })
      )
      .subscribe(() => {
        this._toastService.success(
          this._transloco.translate(
            'Your request is being processed. The status of the operation will be available later.'
          ),
          void 0,
          toastOptions
        );
        this.done.emit(this.package);
      });
  }

  open(pkg: IPackage): void {
    this.package = pkg;
    const textPrefix = this._transloco.translate('By deleting ');
    const textSuffix = this._transloco.translate(
      ' package, recipients will not be able to access the package anymore.'
    );

    let text = `${textPrefix}"{{ title }}"${textSuffix}`;

    text = Utils.format(text, { title: pkg.header.title });

    this.deletePackageModal.setBody(text);
    this.deletePackageModal.open();
  }
}
