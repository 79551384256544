/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { OriginConfigMap } from '../app.config.interface';

const useMockData = window.location.href.indexOf('mock') > -1;
const addressForMocks = 'http://localhost:3000';

export const originSpecificMap: OriginConfigMap[] = [
  {
    origins: ['sharing.dev.commandcentral.com', 'sharing.local.commandcentral.com:8443'],
    config: {
      AZURE_ENV: 'dev',
      API: useMockData ? addressForMocks : 'https://sharing-api.dev.commandcentral.com',
      ADMIN: 'https://admin-api.pi.commandcentral.com',
      CRV_BASE_URL: 'https://records.dev.commandcentral.com',
      EMM: {
        AUDIT_VIEWER: 'https://audit-viewer.dev.commandcentral.com',
        API_2_1: 'https://evidence-api.dev.commandcentral.com',
        AUDIT_LOG_URL: 'https://audit.pi.commandcentral.com/',
      },
      WKID_URL: 'http://wkid.dev.commandcentral.com/api/wkid/',
      VAULT_PUBLIC_SHARES_API: 'https://api-public.develop.commandcentral.com',
      ELASTIC_APM_URL: 'https://observability-v2.dev.commandcentral.com/apm-server/',
      APM_ENV: 'dev-azure-comm-shared-use-cie',
      EVIDENCE_URL: 'https://evidence-api.dev.commandcentral.com/',
      LOGOUT_REDIRECT_URL: 'https://sharing.dev.commandcentral.com',
    },
  },
  {
    origins: ['sharing.stage.commandcentral.com'],
    config: {
      AZURE_ENV: 'usgov-stage',
      API: 'https://sharing-api.stage.commandcentral.com',
      ADMIN: 'https://admin-api.pi.commandcentral.com',
      CRV_BASE_URL: 'https://records.stage.commandcentral.com',
      EMM: {
        AUDIT_VIEWER: 'https://audit-viewer.usgov-stage.commandcentral.com',
        API_2_1: 'https://evidence-api.usgov-stage.commandcentral.com',
        AUDIT_LOG_URL: 'https://audit.pi.commandcentral.com/',
      },
      WKID_URL: 'http://wkid.usgov-stage.commandcentral.com/api/wkid/',
      VAULT_PUBLIC_SHARES_API: 'https://api-public.master.commandcentral.com',
      ELASTIC_APM_URL: 'https://observability-v2.dev.commandcentral.com/apm-server/',
      APM_ENV: 'stage-azure-gov-shared-usva-cie',
      EVIDENCE_URL: 'https://evidence-api.usgov-stage.commandcentral.com/',
      LOGOUT_REDIRECT_URL: 'https://sharing.stage.commandcentral.com',
    },
  },
  {
    origins: ['sharing.commandcentral.com'],
    config: {
      AZURE_ENV: 'usgov',
      API: 'https://sharing-api.commandcentral.com',
      ADMIN: 'https://admin-api.usgov.commandcentral.com',
      CRV_BASE_URL: 'https://records.commandcentral.com',
      EMM: {
        AUDIT_VIEWER: 'https://audit-viewer.usgov.commandcentral.com',
        API_2_1: 'https://evidence-api.usgov.commandcentral.com',
        AUDIT_LOG_URL: 'https://audit.usgov.commandcentral.com/',
      },
      WKID_URL: 'http://wkid.usgov.commandcentral.com/api/wkid/',
      VAULT_PUBLIC_SHARES_API: 'https://api-public.commandcentral.com',
      ELASTIC_APM_URL: 'https://elastic-apm.usgov.commandcentral.com/',
      APM_ENV: 'prod-azure-gov-shared-usva-cie',
      EVIDENCE_URL: 'https://evidence-api.usgov.commandcentral.com/',
      LOGOUT_REDIRECT_URL: 'https://sharing.commandcentral.com',
    },
  },
  {
    origins: ['sharing.fed-stage.commandcentral.com'],
    config: {
      AZURE_ENV: 'fed-stage',
      API: 'https://sharing-api.fed-stage.commandcentral.com',
      ADMIN: 'https://admin-api.fed-stage.commandcentral.com',
      CRV_BASE_URL: 'https://records.fed-stage.commandcentral.com',
      EMM: {
        AUDIT_VIEWER: 'https://audit-viewer.fed-stage.commandcentral.com',
        API_2_1: 'https://evidence-api.fed-stage.commandcentral.com',
        AUDIT_LOG_URL: 'https://audit.fed-stage.commandcentral.com/',
      },
      WKID_URL:
        'https://wkid.usgov-stage.commandcentral.com/api/wkid?environment=stage-sov-fed-us',
      VAULT_PUBLIC_SHARES_API: 'https://api-public.master.commandcentral.com',
      ELASTIC_APM_URL: '', // Not supported. According to CIE "Removed due to security concerns"
      APM_ENV: '',
      EVIDENCE_URL: 'https://evidence-api.fed-stage.commandcentral.com/',
    },
  },
  {
    origins: ['sharing.fed.commandcentral.com'],
    config: {
      AZURE_ENV: 'fed',
      API: 'https://sharing-api.fed-prod.commandcentral.com',
      ADMIN: 'https://admin-api.fed.commandcentral.com',
      CRV_BASE_URL: 'https://records.fed.commandcentral.com',
      EMM: {
        AUDIT_VIEWER: 'https://audit-viewer.fed.commandcentral.com',
        API_2_1: 'https://evidence-api.fed.commandcentral.com',
        AUDIT_LOG_URL: 'https://audit.fed.commandcentral.com/',
      },
      WKID_URL:
        'https://wkid.usgov.commandcentral.com/api/wkid?environment=prod-sov-fed-us',
      VAULT_PUBLIC_SHARES_API: 'https://api-public.fed.commandcentral.com',
      ELASTIC_APM_URL: '', // Not supported. According to CIE "Removed due to security concerns"
      APM_ENV: '',
      EVIDENCE_URL: 'https://evidence-api.fed.commandcentral.com/',
    },
  },
  {
    origins: ['sharing.sec.commandcentral.ca'],
    config: {
      AZURE_ENV: 'cccs-prod',
      API: 'https://sharing-api.sec.commandcentral.ca',
      ADMIN: 'https://admin-api.sec.commandcentral.ca',
      CRV_BASE_URL: 'https://records.sec.commandcentral.ca',
      EMM: {
        AUDIT_VIEWER: 'https://audit-viewer.sec.commandcentral.ca',
        API_2_1: 'https://evidence-api.sec.commandcentral.ca',
        AUDIT_LOG_URL: 'https://audit.sec.commandcentral.ca/',
      },
      WKID_URL:
        'https://wkid.usgov.commandcentral.com/api/wkid?environment=prod-sov-cccs-ca',
      VAULT_PUBLIC_SHARES_API: 'https://api-public.sec.commandcentral.ca',
      ELASTIC_APM_URL: '', // Not supported. According to CIE "Removed due to security concerns"
      APM_ENV: '',
      EVIDENCE_URL: 'https://evidence-api.sec.commandcentral.ca/',
    },
  },
  {
    origins: ['sharing-preprod.sec.commandcentral.ca'],
    config: {
      AZURE_ENV: 'cccs-preprod',
      API: 'https://sharing-api-preprod.sec.commandcentral.ca',
      ADMIN: 'https://admin-api-preprod.sec.commandcentral.ca',
      CRV_BASE_URL: 'https://records-preprod.sec.commandcentral.ca',
      EMM: {
        AUDIT_VIEWER:
          'https://observability-cacentral-2.sec.commandcentral.ca/auditviewer',
        API_2_1: 'https://evidence-api-preprod.sec.commandcentral.ca',
        AUDIT_LOG_URL: 'https://observability-cacentral-2.sec.commandcentral.ca ',
      },
      WKID_URL:
        'https://wkid.usgov.commandcentral.com/api/wkid?environment=prod-sov-cccs-ca-2',
      VAULT_PUBLIC_SHARES_API: 'https://api-public.sec.commandcentral.ca',
      ELASTIC_APM_URL: '', // Not supported. According to CIE "Removed due to security concerns"
      APM_ENV: '',
      EVIDENCE_URL: 'https://evidence-api-preprod.sec.commandcentral.ca/',
    },
  },
];
