/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginResponse, OidcSecurityService } from '@msi/angular-auth-oidc-client';
import { PasswordValidatorService } from '@msi/pp-components';
import { EPackageVersionType } from '@msi/pp-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { withLatestFrom } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'pp-guest-protected',
  templateUrl: './guest-protected.component.html',
  styleUrls: ['./guest-protected.component.scss'],
})
export class GuestProtectedComponent implements OnInit {
  id = '';
  agencyId: string;
  isAuthenticated: boolean;
  isLoggedIn: boolean;
  packageVersion: EPackageVersionType;

  constructor(
    private route: ActivatedRoute,
    private passwordValidator: PasswordValidatorService,
    private oidcSecurityService: OidcSecurityService
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(untilDestroyed(this), withLatestFrom(this.route.queryParams))
      .subscribe(([params, queryParams]) => {
        this.id = params.id;
        this.agencyId = params.agencyId || queryParams.agency || '';
        this.packageVersion = params.agencyId
          ? EPackageVersionType.Version_2
          : EPackageVersionType.Version_1;
      });
    this.passwordValidator.authenticated$
      .pipe(untilDestroyed(this))
      .subscribe((isValid: boolean) => {
        this.isAuthenticated = isValid || this.isAccessTokenValid();
      });
    this.oidcSecurityService
      .checkAuth()
      .pipe(untilDestroyed(this))
      .subscribe((authResult: LoginResponse) => {
        this.isLoggedIn = authResult.isAuthenticated;
      });
  }

  isAccessTokenValid(): boolean {
    if (!!sessionStorage.getItem(`PROTECTED_CONFIG_${this.id}`)) {
      const tokenExpiresAt = JSON.parse(
        sessionStorage.getItem(`PROTECTED_CONFIG_${this.id}`)
      ).access_token_expires_at;
      return dayjs().isBefore(dayjs(tokenExpiresAt));
    }

    return false;
  }

  protected readonly packageVersionType = EPackageVersionType;
}
