/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api, DateTimeUtils, IResponse, Settings, Utils } from '@msi/js-sdk';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PROTECTED_PACKAGE_CONTEXT } from '../../interceptors/protected-package.interceptor';
import { ISettings } from '../../settings/settings.interface';
import { GlobalStore } from '../../stores/global/store';
import { AssetEntityService } from '../asset-entity/asset-entity.service';
import { AssetViewerConfigurationService } from '../asset-viewer-config/asset-viewer-config.service';
import { FilterService } from '../filter/filter.service';
import { Officer } from '../officer/Officer';
import { IOfficer, ISimpleOfficer } from '../officer/officer.interfaces';
import { EAccessProtectionType } from './classes/accessProtection/AccessProtection.enum';
import { Organization } from './classes/organization/Organization';
import { IPackageContent } from './classes/package/PackageContent.interafces';
import { Person } from './classes/person/Person';
import { Property } from './classes/property/Property';
import { Vehicle } from './classes/vehicle/Vehicle';
import {
  ICreatePackage,
  ICreatePackageForm,
  IPackageV2Header,
  IResharePackageForm,
} from './create-package.interfaces';
import { Email } from './Email';
import { IPackageItems, ListPackage } from './ListPackage';
import {
  EDetailPackageMode,
  EPackageAccessType,
  EPackageVersionType,
} from './package.enums';
import {
  IPackage,
  IPackageBody,
  IPackageFile,
  IPackageHeader,
  ISubscribe,
  ISubscribeResponse,
  IUnsubscribe,
} from './package.interfaces';

type PackageSection = Organization | Person | Property | Vehicle;

@Injectable()
class PackageService extends ListPackage {
  private _settings: Settings<ISettings>;
  private _api: Api;
  private _store: GlobalStore;

  constructor(
    settings: Settings<ISettings>,
    api: Api,
    assetEntityService: AssetEntityService,
    filterService: FilterService,
    store: GlobalStore,
    http: HttpClient,
    private _assetViewerConfigService: AssetViewerConfigurationService
  ) {
    super(settings, api, assetEntityService, filterService, http);

    this._settings = settings;
    this._api = api;
    this._store = store;
  }

  private _getDateByExpire(days: number): string {
    const NEVER_ID = this._settings.get<number>('NEVER_ID');
    const now = dayjs();

    return days === NEVER_ID ? DateTimeUtils.never() : now.add(days, 'day').toISOString();
  }

  public getPackage(packageId: string, mode: EDetailPackageMode, agencyId: string) {
    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);

    if (mode === EDetailPackageMode.GUESTS) {
      const packageEndpoint = agencyId
        ? 'PM_API_PACKAGE_V2_ENDPOINT'
        : 'PM_API_PACKAGE_ENDPOINT';
      const params = agencyId ? `/${agencyId}/${packageId}` : `/${packageId}`;
      const url = domain + this._settings.get<string>(packageEndpoint) + params;
      return this.http.get<IPackageBody>(url).pipe(
        tap((response) =>
          this._setAssetViewerConfig(response.body.header.agency, response.body, mode)
        ),
        map((response) => this.mapPackageDataToObjects(response.body))
      );
    } else if (mode === EDetailPackageMode.PROTECTED) {
      const packageEndpoint = agencyId
        ? 'PM_API_PACKAGE_V2_PUBLIC_ENDPOINT'
        : 'PM_API_PACKAGE_PUBLIC_ENDPOINT';
      const urlParams = agencyId
        ? { domain, agencyId, packageId }
        : { domain, packageId };
      const url = Utils.format(this._settings.get<string>(packageEndpoint), urlParams);

      return this.http
        .get<IPackage>(url, {
          context: new HttpContext().set(PROTECTED_PACKAGE_CONTEXT, {
            isProtected: true,
            packageId,
          }),
        })
        .pipe(
          tap((response) => {
            this._setAssetViewerConfig(response.header.agency, response, mode);
          }),
          map((response) => this.mapPackageDataToObjects(response))
        );
    } else if (mode === EDetailPackageMode.SHARES) {
      const packageEndpoint = agencyId
        ? 'PM_API_PACKAGE_V2_PRIVATE_ENDPOINT'
        : 'PM_API_PACKAGE_PRIVATE_ENDPOINT';
      const urlParams = agencyId
        ? { domain, agencyId, packageId }
        : { domain, packageId };
      const url = Utils.format(this._settings.get<string>(packageEndpoint), urlParams);

      return this.http.get<IPackage>(url).pipe(
        tap((response) =>
          this._setAssetViewerConfig(response.header.agency, response, mode)
        ),
        map((response) => this.mapPackageDataToObjects(response))
      );
    } else {
      throw new HttpErrorResponse({
        status: 400,
        statusText: 'GetPackage/get: unknown mode type',
      });
    }
  }

  async create(
    data: ICreatePackageForm,
    files: IPackageFile[],
    content: IPackageContent,
    fullContent: boolean = true,
    hasOverview: boolean = true
  ): Promise<IPackage> {
    const pkg: ICreatePackage = {
      header: {
        title: data.name,
        groupId: content.caseNumber,
        validTo: data.validTo.toISOString(),
        fullContent,
        hasOverview,
      },
      content,
      files,
    };

    if (!data.authMode) {
      pkg.header.password = data.password;
      pkg.header.accessProtectionType = data.password
        ? EAccessProtectionType.PASSWORD
        : EAccessProtectionType.NONE;
    }

    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);
    const url: string =
      domain + this._settings.get<string>('JUDICIAL_CREATE_PACKAGE_API_ENDPOINT');
    const response: IResponse<IPackage> = await this._api.post<IPackage, ICreatePackage>(
      url,
      pkg
    );

    const pkgId: string = response.data.header.id;

    const sharedPackage: IPackage = await this.share(
      pkgId,
      data.subject,
      data.to,
      data.authMode,
      data.sendEmailNotification,
      data.password
    );

    return sharedPackage;
  }

  async modify(data: ICreatePackageForm, pkg: IPackage) {
    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);
    const url: string =
      domain +
      this._settings.get<string>('JUDICIAL_CREATE_PACKAGE_API_ENDPOINT') +
      '/' +
      pkg.header.id;
    const body: Record<string, IPackageHeader> = { header: Utils.deepCopy(pkg.header) };

    body.header.title = data.name;
    body.header.accessProtectionType = data.accessProtectionType;
    body.header.password = data.password;

    body.header.validTo = data.validTo.toISOString();

    const response: IResponse<IPackage> = await this._api.patch<
      IPackage,
      Record<string, IPackageHeader>
    >(url, body);

    const pkgId: string = response.data.header.id;
    const subject: string =
      'CommandCentral: ' + pkg.content.caseNumber + ' ' + pkg.content.nature;

    const sharedPackage: IPackage = await this.share(
      pkgId,
      subject,
      data.to,
      data.authMode,
      data.sendEmailNotification,
      data.password
    );

    return sharedPackage;
  }

  modifyV2(data: ICreatePackageForm, pkg: IPackage): Observable<IPackage> {
    const urlParams = {
      domain: this._settings.get<string>('PLATFORM.API' as keyof ISettings),
      agencyId: pkg.header.agency,
      packageId: pkg.header.id,
    };
    const url = Utils.format(
      this._settings.get<string>('PM_API_PACKAGE_V2_PRIVATE_ENDPOINT'),
      urlParams
    );
    const body: IPackageV2Header = {
      title: pkg.header.title,
      validTo: pkg.header.validTo,
      sharedWith: pkg.header.sharedWith,
      sharedWithAuthenticated: pkg.header.sharedWithAuthenticated,
      accessProtectionType: EAccessProtectionType.NONE,
    };

    body.title = data.name;
    body.accessProtectionType = data.accessProtectionType;
    if (data.authMode) {
      body.sharedWithAuthenticated = data.to.map(
        (item: Officer): ISimpleOfficer => item.toSimple()
      );
    } else {
      body.sharedWith = data.to.map((item: Officer | Email): string => item.email);
    }
    if (data.accessProtectionType === EAccessProtectionType.PASSWORD && data.password) {
      body.passwordCreationData = {
        password: data.password,
        sendPassword: data.sendEmailNotification,
      };
    }
    body.validTo = data.validTo.toISOString();

    return this.http.patch<IPackage>(url, body);
  }

  async update(pkg: IPackage) {
    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);
    const url: string =
      domain +
      this._settings.get<string>('JUDICIAL_CREATE_PACKAGE_API_ENDPOINT') +
      '/' +
      pkg.header.id;
    const response: IResponse<IPackage> = await this._api.put<
      IPackage,
      Record<string, any>
    >(url, pkg);

    return response.data;
  }

  async share(
    packageId: string,
    subject: string,
    emails: (Email | Officer)[],
    auth: boolean,
    sendEmailNotification: boolean = null,
    password: string = ''
  ): Promise<IPackage> {
    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);
    const url: string =
      domain +
      this._settings.get<string>('JUDICIAL_SHARE_PACKAGE_API_ENDPOINT') +
      '/' +
      packageId +
      '/.share';
    const body: Record<string, unknown> = { subject };

    if (auth) {
      body.toAuthenticated = emails.map((officer: Officer) => officer.toSimple());
      body.accessType = EPackageAccessType.AUTH;
      if (sendEmailNotification !== null) {
        body.sendEmailNotification = sendEmailNotification;
      }
    } else {
      body.to = emails.map((item: Email | Officer) => item.email);
      body.accessType = EPackageAccessType.GUEST;
      if (password) {
        body.accessProtectionType = EAccessProtectionType.PASSWORD;
        body.passwordCreationData = {
          password,
          sendPassword: sendEmailNotification,
        };
      }
    }
    body.language = this._store.lang;
    const response: IResponse<IPackage> = await this._api.post<
      IPackage,
      Record<string, any>
    >(url, body);

    return response.data;
  }

  async reshare(
    packageId: string,
    ownerAgency: string,
    data: IResharePackageForm
  ): Promise<IPackage> {
    this._store.sending = true;

    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);
    const link: string = this._settings.get<string>('RESHARE_PACKAGE_URL');
    const url: string = Utils.format(link, { domain, packageId, ownerAgency });

    const body: Record<string, unknown> = {
      title: data.name,
      to: data.to.map((item: Officer | Email): string | ISimpleOfficer => item.email),
    };

    let response: IResponse<IPackage>;

    try {
      response = await this._api.post<IPackage, Record<string, any>>(url, body);
    } catch (err) {
      this._store.sending = false;

      throw err;
    }

    this._store.sending = false;

    return response.data;
  }

  async updateReshare(packageId: string, data: IResharePackageForm): Promise<IPackage> {
    this._store.sending = true;

    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);
    const link: string = this._settings.get<string>('CREATE_PACKAGE_URL');

    let url: string = domain + link + '/' + packageId;

    const bodyTitle: Record<string, unknown> = {
      header: {
        title: data.name,
      },
    };
    const bodyTo: Record<string, unknown> = {
      to: data.to.map((item: Officer | Email): string | ISimpleOfficer => item.email),
    };

    // Update title

    let response: IResponse<IPackage>;

    try {
      response = await this._api.patch<IPackage, Record<string, any>>(url, bodyTitle);
    } catch (err) {
      this._store.sending = false;

      throw err;
    }

    // Update emails

    url = url + '/.share';

    try {
      response = await this._api.post<IPackage, Record<string, any>>(url, bodyTo);
    } catch (err) {
      this._store.sending = false;

      throw err;
    }

    this._store.sending = false;

    return response.data;
  }

  async updateContent(pkg: IPackage) {
    const updateResponse: IPackage = await this.update(pkg);
    const pkgId: string = updateResponse.header.id;
    const subject: string =
      'CommandCentral: ' + pkg.content.caseNumber + ' ' + pkg.content.nature;
    const auth = pkg.header.accessType === EPackageAccessType.AUTH;
    const emails = auth
      ? pkg.header.sharedWithAuthenticated.map(
          (simpleOfficer: IOfficer) => new Officer(simpleOfficer)
        )
      : pkg.header.sharedWith.map((email: string) => new Email(email));
    const shareResponse: IPackage = await this.share(pkgId, subject, emails, auth);

    return shareResponse;
  }

  async subscribe(
    packageId: string,
    email: string,
    mode: EDetailPackageMode = EDetailPackageMode.GUESTS
  ): Promise<string> {
    const data: ISubscribe = { mail: email };
    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);
    let url: string;

    if (mode === EDetailPackageMode.SHARES) {
      url = Utils.format(
        this._settings.get<string>('SUBSCRIBE_AUTH_PACKAGE_API_ENDPOINT'),
        { domain, packageId }
      );
    } else {
      url = Utils.format(
        this._settings.get<string>('SUBSCRIBE_UNAUTH_PACKAGE_API_ENDPOINT'),
        { domain, packageId }
      );
    }
    const response: IResponse<ISubscribeResponse> = await this._api.post<
      ISubscribeResponse,
      ISubscribe
    >(url, data);

    return response.data.subId;
  }

  async unsubscribe(
    packageId: string,
    subId: string,
    mode: EDetailPackageMode = EDetailPackageMode.GUESTS
  ): Promise<void> {
    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);
    let url: string;

    if (mode === EDetailPackageMode.SHARES) {
      url = Utils.format(
        this._settings.get<string>('UNSUBSCRIBE_AUTH_PACKAGE_API_ENDPOINT'),
        { domain, packageId, subId }
      );
    } else {
      url = Utils.format(
        this._settings.get<string>('UNSUBSCRIBE_UNAUTH_PACKAGE_API_ENDPOINT'),
        { domain, packageId, subId }
      );
    }

    await this._api.delete<IUnsubscribe>(url);
  }

  delete(
    id: string,
    agency: string = '',
    packageVersion: EPackageVersionType = EPackageVersionType.Version_1
  ): Observable<IPackage> {
    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);
    let url: string;

    if (packageVersion === EPackageVersionType.Version_2) {
      const link = `${domain}${this._settings.get<string>('DELETE_V2_PACKAGE_URL')}`;
      url = Utils.format(link, { agencyId: agency, packageId: id });
    } else {
      url = `${domain}${this._settings.get<string>(
        'JUDICIAL_SHARE_PACKAGE_API_ENDPOINT'
      )}/${id}`;
    }
    return this.http.delete<IPackage>(url);
  }

  regenerate(pkg: IPackage): Observable<IPackage> {
    const domain: string = this._settings.get<string>('PLATFORM.API' as keyof ISettings);
    let url: string;

    if (pkg.header.packageVersion === EPackageVersionType.Version_2) {
      const link = `${domain}${this._settings.get<string>('REGENERATE_V2_PACKAGE_URL')}`;
      url = Utils.format(link, { agencyId: pkg.header.agency, packageId: pkg.header.id });
    } else {
      url = `${domain}${this._settings.get<string>(
        'JUDICIAL_SHARE_PACKAGE_API_ENDPOINT'
      )}/${pkg.header.id}/.regenerate-link`;
    }

    return this.http.post<IPackage>(url, pkg);
  }

  getSubscriptionId(): string {
    let queryString: URLSearchParams;
    let subID = '';

    if (window.location.search) {
      queryString = new URLSearchParams(window.location.search);
      subID = queryString.get('unsubscribe');
    }

    return subID;
  }

  informationSectionPresent(section: PackageSection): boolean {
    if (section instanceof Organization) {
      return !!section.phones?.length;
    } else if (section instanceof Person) {
      return (
        !!section.driverLicense ||
        !!section.expirationDate ||
        !!section.ssn ||
        !!section.localId ||
        !!section.stateId ||
        !!section.fbiNumber ||
        !!section.race ||
        !!section.ethnicity ||
        !!section.sex ||
        !!section.eyes ||
        !!section.hair ||
        !!section.height ||
        !!section.weight
      );
    } else if (section instanceof Property) {
      return (
        !!section.qty ||
        !!section.tag ||
        !!section.serialNumber ||
        !!section.ownerApplied ||
        !!section.totalValue ||
        !!section.itemStatus ||
        !!section.valueRecovered ||
        !!section.accumulativeValueRecovered ||
        !!section.dateRecovered
      );
    } else if (section instanceof Vehicle) {
      return (
        !!section.license ||
        !!section.expirationDate ||
        !!section.type ||
        !!section.year ||
        !!section.name ||
        !!section.vin ||
        !!section.value ||
        !!section.door ||
        !!section.primaryColor ||
        !!section.secondaryColor ||
        !!section.dateRecovered ||
        !!section.valueRecovered
      );
    } else {
      return true;
    }
  }

  locationSectionPresent(section: PackageSection): boolean {
    if (section instanceof Organization) {
      return !!section.locations?.length;
    } else {
      return true;
    }
  }

  alertSectionPresent(section: PackageSection): boolean {
    if (section instanceof Organization) {
      return !!section.alerts?.length;
    } else {
      return true;
    }
  }

  statusSectionPresent(section: PackageSection): boolean {
    if (section instanceof Property) {
      return (
        !!section.urcStatus ||
        !!section.urcStatusDate ||
        !!section.localStatus ||
        !!section.localStatusDate ||
        !!section.agency
      );
    } else if (section instanceof Vehicle) {
      return (
        !!section.urcStatus ||
        !!section.urcStatusDate ||
        !!section.localStatus ||
        !!section.localSatusDate ||
        !!section.agency
      );
    } else {
      return true;
    }
  }

  private _setAssetViewerConfig(
    agency: string,
    pkg: IPackage,
    mode: EDetailPackageMode
  ): void {
    this._assetViewerConfigService.configureEmmComponents(agency, pkg, mode);
  }
}

export { IPackageItems, PackageService };
