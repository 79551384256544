/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { EventEmitter } from '@msi/js-sdk';

import { FS } from '../../FS';
import { DownloadItemDTO } from '../DownloadItemDTO';
import { EDownloadStatus } from '../enums';
import { ExpireLinkTask } from './tasks/ExpireLinkTask';
import { FetchFileTask } from './tasks/FetchFile';

class DownloadItemProcess extends EventEmitter {
  private _downloadItemDTO: DownloadItemDTO;
  private _fs: FS;

  constructor(downloadItemDTO: DownloadItemDTO, fs: FS) {
    super();

    this._downloadItemDTO = downloadItemDTO;
    this._fs = fs;
  }

  async run(): Promise<void> {
    if (
      this._downloadItemDTO.status !== EDownloadStatus.NEW &&
      this._downloadItemDTO.status !== EDownloadStatus.PENDING &&
      this._downloadItemDTO.status !== EDownloadStatus.IN_PROGRESS
    ) {
      return;
    }

    this._downloadItemDTO.status = EDownloadStatus.IN_PROGRESS;
    this._downloadItemDTO.error = null;
    this._downloadItemDTO.emit(EDownloadStatus.IN_PROGRESS);

    try {
      await new ExpireLinkTask(this._downloadItemDTO).run();
    } catch (err) {
      this._downloadItemDTO.status = EDownloadStatus.ERROR;
      this._downloadItemDTO.error = err;
      this._downloadItemDTO.emit(EDownloadStatus.ERROR, err);

      return;
    }

    try {
      await new FetchFileTask(this._downloadItemDTO, this._fs).run();
    } catch (err) {
      const downloadItem: any = this._downloadItemDTO;

      if (downloadItem.status !== EDownloadStatus.CANCEL) {
        this._downloadItemDTO.status = EDownloadStatus.ERROR;
        this._downloadItemDTO.error = err;
        this._downloadItemDTO.emit(EDownloadStatus.ERROR, err);
      }

      return;
    }

    this._downloadItemDTO.status = EDownloadStatus.DONE;
    this._downloadItemDTO.emit(EDownloadStatus.DONE);
  }
}

export { DownloadItemProcess };
