<msi-icon
  name="ic_filter_sm"
  class="icon-trigger msi-hover pp-filter-icon msi-btn msi-btn-icon"
  [msiDropdownTriggerFor]="filter">
</msi-icon>
<msi-dropdown
  #filter
  class="pp-filter-dropbox">
  <msi-checkbox-group *transloco="let t">
    <msi-checkbox
      *ngFor="let option of isReceived ? optionsReceived : optionsShared"
      [checked]="filters.includes(option.id)"
      (change)="changeFilter(option)">
      {{ t(option.name) }}
    </msi-checkbox>
  </msi-checkbox-group>
</msi-dropdown>
