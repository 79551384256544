/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AssetEntity, AssetEntityItem, DateFormatter } from '@msi/emm-sdk';
import { Api, IResponse, Settings } from '@msi/js-sdk';
import { IVideoStream } from '@msi/msi-download-manager';
import { cloneDeep } from 'lodash-es';

import { DownloadItemDTO } from '../../common/DownloadManager/DownloadItemDTO';
import { IPackage } from '../../services/package/package.interfaces';
import { ISettings } from '../../settings/settings.interface';
import { AssetEntityService } from '../asset-entity/asset-entity.service';
import { EDetailPackageMode } from '../package/package.enums';

interface IStreamLink {
  downloadableLink: string;
  streamId: string;
}

class StreamToDownload {
  private _api: Api;
  private _settings: Settings<ISettings>;

  constructor(
    api: Api,
    settings: Settings<ISettings>,
    private _assetEntityService: AssetEntityService
  ) {
    this._api = api;
    this._settings = settings;
  }

  private async _generateAuthLink(
    assetEntity: AssetEntity,
    streamId: string,
    pkg: IPackage
  ): Promise<string> {
    const evLink: string = this._settings.get('PLATFORM.EVIDENCE_URL' as any);
    const downloadFileName = this.createStreamDownloadName(assetEntity);
    const body: Record<string, any> = {
      streamId,
      downloadFileName,
      agency: pkg.header.agency,
    };
    const params: string = new URLSearchParams(body).toString();
    const link =
      evLink +
      'api/vault/v2/files/' +
      assetEntity.fileId +
      '/downloadable-link?' +
      params;

    let response: IResponse<IStreamLink>;

    try {
      response = await this._api.post<IStreamLink, Record<string, any>>(link, body);
    } catch (err) {
      throw err;
    }

    return response.data.downloadableLink;
  }

  private async _generateGuestLink(
    assetEntity: AssetEntity,
    streamId: string,
    pkg: IPackage
  ): Promise<string> {
    const evLink: string = this._settings.get('PLATFORM.EVIDENCE_URL' as any);
    const file = pkg.files.find((item) => item.fileId === assetEntity.fileId);

    if (!file) {
      throw new Error(
        'The media info identified by fileId: ' + assetEntity.fileId + ' was not found'
      );
    }

    if (!file.shareId) {
      throw new Error(
        'The media info identified by fileId: ' + assetEntity.fileId + ' has no shareId'
      );
    }

    const shareId: string = file.shareId;
    const downloadFileName = this.createStreamDownloadName(assetEntity);
    const body: Record<string, any> = {
      streamId,
      downloadFileName,
      agency: pkg.header.agency,
    };
    const params: string = new URLSearchParams(body).toString();
    const link =
      evLink + 'api/vault/v2/sharedContent/' + shareId + '/downloadable-link?' + params;

    let response: IResponse<IStreamLink>;

    try {
      response = await this._api.get<IStreamLink>(link);
    } catch (err) {
      throw err;
    }

    return response.data.downloadableLink;
  }

  private _getStreamDownloadItemDTO(
    assetEntity: AssetEntity,
    stream: IVideoStream,
    assetItem: AssetEntityItem,
    pkg: IPackage,
    mode: EDetailPackageMode = EDetailPackageMode.GUESTS
  ): DownloadItemDTO {
    const assetCopy = cloneDeep(assetEntity);
    const downloadItem: DownloadItemDTO = new DownloadItemDTO({
      link: async (): Promise<string> => {
        let link: string;

        if (mode === EDetailPackageMode.SHARES) {
          link = await this._generateAuthLink(assetEntity, stream.streamId, pkg);
        } else {
          link = await this._generateGuestLink(assetEntity, stream.streamId, pkg);
        }

        return link;
      },
      accessType: pkg.header.accessType,
      name: this.createStreamDownloadName(assetEntity, stream),
      size: assetItem?.size,
      icon: assetEntity.iconName,
      parentId: assetEntity.fileId,
      expiredAt: assetEntity.fileLinkExpireAt,
      assetStorageType: assetEntity.assetStorageType,
      stream: stream,
      handlerRefreshLink: async () =>
        await this._updateLink(downloadItem, mode, assetCopy, stream.streamId, pkg),
    });

    return downloadItem;
  }

  getDownloadItemsDTO(
    assetEntity: AssetEntity,
    pkg: IPackage,
    downloadType: string = 'all',
    mode: EDetailPackageMode = EDetailPackageMode.GUESTS
  ): DownloadItemDTO[] {
    const downloadItems: DownloadItemDTO[] = [];

    assetEntity?.eventInfo?.videoStreams.forEach((item: IVideoStream) => {
      if (downloadType === 'all' || downloadType === 'evidence') {
        downloadItems.push(
          this._getStreamDownloadItemDTO(
            assetEntity,
            item,
            assetEntity.getFirstChild() as any,
            pkg,
            mode
          )
        );
      }
    });

    return downloadItems;
  }

  public createStreamDownloadName(asset: AssetEntity, stream?: IVideoStream): string {
    const assetName = asset?.displayName || '';
    const streamName = stream?.displayName || '';
    const date: string = DateFormatter.formatForSystemDate(asset.startTime);
    const streamId: string = stream?.streamId || '';
    const streamDownloadName = `${[assetName, streamName, date, streamId]
      .filter((namePart) => !!namePart)
      .join('_')}.mp4`;

    return streamDownloadName.replace(/\n+|\t+/g, '').replace(/[/\\?%*:|"<>]/g, '_');
  }

  private async _updateLink(
    downloadItem: DownloadItemDTO,
    mode: EDetailPackageMode = EDetailPackageMode.GUESTS,
    assetEntity: AssetEntity,
    streamId: string,
    pkg: IPackage
  ): Promise<void> {
    const updatedAssetEntity: AssetEntity =
      await this._assetEntityService.getAssetEntityByAsset(assetEntity, pkg);
    assetEntity.fileLinkExpireAt = updatedAssetEntity.fileLinkExpireAt;
    downloadItem.expiredAt = updatedAssetEntity.fileLinkExpireAt;

    if (mode === EDetailPackageMode.SHARES) {
      downloadItem.link = await this._generateAuthLink(assetEntity, streamId, pkg);
    } else {
      downloadItem.link = await this._generateGuestLink(assetEntity, streamId, pkg);
    }

    const first = updatedAssetEntity.getFirstChild();
    first.fileLink = downloadItem.link;
    first.inlineLink = downloadItem.link;

    assetEntity.inlineLink = downloadItem.link;
    assetEntity.fileLink = downloadItem.link;
  }
}

export { IStreamLink, StreamToDownload };
