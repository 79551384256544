/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { Api, IResponse, Settings } from '@msi/js-sdk';

import { ISettings } from '../../settings/settings.interface';
import { PackageItem } from './classes/package/PackageItem';
import {
  IPackageCalculationRequest,
  IPackageCalculationResponse,
  IPackageUpdatesRequest,
  IPackageUpdatesResponse,
} from './package.interfaces';

@Injectable()
class PackageUpdatesService {
  constructor(
    private _settings: Settings<ISettings>,
    private _api: Api
  ) {}

  async receivePackageUpdates(packageId: string): Promise<IPackageUpdatesResponse> {
    const domain: string = this._settings.get<string>('PLATFORM.API' as any);
    const url: string =
      domain +
      this._settings.get<string>('CALCULATE_PACKAGE_CHANGES') +
      '/' +
      'package-updates';

    const data: IPackageUpdatesRequest = { id: packageId };

    let response: IResponse<IPackageUpdatesResponse>;
    let updatesFailed: IPackageUpdatesResponse;

    try {
      response = await this._api.post<IPackageUpdatesResponse, IPackageUpdatesRequest>(
        url,
        data
      );
    } catch (err) {
      updatesFailed = {
        message: 'Updates failed',
        updates: [],
        fileUpdates: [],
        packageUpdatesFailed: true,
      };
    }

    return response ? response.data : updatesFailed;
  }

  async calculateUpdates(packageItems: PackageItem[]): Promise<void> {
    const fullContentPackages: PackageItem[] = packageItems.filter(
      (item) => item.fullContent !== false
    );
    const itemIds: string[] = fullContentPackages.map(
      (packageItem: PackageItem): string => packageItem.id
    );
    const domain: string = this._settings.get<string>('PLATFORM.API' as any);
    const url: string =
      domain +
      this._settings.get<string>('CALCULATE_PACKAGE_CHANGES') +
      '/' +
      'calculate-updates';
    const body: IPackageCalculationRequest = { items: itemIds };

    let response: IResponse<IPackageCalculationResponse>;

    try {
      response = await this._api.post<IPackageCalculationResponse, Record<string, any>>(
        url,
        body
      );
    } catch (err) {
      return;
    }

    response.data.packageIds.forEach((id: string): void => {
      const pkg: PackageItem = packageItems.find((item: PackageItem) => item.id === id);

      if (pkg) {
        pkg.needsUpdate = true;
      }
    });
  }
}

export { PackageUpdatesService };
