/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { emm } from '@msi/emm-sdk';
import { IStreamingMetadataApiConfiguration } from '@msi/msi-download-manager';

import { IPackage } from '../package/package.interfaces';
@Injectable({
  providedIn: 'root',
})
export class EvidenceService implements IStreamingMetadataApiConfiguration {
  private isAuth = true;
  protected pkg: IPackage;
  agencyName = '';

  assetMetadataLinksEndpointUrlGetter(assetId: string): string {
    if (this.isAuth) {
      return emm.settings
        .getUrl('GET_STREAMING_METADATA_URL')
        .replace('{{ assetId }}', assetId);
    } else {
      const shareId = this.getShareId(assetId);
      return this.setSharedStreamingMetadataUrl(
        'GET_SHARED_STREAMING_METADATA_URL',
        shareId
      );
    }
  }

  clipMetadataLinksEndpointUrlGetter(clipId: string, assetId: string): string {
    if (this.isAuth) {
      return emm.settings
        .getUrl('GET_CLIP_STREAMING_METADATA_URL')
        .replace('{{ clipId }}', clipId)
        .replace('{{ assetId }}', assetId);
    } else {
      const shareId = this.getShareId(assetId, clipId);
      return this.setSharedStreamingMetadataUrl(
        'GET_SHARED_CLIP_STREAMING_METADATA_URL',
        shareId
      );
    }
  }

  exportedRedactionMetadataLinksEndpointUrlGetter(
    exportedRedactionId: string,
    assetId: string
  ): string {
    if (this.isAuth) {
      return emm.settings
        .getUrl('GET_EXPORTED_REDACTION_STREAMING_METADATA_URL')
        .replace('{{ exportedRedactionId }}', exportedRedactionId)
        .replace('{{ assetId }}', assetId);
    } else {
      const shareId = this.getShareId(assetId, exportedRedactionId);
      return this.setSharedStreamingMetadataUrl(
        'GET_SHARED_EXPORTED_REDACTION_STREAMING_METADATA_URL',
        shareId
      );
    }
  }

  setAuthApiMode(agency: string, pkg: IPackage): void {
    this.isAuth = true;
    this.agencyName = agency;
    this.pkg = pkg;
  }

  setUnauthApiMode(agency: string, pkg: IPackage): void {
    this.isAuth = false;
    this.agencyName = agency;
    this.pkg = pkg;
  }

  private setSharedStreamingMetadataUrl(urlKey: string, shareId: string = ''): string {
    return emm.settings.getUrl(urlKey as any).replace('{{ shareId }}', shareId);
  }

  private getShareId(fileId: string, derivedItemId: string = ''): string {
    const assetId = derivedItemId ? derivedItemId : fileId;
    if (derivedItemId) {
      return (
        this.pkg?.files?.find((file) => file.derivedItemId === assetId).shareId || ''
      );
    }
    return this.pkg?.files?.find((file) => file.fileId === assetId).shareId || '';
  }
}
