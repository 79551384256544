/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AssetStorageType } from '@msi/emm-sdk';
import { EventEmitter } from '@msi/js-sdk';
import { Utils } from '@msi/js-sdk';
import { IVideoStream } from '@msi/msi-download-manager';

import {
  DerivedItemType,
  EPackageAccessType,
} from '../../services/package/package.enums';
import { EDownloadStatus } from './enums';
import { IDownloadContent, IDownloadItemPropsDTO } from './interfaces';

class DownloadItemDTO extends EventEmitter {
  public status: EDownloadStatus = EDownloadStatus.NEW;
  public link: string | ((...args: any[]) => string) = '';
  public name = '';
  public path = '';
  public size = 0;
  public humanSize = '';
  public icon = '';
  public chunkIndexOf = 0;
  public percentage = 1;
  public parentId = '';
  public derivedItemId?: string = '';
  public derivedItemType?: DerivedItemType;
  public expiredAt = 0;
  public error: Error | null | unknown = null;
  public assetStorageType: AssetStorageType;
  public accessType: EPackageAccessType;
  public stream?: IVideoStream;
  public handlerRefreshLink?: (parentId: string) => Promise<void>;
  public handlerGetContent?: (options: RequestInit) => Promise<IDownloadContent>;

  private _expiredAdditionalTime = 10000;

  constructor(props: IDownloadItemPropsDTO = {}) {
    super();

    Object.keys(props).forEach(
      (name: string): void =>
        ((this as any)[name] = props[name as keyof IDownloadItemPropsDTO])
    );

    if (this.size) {
      this.humanSize = Utils.convertBytes(this.size).fullValue;
    }

    if (this.path) {
      if (this.path[0] !== '/') {
        this.path = '/' + this.path;
      }

      if (this.path[this.path.length - 1] !== '/') {
        this.path = this.path + '/';
      }
    }
  }

  isExpired(): boolean {
    if (this.expiredAt) {
      return this.expiredAt < Date.now() + this._expiredAdditionalTime;
    } else {
      return false;
    }
  }

  async refreshLink(): Promise<void> {
    if (this.handlerRefreshLink) {
      await this.handlerRefreshLink(this.parentId);
    }
  }

  async getContent(options: RequestInit = {}): Promise<IDownloadContent> {
    if (this.handlerGetContent) {
      return await this.handlerGetContent(options);
    } else {
      throw new Error('DownloadItemDTO: "handlerGetContent" property is not defined');
    }
  }

  resume(): void {
    if (this.status === EDownloadStatus.ERROR || this.status === EDownloadStatus.CANCEL) {
      this.status = EDownloadStatus.PENDING;
      this.error = null;
      this.chunkIndexOf = 0;
      this.percentage = 1;

      this.emit(EDownloadStatus.PENDING);
    }
  }

  cancel(): void {
    if (this.status === EDownloadStatus.IN_PROGRESS) {
      this.status = EDownloadStatus.CANCEL;

      this.emit(EDownloadStatus.CANCEL);
    } else {
      this.emit(EDownloadStatus.REMOVE);
    }
  }
}

export { DownloadItemDTO };
