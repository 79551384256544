/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { ISpan } from './elastic-apm.interfaces';

class ApmSpan implements ISpan {
  private _span: any;

  constructor(span: any) {
    this._span = span;
  }

  end() {
    this._span.end();
  }
}

export { ApmSpan };
