<ng-container *transloco="let t; read: 'ccs'">
  <div class="date-range">
    <section
      class="date-range__input msi-input"
      [class.border-error]="!value && inputDateTouchedState">
      <div
        class="date-range__input-info"
        (click)="toggle($event)">
        <span
          class="date-range__input-value"
          [class.placeholder]="!value">
          {{ value || t('None selected') }}
        </span>
        <span class="date-range__icon">
          <msi-icon
            name="ic_date_time"
            [size]="18">
          </msi-icon>
        </span>
      </div>
      <msi-calendar
        #msiCalendar
        class="msi-calendar-size"
        [displayMonths]="1"
        outsideDays="hidden"
        [allowEmpty]="false"
        [ngClass]="{ hidden: !isOpen }"
        [quickSelectionRanges]="dateRanges"
        [allowFutureTime]="true"
        [minDate]="startDate"
        [maxDate]="endDate"
        [dateFormat]="calendarDateFormat"
        [language]="language"
        (dateChanged)="onDateChanged($event)"
        (dateRangeOptionObjectChange)="onDateRangeOptionObjectChange($event)">
      </msi-calendar>
    </section>
  </div>
  <ng-container *ngIf="!value && inputDateTouchedState">
    <span class="date-range__error">
      {{ t('You cannot proceed without selecting an expiration date') }}
    </span>
  </ng-container>
</ng-container>
