<div class="pp-action-icons">
  <msi-icon
    name="ic_more"
    class="icon-trigger"
    *ngIf="actionControls && actionControls.length"
    [size]="18"
    [msiDropdownTriggerFor]="actionsDropdown"></msi-icon>

  <msi-dropdown
    #actionsDropdown
    [backdrop]="false">
    <msi-dropdown-item
      *ngFor="let option of actionControls"
      [class.incident-removed]="incidentRemoved && option.id !== actions.DELETE"
      (click)="onAction($event, option, incidentRemoved)">
      {{ option.title | transloco }}
    </msi-dropdown-item>
  </msi-dropdown>
</div>
