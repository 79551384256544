/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { EAccessProtectionType } from './classes/accessProtection/AccessProtection.enum';
import { IPackageContent } from './classes/package/PackageContent.interafces';

const header: any = {
  title: 'DG 1213',
  validTo: '2020-10-31T22:59:59.000Z',
  groupId: '11',
};

const content: any = {
  sourceId: '11',
  flexMetadata: {
    sourceId: '11',
  },
  nature: 'Alarm',
  _nature_codeDetails: {
    id: '87Z5VzV5Y/cKr5Of12yvTvFdOSiJrO5AFoorPhPO3o4=',
    code: 'Alarm',
    description: 'Alarm',
    attributes: [
      { name: 'defaultPriority', value: '3' },
      { name: 'sendToMobile', value: 'Y' },
      { name: 'lawOffenseCode', value: 'ALAR' },
      { name: 'checkSpecialInstructions', value: 'N' },
      { name: 'minutesAllowedToEnter', value: '4' },
      { name: 'minutesAllowedToAssign', value: '4' },
      { name: 'minutesAllowedToRespond', value: '4' },
      { name: 'minutesAllowedToArrive', value: '10' },
      { name: 'minutesAllowedToComplete', value: '30' },
      { name: 'callType', value: 'a' },
      { name: 'automaticallyCreateIncident', value: 'Y' },
    ],
  },
  agency: 'Spillman Technologies',
  _agency_codeDetails: {
    id: 'VLazVtcOJYmZGAy7i9MLL3yXRKiZuAuu0gkhLKBO/cE=',
    code: 'SDS',
    description: 'Spillman Technologies',
    attributes: [
      { name: 'privilegeId', value: '1' },
      { name: 'name', value: 'Spillman Technologies' },
    ],
  },
  responsiblePersonnel: 'mort',
  _responsiblePersonnel_codeDetails: {
    id: 'cCJ0qm518h1wv1/nv5iLgFxaHJ+i+RM4J+T+MQSkOQA=',
    code: 'mort',
    description: 'mort',
    attributes: [
      { name: 'abbr', value: 'SDSIN' },
      { name: 'flexLoginName', value: 'mort' },
      { name: 'agencyCode', value: 'SDS' },
      { name: 'name', value: 'mort' },
      { name: 'isActive', value: 'True' },
      { name: 'customerId', value: 'onerms' },
      { name: 'agencyKey', value: 'host' },
    ],
  },
  caseNumber: '11',
  occurredAfterDateTime: '2019-02-27T01:00:00Z',
  occurredBeforeDateTime: '2019-01-27T02:00:00Z',
  whenReported: '2019-02-27T00:00:00Z',
  id: '9fd8141e-572f-442b-9103-fd9e1c48f100',
  historicId: '2c38f8b8-c674-4d28-aa00-af8d252454c5',
  tenant: 'msi.onerms',
  persons: [],
  organizations: [],
  schemaVersion: 'v2',
  vehicles: [],
  digitalEvidenceOnly: false,
};

const files: any[] = [
  { fileId: '88478142-7675-46cc-b5b0-528040e69847', entityId: '11' },
  { fileId: '5abb4544-7d5b-4914-82e0-4cad03306f01', entityId: '11' },
  { fileId: '16074fa3-19bd-435b-a9d6-c0790e9122c6', entityId: '11' },
  { fileId: 'c8fa91aa-3b26-491f-8da6-351d009e09e6', entityId: '11' },
  { fileId: '1b918d9d-694a-4c93-9feb-81941ef32d56', entityId: '11' },
  { fileId: '0ddbdfec-c425-4280-9a1f-f3faab7e035a', entityId: '11' },
  { fileId: '10550a61-774c-4b12-9920-8cfea8a90349', entityId: '11' },
  { fileId: '02ba25ed-0ded-4a1a-8ef4-42afcb2230f2', entityId: '11' },
  { fileId: '6aebdc91-0301-459a-b3e6-23479ae9f841', entityId: '11' },
  { fileId: '0e1399fa-9ab8-4f70-a2f8-2f029c1bae41', entityId: '11' },
  { fileId: '4a74c098-5a0f-40db-a1a9-a4dab422f9ca', entityId: '11' },
  { fileId: '4b622485-836c-4f51-92db-0da7b1c5aaa8', entityId: '11' },
  { fileId: 'f7a645d8-90ae-49bd-a368-1f4305a4a04e', entityId: '11' },
  { fileId: 'd63d647d-7543-46f2-b33c-efeeaed37a6d', entityId: '11' },
  { fileId: '8d930527-4b57-47b7-b73f-8fe15c0f100b', entityId: '11' },
  { fileId: 'd6faea72-ce2c-4ed4-89e4-d16972f835a4', entityId: '11' },
  { fileId: 'a47d7a38-2e55-413e-8683-99068045daf0', entityId: '11' },
];

const selectivePackageContent: IPackageContent = {
  caseNumber: '235',
  reportDateIndicator: false,
  persons: [
    {
      id: 'f9e0f564-0df5-4060-9f14-9a2de6993493',
      lastName: 'McGill',
      firstName: 'Jimmy',
      birthDate: '1970-01-20',
      race: 'White',
      hairStyle: 'Short',
      facialHair: 'Mustache',
      glasses: 'Wire Frames',
      complexion: 'Light',
      speechType: 'Articulate',
      physicalBuild: 'Weak',
      age: '50',
      heightInInches: 58,
      weightInPounds: 192,
      ethnicity: 'Unknown',
      eyeColor: 'Hazel',
      hairColor: 'Brown',
      socialSecurityNumber: '123-45-6789',
      monikers: ['Fireboy'],
      comments: [
        {
          id: '675e19c2-d415-48b5-bc3a-be8719ca5ea4',
          text: 'This is a placeholder comment for the person section comments field.',
        },
      ],
      locations: [
        {
          id: '5727f215-20fa-4377-b25f-66a7b82bf753',
        },
      ],
      phones: [
        {
          id: '0ef578f3-b186-46a4-b647-7f8747f98e24',
          type: 'Cell Phone',
          number: '403-861-7632',
        },
      ],
    },
    {
      id: '9bc653b7-605b-42d8-9b02-5c06d2a94c8d',
      lastName: 'McGill',
      firstName: 'Ralph',
      birthDate: '1985-03-15',
      age: '35',
      race: 'White',
      ethnicity: 'Not Hispanic or Latino',
      hairColor: 'White',
      monikers: ['Fireboy'],
    },
    {
      incidentContext: {},
      id: '4f2becf9-5a60-4867-8634-a9dd52c2b4f6',
      lastName: 'Moradzadeh',
      firstName: 'Gelayol',
      age: '32',
    },
    {
      id: '9bc653b7-605b-42d8-9b02-5c06d2a94c81',
      lastName: 'McGill',
      firstName: 'Ralph',
      birthDate: '1985-03-15',
      age: '35',
      race: 'White',
      ethnicity: 'Not Hispanic or Latino',
      hairColor: 'White',
      monikers: ['Fireboy'],
    },
    {
      incidentContext: {},
      id: '4f2becf9-5a60-4867-8634-a9dd52c2b4f2',
      lastName: 'Moradzadeh',
      firstName: 'Gelayol',
      age: '32',
    },
  ],
  organizations: [
    {
      name: 'government-calitri1',
      id: 'f588b459-5bcb-4002-8138-11e8a563a3bb',
    },
    {
      name: 'calitri-transportation',
      id: '4b6aed51-fdc3-47e8-8941-dfef92ebacf9',
    },
    {
      name: 'government-calitri1',
      id: 'f588b459-5bcb-4002-8138-11e8a563a3b1',
    },
    {
      name: 'calitri-transportation',
      id: '4b6aed51-fdc3-47e8-8941-dfef92ebacf1',
    },
  ],
  occurredBeforeDateTime: '2021-01-30T04: 59: 00Z',
  occurredAfterDateTime: '2021-01-29T05: 00: 00Z',
  narratives: [
    {
      type: 'primary',
      author: 'Admin, Agency',
      whenCreated: '2021-01-29T16: 13: 00Z',
      id: 'b26933ed-b95c-4f5b-8032-259e17e78351',
      text: 'This copy is for placement only This narrative is for placement only. Lorem ipsum dolor Jimmy McGill sit amet. This narrative is for placement only. Lorem ipsum dolor sit amet. test\n\nPepper jack cheese and biscuits lancashire. Cheesy feet mascarpone who moved my cheese cheese triangles pecorino cow when the cheese comes out everybody’s happy paneer. Smelly cheese st. agur blue cheese everyone loves cheddar the big cheese parmesan cheese and wine airedale.   \n\nCheese and biscuits cheese and biscuits caerphilly swiss cheese slices airedale fromage frais cow. Goat pepper jack. This narrative is for placement only. Lorem ipsum dolor sit amet. This narrative is for placement only. Lorem ipsum dolor sit amet. Pepper jack cheese and biscuits lancashire. Cheesy feet pepper jack cheese and biscuits lancashire. Cheesy feet mascarpone who moved my cheese cheese triangles pecorino cow when the cheese comes out everybody’s happy paneer. Smelly cheese st. agur blue cheese everyone loves cheddar the big cheese parmesan cheese and wine airedale. \n\nCheese and biscuits cheese and biscuits caerphilly swiss cheese slices airedale fromage frais cow. Goat pepper jack. This narrative is for placement only. Lorem ipsum dolor sit amet. This narrative is for placement only. Lorem ipsum dolor sit amet. Pepper jack cheese and biscuits lancashire. Cheesy feet mascarpone who moved my cheese cheese triangles pecorino cow when this narrative is for placement only. Lorem ipsum dolor sit amet. This narrative is for placement only. Lorem ipsum dolor sit amet. \n\nPepper jack cheese and biscuits lancashire. Cheesy feet mascarpone who moved my cheese cheese triangles pecorino cow when the cheese comes out everybody’s happy paneer. Smelly cheese st. agur blue cheese everyone loves cheddar the big cheese parmesan cheese and wine airedale. \n\nCheese and biscuits cheese and biscuits caerphilly swiss cheese slices airedale fromage frais cow. test',
    },
    {
      author: 'Brown, Preston',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2021-02-11T00: 18: 00+00: 00',
      text: 'this is a new narrative from Ken testing. ',
      id: 'c3bcaf5e-fa29-4b04-9b78-cb995082c9bc',
    },
    {
      author: 'Brown, Preston',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2021-02-11T00: 21: 00+00: 00',
      text: 'another tejstd',
      id: 'e52e5767-8408-4c05-821e-ae61c5eb324f',
    },
    {
      author: 'Brown, Preston',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2021-03-02T22: 41: 00+00: 00',
      text: 'Mr. Jimmy mcgill and his brother Ralph mcgill where the main problem as this fight. \n\nTheir friend Gelayol Morzad was also at the party. \n\n',
      id: '04878e10-8190-42be-81c0-1a2c0fbdad75',
    },
  ],
  whenReported: '2021-02-03T19: 07: 00Z',
  workflow: {
    status: 'REJECT',
  },
  id: 'a3bf3b19-2f8b-4e2f-b84e-348a77ce4861',
  historicId: '877d01b9-b55f-494b-abb5-7dc5fa39e258',
  nature: 'Alcohol Offense',
  circumstances: ['Animal, Dog', 'Accident'],
  modusOperandi: [
    {
      id: '4a3bc19f-9b13-4e18-8728-7c344a416a16',
      factor: 'Cigarette Brand',
      method: 'Accent or Dialect',
    },
  ],
  offenses: [
    {
      id: '7991ee80-2d80-4faa-9237-acc959720d67',
      code: 'Aggravated Assault',
      _code_codeDetails: {
        id: '995b9a70-1992-49d1-82a9-c0d72c770013',
        code: '13A',
        description: 'Aggravated Assault',
        attributes: [
          {
            name: 'IBRCode',
            value: '13A',
          },
          {
            name: 'offenseCodeAbbreviation',
            value: '13A',
          },
          {
            name: 'offenseCodeDescription',
            value: 'Aggravated Assault',
          },
          {
            name: 'Enable type of weapon force',
            value: 'true',
          },
        ],
      },
      locationType: 'Bank/Savings and Loan',
      premisesEntered: 3,
      methodOfEntry: 'Force',
    },
    {
      id: '1da12143-6ff8-426e-9f7a-045185327596',
      code: 'Destruction/Damage/Vandalism of Property',
      _code_codeDetails: {
        id: '7b4f9ae4-93f2-4568-bede-6538163cf5bf',
        code: '290',
        description: 'Destruction/Damage/Vandalism of Property',
        attributes: [
          {
            name: 'IBRCode',
            value: '290',
          },
          {
            name: 'offenseCodeAbbreviation',
            value: '290',
          },
          {
            name: 'offenseCodeDescription',
            value: 'Destruction/Damage/Vandalism of Property',
          },
        ],
      },
    },
    {
      id: 'b16a01d3-6db7-420f-97e5-0e5f69d21204',
      code: 'All Other Offenses',
      _code_codeDetails: {
        id: '8a872b43-4c63-48c0-b2d2-db76dccd61aa',
        code: '90Z',
        description: 'All Other Offenses',
        attributes: [
          {
            name: 'IBRCode',
            value: '90Z',
          },
          {
            name: 'offenseCodeAbbreviation',
            value: '90Z',
          },
          {
            name: 'offenseCodeDescription',
            value: 'All Other Offenses',
          },
          {
            name: 'Enable society is victim',
            value: 'true',
          },
        ],
      },
    },
    {
      id: '88d976a7-c712-469c-9a16-c541256f001f',
      code: 'Murder & Non-negligent Manslaughter',
      _code_codeDetails: {
        id: 'd6c505ce-ac28-4481-9c07-50df9301f4e9',
        code: '09A',
        description: 'Murder & Non-negligent Manslaughter',
        attributes: [
          {
            name: 'IBRCode',
            value: '09A',
          },
          {
            name: 'offenseCodeAbbreviation',
            value: '09A',
          },
          {
            name: 'offenseCodeDescription',
            value: 'Murder & Non-negligent Manslaughter',
          },
          {
            name: 'Enable type of weapon force',
            value: 'true',
          },
        ],
      },
    },
  ],
  respondingPersonnels: ['Reese Davis', 'Preston Brown', 'G C', 'Shane Miller'],
  vehicles: [
    {
      id: '77ed645e-370e-408f-8b15-92926daccfaa',
      doors: '5',
      licensePlate: 'BLM123',
      licenseState: 'NV',
      licenseYear: '2019',
      licenseExpirationDate: '2021-06-01',
      make: 'Ford',
      model: 'Explorer',
      vehicleType: 'Sport Utility Vehicle',
      primaryColor: 'White',
      secondaryColor: 'Black',
      vin: '35654346677655434567654356765',
      licenseType: 'Ambulance',
      year: 2005,
      style: 'Hatchback/Fastback',
      ownerAppliedNumber: '123456789',
      owner: {
        person: {
          lastName: 'Doe',
          firstName: 'John',
          middleName: 'Bob',
          suffix: 'abc',
          birthDate: '2018-10-08',
          age: '28',
          phones: [
            {
              id: 'e78674ab-e116-46dc-ad5a-660548f3cec8',
              type: 'School Phone',
              number: '123123123123',
            },
            {
              id: '881cd095-c0da-4363-907d-627015824cbe',
              type: 'Pager',
              number: '123123123123123',
            },
            {
              id: '1b59baa6-fe6f-44d7-b3aa-62ebe45738f1',
              type: 'Secondary Home Phone',
              number: '123123123123123',
            },
            {
              id: '35d70692-5c7e-4408-9601-6bb5be77b825',
              type: 'Secondary Home Phone',
              number: '123556787',
            },
          ],
          driverLicense: {
            number: 'DCX123456789',
            state: 'Georgia',
            expirationDate: '2017-08-25',
          },
          locations: [
            {
              id: 'ed57d8db-2678-49a5-8d71-600da57b6706',
              type: 'Mailing Address',
            },
            {
              id: '38b0258f-7145-47a7-89de-67a2d98c9f2f',
              type: 'Work Address',
            },
            {
              id: '75cf875d-2096-450a-96e4-cbbb3de6f603',
              type: 'Work Address',
            },
          ],
          id: 'd2a8d2b6-57ba-4072-86c7-a2c504f6f241',
        },
      },
      garageLocation: {},
    },
    {
      id: 'e7afbafa-322f-488c-b8ca-52444869ca71',
      make: 'Audi',
      model: 'CSX',
      vehicleType: 'kFXlGleemBTDFCswOoJtp4MgcpUt7Vo8ELvLojKYIQ4=',
      year: 1990,
    },
    {
      id: '3b5947a4-3fd8-4894-bcbf-c5db63c36b9d',
      make: 'BMW',
      model: 'Legend',
      vehicleType: 'Passenger Car',
    },
    {
      id: 'e7afbafa-322f-488c-b8ca-52444869ca72',
      make: 'Audi',
      model: 'CSX',
      vehicleType: 'kFXlGleemBTDFCswOoJtp4MgcpUt7Vo8ELvLojKYIQ4=',
      year: 1990,
    },
    {
      id: '3b5947a4-3fd8-4894-bcbf-c5db63c36b93',
      make: 'BMW',
      model: 'Legend',
      vehicleType: 'Passenger Car',
    },
  ],
  location: {
    address: {
      streetFullText: 'Rob\'s House',
    },
  },
  responsiblePersonnel: 'Agency Admin',
  tenant: 'msi.ccr.sales.a',
  chainOfCustody: {},
  digitalEvidenceOnly: false,
  propertyItems: [
    {
      id: '1bc86b8c-cae4-417a-9406-b1f4b2b981b0',
      itemType: 'Alcohol',
      status: 'None',
      brand: 'ada',
      model: 'dadada',
      year: 2000,
      count: '1',
      whenStatusDeclared: '2021-08-24T14: 58: 00Z',
    },
    {
      id: '1bc86b8c-cae4-417a-9406-b1f4b2b981b9',
      itemType: 'Alcohol',
      status: 'None',
      brand: 'ada',
      model: 'dadada',
      year: 2000,
      count: '1',
      whenStatusDeclared: '2021-08-24T14: 58: 00Z',
    },
    {
      id: '1bc86b8c-cae4-417a-9406-b1f4b2b981b8',
      itemType: 'Alcohol',
      status: 'None',
      brand: 'ada',
      model: 'dadada',
      year: 2000,
      count: '1',
      whenStatusDeclared: '2021-08-24T14: 58: 00Z',
    },
    {
      id: '1bc86b8c-cae4-417a-9406-b1f4b2b981b7',
      itemType: 'Alcohol',
      status: 'None',
      brand: 'ada',
      model: 'dadada',
      year: 2000,
      count: '1',
      whenStatusDeclared: '2021-08-24T14: 58: 00Z',
    },
    {
      id: '1bc86b8c-cae4-417a-9406-b1f4b2b981b6',
      itemType: 'Alcohol',
      status: 'None',
      brand: 'ada',
      model: 'dadada',
      year: 2000,
      count: '1',
      whenStatusDeclared: '2021-08-24T14: 58: 00Z',
    },
    {
      id: '1bc86b8c-cae4-417a-9406-b1f4b2b981b5',
      itemType: 'Alcohol',
      status: 'None',
      brand: 'ada',
      model: 'dadada',
      year: 2000,
      count: '1',
      whenStatusDeclared: '2021-08-24T14: 58: 00Z',
    },
  ],
};

const packageUpdates: any[] = [
  {
    entityId: '',
    entityType: 'HEADER',
    operation: 'REPLACE',
  },
];

const searchResult: any = {
  incidentReport: content,
  lawIncidentFiles: files,
};

const pkg: any = {
  content,
  files,
  header: {
    accessType: 'openLink',
    agency: 'msi.onerms',
    agencyName: 'Motorola OneRMS Krakow',
    broken: false,
    created: '2020-11-03T18:06:15.2703335Z',
    createdBy: 'CCRecordsFTS@motorolasolutions.com',
    groupId: '11',
    id: '75c3ce09-aafc-4fed-9dcf-84864f0ec56c',
    link: 'eBHsviiS4yCM433Z6u4NBot30eP_ydACoXbOliTfRqWBS24uk8HD7njVgkEApBT1euqaJGf7arGyu6q4jTcFy_nUdUbdfaRNCw7X277yJRaSdSrwmgUR6aG7RQMpimeEHxDYRnFsMQ7Wi9L2wdsiKpZ4niI4GnlRIx9JngSRt-4',
    sharedWith: ['dmytro.hotovskyi@motorolasolutions.com'],
    sharedWithAuthenticated: [],
    state: 'share',
    subscribedWith: [],
    title: ' adasdDG 121212-sfashfakjdhflkahf',
    updated: '2020-11-04T12:10:48.963544Z',
    validTo: '2021-02-02T22:59:59Z',
    views: 0,
    accessProtectionType: EAccessProtectionType.PASSWORD,
  },
  updates: packageUpdates,
};

const lawIncidentData: any = {
  caseNumber: '1',
  historicId: '393841ca-fe82-4a03-a44d-b3a07aa22b67',
  id: 'bb6938c9-bafe-4de9-ad51-bc9427d69dab',
  narratives: [
    {
      id: '2444bfce-8986-46d9-a5cc-4423c1dbd37f',
      author: 'USER1, UX',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-16T14:33:00+00:00',
      text: 'vvvvv',
    },
    {
      id: '26ff5162-991c-4f92-baff-ed2d0bad3ee5',
      author: 'USER1, UX',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-16T14:31:00+00:00',
      text: 'qwerty',
    },
    {
      id: '42817a71-63af-4666-8ea5-18e44e5ad602',
      author: 'USER1, UX',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-22T07:32:00+00:00',
      text: 'He was a slow men',
    },
    {
      id: '513fd5a0-dcb0-4fe1-a8a3-ca771ed79d9c',
      author: 'Madej, Adrian',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-15T09:21:00+00:00',
      text: 'New narrative',
    },
    {
      id: '54734aa7-a903-49a5-be0f-c42ce9a7410c',
      author: 'Madej, Adrian',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-16T14:26:00+00:00',
      text: 'dadas',
    },
    {
      id: '7e1024bb-069b-4c3d-bc30-f7c0dd201f5c',
      author: 'USER1, UX',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-27T12:08:00+00:00',
      text: 'testest',
    },
    {
      id: '9b300496-dd32-436a-8ddb-41ccf645ea1e',
      author: 'Madej, Adrian',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-15T09:50:00+00:00',
      text: 'dasdasdasd',
    },
    {
      id: 'a36fa20d-accc-4392-aec4-bf899b966b59',
      author: 'USER1, UX',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-04-29T11:55:35+00:00',
      text: 'Test of date for supplemental',
    },
    {
      id: 'bafa80ed-9404-4f4f-9809-42fd14072ab3',
      author: 'USER1, UX',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-05-11T18:34:14+00:00',
      text: 'Test of a new narrative',
    },
    {
      id: 'cc7682bd-c7d5-4e21-835f-9072960fea67',
      author: 'USER1, UX',
      mimeType: 'text/plain',
      type: 'primary',
      whenCreated: '2020-04-14T18:44:47+00:00',
      text: 'THis is a test for narative',
    },
    {
      id: 'd536693e-5511-4299-b200-8fad7be27d2e',
      author: 'Kocela, Anna',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-05-21T12:00:00+00:00',
      text: 'new narrative',
    },
    {
      id: 'e168a348-7b8b-4c70-8fc0-7b25945f0664',
      author: 'USER1, UX',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-16T14:34:00+00:00',
      text: 'rrrrrrrrrrrrrr',
    },
    {
      id: 'e603129f-4520-4f19-b287-9f5ab6a88c1e',
      author: 'Madej, Adrian',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-16T14:27:00+00:00',
      text: 'zzzzzzzzzz',
    },
    {
      id: 'efa0d6b3-8789-406e-a208-f82fc77e2fb8',
      author: 'USER1, UX',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-16T14:41:00+00:00',
      text: 'dasdasdasdasdasdasda',
    },
    {
      id: 'f6da1429-0bd4-418c-88f5-8899b603672d',
      author: 'USER1, UX',
      mimeType: 'text/plain',
      type: 'supplemental',
      whenCreated: '2020-07-22T07:32:00+00:00',
      text: 'I dont remember, It happened so quickly',
    },
  ],
  organizations: [],
  persons: [],
  schemaVersion: 'v2',
  tenant: 'msi.onerms',
  vehicles: [],
};

const filesSharingInfo: any = {
  sharableFiles: [
    { fileId: '01165d93-3ffe-447a-89f6-fcca09aec974', entityId: '1' },
    { fileId: '0c99b965-7cf0-4f19-81d6-8443338cb18a', entityId: '1' },
    { fileId: '0dd87886-f137-496e-a32e-2442808eae1f', entityId: '1' },
    { fileId: '19b76fd7-a9e6-49f6-a8ad-4af3b5ce8ab0', entityId: '1' },
    { fileId: '1ba878cc-5f11-4063-b1ef-37d8fb9d9294', entityId: '1' },
    { fileId: '207707f1-d921-4ef1-8e9a-629fe622841b', entityId: '1' },
    { fileId: '3054cb33-9e7e-4fd4-abca-cd84e3791f4e', entityId: '1' },
    { fileId: '3400fcd8-7325-46ab-aa3b-fa3d56ba907a', entityId: '1' },
    { fileId: '3b555c50-99cc-4e6d-937e-ded1a26ab8fe', entityId: '1' },
    { fileId: '3e544781-9637-435c-9712-92b726367b4e', entityId: '1' },
    { fileId: '499b2c55-d5ba-41f5-8dbf-50b549dda7ae', entityId: '1' },
    { fileId: '4c16080c-0f8c-4a41-8a3b-4e3dd21b9153', entityId: '1' },
    { fileId: '4d6a5edf-f0b6-495a-affe-ee606d27ab2b', entityId: '1' },
    { fileId: '5fb95eca-38f7-49a8-b0d8-bad52c8102c3', entityId: '1' },
    { fileId: '67d9b179-f558-4c3c-8298-667bf40311f1', entityId: '1' },
    { fileId: '8a69da83-472a-4f5f-a9c4-fdc238e8dbbe', entityId: '1' },
    { fileId: '913dbf49-a51b-4c42-82f4-3828c38ced51', entityId: '1' },
    { fileId: '95a3de56-ed46-4d6f-bf79-099c6ad9d0de', entityId: '1' },
    { fileId: '95ba746a-aa62-47a3-9f27-bb175d00c550', entityId: '1' },
    { fileId: '978de369-402b-4297-acd7-5d53d87af45c', entityId: '1' },
    { fileId: '98893b5d-827f-4dd4-a514-2de988ef31cf', entityId: '1' },
    { fileId: 'ab976637-2337-4ac5-9ec6-8818102f2afe', entityId: '1' },
    { fileId: 'b4f1a710-cd9a-47be-b046-df346dfcb10e', entityId: '1' },
    { fileId: 'bacbc575-0a9e-4ae3-b17a-3949df6f94d6', entityId: '1' },
    { fileId: 'bc44cb87-7e44-4a36-9763-0120b3f2cf23', entityId: '1' },
    { fileId: 'c1416fd1-6c6e-4198-ac49-1ea30e70843a', entityId: '1' },
    { fileId: 'c66bd652-eef6-4367-8d14-410ee4e69722', entityId: '1' },
    { fileId: 'c71cfba9-ab97-40c3-a052-58d16252a100', entityId: '1' },
    { fileId: 'cd1bc2a4-f04b-4af6-a439-14962bcd564f', entityId: '1' },
    { fileId: 'cfad469b-3572-4b60-9cfb-f7a461971ba7', entityId: '1' },
    { fileId: 'd906ac9c-765e-45b3-a9f7-07db69428d52', entityId: '1' },
    { fileId: 'e1e7b12c-bbe7-421e-b704-1cc2d349eb9e', entityId: '1' },
    { fileId: 'e4534e88-d8e1-4834-be58-8d52bbef0d4e', entityId: '1' },
    { fileId: 'e7a5463d-4bf1-413f-a4f9-06ce385113a0', entityId: '1' },
    { fileId: 'ebcf5d07-1c58-4d55-9f33-87d9ce52b883', entityId: '1' },
    { fileId: 'fc00b34c-d660-46b7-b6e5-1fcb8781d4ac', entityId: '1' },
    { fileId: 'ff1e547d-b890-4f8d-b55e-874efc0889de', entityId: '1' },
  ],
};

const bdpSearchResult: any = {
  searchId: '81725000-d712-4b6d-a6a6-7b8f05825383',
  items: [
    {
      header: {
        id: '5019bdb1-451b-4f89-a453-664fce967692',
        agency: 'msi.onerms',
        agencyName: 'Motorola OneRms',
        title:
          'DG 111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111',
        groupId: 'bb51fea6-ccbd-4432-a9a1-211722b47cf5',
        created: '2021-03-16T11:12:59.9349754Z',
        updated: '2021-03-16T11:12:59.9349754Z',
        createdBy: 'CCRecordsFTS@motorolasolutions.com',
        views: 0,
        validTo: '2021-06-14T10:12:59.557Z',
        state: 'share',
        sharedWith: [],
        sharedWithAuthenticated: [
          {
            email: 'dmytro.hotovskyi@motorolasolutions.com',
            agency: 'test.prosecutor',
          },
        ],
        link: 'ua9JHOxKBRR1OyGnqdcfFcY2O1_f-KKEu08pFYufmkDR4XgrmCEL2uY537QO2aisR9dDotlrb29pkx-I9Fzbh52fbs33f-ffnD_Rk9wNEk6t7t08WqS783mtuwHa36BfjpPnofh1n8Y47woXKSNRPjN1FsozgTvewnOpymX4-r4',
        subscribedWith: [],
        broken: false,
        accessType: 'accountLogin',
        needsUpdate: false,
        incidentRemoved: true,
      },
      content: {
        DocumentId: '653558d8-6320-4935-acf3-b7967d2d9af9',
        id: 'bb51fea6-ccbd-4432-a9a1-211722b47cf5',
        caseNumber: 'bb51fea6-ccbd-4432-a9a1-211722b47cf5',
        sourceId: 'bb51fea6-ccbd-4432-a9a1-211722b47cf5',
        historicId: '11a0438e-a6f5-4323-917a-36f25e2d1401',
        narratives: [
          {
            id: 'a08111ea-0d2c-4ebc-98bb-51e9cb0f94fd-text',
            author: '6dc51fa8-972d-4d84-a40a-f8ef0a5cabb6',
            type: 'primary',
            text: '20210211070727freetextsearchp1',
            mimeType: 'text/plain',
            P1NarrativeSourceId: '6d8645cd-1937-4462-9f49-8df5c2b68583',
          },
          {
            id: 'a08111ea-0d2c-4ebc-98bb-51e9cb0f94fd-rtf',
            author: '6dc51fa8-972d-4d84-a40a-f8ef0a5cabb6',
            type: 'supplemental',
            text: '20210211070727freetextsearchp1',
            mimeType: 'text/plain',
            P1NarrativeSourceId: '6d8645cd-1937-4462-9f49-8df5c2b68583',
          },
        ],
        tenant: 'msi.onerms',
        persons: [],
        organizations: [],
        schemaVersion: 'v2',
        vehicles: [],
        propertyItems: [],
        chainOfCustody: {},
        digitalEvidenceOnly: false,
      },
      updates: [],
      files: [],
    },
    {
      header: {
        id: '5d03fd86-e053-407c-86f3-caeb57c3666c',
        agency: 'msi.onerms',
        agencyName: 'Motorola OneRms',
        title: 'Test',
        groupId: '20210301151326',
        created: '2021-04-23T13:46:52.2518882Z',
        updated: '2021-04-23T13:46:52.2518882Z',
        createdBy: 'CCRecordsFTS@motorolasolutions.com',
        views: 2,
        validTo: '2021-07-22T13:46:51.914Z',
        state: 'share',
        sharedWith: [],
        sharedWithAuthenticated: [
          {
            email: 'atw102@gh.motorolasolutions.com',
            agency: 'msi.krk.prosecutor',
          },
        ],
        link: '9q-8A338GOEvYxz0d4VBC3FwXCN7mpAKwPsGA6mD6QZZ9wpAs8RLwp5ybioFDKu88rj6wskJC4ezoxiFSquXtkbeTQj7qmTYfRALfPZlkKkM3_sQ3j8xm-04pA6ns_iU3GFDP0zIGsiWTUeDuVP9QfZUGwI33-BGtzYQ972BgQI',
        subscribedWith: [],
        broken: false,
        accessType: 'accountLogin',
        incidentRemoved: false,
      },
      content: {
        caseNumber: '20210301151326',
        narratives: [
          {
            id: 'prim-1',
            type: 'primary',
            mimeType: 'text/plain',
            text: 'PrimaryNarrative 20210301151326',
            whenCreated: '2019-03-21T12:52:37Z',
            author: 'E2E Tests',
          },
          {
            id: 'supp-1',
            type: 'supplemental',
            mimeType: 'text/plain',
            text: 'SupplementalNarrative 20210301151326',
            whenCreated: '2019-03-21T12:52:32Z',
            author: 'E2E Tests',
            compoundSourceId: {
              number: '20210301151326',
              seq: '1',
            },
            flexMetadata: {
              compoundSourceId: {
                number: '20210301151326',
                seq: '1',
              },
            },
          },
        ],
        id: '9493a092-2b7f-4a9a-93c1-e45d9914aba3',
        historicId: 'b5f0df58-7125-4361-a48a-14e1ea0ba64e',
        tenant: 'msi.onerms',
        persons: [],
        organizations: [],
        schemaVersion: 'v2',
        vehicles: [],
        propertyItems: [],
        chainOfCustody: {},
        digitalEvidenceOnly: false,
      },
      updates: [],
      files: [
        // {
        //     'fileId': '2d9658b4-4693-46ec-83a4-0d5b8788149a',
        //     'entityId': '20210301151326'
        // },
        // {
        //     'fileId': '11725f82-a6f4-4d81-9092-fc18f10fab79',
        //     'entityId': '20210301151326'
        // }
      ],
    },
    {
      header: {
        id: '337b622f-8efc-4a7d-8f5d-551c095c2a96',
        agency: 'msi.onerms',
        agencyName: 'Motorola OneRms',
        title: 'Streams auth',
        groupId: '20210219151740',
        created: '2021-04-23T12:08:48.692122Z',
        updated: '2021-04-23T12:08:48.692122Z',
        createdBy: 'CCRecordsFTS@motorolasolutions.com',
        views: 4,
        validTo: '2021-05-23T12:08:48.43Z',
        state: 'share',
        sharedWith: [
          {
            email: 'ckdw86@gh.motorolasolutions.com',
            agency: 'msi.krk.prosecutor',
          },
        ],
        sharedWithAuthenticated: [
          {
            email: 'ckdw86@gh.motorolasolutions.com',
            agency: 'msi.krk.prosecutor',
          },
        ],
        link: 'qeuEKDE57hxVhiMI7hiEJsebBaDrADDzlHk_hvRv2z_0XCAsOUezdgCB1DIGArbvqkU3HJU2oo05C1zHdiT_Mj8GTe2U2KvijC58-c7LBa3fjy0GoWGZhvLQt8w1dkYgvS8gSspqX6_TqjHz3f9o8XWB719VaswlbhqjKJ6xREI',
        subscribedWith: [],
        broken: false,
        accessType: 'accountLogin',
        needsUpdate: false,
        incidentRemoved: false,
      },
      content: {
        caseNumber: '20210219151740',
        narratives: [
          {
            id: 'prim-1',
            type: 'primary',
            mimeType: 'text/plain',
            text: 'PrimaryNarrative 20210219151740',
            whenCreated: '2019-03-21T12:52:37Z',
            author: 'E2E Tests',
          },
          {
            id: 'supp-1',
            type: 'supplemental',
            mimeType: 'text/plain',
            text: 'SupplementalNarrative 20210219151740',
            whenCreated: '2019-03-21T12:52:32Z',
            author: 'E2E Tests',
            compoundSourceId: {
              number: '20210219151740',
              seq: '1',
            },
            flexMetadata: {
              compoundSourceId: {
                number: '20210219151740',
                seq: '1',
              },
            },
          },
        ],
        id: '93eee0a0-903e-4763-a175-c8aff9b37599',
        historicId: '4f608743-2a30-47c2-950a-57d66d2527c5',
        tenant: 'msi.onerms',
        persons: [],
        organizations: [],
        schemaVersion: 'v2',
        vehicles: [],
        propertyItems: [],
        chainOfCustody: {},
        digitalEvidenceOnly: false,
      },
      updates: [],
      files: [
        // {
        //     'fileId': '2d9658b4-4693-46ec-83a4-0d5b8788149a',
        //     'entityId': '20210219151740'
        // },
        // {
        //     'fileId': '11725f82-a6f4-4d81-9092-fc18f10fab79',
        //     'entityId': '20210219151740'
        // }
      ],
    },
    {
      header: {
        id: '80bdadf4-2194-46fc-ab27-3f6734c06228',
        agency: 'msi.onerms',
        agencyName: 'Motorola OneRms',
        title: 'unauth streams',
        groupId: '20210219151740',
        created: '2021-04-23T12:09:12.1804979Z',
        updated: '2021-04-23T12:09:12.1804979Z',
        createdBy: 'CCRecordsFTS@motorolasolutions.com',
        views: 0,
        validTo: '2021-05-23T12:09:12.072Z',
        state: 'share',
        sharedWith: [
          'ckdw86@gh.motorolasolutions.com',
          'test.user1@motorolasolutions.com',
        ],
        sharedWithAuthenticated: [],
        link: '852tBaPOVFZ8k7GYfVwT8w-V8zz71QXjO289K9EsML2iZ5KjOb2DB90ss4p4dW8p7QCtZJYS8ue5LpWofBeZjC5DHaGN5Jp__2jQkvY-iSlacvqrmZZIF4e4mpXWB_IIb0xlyQ89yIWQ2RrqpDyvhIqeRAMh90EvPL498M9UunU',
        subscribedWith: [],
        broken: false,
        accessType: 'openLink',
        needsUpdate: false,
        incidentRemoved: true,
      },
      content: {
        caseNumber: '20210219151740',
        narratives: [
          {
            id: 'prim-1',
            type: 'primary',
            mimeType: 'text/plain',
            text: 'PrimaryNarrative 20210219151740',
            whenCreated: '2019-03-21T12:52:37Z',
            author: 'E2E Tests',
          },
          {
            id: 'supp-1',
            type: 'supplemental',
            mimeType: 'text/plain',
            text: 'SupplementalNarrative 20210219151740',
            whenCreated: '2019-03-21T12:52:32Z',
            author: 'E2E Tests',
            compoundSourceId: {
              number: '20210219151740',
              seq: '1',
            },
            flexMetadata: {
              compoundSourceId: {
                number: '20210219151740',
                seq: '1',
              },
            },
          },
        ],
        id: '93eee0a0-903e-4763-a175-c8aff9b37599',
        historicId: '4f608743-2a30-47c2-950a-57d66d2527c5',
        tenant: 'msi.onerms',
        persons: [],
        organizations: [],
        schemaVersion: 'v2',
        vehicles: [],
        propertyItems: [],
        chainOfCustody: {},
        digitalEvidenceOnly: false,
      },
      updates: [],
      files: [
        // {
        //     'fileId': '2d9658b4-4693-46ec-83a4-0d5b8788149a',
        //     'entityId': '20210219151740'
        // },
        // {
        //     'fileId': '11725f82-a6f4-4d81-9092-fc18f10fab79',
        //     'entityId': '20210219151740'
        // }
      ],
    },
    {
      header: {
        id: '1915f1ce-e481-482c-8ccd-36c8516d89f9',
        agency: 'msi.onerms',
        agencyName: 'Motorola OneRms',
        title: 'test2',
        groupId: '20210219151740',
        created: '2021-04-23T11:42:59.5344004Z',
        updated: '2021-04-23T11:42:59.5344004Z',
        createdBy: 'CCRecordsFTS@motorolasolutions.com',
        views: 2,
        validTo: '2021-07-22T11:42:59.26Z',
        state: 'share',
        sharedWith: [],
        sharedWithAuthenticated: [
          {
            email: 'atw102@gh.motorolasolutions.com',
            agency: 'msi.krk.prosecutor',
          },
        ],
        link: 'OdBQK5s1Ecfk1xkKh64txaVHPkFuDqsj2V1tza2aSQDUeU6coRitu_A4fTZui3g4oXxVDKki80nIN7ed0rWkCo85AJh2eI8JSg3r8thLzuXZRyDSYEURyJkE-Jb7KgzATj9aDyEXQZfA9lucd7TE08pjZdjBOOIJLTWjaAaLF50',
        subscribedWith: [],
        broken: false,
        accessType: 'accountLogin',
        needsUpdate: false,
        incidentRemoved: true,
      },
      content: {
        caseNumber: '20210219151740',
        narratives: [
          {
            id: 'prim-1',
            type: 'primary',
            mimeType: 'text/plain',
            text: 'PrimaryNarrative 20210219151740',
            whenCreated: '2019-03-21T12:52:37Z',
            author: 'E2E Tests',
          },
          {
            id: 'supp-1',
            type: 'supplemental',
            mimeType: 'text/plain',
            text: 'SupplementalNarrative 20210219151740',
            whenCreated: '2019-03-21T12:52:32Z',
            author: 'E2E Tests',
            compoundSourceId: {
              number: '20210219151740',
              seq: '1',
            },
            flexMetadata: {
              compoundSourceId: {
                number: '20210219151740',
                seq: '1',
              },
            },
          },
        ],
        id: '93eee0a0-903e-4763-a175-c8aff9b37599',
        historicId: '4f608743-2a30-47c2-950a-57d66d2527c5',
        tenant: 'msi.onerms',
        persons: [],
        organizations: [],
        schemaVersion: 'v2',
        vehicles: [],
        propertyItems: [],
        chainOfCustody: {},
        digitalEvidenceOnly: false,
      },
      updates: [],
      files: [
        // {
        //     'fileId': '2d9658b4-4693-46ec-83a4-0d5b8788149a',
        //     'entityId': '20210219151740'
        // },
        // {
        //     'fileId': '11725f82-a6f4-4d81-9092-fc18f10fab79',
        //     'entityId': '20210219151740'
        // }
      ],
    },
  ],
};

export {
  bdpSearchResult,
  content,
  files,
  filesSharingInfo,
  header,
  lawIncidentData,
  packageUpdates,
  pkg,
  searchResult,
  selectivePackageContent,
};
