<div
  *ngIf="incompleteAssetsCount"
  class="asset-warning__container">
  <h6 class="asset-warning__header">
    <msi-icon
      name="ic_information"
      size="24"></msi-icon>
    <p>{{ 'Incomplete assets' | transloco }}</p>
  </h6>
  <section class="asset-warning__content">
    <p>
      <span *ngIf="incompleteAssetsCount === 1">{{ 'There is ' | transloco }}</span>
      <span *ngIf="incompleteAssetsCount > 1">{{ 'There are ' | transloco }}</span>
      <span>{{ incompleteAssetsCount }}</span>
      <span *ngIf="incompleteAssetsCount === 1">{{ ' incomplete asset' | transloco }}</span>
      <span *ngIf="incompleteAssetsCount > 1">{{ ' incomplete assets' | transloco }}</span>
      {{ ' which won’t be included in the share package.' | transloco }}
    </p>
  </section>
</div>
<div
  *ngIf="directoryAssetsCount"
  class="asset-warning__container"
  [class.remove-top-padding]="incompleteAssetsCount">
  <h6 class="asset-warning__header">
    <msi-icon
      name="ic_information"
      size="24"></msi-icon>
    <p>{{ 'CC Evidence loader assets' | transloco }}</p>
  </h6>
  <section class="asset-warning__content">
    <p>
      <span *ngIf="directoryAssetsCount === 1">{{ 'There is ' | transloco }}</span>
      <span *ngIf="directoryAssetsCount > 1">{{ 'There are ' | transloco }}</span>
      <span>{{ directoryAssetsCount }}</span>
      <span *ngIf="directoryAssetsCount === 1">
        {{ ' large asset uploaded by CC Evidence loader' | transloco }}
      </span>
      <span *ngIf="directoryAssetsCount > 1">
        {{ ' large assets uploaded by CC Evidence loader' | transloco }}
      </span>
      {{ ' which won’t be included in the share package.' | transloco }}
    </p>
  </section>
</div>
<div
  *ngIf="shortRetentionAssetsCount"
  class="asset-warning__container"
  [class.asset-retention-warrning]="shortRetentionAssetsCount">
  <h6 class="asset-warning__header">
    <msi-icon
      name="ic_information"
      size="24"
      class="alert"></msi-icon>
    <p class="alert">{{ 'Asset with shorter retention time' | transloco }}</p>
  </h6>
  <section class="asset-warning__content alert">
    <p>
      <span
        *ngIf="shortRetentionAssetsCount === 1"
        class="alert">
        {{ 'There is ' | transloco }}
      </span>
      <span
        *ngIf="shortRetentionAssetsCount > 1"
        class="alert">
        {{ 'There are ' | transloco }}
      </span>
      <span class="alert">{{ shortRetentionAssetsCount }}</span>
      <span
        *ngIf="shortRetentionAssetsCount === 1"
        class="alert">
        {{ ' asset with a retention period shorter than the package expiration.' | transloco }}
      </span>
      <span
        *ngIf="shortRetentionAssetsCount > 1"
        class="alert">
        {{ ' assets with a retention period shorter than the package expiration.' | transloco }}
      </span>
    </p>
    <p class="alert">
      {{
        'Check retention time of assets in Digital Evidence section or adjust package expiration date.'
          | transloco
      }}
    </p>
  </section>
</div>
<div
  *ngIf="restrictedAssetsCount"
  class="asset-warning__container"
  [class.asset-ia-warrning]="restrictedAssetsCount">
  <h6 class="asset-warning__header">
    <msi-icon
      name="ic_information"
      size="24"
      class="alert"></msi-icon>
    <p class="alert">{{ 'Restricted assets' | transloco }}</p>
  </h6>
  <section class="asset-warning__content alert">
    <p>
      <span class="alert">{{ 'The package contains ' | transloco }}</span>
      <span class="alert">{{ restrictedAssetsCount }}</span>
      <span
        *ngIf="restrictedAssetsCount === 1"
        class="alert">
        {{ ' asset' | transloco }}
      </span>
      <span
        *ngIf="restrictedAssetsCount > 1"
        class="alert">
        {{ ' assets' | transloco }}
      </span>
      <span class="alert">{{ ' with restricted access.' | transloco }}</span>
    </p>
  </section>
</div>
