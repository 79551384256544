/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { AssetEntity } from '@msi/emm-sdk';
import { createReducer, on } from '@ngrx/store';

import { PackageItem } from '../../../services/package/classes/package/PackageItem';
import { IPackage } from '../../../services/package/package.interfaces';
import { packageDetailsActions } from '../actions';

export interface IDetailPackageState {
  packageLoading: boolean;
  package?: IPackage;
  pkgItem?: PackageItem;
  assetsLoading: boolean;
  assetEntities: AssetEntity[];
  fullPackageAssetsLen?: number;
  selectedPackages: PackageItem[];
  packageError?: HttpErrorResponse;
}

export const initialDetailPackageState: IDetailPackageState = {
  packageLoading: false,
  assetsLoading: false,
  assetEntities: [],
  selectedPackages: [],
};

export const detailPackageReducer = createReducer(
  initialDetailPackageState,
  on(packageDetailsActions.loadPackage, (state) => ({
    ...state,
    packageLoading: true,
    packageError: undefined,
  })),
  on(packageDetailsActions.setPackageAndPkgItem, (state, action) => ({
    ...state,
    packageLoading: false,
    pkgItem: action.pkgItem,
    package: action.package,
  })),
  on(packageDetailsActions.loadPackageFailure, (state, action) => ({
    ...state,
    packageLoading: false,
    packageError: action.error,
  })),
  on(packageDetailsActions.setSelectedPackages, (state, action) => ({
    ...state,
    selectedPackages: action.selectedPackages,
  })),
  on(packageDetailsActions.loadAssets, (state) => ({
    ...state,
    assetsLoading: true,
  })),
  on(packageDetailsActions.setAssetEntities, (state, action) => ({
    ...state,
    assetsLoading: false,
    assetEntities: action.assetEntities,
  })),
  on(packageDetailsActions.loadAssetsFailure, (state) => ({
    ...state,
    assetsLoading: false,
  })),
  on(packageDetailsActions.clearPackageDetails, () => ({
    ...initialDetailPackageState,
  }))
);
