/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { EventEmitter } from '@msi/js-sdk';

import { DownloadItemDTO } from '../../DownloadItemDTO';
import { EDownloadStatus } from '../../enums';

class NewQueueTask extends EventEmitter {
  private _queue: DownloadItemDTO[] = [];
  private _downloadItems: DownloadItemDTO[] = [];
  private _parallelProcesses = 1;

  constructor(downloadItems: DownloadItemDTO[], queue: DownloadItemDTO[]) {
    super();

    this._downloadItems = downloadItems;
    this._queue = queue;
  }

  run(): DownloadItemDTO[] {
    const len = this._parallelProcesses - this._queue.length;
    const newQueue: DownloadItemDTO[] = [];

    if (len > 0) {
      for (let i = 0; i < len; i++) {
        if (this._downloadItems[i]) {
          const status: EDownloadStatus = this._downloadItems[i].status;

          if (status === EDownloadStatus.NEW || status === EDownloadStatus.PENDING) {
            newQueue.push(this._downloadItems[i]);
          }
        }
      }
    }

    return newQueue;
  }
}

export { NewQueueTask };
