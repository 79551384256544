/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TableHeaderComponent } from '@msi/cobalt';
import { Connect, Settings } from '@msi/js-sdk';
import dayjs from 'dayjs';

import { ApmTransactionMethod } from '../../../services/elastic-apm';
import { ElasticApmService } from '../../../services/elastic-apm/elastic-apm.service';
import { ESortsType } from '../../../services/filter/filter.enums';
import { ISort } from '../../../services/filter/filter.interfaces';
import { PackageItem } from '../../../services/package/classes/package/PackageItem';
import { IAdditionalInfo } from '../../../services/package/create-package.interfaces';
import { PackageService } from '../../../services/package/package.service';
import { ISettings } from '../../../settings/settings.interface';
import { UrlSettings } from '../../../settings/UrlSettings';
import { IStore } from '../../../stores/global/store.interface';
import { ActionControlsComponent } from '../../common/action-controls/action-controls.component';
import { EActionControls } from '../../common/action-controls/action-controls.enums';
import { IActionControlOption } from '../../common/action-controls/action-controls.interfaces';
import { ActionsDropdownComponent } from '../../common/actions-dropdown/actions-dropdown.component';
import { ResharePackageComponent } from '../../reshare-package/reshare-package.component';

interface IFieldsName {
  title: ESortsType | null;
  agencyRecord: ESortsType | null;
  agencyName: ESortsType | null;
  updated: ESortsType | null;
  validTo: ESortsType | null;
}

@Component({
  selector: 'pp-packages-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class PackagesTableComponent {
  @Input()
  packages: PackageItem[] = [];

  @Input()
  loading = false;

  @Input()
  statusCode: number = null;

  @Input()
  readAuditLogsPermission: boolean;

  @Output()
  loadMore: EventEmitter<void> = new EventEmitter();

  @Output()
  sort: EventEmitter<ISort> = new EventEmitter();

  @Output()
  reload: EventEmitter<void> = new EventEmitter();

  @ViewChild('reshareComponent', { static: false })
  reshareComponent: ResharePackageComponent;

  @ViewChild('sharedWithColumn', { static: false })
  sharedWithColumn!: TableHeaderComponent;

  @ViewChildren('v2actionsDropdown')
  v2ActionsDropdown: QueryList<ActionsDropdownComponent>;
  @ViewChildren('v1actionsDropdown')
  v1ActionsDropdown: QueryList<ActionControlsComponent>;

  @Connect<IStore>('isUnauthSharingEnabled')
  isUnauthSharingEnabled: boolean;

  sortable = true;
  selectedPackageItem: PackageItem;
  additionalInfo: IAdditionalInfo[];
  indeterminate = false;
  selectAll = false;

  fieldsName: IFieldsName = {
    title: null,
    agencyRecord: null,
    agencyName: null,
    updated: null,
    validTo: null,
  };

  constructor(
    private _packageService: PackageService,
    private _elasticApmService: ElasticApmService,
    private _settings: Settings<ISettings>
  ) {}

  getActionOptions(packageItem: PackageItem): IActionControlOption[] {
    let options: IActionControlOption[] = [];

    if (packageItem.canReshare() && this.isUnauthSharingEnabled) {
      options = [{ id: EActionControls.RESHARE, title: 'Share package' }];
    } else if (packageItem.canUpdateReshare()) {
      options = [
        { id: EActionControls.UPDATE_RESHARE, title: 'Modify reshared package' },
      ];
    }
    options.push({ id: EActionControls.VIEW_AUDIT_LOG, title: 'View audit log' });

    return options;
  }

  onLoadMore(): void {
    this.loadMore.emit();
  }

  @ApmTransactionMethod('home page sort')
  onSort(field: string, order: ESortsType) {
    Object.keys(this.fieldsName).forEach((name) => (this.fieldsName[name] = null));

    this.fieldsName[field] = order;

    // Cobalt bug
    if (order === ESortsType.DEC) {
      order = ESortsType.DESC;
    }

    const value: ISort = { field, order };

    this.sort.emit(value);
  }

  async onViewAuditLog(pkg: PackageItem): Promise<void> {
    const env = this._settings.get<string>('PLATFORM.AZURE_ENV' as any);
    const queryParams = {
      packageId: pkg.id,
      application:
        this._settings.get<string>('AUDIT_LOG_APPLICATION_NAME') || 'ccsharing',
      startDate: dayjs(0).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: 'now',
    };
    const auditLogUrl = UrlSettings.getAuditViewerUrl(env, queryParams);

    window.open(auditLogUrl, '_blank');
  }

  async onAction([name, , packageItem]): Promise<void> {
    if (name === EActionControls.RESHARE || name === EActionControls.UPDATE_RESHARE) {
      this.selectedPackageItem = packageItem;

      this.additionalInfo =
        await this._packageService.getAdditionalInfoFromPackageForReshare(
          packageItem.getProps()
        );

      setTimeout(() => {
        this.reshareComponent.open();
      });

      this._elasticApmService.track('home page action ' + name);
    } else if (name === EActionControls.VIEW_AUDIT_LOG) {
      this.onViewAuditLog(packageItem);
    }
  }

  defineRouterLink(packageItem: PackageItem): string[] {
    if (packageItem.isExpired) {
      return null;
    } else if (packageItem.link) {
      if (packageItem.isOpenLink()) {
        return ['public/' + packageItem.link];
      } else if (packageItem.isAccountLogin()) {
        return ['packages/' + packageItem.link];
      }
    }
  }

  onReshareDone(): void {
    this.reload.emit();
  }

  closeDropdownInUnfocusedRow(pkgId: string): void {
    const openedPanelInDifferentRow =
      this.v1ActionsDropdown.find((a) => a.dropdown.panelOpen) ||
      this.v2ActionsDropdown.find((a) => a.dropdown.panelOpen);
    if (
      openedPanelInDifferentRow instanceof ActionsDropdownComponent &&
      openedPanelInDifferentRow.rowId !== pkgId
    ) {
      openedPanelInDifferentRow.dropdown.close();
    } else if (
      openedPanelInDifferentRow instanceof ActionControlsComponent &&
      openedPanelInDifferentRow.data.id !== pkgId
    ) {
      openedPanelInDifferentRow.dropdown.close();
    }
  }

  isDropdownOpenedOnTheSameRow(pkgId: string): boolean {
    const openedPanelSameRow: ActionControlsComponent | ActionsDropdownComponent =
      this.v2ActionsDropdown?.find((a) => a.dropdown.panelOpen && a.rowId === pkgId) ||
      this.v1ActionsDropdown?.find((a) => a.dropdown.panelOpen && a.data.id === pkgId);
    return !!openedPanelSameRow;
  }
}
