<div
  *ngIf="pkg.persons.length"
  class="pp-detail-package-person">
  <h2
    id="pp-detail-package-persons"
    class="pp-detail-package-person__header">
    {{ 'Persons ' | transloco }}({{ pkg.persons.length }})
  </h2>
  <ng-template
    ngFor
    let-item
    [ngForOf]="pkg.persons"
    let-i="index">
    <ng-container
      *ngTemplateOutlet="
        childItem;
        context: { $implicit: { person: item, idx: i + 1, len: pkg.persons.length } }
      "></ng-container>
  </ng-template>
</div>

<ng-template
  #childItem
  let-item>
  <div class="pp-detail-package-person__item">
    <div class="pp-row-item">
      <div class="pp-detail-package-person__item__img">
        <div
          class="image"
          [class.image--default]="item.person.isDefaultThumb"
          [ngStyle]="{ 'background-image': 'url(' + item.person.thumb + ')' }"></div>
      </div>
      <div class="pp-detail-package-person__item__descr">
        <div class="pp-detail-package-person__item__descr__count"
          >{{ 'Person ' | transloco }}{{ item.idx }}{{ ' of ' | transloco }}{{ item.len }}</div
        >
        <div
          *ngIf="item.person.kind"
          class="pp-detail-package-person__item__descr__title"
          >{{ item.person.kind | transloco }}</div
        >
        <div
          *ngIf="item.person.fullName"
          class="pp-detail-package-person__item__descr__title"
          >{{ item.person.fullName }}</div
        >
        <div
          *ngIf="item.person.dob"
          class="pp-detail-package-person__item__descr__subtitle first"
          >{{ 'DOB: ' | transloco }}{{ item.person.dob }}</div
        >
        <div
          *ngIf="item.person.location"
          class="pp-detail-package-person__item__descr__subtitle"
          >{{ item.person.location }}</div
        >
      </div>
    </div>
  </div>

  <div
    *ngIf="informationPresent(item.person)"
    class="pp-detail-package-person__info">
    <h2>{{ 'Information' | transloco }}</h2>
    <div class="pp-detail-package-person__info__row">
      <div
        *ngIf="item.person.driverLicense"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Driver License #' | transloco }}</h3>
        <span>{{ item.person.driverLicense }}</span>
      </div>
      <div
        *ngIf="item.person.expirationDate"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Expiration date' | transloco }}</h3>
        <span>{{ item.person.expirationDate }}</span>
      </div>
      <div
        *ngIf="item.person.ssn"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'SSN' | transloco }}</h3>
        <span>{{ item.person.ssn }}</span>
      </div>
      <div
        *ngIf="item.person.localId"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Local ID' | transloco }}</h3>
        <span>{{ item.person.localId }}</span>
      </div>
      <div
        *ngIf="item.person.stateId"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'State ID' | transloco }}</h3>
        <span>{{ item.person.stateId }}</span>
      </div>
      <div
        *ngIf="item.person.fbiNumber"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'FBI Number' | transloco }}</h3>
        <span>{{ item.person.fbiNumber }}</span>
      </div>
      <div
        *ngIf="item.person.race"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Race' | transloco }}</h3>
        <span>{{ item.person.race }}</span>
      </div>
      <div
        *ngIf="item.person.ethnicity"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Ethnicity' | transloco }}</h3>
        <span>{{ item.person.ethnicity }}</span>
      </div>
      <div
        *ngIf="item.person.sex"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Sex' | transloco }}</h3>
        <span>{{ item.person.sex }}</span>
      </div>
      <div
        *ngIf="item.person.eyes"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Eyes' | transloco }}</h3>
        <span>{{ item.person.eyes }}</span>
      </div>
      <div
        *ngIf="item.person.hair"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Hair' | transloco }}</h3>
        <span>{{ item.person.hair }}</span>
      </div>
      <div
        *ngIf="item.person.height"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Height' | transloco }}</h3>
        <span>{{ item.person.height }}</span>
      </div>
      <div
        *ngIf="item.person.weight"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Weight' | transloco }}</h3>
        <span>{{ item.person.weight }}</span>
      </div>
      <div
        *ngIf="item.person.aliases && item.person.aliases.length"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Aliases' | transloco }}</h3>
        <ol>
          <li *ngFor="let alias of item.person.aliases">
            {{ alias }}
          </li>
        </ol>
      </div>
      <div
        *ngIf="item.person.scarsMarksTattoos && item.person.scarsMarksTattoos.length"
        class="pp-detail-package-person__info__row__cell">
        <h3>{{ 'Tattoo / Scar' | transloco }}</h3>
        <ol>
          <li *ngFor="let alias of item.person.scarsMarksTattoos">
            {{ alias }}
          </li>
        </ol>
      </div>
      <ng-template [ngIf]="item.person.phones && item.person.phones.length">
        <div
          *ngFor="let phone of item.person.phones"
          class="pp-detail-package-person__info__row__cell">
          <h3>{{ phone.type | transloco }}</h3>
          <span>{{ phone.number }}</span>
        </div>
      </ng-template>
    </div>
  </div>

  <pp-detail-package-chainofcustody
    [chainOfCustody]="item.person.chainOfCustody"></pp-detail-package-chainofcustody>
</ng-template>
