<ng-container>
  <div class="date-range">
    <section
      class="date-range__input msi-input"
      [class.border-error]="!currentValue && inputDateTouchedState">
      <div
        class="date-range__input-info"
        (click)="toggleCalendar($event)">
        <span
          class="date-range__input-value"
          [class.placeholder]="!currentValue">
          {{ currentValue || 'None' }}
        </span>
        <span class="date-range__icon">
          <msi-icon
            name="ic_date_time"
            [size]="18">
          </msi-icon>
        </span>
      </div>
      <msi-calendar
        #msiCalendar
        class="msi-calendar-size"
        [displayMonths]="1"
        outsideDays="hidden"
        [allowEmpty]="false"
        [ngClass]="{ hidden: !isCalendarOpen }"
        [quickSelectionRanges]="dateRanges"
        [allowFutureTime]="true"
        [minDate]="startDate"
        [maxDate]="endDate"
        [dateFormat]="calendarDateFormat"
        (dateChanged)="dateChanged($event)"
        (dateRangeOptionObjectChange)="setPredefinedDate($event)">
      </msi-calendar>
    </section>
  </div>
  <ng-container *ngIf="!currentValue && inputDateTouchedState">
    <span class="date-range__error">
      {{ 'Field cannot be empty' | transloco }}
    </span>
  </ng-container>
</ng-container>
