/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, Input } from '@angular/core';
import { AssetEntity } from '@msi/emm-sdk';
import { Observable } from 'rxjs';

import {
  EDigitalEvidenceGroupProperty,
  IDigitalEvidenceGroupByConfig,
} from '../../services/digital-evidence-filter-options/digital-evidence-filter-options.interfaces';
import { DigitalEvidenceFilterOptionService } from '../../services/digital-evidence-filter-options/digital-evidence-filter-options.service';

@Component({
  selector: 'pp-group-digital-evidence',
  templateUrl: './group-digital-evidence.component.html',
  styleUrls: ['./group-digital-evidence.component.scss'],
})
export class GroupDigitalEvidenceComponent {
  @Input()
  assetEntities: AssetEntity[];

  readonly groupByConfig$: Observable<IDigitalEvidenceGroupByConfig> =
    this._digitalEvidenceFilterOption.groupByConfig$;
  readonly groupByOption$: Observable<EDigitalEvidenceGroupProperty[]> =
    this._digitalEvidenceFilterOption.groupingOptions$;
  readonly groupPropertyEnum = EDigitalEvidenceGroupProperty;

  constructor(private _digitalEvidenceFilterOption: DigitalEvidenceFilterOptionService) {}

  updateGroupByProperty(property: EDigitalEvidenceGroupProperty): void {
    this._digitalEvidenceFilterOption.groupByProperty(this.assetEntities, property);
  }
}
