/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Clipboard } from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TableHeaderComponent, ToastService } from '@msi/cobalt';
import { Settings } from '@msi/js-sdk';
import { TranslocoService } from '@ngneat/transloco';
import dayjs from 'dayjs';
import { Observable, Subject } from 'rxjs';

import { ApmTransactionMethod } from '../../../services/elastic-apm';
import { ElasticApmService } from '../../../services/elastic-apm/elastic-apm.service';
import { ESortsType } from '../../../services/filter/filter.enums';
import { ISort } from '../../../services/filter/filter.interfaces';
import { PackageItem } from '../../../services/package/classes/package/PackageItem';
import { IPackage } from '../../../services/package/package.interfaces';
import { UserService } from '../../../services/user/user.service';
import { ISettings } from '../../../settings/settings.interface';
import { UrlSettings } from '../../../settings/UrlSettings';
import { ActionControlsComponent } from '../../common/action-controls/action-controls.component';
import { EActionControls } from '../../common/action-controls/action-controls.enums';
import { IActionControlOption } from '../../common/action-controls/action-controls.interfaces';
import { ActionsDropdownComponent } from '../../common/actions-dropdown/actions-dropdown.component';
import { DeletePackageModalComponent } from '../../delete-package-modal/delete-package-modal.component';
import { ModifyPackageComponent } from '../../modify-package/modify-package.component';
import { RegeneratePackageModalComponent } from '../../regenerate-package-modal/regenerate-package-modal.component';
import { UpdatePackageContentComponent } from '../../update-package-content/update-package-content.component';
import { IFieldsName } from '../table/share-manager-table.interfaces';
import { EShareManagerTableMode } from './share-manager-table.enums';

@Component({
  selector: 'pp-share-manager-table',
  templateUrl: './share-manager-table.component.html',
  styleUrls: ['./share-manager-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareManagerTableComponent implements OnChanges {
  @Input()
  packages: PackageItem[] = [];

  @Input()
  loading = false;

  @Input()
  mode: EShareManagerTableMode = EShareManagerTableMode.LANDING;

  @Input()
  lang = 'en-US';

  @Input()
  statusCode: number = null;

  @Input()
  readAuditLogsPermission: boolean;

  @Output()
  loadMore: EventEmitter<void> = new EventEmitter();

  @Output()
  sort: EventEmitter<ISort> = new EventEmitter();

  @Output()
  refresh: EventEmitter<IPackage> = new EventEmitter();

  @ViewChild('updatePackageContent', { static: false })
  updatePackageContent: UpdatePackageContentComponent;

  @ViewChild('modifySharePackage', { static: false })
  modifySharePackage: ModifyPackageComponent;

  @ViewChild('deletePackage', { static: true })
  deletePackage: DeletePackageModalComponent;

  @ViewChild('regeneratePackage', { static: true })
  regeneratePackage: RegeneratePackageModalComponent;

  @ViewChild('receiversColumn', { static: true })
  receiversColumn!: TableHeaderComponent;

  @ViewChildren('v2actionsDropdown') v2Actions: QueryList<ActionsDropdownComponent>;
  @ViewChildren('v1actionsDropdown') v1Actions: QueryList<ActionControlsComponent>;

  sortable = true;
  shareManagerTableMode = EShareManagerTableMode;

  fieldsName: IFieldsName = {
    title: null,
    lastUpdated: null,
    description: null,
    accessBy: null,
    agency: null,
    preparedBy: null,
    expirationDate: null,
  };

  modalLoading$ = new Subject<boolean>();
  isModalLoading$: Observable<boolean>;

  constructor(
    private _elasticApmService: ElasticApmService,
    private _transloco: TranslocoService,
    private _settings: Settings<ISettings>,
    private _userService: UserService,
    private clipboard: Clipboard,
    private toastService: ToastService
  ) {
    this.isModalLoading$ = this.modalLoading$.asObservable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lang?.currentValue !== changes.lang?.previousValue) {
      this._transloco.setActiveLang(this.lang);
    }
  }

  onLoadMore(): void {
    this.loadMore.emit();
  }

  @ApmTransactionMethod('share manager sort')
  onSort(field: string, order: ESortsType) {
    Object.keys(this.fieldsName).forEach((name) => (this.fieldsName[name] = null));

    this.fieldsName[field] = order;

    // Cobalt bug
    if (order === ESortsType.DEC) {
      order = ESortsType.DESC;
    }

    const value: ISort = { field, order };

    this.sort.emit(value);
  }

  async onUpdateContent(pkg: IPackage): Promise<void> {
    this.modalLoading$.next(true);
    await this.updatePackageContent.open(pkg).then(() => {
      this.modalLoading$.next(false);
    });
  }

  async onModify(pkg: IPackage): Promise<void> {
    this.modifySharePackage.open(pkg);
  }

  async onRenew(pkg: IPackage): Promise<void> {
    this.regeneratePackage.open(pkg);
  }

  async onDelete(pkg: IPackage): Promise<void> {
    this.deletePackage.open(pkg);
  }

  onCopyID(pkg: IPackage): void {
    this.clipboard.copy(pkg.header.id);
    this.toastService.success(
      this._transloco.translate('Successfully copied to clipboard!'),
      undefined,
      {
        autoDismiss: 3000,
      }
    );
  }

  async onViewAuditLog(pkg: IPackage): Promise<void> {
    const env = this._settings.get<string>('PLATFORM.AZURE_ENV' as any);
    const queryParams = {
      packageId: pkg.header.id,
      application:
        this._settings.get<string>('AUDIT_LOG_APPLICATION_NAME') || 'ccsharing',
      startDate: dayjs(0).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: 'now',
    };
    const auditLogUrl = UrlSettings.getAuditViewerUrl(env, queryParams);

    window.open(auditLogUrl, '_blank');
  }

  isExpired(validTo: string): boolean {
    return dayjs(validTo).diff(dayjs(), 'd') <= 0;
  }

  getActionOptions(pkg: PackageItem): IActionControlOption[] {
    const options: IActionControlOption[] = [];
    const pkgProps: IPackage = pkg.getProps();

    if (
      !this.isExpired(pkgProps.header.validTo) &&
      !pkg.incidentRemoved &&
      pkg.needsUpdate
    ) {
      options.push({ id: EActionControls.UPDATE, title: 'Send updates' });
    }
    options.push({ id: EActionControls.MODIFY, title: 'Modify package' });

    if (pkgProps.header.accessType === 'openLink') {
      options.push({ id: EActionControls.RENEW, title: 'Renew unauthenticated link' });
    }

    options.push({ id: EActionControls.VIEW_AUDIT_LOG, title: 'View audit log' });
    options.push({ id: EActionControls.DELETE, title: 'Delete package' });
    options.push({ id: EActionControls.COPY_ID, title: 'Copy package ID' });

    return options;
  }

  closeDropdownInUnfocusedRow(pkgId: string): void {
    const openedPanelInDifferentRow =
      this.v1Actions.find((a) => a.dropdown.panelOpen) ||
      this.v2Actions.find((a) => a.dropdown.panelOpen);
    if (
      openedPanelInDifferentRow instanceof ActionsDropdownComponent &&
      openedPanelInDifferentRow.rowId !== pkgId
    ) {
      openedPanelInDifferentRow.dropdown.close();
    } else if (
      openedPanelInDifferentRow instanceof ActionControlsComponent &&
      openedPanelInDifferentRow.data.id !== pkgId
    ) {
      openedPanelInDifferentRow.dropdown.close();
    }
  }

  isDropdownOpenedOnTheSameRow(pkgId: string): boolean {
    const openedPanelSameRow: ActionControlsComponent | ActionsDropdownComponent =
      this.v2Actions?.find((a) => a.dropdown.panelOpen && a.rowId === pkgId) ||
      this.v1Actions?.find((a) => a.dropdown.panelOpen && a.data.id === pkgId);
    return !!openedPanelSameRow;
  }

  onAction([name, , pkg]): void {
    switch (name) {
      case EActionControls.UPDATE:
        this.onUpdateContent(pkg.getProps());
        break;
      case EActionControls.MODIFY:
        this.onModify(pkg.getProps());
        break;
      case EActionControls.RENEW:
        this.onRenew(pkg.getProps());
        break;
      case EActionControls.VIEW_AUDIT_LOG:
        this.onViewAuditLog(pkg.getProps());
        break;
      case EActionControls.DELETE:
        this.onDelete(pkg.getProps());
        break;
      case EActionControls.COPY_ID:
        this.onCopyID(pkg.getProps());
        break;
    }

    this._elasticApmService.track('share manager action ' + name);
  }

  onPackageChanged(pkg: IPackage): void {
    this.refresh.emit(pkg);
  }

  isRedirectAvailable(pkg: PackageItem): boolean {
    return (
      pkg.isCreatedByUser(
        this._userService.getUserEmail(),
        this._userService.getUserGuid()
      ) && !pkg.isExpired
    );
  }
}
