/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { createAction, props } from '@ngrx/store';

import { IFeatureFlags } from '../../../models/feature-flags/feature-flags.model';

export const clearStore = createAction('[FeatureFlags] Clear feature flags store');

export const setFeatureFlags = createAction(
  '[FeatureFlags] Set feature flags',
  props<{ flags: IFeatureFlags }>()
);

export const loadFeatureFlags = createAction('[FeatureFlags] Load feature flags');

export const loadFeatureFlagsFailure = createAction(
  '[FeatureFlags] Load feature flags failure'
);
