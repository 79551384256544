/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { EventEmitter } from '@msi/js-sdk';

import { FS } from '../../../FS';
import { DownloadItemProcess } from '../../DownloadItem/DownloadItemProcess';
import { DownloadItemDTO } from '../../DownloadItemDTO';

class DownloadItemsTask extends EventEmitter {
  private _queue: DownloadItemDTO[] = [];
  private _fs: FS;

  constructor(queue: DownloadItemDTO[], fs: FS) {
    super();

    this._queue = queue;
    this._fs = fs;
  }

  async run(): Promise<void> {
    const downloadItemProcess: DownloadItemProcess = new DownloadItemProcess(
      this._queue[0],
      this._fs
    );

    await downloadItemProcess.run();
  }
}

export { DownloadItemsTask };
