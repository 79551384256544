/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmAuthGuardService } from '@msi/commandcentral-common-header';
import { settings } from '@msi/pp-components';
import { PasswordValidatorComponent } from '@msi/pp-components';

import { AuthComponent } from './components/auth/auth.component';
import { GuestComponent } from './components/guest/guest.component';
import { GuestProtectedComponent } from './components/guest-protected/guest-protected.component';
import { MfCreatePackageComponent } from './components/mf/create-package/create-package.component';
import { MfSmComponent } from './components/mf/sm/sm.component';
import { MfSsmComponent } from './components/mf/ssm/ssm.component';
import { ShareDataSandboxComponent } from './components/sandbox-share-data/sandbox-share-data.component';
import { ShareManagerComponent } from './components/share-manager/share-manager.component';
import { SingleShareManagerPageComponent } from './components/single-share-manager/single-share-manager.component';
import { SingleViewComponent } from './components/single-view/single-view.component';
import { TestComponent } from './components/test/test.component';
import { GLOBAL_CONFIG } from './config/app.config';
import { GuardService } from './guards/guard.service';

settings.setOptions(GLOBAL_CONFIG);

const env: string = settings.get<string>('PLATFORM.AZURE_ENV' as any);
const routes: Routes = [
  {
    path: 'public/:id',
    component: GuestComponent,
  },
  {
    path: 'public/:agencyId/:id',
    component: GuestComponent,
  },
  {
    path: 'protected/:id',
    component: GuestProtectedComponent,
  },
  {
    path: 'protected/:agencyId/:id',
    component: GuestProtectedComponent,
  },
  {
    path: 'packages/:id',
    component: AuthComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  },
  {
    path: 'packages/:agencyId/:id',
    component: AuthComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  },
  {
    path: 'incident-shares/:id',
    component: SingleViewComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  },
  {
    path: 'share-manager/:id',
    redirectTo: 'incident-shares/:id',
    pathMatch: 'full',
  },
  {
    path: 'share-manager',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: 'authenticate-package',
    component: PasswordValidatorComponent,
  },
  {
    path: '',
    component: SingleViewComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  },
];

if (env === 'dev') {
  routes.push({
    path: 'test',
    component: TestComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  });
  routes.push({
    path: 'mf-sm',
    component: MfSmComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  });
  routes.push({
    path: 'mf-ssm',
    component: MfSsmComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  });
  routes.push({
    path: 'mf-create-package',
    component: MfCreatePackageComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  });
  routes.push({
    path: 'sm',
    component: ShareManagerComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  });
  routes.push({
    path: 'ssm/:id',
    component: SingleShareManagerPageComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  });
  routes.push({
    path: 'share-data-lib',
    component: ShareDataSandboxComponent,
    canActivate: [GuardService, ConfirmAuthGuardService],
  });
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
class RoutingModule {}

export { routes, RoutingModule };
