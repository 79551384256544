/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { AssetStorageType } from '@msi/emm-sdk';

import { IPackageFile } from '../package/package.interfaces';

const EMPTY = '';

class AssetItem {
  private _props: IPackageFile;
  public origin: string;
  public assetStorageType: AssetStorageType;
  public entityId: string;
  public fileId: string;
  public link: string;
  public publicShareId: string;
  public shareId: string;
  public agency: string;
  public created: string;
  public displayName: string;
  public duration: number;
  public externalLink: string;
  public importanceLevel: string;
  public mimeType: string;
  public name: string;
  public owner: string;
  public size: number;
  public sourceId: string;
  public sourceName: string;
  public state: string;
  public thumbnailUrl: string;

  constructor(props: IPackageFile) {
    this._props = props;
    this.origin = props.origin || EMPTY;
    this.assetStorageType = props.assetStorageType || null;
    this.entityId = props.entityId || EMPTY;
    this.fileId = props.fileId || EMPTY;
    this.link = props.link || EMPTY;
    this.publicShareId = props.publicShareId || EMPTY;
    this.shareId = props.shareId || EMPTY;
    this.agency = props.agency || EMPTY;
    this.created = props.created || EMPTY;
    this.displayName = props.displayName || EMPTY;
    this.duration = props.duration;
    this.externalLink = props.externalLink || EMPTY;
    this.importanceLevel = props.importanceLevel || EMPTY;
    this.mimeType = props.mimeType || EMPTY;
    this.name = props.name || EMPTY;
    this.owner = props.owner || EMPTY;
    this.size = props.size;
    this.sourceId = props.sourceId || EMPTY;
    this.sourceName = props.sourceName || EMPTY;
    this.state = props.state || EMPTY;
    this.thumbnailUrl = props.thumbnailUrl || EMPTY;
  }

  public get props(): IPackageFile {
    return this._props;
  }
  public set props(value: IPackageFile) {
    this._props = value;
  }
}

export { AssetItem };
