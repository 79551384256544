/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ENotShareableAssetType } from '../../models/share-data.models';
import { AssetVerificationService } from '../../services/asset-verification/asset-verification.service';

@UntilDestroy()
@Component({
  selector: 'ccs-assets-warnings',
  templateUrl: './assets-warnings.component.html',
  styleUrls: ['./assets-warnings.component.scss'],
})
export class AssetsWarningsComponent implements OnInit {
  notShareableAssets: Map<ENotShareableAssetType, string[]>;
  notShareableType = ENotShareableAssetType;
  displayIncompleteAssetsWarning: boolean;
  displayDirectoryAssetsWarning: boolean;

  constructor(private _assetVerificationService: AssetVerificationService) {}

  ngOnInit(): void {
    this._assetVerificationService.notShareableAssets$
      .pipe(untilDestroyed(this))
      .subscribe((notSharableAssets: Map<ENotShareableAssetType, string[]>) => {
        this.notShareableAssets = notSharableAssets;
        this.displayIncompleteAssetsWarning = !!notSharableAssets.get(
          ENotShareableAssetType.Incomplete
        )?.length;
        this.displayDirectoryAssetsWarning = !!notSharableAssets.get(
          ENotShareableAssetType.Directory
        )?.length;
      });
  }

  onCloseWarning(key: ENotShareableAssetType): void {
    if (key === ENotShareableAssetType.Incomplete) {
      this.displayIncompleteAssetsWarning = false;
    } else {
      this.displayDirectoryAssetsWarning = false;
    }
  }
}
